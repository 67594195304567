import { dataheaderRow } from "src/Component/Laborotry/Table/indexData";
import {
  SampleStepListType,
  AllStepType,
  SampleStepType,
} from "src/mockdata/laboratoryData";
export interface catagore {
  label: string;
  value: number;
}
export interface product_list {
  label: string;
  value: string | number;
}
export interface res_program_list {
  programId: number,
  nameOfProduct: string[];
  productType: string[];
  manufacturer: string[];
  applicant: string;
  date:string;
}
export interface response_programa_object {
  applicant: string;
  date: string;
  manufacturer: string;
  nameOfProduct: string[];
  productType: string;
  programId: number;
}
export interface optionbackend {
  id: number;
  name: string;
  normativeNumber: string;
}
export interface requirment_list {
  label: string;
  value: number;
}
export interface DataOrganaztionType {
  id: number;
  name: string;
}
export interface requirmentDTOList {
  requirementId: number;
  normativeIds: number[];
  testMethodIds: null;
}
export interface programma_change_product {
  productId: number;
  productNameInTestProtocol: string;
}
export interface program_data {
  productInTestProtocolList: programma_change_product[];
  productType: string;
  solutionNumber: string;
  certificationOrganId: number;
  userIds: number[];
  requirementForProgramDTOList: requirmentDTOList[];
}
export const create_programa = {
  requirementId: 0,
  normativeIds: [0],
  testMethodIds: null,
};
export const SampleStepOne: SampleStepType = {
  title: "Добавить испытание",
  title1: "",
  formTypeCatagorie: "add_name_products",
  list: [
    {
      id: 1,
      label: "Наименование продукции",
      type: "change_product",
      key: "productId",
      placholder: "Оборудование для видеоконференций модели CS-BAR-K9",
    },
    {
      id: 2,
      label: "Тип продукции",
      type: "input",
      key: "productType",
      placholder: "",
    },
  ],
};
export const SampleStepTwo: SampleStepType = {
  title: "Определяемые показатели",
  title1: "Выбор категорий",
  formType: "full",
  formTypeCatagorie: "add_programma",
  list: [
    {
      id: 1,
      label: "Долговечность маркировки",
      type: "select",
      key: "requirmend",
      placholder: "ГОСТ 18620-86 п.7.4, п.7.5",
      decription: "Электротехнические изделия и связанное с ним оборудование",
    },
  ],
  listcatagorie: [
    {
      id: 1,
      label: "Категории",
      type: "select",
      placholder: "Требования по безопасности ТСТ",
      key: "name",
    },
    {
      id: 2,
      label: "Подкатегории",
      type: "select",
      key: "parent",
      placholder: "Параметры цифровых",
      options: "parent",
    },
  ],
};
export const SampleStepThree: SampleStepType = {
  title: "Данные продукции",
  title1: "Данные организации",
  formType: "full",
  formTypeCatagorie: "half",
  rows_number: "5",
  list: [
    {
      id: 1,
      label: "Наименование продукции",
      type: "input",
      key: "",
      placholder: "Коммутатор Cisco 12312312AD моделей",
      decription: "",
    },
    {
      id: 2,
      label: "Наименование НД на продукцию",
      type: "input",
      key: "",
      placholder: "UzTR.102-12312-3-12312",
      decription: "",
    },
    {
      id: 3,
      label: "Наименование предприятия заявителя",
      type: "input",
      key: "",
      placholder: "ГОСТ 30326-95 п.5.3",
      decription: "",
    },
    {
      id: 4,
      label: "Номер схемы сертификации",
      type: "input",
      key: "",
      placholder: "7",
      decription: "",
    },
    {
      id: 5,
      label: "Метод испытаний по",
      type: "input",
      key: "",
      placholder: "ГОСТ 1020-21301100, ГОСТ 1020-21301100, ГОСТ 1020-21301100,",
      decription: "",
    },
  ],
  listcatagorie: [
    {
      id: 1,
      label: "Наименование Органа по сертификации",
      type: "input",
      key: "nameOfCertificationOrgan",
      placholder: "“UNICON.UZ” MCHJ",
      decription: "",
    },
    {
      id: 2,
      label: "Номер реестра",
      type: "input",
      key: "numberRegister",
      placholder: "ГОСТ 30326-95 п.2.7.3",
      decription: "",
    },
    {
      id: 3,
      label: "Телефон",
      type: "input",
      key: "phone",
      placholder: "+998710230213903",
      decription: "",
    },
    {
      id: 4,
      label: "Электрон почта",
      type: "input",
      key: "email",
      placholder: "unicon@gmail.com",
      decription: "",
    },
  ],
};
export const SampleStepFour: SampleStepType = {
  title: "Задействованные сотрудники",
  title1: "",
  formTypeCatagorie: "add_name_products",
  list: [
    {
      id: 1,
      label: "Сотрудник",
      type: "userlist",
      key: "userIds",
      placholder: "Оборудование для видеоконференций модели CS-BAR-K9",
    },
  ],
};
export const AllSampleStepProgram: AllStepType[] = [
  {
    id: 1,
    stepLabel: "1. Заявитель",
    component: SampleStepOne,
  },
  {
    id: 2,
    stepLabel: "2. Изделие",
    component: SampleStepTwo,
  },
  {
    id: 3,
    stepLabel: "3. Организация и продукция",
    component: SampleStepThree,
  },
  {
    id: 4,
    stepLabel: "4.Заключение",
    component: SampleStepFour,
  },
];
export const dataTablePrograma: string[] = [
  "product_list",
  "Тип изделия",
  "Фирма производитель",
  "Заявитель",
  "Дата и время поступления образцов",
  "download",
  "delete",
];
