import './style.css'
import { Tabs } from "antd"
import type { TabsProps } from 'antd';
import Loading from 'src/Component/Loading/Loading';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "src/App";
import { normativ_list_type, test_method_list,laboratory_list, catagorie_list, requirment_list,appilance_list} from "./indexTypes";
import Normativ from "src/Component/Laborotry/Standarts/Normativ";
import Testmethod from "src/Component/Laborotry/Standarts/Testmethod";
import { StandartStore } from "src/Component/Laborotry/Standarts/StandartStore";
import Appilance from "src/Component/Laborotry/Standarts/Appilance";
import Laboratory from "src/Component/Laborotry/Standarts/Laboratory";
import Catagorie from "src/Component/Laborotry/Standarts/Catagorie";
import Warehouse from "src/Component/Organ/standart/warehouse";
import { warehouse_list_type } from "../organ/indexType";
import Requirment from "src/Component/Laborotry/Standarts/Requirment";
function LaboratoryStandart(){
  const {setheaderTitle}=useContext(UserContext)
  const [activeKey,setActiveKey]=useState<string>('1')
  const [loading,setLoading]=useState<boolean>(true)
  const [normativ_list,setNormativ_list]=useState<normativ_list_type[]>([])
  const [test_method_list,setTest_method_list]=useState<test_method_list[]>([])
  const [warehouse_list,setWarehouse_list]=useState<warehouse_list_type[]>([])
  const [appilance_list, setAppilance_list] = useState<appilance_list[]>([]);
  const [laboratory_list,setLaboratory_list]=useState<laboratory_list[]>([])
  const [catagorie_list,setCatagorie_list]=useState<catagorie_list[]>([])
  const [requirment_list,setRequirment_list]=useState<requirment_list[]>([])
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div className="text-base">Hовые ГОСТ</div>,
      children: <Normativ loading={loading} normativ_list={normativ_list} setLoading={setLoading} getNormativList={get_standarts}/>,
    },
    {
      key: '2',
      label: <div className="text-base">Cклад</div>,
      children: <Warehouse loading={loading} get_measurment_list={get_standarts} warehouse_list={warehouse_list}/>,
    },
    {
      key: '3',
      label: <div className="text-base">Mетод испытания</div>,
      children: <Testmethod loading={loading} get_test_method_list={get_standarts} test_method_list={test_method_list}/>,
    },
    {
      key: '4',
      label: <div className="text-base">Требование</div>,
      children: <Requirment loading={loading} requirment_list={requirment_list} get_requirment_list={get_standarts}/>,
    },
    {
      key: '5',
      label: <div className="text-base">Прибор</div>,
      children: <Appilance loading={loading} setLoading={setLoading} appilance_list={appilance_list} getApplianceList={get_standarts}/>,
    },
    {
      key: '6',
      label: <div className="text-base">Kатегория</div>,
      children:<Catagorie loading={loading} catagorie_list={catagorie_list} get_catagoire_list={get_standarts}/>,
    },
    {
      key: '7',
      label: <div className="text-base">Лаборатория</div>,
      children:<Laboratory loading={loading} laboratory_list={laboratory_list} get_laboratory_list={get_standarts} />,
    },
  ];
  const onChange = (key: string) => {
    setActiveKey(key)
  };

  useEffect(()=>{
    setheaderTitle('Стандарты')
    if(activeKey==='1'){
      // get_normativ_list()
    }
  },[])
  async function get_standarts(){
    setLoading(false)
    // if(activeKey==='1'){const result =await StandartStore('1')
    // setNormativ_list(result)}
    switch (activeKey) {
      case '1':
        const result =await StandartStore('1')
        setNormativ_list(result)
        setLoading(true)
        break;
      case '2':
        const result2 =await StandartStore('2')
        setWarehouse_list(result2)
        setLoading(true)
        break;
      case '3':
        const result3 =await StandartStore('3')
        setTest_method_list(result3)
        setLoading(true)
        break;
      case '4':
          const result4 =await StandartStore('4')
          setRequirment_list(result4)
          setLoading(true)
          break;
      case '5':
          const result5 =await StandartStore('5')
          setAppilance_list(result5)
          console.log(result5)
          setLoading(true)
          break;
      case '6':
        const result6 =await StandartStore('6')
        setCatagorie_list(result6)
        setLoading(true)
        break;
      case '7':
        const result7 =await StandartStore('7')
        setLaboratory_list(result7)
        setLoading(true)
        break;
      default:
        break;
    }
    
  }
  useEffect(()=>{
    get_standarts()
  },[activeKey])
    return (
        <div className="w-full h-full  flex items-center justify-center">
          {
            <div className="w-full h-full py-3  bg-[#fff] rounded-3xl">
            <div className="standart_laboratoriya_wrapper pl-7 pr-8">
            <Tabs defaultActiveKey={activeKey} items={items} onChange={onChange} />
            </div>
            </div>
          }
        </div>
    )
}
export default LaboratoryStandart