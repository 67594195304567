import "./Statement.css";
import style from './style.module.css'
import { useContext, useEffect, useState } from "react";
import { DatePicker,Modal,Dropdown, Empty, Input, Select } from "antd";
import type { MenuProps } from 'antd';
import {EyeOutlined,EditOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import {RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
import Search from "src/Component/Search/Search";
import Filter from "src/Component/filter/Filter";
import { DropDownDataStatementList,type_statement } from "src/mockdata/uniconuser/UniconUserData";
import moment from "moment";
import { UserContext } from "src/App";
interface user {
  id:number,
  firstName:string
  lastName:string
}
interface user_list{
  label:string,
  value:number
}
interface statement {
  id:number,
  products:{name:string,price:number}[]
  company:string,
  applicant:string,
  schemeCertification:string
  applicantFullName:string,
  applicantAddress:string
  dateOrder:string,
  phoneNumber:string,
  numberOrder:string,
  madeIn:string,
  user:{lastName:string,firstName:string,id:number}[]
}

function Statment() {
  const navigate = useNavigate();
  const _role=localStorage.getItem('_role')
  const {setheaderTitle}=useContext(UserContext)
  const [applicantId,setApplicanId]=useState(0)
  const [statment_list, setStatment_list] = useState<statement[]>([]);
  const [type,setType]=useState<string>('')
  const [user_list,setUserList]=useState<user_list[]>()
  const [searchApplicant,setSearchApplicant]=useState<string>('')
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const items:MenuProps['items'] =_role==='EMPLOYEE_AGENCY' ? [
    {
      label: <div onClick={()=>setOpenModal(true)}><span className="text-base mr-2"><EyeOutlined /></span>Просмотр</div>,
      key: '0',
    }
  ]:[
    {
      label: <NavLink to={`/home/${1}`}><span className="text-base"><EyeOutlined /></span>Просмотр</NavLink>,
      key: '0',
    }
  ];
  const infoApplicant=()=>{

  }
  const getData = async () => {
    setLoading(false);
    const Data = await RequestToken("/order/acceptedOrders?page=0&size=100", "GET", null);
    if (Data.success) {
      setLoading(true);
      setStatment_list(Data.object?.map((item:any,index:number)=>{
        return {
          products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
          applicantAddress:item?.applicantAddress,
          applicantFullName:item?.applicantFullName,
          company:item?.company,
          dateOrder:item?.dateOrder,
          applicant:item?.applicant,
          numberOrder:item?.numberOrder,
          id:item?.id,
          madeIn:item?.madeIn,
          phoneNumber:item?.phoneNumber,
          schemeCertification:item?.schemeCertification,
          user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
            lastName:item?.lastName,firstName:item?.firstName,id:item?.id
          }}),
        }
      }));
    } else if (Data.status) {
      navigate(`/error/${Data.status}`);
    } else {
      navigate(`/error/500`);
    }
  };
  const handleChange=(parm:string,name:string)=>{
     typeSearch(parm)
  }
  async function typeSearch(parm:string){
    setLoading(false)
   if(parm){
    const response=await RequestToken(parm?.includes('order') ? parm:`/order/${parm}?page=0&size=100`)
    if(response.success){
      setStatment_list(response.object?.map((item:any,index:number)=>{
        return  {
          products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
          applicantAddress:item?.applicantAddress,
          applicantFullName:item?.applicantFullName,
          company:item?.company,
          dateOrder:item?.dateOrder,
          applicant:item?.applicant,
          numberOrder:item?.numberOrder,
          id:item?.id,
          madeIn:item?.madeIn,
          phoneNumber:item?.phoneNumber,
          schemeCertification:item?.schemeCertification,
          user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
            lastName:item?.lastName,firstName:item?.firstName,id:item?.id
          }}),
        }
      }))
      setLoading(true)
    }
   }
   else{getData()}
  }
  async function TimeTypeApplicant(parm:string){
    const response=await RequestToken(`/order/inProgress?page=0&size=100`,"GET",null)
    if(response.success){
      setStatment_list(response.object?.map((item:any,index:number)=>{
        return {
          products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
          applicantAddress:item?.applicantAddress,
          applicantFullName:item?.applicantFullName,
          company:item?.company,
          dateOrder:item?.dateOrder,
          id:item?.id,
          applicant:item?.applicant,
          numberOrder:item?.numberOrder,
          madeIn:item?.madeIn,
          phoneNumber:item?.phoneNumber,
          schemeCertification:item?.schemeCertification,
          user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
            lastName:item?.lastName,firstName:item?.firstName,id:item?.id
          }}),
        }
      }))
    }
  }
  async function Searchuser(parm:number){
    if(parm){
      setLoading(false)
      const response=await RequestToken(`/order/byUser/${parm}`,"GET",null)
    if(response.success){
      setStatment_list(response.object?.map((item:any,index:number)=>{
        return {
          products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
          applicantAddress:item?.applicantAddress,
          applicantFullName:item?.applicantFullName,
          company:item?.company,
          dateOrder:item?.dateOrder,
          id:item?.id,
          applicant:item?.applicant,
          numberOrder:item?.numberOrder,
          madeIn:item?.madeIn,
          phoneNumber:item?.phoneNumber,
          schemeCertification:item?.schemeCertification,
          user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
            lastName:item?.lastName,firstName:item?.firstName,id:item?.id
          }}),
        }    
      }))
      setLoading(true)
    }
    }else{getData()}
  }
  async function SearchApplicant(parm:string,type?:string){
    setLoading(false)
    setSearchApplicant(parm)
    if(parm){
     if(type==='date'){
      const response=await RequestToken(`/order/byDate/${moment(parm).format('DD-MM-YYYY')}`,"GET",null)
      if(response.success){
        setStatment_list(response.object?.map((item:any,index:number)=>{
          return {
            products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
            applicantAddress:item?.applicantAddress,
            applicantFullName:item?.applicantFullName,
            company:item?.company,
            dateOrder:item?.dateOrder,
            applicant:item?.applicant,
            numberOrder:item?.numberOrder,
            id:item?.id,
            madeIn:item?.madeIn,
            phoneNumber:item?.phoneNumber,
            schemeCertification:item?.schemeCertification,
            user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
              lastName:item?.lastName,firstName:item?.firstName,id:item?.id
            }}),
          }
        }))
      }
      setLoading(true)
     }
     else{
      const response=await RequestToken(`/order/byApplicant/${parm}`,"GET",null)
      if(response.success){
        setStatment_list(response.object?.map((item:any,index:number)=>{
          return {
            products:item?.products?.map((item:{name:string,price:number})=>{return {name:item.name,price:item.price}}),
            applicantAddress:item?.applicantAddress,
            applicantFullName:item?.applicantFullName,
            company:item?.company,
            dateOrder:item?.dateOrder,
            applicant:item?.applicant,
            numberOrder:item?.numberOrder,
            id:item?.id,
            madeIn:item?.madeIn,
            phoneNumber:item?.phoneNumber,
            schemeCertification:item?.schemeCertification,
            user:item?.user?.map((item:{lastName:string,firstName:string,id:number})=>{return {
              lastName:item?.lastName,firstName:item?.firstName,id:item?.id
            }}),
          }
        }))
      }
      setLoading(true)
     }
    }
    else{
      getData()
    }
  }
  async function get_user_list(){
    const response_user_list=await RequestToken('/user/getUsersWithoutSuperAdmin',"GET",null)
    if(response_user_list.success){
      setUserList(response_user_list?.object?.map((item:user,index:number)=>{return {label:item.lastName+" "+item.firstName,value:item.id}}))
    }
  }
  useEffect(() => {
    getData();
    get_user_list()
    setheaderTitle('Все заявления')
  }, []);
  useEffect(()=>{
  if(type==='update'){
    navigate(`/home/${applicantId}`)
  }
  },[type])
  return (
    <div className="Ariza w-100 h-100">
      <Modal open={openModal} onCancel={()=>setOpenModal(false)}
      onOk={()=>setOpenModal(false)} centered footer={null}>
          <div className="w-full text-xl font-medium my-3">
            O заявлении
          </div>
          <div className="Modal_wrapper_info_body_product_row">
              <div className="Modal_wrapper_info_body_product_row_left">
                Ф.И.О и подпись сдавшего лица:
              </div>
              <div className="Modal_wrapper_info_body_product_row_right">
               {statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.user?.length>0 ? statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.user?.map((item:{firstName:string,lastName:string},indexuser:number)=>{
                return (
                  <div className="w-full" key={indexuser}>
                    {item.lastName } {item.firstName}
                  </div>
                )
               }):"В отчет не добавлен ни один сотрудник"}
              </div>
          </div>
          <div className="Modal_wrapper_info_body_product_row">
              <div className="Modal_wrapper_info_body_product_row_left">
              Цена продукта:
              </div>
          </div>
          <hr />
          {statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.products?.length>0 ? statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.products?.map((item:{name:string,price:number},indexuser:number)=>{
                return (
                  <div className="Modal_wrapper_info_body_product_row">
                  <div className="Modal_wrapper_info_body_product_row_left">
                    {item.name}
                  </div>
                  <div className="Modal_wrapper_info_body_product_row_right">
                    {item.price}
                    </div> 
              </div>
                )
               }):"Товар недоступен"}
               <hr />
          <div className="Modal_wrapper_info_body_product_row">
              <div className="Modal_wrapper_info_body_product_row_left">
                Номер решения
              </div>
              <div className="Modal_wrapper_info_body_product_row_right">
               {statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.schemeCertification||"Hомер решения не указан"}
              </div>
          </div>
          <div className="Modal_wrapper_info_body_product_row">
              <div className="Modal_wrapper_info_body_product_row_left">
                 Адрес заявителя
              </div>
              <div className="Modal_wrapper_info_body_product_row_right">
               {statment_list?.filter((item:statement)=>{
                if(item.id===applicantId){
                  return item
                }
               })[0]?.applicantAddress||"Hомер решения не указан"}
              </div>
          </div>
          <div className="w-full flex justify-end">
            <button className="px-3 py-2 rounded-md bg-[#1677FF] text-[#fff] " onClick={()=>setOpenModal(false)}>Понятно</button>
          </div>
      </Modal>
      <div className="Ariza_header flex items-center">
        <Input allowClear placeholder="Поиск" onChange={(e)=>SearchApplicant(e.target.value)} className=" ml-2.5" style={{width:"260px",height:"45px"}}/>
        <Filter style={{width:"160px",marginLeft:"10px",height:"45px",paddingLeft:'10'}} data={type_statement} HandleChange={handleChange}/>
        <Select allowClear placeholder="Сотрудники" options={user_list} style={{width:'280px'}} className="h-11  ml-2.5" onChange={(value:number)=>Searchuser(value)}/>
        {/* <DatePicker  style={{height:'44px'}} className="ml-2.5" allowClear placeholder="Выберите дату" onChange={(date,dateString)=>SearchApplicant(dateString,'date')}/> */}
      </div>
     {
        loading ?
        <div className="Ariza_wrapper_box">
          <div className="Ariza-box ">
           <table className={`w-full ${style.Table}`}>
             <thead>
                <tr>
                  <th className={style.Table_th} style={{width:'50px'}}>Рег.№ п/п</th>
                  <th className={style.Table_th}>Наименование Продукта</th>
                  <th className={style.Table_th}>Фирма производитель</th>
                  <th className={style.Table_th}>Заявитель имя</th>
                  <th className={style.Table_th_date}>Дата и время поступления образцов</th>
                  <th className={style.Table_th}>заявитель</th>
                  <th className={style.Table_th}>Номер телефона</th>
                  <th className={style.Table_th}>Номер заказ</th>
                {_role==='SUPER_ADMIN' ?   null:<th className={style.Table_th}></th>}
                </tr>
              </thead> 

              <tbody> 
                {
                  statment_list?.length>0 ? statment_list?.map((item:statement,index:number)=>{
                    return (
                     <tr key={index}>
                      <td className={style.Table_td}>{index+1}</td>
                      <td className={style.Table_td}>{item.products?.map((item:{name:string,price:number},index:number)=>{
                        return (
                          <div key={index}>{item.name}</div>
                        )
                      })}</td>
                      <td className={style.Table_td}>{item.company}</td>
                      <td className={style.Table_td}>{item.applicantFullName}</td>
                      <td className={style.Table_td_date}>{moment(item.dateOrder).format('DD-MM-YYYY')}</td>
                      <td className={style.Table_td}>{item?.applicant}</td>
                      <td className={style.Table_td}>{item.phoneNumber}</td>
                      <td className={style.Table_td}>{item.numberOrder}</td>
                     {_role==='SUPER_ADMIN' ?    null:<td className={style.Table_td}><NavLink to={`/home/${1}`}><span className="text-base"><EyeOutlined /></span></NavLink></td>}
                    </tr>
                    )
                  }):<tr>
                    <td colSpan={7} className="text-center"><Empty description="Заявления недоступны"/></td>
                  </tr>
                }
              </tbody>
           </table> 
         </div>
        </div>:<div style={{height:'90%'}} className="w-full flex items-center justify-center">
        <Loading/>
        </div>
     }
    </div>
  );
}
export default Statment;
