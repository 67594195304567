import { useContext, useEffect, useState } from "react";
import { Checkbox, Empty, Input, message, Popconfirm, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Step_seven_page,user_type,
  Step_seven_page_certification,
  Step_seven_page_type,organazation_list_type,
  props,certificateDTO,step_seven_data_type,new_certificat_obj,productInFormOfCertificateObject,
  Step_seven_page_cert,Step_seven_type
} from "./StepSevenType";
import Loading from "src/Component/Loading/Loading";
import SelectIndicator from "src/Component/Select/Select";
import { DeleteOutline } from "@mui/icons-material";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useParams,useNavigate } from "react-router";
import DataPicker from "src/Component/DatePickerFolder/DataPicker/DataPicker";
import moment from "moment";
import { UserContext } from "src/App";
const {TextArea}=Input
function StepSeven({type}:props) {
  const {id}=useParams()
  const navigate=useNavigate()
  const {setheaderTitle}=useContext(UserContext)
  const [loading, setLoading] = useState<boolean>(true);
  const [Delete, setDelete] = useState<boolean>(false);
  const [certification_ID,setCertification_ID]=useState<number>(1)
  const [organazation_list,setOrganazation_list]=useState<organazation_list_type[]>([])
  const [user_list,setUser_list]=useState<user_type[]>([])
  const [product_list,setProducts_List]=useState<user_type[]>([])
  const [laboratory_list,setLaboratory_List]=useState<user_type[]>([])
  const [res_cert_list,setRes_cer_list]=useState<Step_seven_type[]>([])
  const [step_seven_data,setStep_Seven_Step_data]=useState<step_seven_data_type>({
  certificationOrganId: 0,
  orderId: 0,
  directorId: 0,
  expertId: 0,
  certificateDTOList: [
    {
      productInFormOfCertificateList:[],
      isValidUntil:false,
      fromDate: "",
      validUntil: "",
      amount: "",
   //   actOfSelectionId:0,
      laboratoryId: 0,
      infoAboutActOfInspectionOfProduction: "",
      inspectionControl: "",
      specialMarks: "",
      markOfConformity: "",
      numberOfBlank: "",
      registrationOfGovernmentRegistry: "",
      visibleNumberInCertificate: false,
      application: false
    }
  ]
})
  const [mock_data,setMock_data]=useState({
    numberOfRegistir:"",
    numberOrder:"",
    schemeCertification:"",
    applicant:"",
    applicantAddress:"",
    characteristics:"",
    manufacturer:[],
    normativ:[]
  })
  const option ={
    Null:null,
    organazation:organazation_list.map((item:organazation_list_type)=>{return {label:item.nameOfCertificationOrgan,value:item.id}}),
    user_list:user_list,
  }
  function NewCertification() {
   const new_certificat={...step_seven_data}
   new_certificat.certificateDTOList=[...new_certificat.certificateDTOList,{...new_certificat_obj}]
   setStep_Seven_Step_data(new_certificat)
  }
  function Remove(id: number) {
   if(Delete){
    const new_certificat={...step_seven_data}
    new_certificat.certificateDTOList=new_certificat.certificateDTOList.filter((item:certificateDTO,index:number)=>index!==id)
    setStep_Seven_Step_data(new_certificat)
   }
  }
  const confirm = (e: any) => {
    setDelete(true)
    Remove(certification_ID)
  };
  const cancel = (e: any) => {
   // message.error("Click on No");
  };
  function HandleChange(parm: string|number|boolean, name: string, id?: number,type?:string) {
    if(id!==undefined){
     if(typeof parm!=='boolean'){ setStep_Seven_Step_data({...step_seven_data,
      certificateDTOList:step_seven_data.certificateDTOList.map((item:certificateDTO,index:number)=>{
        if(index===id){
          if(type==='number' && typeof parm==='string'){return {...item,[name]:parseInt(parm)}}
          else {return {...item,[name]:parm}}
        }
        else {return item}
      })})}
     else if(typeof parm==='boolean'){
      if(name==='isValidUntil'){
        setStep_Seven_Step_data({...step_seven_data,
          certificateDTOList:step_seven_data.certificateDTOList.map((item:certificateDTO,index:number)=>{
            if(index===id){
              return {...item,validUntil:parm?null:"",isValidUntil:parm}
            }
            else {return item}
          })})
      }
      else {setStep_Seven_Step_data({...step_seven_data,
      certificateDTOList:step_seven_data.certificateDTOList.map((item:certificateDTO,index:number)=>{
        if(index===id){
          return {...item,[name]:parm}
        }
        else {return item}
      })})}
     }
    }
    else if(typeof parm!=='boolean') {
       if(typeof parm==='number'){
        if(name==='certificationOrganId'){
          setStep_Seven_Step_data({...step_seven_data,certificationOrganId:parm})
          setMock_data({...mock_data,numberOfRegistir:organazation_list.filter(item=>item.id===parm)[0].numberRegister})
         }
         else{
          setStep_Seven_Step_data({...step_seven_data,[name]:parm})
         }
       }
    }
  }
  const LabelProduct = (id:number) =>  {
    return product_list.find((item:user_type)=>item.value === id)?.label || 'продукт'
  }
  function HandleChangeProduct(parm:string,id:number,index:number){
     const newcertificat=step_seven_data?.certificateDTOList?.map((item:certificateDTO,indexCert:number)=>{
      if(indexCert===id){
        return {...item,productInFormOfCertificateList:item.productInFormOfCertificateList?.map((itemproduct:productInFormOfCertificateObject,indexProduct:number)=>{
          if(indexProduct===index){
            return {...itemproduct,productNameInFormOfCertificate:parm}
          }
          else{
            return itemproduct
          }
        })}
      }
      else{ return item }
     })
     setStep_Seven_Step_data({...step_seven_data,certificateDTOList:newcertificat})
  }
  function handleModeTags(parm:number,name:string,id?:number){
    if(step_seven_data.certificateDTOList[id||0].productInFormOfCertificateList.filter((item:productInFormOfCertificateObject)=>item.productId===parm)?.length>0){
      errorNotifacion()
    }
    else{
      const newcertificat=step_seven_data.certificateDTOList.map((item:certificateDTO,index:number)=>{
        if(index===id){
          return {...item,productInFormOfCertificateList:[...item.productInFormOfCertificateList,{productId:parm,productNameInFormOfCertificate:''}]}
        }
        else {return item}
      })
      setStep_Seven_Step_data({...step_seven_data,certificateDTOList:newcertificat})
    }
  }
  function RenderPage(type: string, key: string,id?:number, description?: string,options?:string,Value?:string) { 
    switch (type) { 
      case "input":
        return <Input required={true} style={{ width: "350px", height: "45px" }} className={Value==='auto' ? 'cursor-no-drop':""} name={key} 
        value={Value==='auto' ? (mock_data as any)[key]:(step_seven_data as any)[key]} onChange={(e)=>HandleChange(e.target.value,key,id)} disabled={Value==='auto' ? true:false}/>;
     case "number_input":
        return <Input type="number" required={true} style={{ width: "350px", height: "45px" }} className={Value==='auto' ? 'cursor-no-drop':""} name={key} 
        value={Value==='auto' ? (mock_data as any)[key]:(step_seven_data as any)[key]} onChange={(e)=>HandleChange(e.target.value,key,id,'number')}/>;
      case "select":
        return (
          <Select
            onChange={(value:string|number)=>HandleChange(value,key)}
            options={(option as any)[options||"null"]}
            style={{ width: "350px", height: "45px" }}
          />
        );
      case "checkbox":
        return (
          <div className="w-full flex">
            <Checkbox  style={{ transform: "scale(1.3)" }} id={`${description}+${id}`} onChange={(e)=>HandleChange(e.target.checked,key,id,'list')}
             checked={(step_seven_data.certificateDTOList[id||0] as any)[key]}/>
            <label htmlFor={`${description}+${id}`} className="ml-4 cursor-pointer">{description||null}</label>
          </div>
        );
      case "textarea":
         return (
          <TextArea required={true} rows={6} style={{ width: "350px", height: "245px" }}/>
          );
     case 'mode_tags':
      return <Select mode="multiple" allowClear value={(mock_data as any)[key]} options={(mock_data as any)[key]}
      style={{ width: "350px", height: "45px" }} disabled={true}/>
      case 'date':
        return <DataPicker handleChange={HandleChange} name={key} id={id}  />
      case 'unlockdate':
          return <DataPicker handleChange={HandleChange} name={key} id={id}  disabled={step_seven_data.certificateDTOList[id||0].isValidUntil}/>
      case 'laboratory':
        return <Select options={laboratory_list} onChange={(value:number)=>HandleChange(value,key,id)} style={{ width: "350px", height: "45px" }}/>
      case 'product':
        return  <div className="w-96">
          {step_seven_data.certificateDTOList[id||0]?.productInFormOfCertificateList?.map((itemProduct:productInFormOfCertificateObject,index:number)=>{
            return (
              <div className="w-full flex " key={index}>
                <div className="w-1/2 "><Input disabled className="w-full h-11" value={LabelProduct(itemProduct.productId)}/></div>
                <div className="w-1/2 "><Input className="w-full h-11" value={itemProduct.productNameInFormOfCertificate}
                onChange={(e)=>HandleChangeProduct(e.target.value,id||0,index)}/></div>
              </div>
            )
          })}
        <Select onChange={(value:number)=>handleModeTags(value,key,id)}  options={product_list} style={{ width: "350px", height: "45px" }}/>
        </div>
      // case 'normativ':
      //   return <AddBox Handle={handleModeTags} id={id} name={key} normativ_list={normativ_list} />
          default:
        break;
  }}
  function errorNotifacion(){
    Swal.fire({
      title:"Осторожность",
      text:"Нельзя назвать один и тот же товар дважды",
      icon:'warning'
    })
  }
  async function get_organazation_list(){
    
    const response_organaztion_list=await RequestToken("/certificationOrgan?page=0&size=100","GET",null)
    if(response_organaztion_list.success){
      setLoading(true)
      setOrganazation_list(response_organaztion_list.object.map((item:organazation_list_type)=>{
        return item
      }))
    }
    else{
      setLoading(true)
      Swal.fire({
        title:response_organaztion_list.response.data.message||response_organaztion_list.response.message
      })
    }
  }
  async function get_laboratory_list() {
     const response_laboratory_list=await RequestToken('/laboratory?page=0&size=100')
     if(response_laboratory_list.success){
      get_organazation_list()
      setLaboratory_List(response_laboratory_list.object.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}}))
     }
  }
  async function get_act() {
    const response_act=await RequestToken(`/actOfSelection/${id}`)
    if(response_act.success){
      setMock_data({...mock_data,characteristics:response_act.object.characteristics,
      normativ:response_act.object.normative.map((item:string)=>{return {label:item,value:item}})})
    }
 }
  async function get_user_list(){
    const response_user_list=await RequestToken('/user/getUsersWithoutSuperAdmin','GET',null)
    if(response_user_list.success){ get_laboratory_list()
     setUser_list(response_user_list.object.map((item:{firstName:string,lastName:string,id:number})=>{return {label:item.lastName+" "+item.firstName,value:item.id}}))
    }
  }
  async function GetApplicant(id:number){
    setLoading(false)
    const response_applicant=await RequestToken(`/order/${id}`,"GET",null)
    if(response_applicant.success){get_user_list()
      get_act()
    mock_data.applicant=response_applicant.object.applicant
    mock_data.applicantAddress=response_applicant.object.applicantAddress
    mock_data.schemeCertification=response_applicant.object.schemeCertification
    mock_data.numberOrder=response_applicant.object.numberOrder
    mock_data.manufacturer=response_applicant.object.products.map((item:{manufacturer:string})=>{return {label:item.manufacturer,value:item.manufacturer}})
    mock_data.normativ=response_applicant.object.products[0].normativeList
    setMock_data(mock_data)
    setProducts_List(response_applicant.object.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}}))
    }
    else if(response_applicant.response?.status===401 || response_applicant.response.status===403){
     navigate('/')
    }
    else{
      Swal.fire({
        icon:'error',
        title:response_applicant.response.data.message||response_applicant.response.message
      })
    }
  }
  async function Save(event:React.FormEvent<HTMLFormElement>){
    event.preventDefault()
    const response=await RequestToken('/formOfCertificate',"POST",step_seven_data)
    console.log(response)
    if(response.success){
      Swal.fire({
        icon:"success",
        title:response.response?.data?.message||response?.response?.message
      })
      navigate(`/home/${id}`)
    }
    else if(response.response?.status===403 || response?.response?.status===401){
      navigate('/')
    }
    else {
      Swal.fire({
        icon:"error",
        title:response.response?.data?.message||response?.response?.message
      })
    }
  }
  async function get_seven_data(){
    const res_seven_data=await RequestToken(`/formOfCertificate/${id}`,"GET",null)
    setRes_cer_list(res_seven_data.object?.map((item:any)=>{
      return {
        nameOfOrganization:item.nameOfOrganization,
    numberOfBlank:item.numberOfBlank,
    registrationOfGovernmentRegistry:item.registrationOfGovernmentRegistry,
    isApplication: item.isApplication,
    namesOfProducts:item.namesOfProducts,
    codesOfProducts:item.codesOfProducts,
    fromDate:moment(item.fromDate).format("DD-MM-YYYY"),
    validUntil:item.validUntil,
    numberRegister:item.numberRegister,
    manufacturer:item.manufacturer,
    normativeList:item.normativeList,
    schemeOfCertification:item.schemeOfCertification,
    applicant:item.applicant,
    addressOfApplicant:item.addressOfApplicant,
    document:item.document,
    laboratoryName:item.laboratoryName,
    infoAboutActOfInspectionOfProduction:item.infoAboutActOfInspectionOfProduction,
    inspectionControl:item.inspectionControl,
    specialMarks:item.specialMarks,
    markOfConformity:item.markOfConformity,
    directorOfCertificationOrgan:item.directorOfCertificationOrgan.username,
    expert:item.expert.username
      }
    }))
  }
  useEffect(()=>{
  if(type==='edit'){
    if(id){setStep_Seven_Step_data({...step_seven_data,orderId:parseInt(id)})
    GetApplicant(parseInt(id))}
  }
  else{
    get_seven_data()
  }
  setheaderTitle('Форма сертификата')
  },[])
  console.log(step_seven_data)
  return (
    <div className="w-full h-full flex items-center justify-center">
      {loading ? (
        type==='edit' ? <form className="w-full h-full" action="step_seven" onSubmit={Save}>
        {Step_seven_page.map((item: Step_seven_page_type, index: number) => {
          return (
            <div className="StepThree_wrapper_row" key={index}>
              <div className="StepThree_wrapper_row_left">{item.label}</div>
              <div className="StepThree_wrapper_row_right">
                {type==='edit' ? RenderPage(item.type, item.key,undefined,item.description,item.options,item.value):"Нет в наличии"
                }
              </div>
            </div>
          );
        })}
        <div className="w-full h-0.5 mb-2  px-2.5">
          <div className="w-full border-b-2  border-[#ccc]"></div>
        </div>
        <div className="w-full mb-3 px-2.5 ">Cписок сертификатов:</div>

        {step_seven_data?.certificateDTOList?.map((item:certificateDTO,index:number) => {
          return (
            <>
              <div className="w-full py-2 px-2.5 my-5 bg-[#d3d0d0] flex justify-between" key={index}>
                <div className="w-1/2 flex items-center justify-start text-lg">
                  {" "}
                  Сертификат {index+1}
                </div>
                <div className="w-1/2 flex items-center justify-end text-lg cursor-pointer">
                  <Popconfirm
                    title="Удалить этот сертификат"
                    description="Вы уверены, что хотите удалить этот сертификат?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="Да"
                    cancelText="Нет">
                   {type==="edit" ? <span onClick={()=>setCertification_ID(index)}
                     style={{ transform: "scale(1.2)" }}>
                      <DeleteOutline />
                    </span>:<></>}
                  </Popconfirm>
                </div>
              </div>
              {Step_seven_page_certification.map(
                (item_cert: Step_seven_page_type, index1: number) => {
                  return (
                    <div className="StepThree_wrapper_row" key={index1}>
                      <div className="StepThree_wrapper_row_left">
                        {item_cert.label}
                      </div>
                      <div className="StepThree_wrapper_row_right">
                        {type==='edit' ? RenderPage(
                          item_cert.type,
                          item_cert.key,
                          index,
                          item_cert.description,
                          item_cert.options,
                          item_cert.value
                        ):"Нет в наличии"
                        }
                      </div>
                    </div>
                  );
                }
              )}
            </>
          );
        })}
        {type==='edit' ?<div className="w-full">
          <div className="w-full flex justify-center ">
            <span
              className="text-4xl mt-3 cursor-pointer"
              onClick={NewCertification}
            >
              <PlusCircleOutlined />
            </span>
          </div>
          <div className="w-full flex justify-center text-md font-medium">
            Добавить новый сертификат
          </div>
        </div>:<></>}
        {type==='edit' ? <div className="w-full flex justify-end">
          <button className="px-3 py-2 rounded-lg mx-1 bg-[#ccc]">отмена</button>
          <input type="submit" id="step_seven" className="px-3 py-2 text-[#fff] rounded-lg mx-1 bg-[#1a56db]" value={'Сохраните'}/>
        </div>:<></>}
      </form>:<div className="w-full h-full">
        {
          res_cert_list?.length>0 ? res_cert_list?.map((itemres:any,indexTable:number)=>{
               return (
                Step_seven_page_cert.map((item:Step_seven_type,index:number)=>{
                  return (
                    <div className="StepThree_wrapper_row" key={index}>
                    <div className="StepThree_wrapper_row_left">{item.label}</div>
                    <div className="StepThree_wrapper_row_right">
                      {(itemres as any)[item.key] ?  Array.isArray((itemres as any)[item.key])? (itemres as any)[item.key].length>0 ? (itemres as any)[item.key].map((item:string)=>{
                        return (
                          <div className="w-full border-b-2 py-1">{item}</div>
                        )
                      }):<Empty description="Не найдено"/>:(itemres as any)[item.key]:"mavjud emas"}
                    </div>
                  </div>
                  )
                })
               )
           }):  Step_seven_page_cert.map((item:Step_seven_type,index:number)=>{
            return (
              <div className="StepThree_wrapper_row" key={index}>
              <div className="StepThree_wrapper_row_left">{item.label}</div>
              <div className="StepThree_wrapper_row_right">Нет в наличии</div>
            </div>
            )
          })
         
        }
      </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default StepSeven;
// return (
//   <div className="StepThree_wrapper_row" key={index}>
//   <div className="StepThree_wrapper_row_left">{item.label}</div>
//   <div className="StepThree_wrapper_row_right">
//     {(itemres as any)[item.key] ?  Array.isArray((itemres as any)[item.key])? (itemres as any)[item.key].length>0 ? (itemres as any)[item.key].map((item:string)=>{
//       return (
//         <div className="w-full border-b-2 py-1">{item}</div>
//       )
//     }):<Empty description="Не найдено"/>:(itemres as any)[item.key]:"mavjud emas"}
//   </div>
// </div>
// )

