import { Button, Modal, Select, notification, Skeleton,Image, Empty} from "antd";
import logotip from 'src/assets/image/cert_logo.jpg'
import UploadAnimation from "src/assets/animation/Upload.json";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { UploadOutlined, PlusCircleOutlined,CaretRightOutlined } from "@ant-design/icons";
import { Exit, Cancel, Add } from "src/assets/icons/laboratory";
import Swal from "sweetalert2";
import { RequestToken } from "src/Hook/Request/Request";
interface props {
  program_data: any;
  setLoading: (loading: boolean) => void;
}
interface toolDTOList {
  requirementId: number;
  nameOfProduct: string;
  attachmentIds: number[];
}
interface testingprotocolTool {
  programId: number;
  toolDTOList: toolDTOList[];
}
interface imagelistInfo {
  id:number,
  fileOriginalName:string,
  size:number,
  contentType:string,
  name:string,
}
function UploadPhotoCard({ program_data, setLoading }: props) {
  const [api, contextHolder] = notification.useNotification();
  const [imageListlinkBottom, setImageListLinkBottom] = useState<string>("");
  const [imageListInfo, setImageListInfo] = useState<imagelistInfo[]>([]);
  const [imageList, setImageList] = useState<File | null>(null);
  const [tool_id, setToolId] = useState<number | null>(null);
  const [active_key,setActive_Key]=useState<number>(0)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [productID, setProductID] = useState<number>(0);
  const [testingprotocolTool, setTestingprotocolTool] =
    useState<testingprotocolTool>({
      programId: 0,
      toolDTOList: [
        {
          requirementId: 0,
          nameOfProduct: "",
          attachmentIds: [],
        },
      ],
    });
  function handleChange(parm: string | number, key: string, id: number) {
    setTestingprotocolTool({
      ...testingprotocolTool,
      toolDTOList: testingprotocolTool.toolDTOList.map(
        (item: toolDTOList, index: number) => {
          if (index === id) {
            if (key === "nameOfProduct") {
              if (typeof parm === "number") {
                setProductID(parm);
              }
              return {
                ...item,
                nameOfProduct: program_data?.products?.filter(
                  (item: any) => item.id === parm
                )[0]?.name,
              };
            } else {
              return { ...item, [key]: parm };
            }
          } else {
            return item;
          }
        }
      ),
    });
  }
  function AddToolDTOList() {
    setTestingprotocolTool({
      ...testingprotocolTool,
      toolDTOList: [
        ...testingprotocolTool.toolDTOList,
        {
          requirementId: 0,
          nameOfProduct: "",
          attachmentIds: [],
        },
      ],
    });
  }
  function ImageUpload(files: FileList | null) {
    if (files) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const url = event.target?.result as string;
        setImageListLinkBottom(url);
      };
      setImageList(file);
      reader.readAsDataURL(file);
    }
  }
  function notifaction(message: string, status: string) {
    if (status === "success" && message) {
      api[status]({
        message: message,
      });
    } else {
      Swal.fire(
        "Ошибка с сервером",
        "Произошел сбой на сервере! Подождите несколько минут",
        "error"
      );
    }
  }

  function Cancel() {
    setIsOpenModal(false);
    setImageList(null);
    setToolId(null);
  }
  function OpenModal(id: number) {
    setIsOpenModal(true);
    setToolId(id);
  }
  async function Saved() {
    const res_save = await RequestToken(
      "/testingProtocol/tool",
      "POST",
      testingprotocolTool
    );
    if (res_save.success) {
      Swal.fire("Успех", res_save?.message, "success");
    } else {
      Swal.fire(
        "Ошибка",
        res_save?.message ||
          res_save?.response?.message ||
          "Произошла ошибка на сервере",
        "error"
      );
    }
  }
  async function UploadImageBackend() {
    if (imageList) {
      const formData = new FormData();
      formData.append("image", imageList);
      const res_attachment_number = await RequestToken(
        "/attachment/upload",
        "POST",
        formData,
        "multipart/form-data"
      );
      if (res_attachment_number.success) {
        notifaction(res_attachment_number?.message, "success");
        setTestingprotocolTool({
          ...testingprotocolTool,
          toolDTOList: testingprotocolTool.toolDTOList.map(
            (item: toolDTOList, index: number) => {
              if (index === tool_id) {
                return {
                  ...item,
                  attachmentIds: [
                    ...item.attachmentIds,
                    res_attachment_number.object,
                  ],
                };
              } else {
                return item;
              }
            }
          ),
        });
        Cancel();
        test()
      } else {
        notifaction(
          res_attachment_number?.message ||
            res_attachment_number?.response?.message,
          "error"
        );
      }
    }
  }
  async function test() {
    const res = await RequestToken("/attachment/info", "GET", null);
    if(res?.length>0){
      testingprotocolTool.toolDTOList.forEach((item:toolDTOList,index:number)=>{
        if(index===active_key){
           setImageListInfo(res?.filter((res_item:imagelistInfo,res_index:number)=>{
            if(item.attachmentIds.includes(res_item.id)){
              return res_item
            }
           }))
        }
      })
    }
  }
  useEffect(() => {
    setTestingprotocolTool({
      ...testingprotocolTool,
      programId: program_data?.id,
    });
   
  }, []);
  console.log(imageListInfo)
  console.log(active_key)
  return (
    <div className="w-full h-full bg-[#f5f5f5] flex flex-wrap justify-between">
      {contextHolder}
      <div
        style={{ width: "74%", height: "93%" }}
        className="bg-[#fff] rounded-lg "
      >
        {testingprotocolTool.toolDTOList?.map(
          (item: toolDTOList, index: number) => {
            return (
              <div key={index} onClick={()=>setActive_Key(index)} className={index===active_key ? "w-full px-2 cursor-pointer py-2 flex items-center bg-[#f5f5f5]":"w-full px-2 cursor-pointer py-2 flex items-center "}>
                <div className="p-2 w-2/5">
                  <label htmlFor={`nameOfProduct ${index}`}>
                    Выберите продукт
                  </label>
                  <Select
                    id={`nameOfProduct ${index}`}
                    className="w-full"
                    options={program_data?.products?.map(
                      (item: { name: string; id: number }) => {
                        return { label: item.name, value: item.id };
                      }
                    )}
                    onChange={(value: number) =>
                      handleChange(value, "nameOfProduct", index)
                    }
                  />
                </div>
                <div className="p-2 w-2/5">
                  <label htmlFor={`requirementId ${index}`}>
                    Требование выбрать
                  </label>
                  <Select
                    id={`requirementId ${index}`}
                    className="w-full"
                    options={program_data?.products
                      ?.find(
                        (item: {
                          testResults: {
                            requirement: { name: string; id: number };
                          };
                          id: number;
                        }) => item.id === productID
                      )
                      ?.testResults?.map(
                        (item: {
                          requirement: { id: number; name: string };
                        }) => {
                          return {
                            label: item.requirement.name,
                            value: item.requirement.id,
                          };
                        }
                      )}
                    onChange={(value: number) =>
                      handleChange(value, "requirementId", index)
                    }
                  />
                </div>
                <div className="w-1/5 p-2 flex">
                  <Button
                    className=" mt-5"
                    style={{ height: "50px" }}
                    onClick={() => OpenModal(index)}
                    icon={<UploadOutlined />}
                  >
                    Загрузка фото
                  </Button>
                <span style={{height:'50px'}} className="mt-5 text-3xl text-[#7f7f7f] flex items-center ]">{index===active_key ? <CaretRightOutlined />:null}</span>
                </div>
              </div>
            );
          }
        )}
        <hr />
        <div className="w-full my-3 text-center">
          <span onClick={AddToolDTOList} className="text-3xl cursor-pointer">
            <PlusCircleOutlined />
          </span>
        </div>
      </div>
      <div
        style={{ width: "25%", height: "93%" }}
        className="bg-[#fff] rounded-lg overflow-hidden"
      >
       {imageListInfo?.length>0 ? imageListInfo?.map((item:imagelistInfo,index:number)=>{
        if(item.contentType.includes('image')){
          return ( <div className="w-full h-28 p-2 flex">
          <div className="w-2/5 h-full"><Image style={{width:'75px',height:'75px'}} src={logotip} /></div>
          <div className="w-3/5 h-full">
            <div className="w-full h-1/2 font-semibold">{item.fileOriginalName}</div>
            <div className="w-full flex">{(item.size/1024).toLocaleString().slice(0,5)} kb 
            <div className="w-1/3"></div></div>
          </div>
        </div>)
        }
       }):<Empty description="К этому продукту не было загружено изображение результатов теста."/>}
      </div>
      <div
        className="w-full flex item-end justify-end"
        style={{ height: "7%" }}
      >
        <button
          onClick={Saved}
          className="px-3 py-2 my-1 rounded-md text-[#fff] bg-[#1677FF]"
        >
          Сохраните
        </button>
      </div>
      <Modal
        open={isOpenModal}
        centered
        onCancel={Cancel}
        cancelText={"Отмена"}
        okText={"Загрузить"}
        onOk={UploadImageBackend}
      >
        <div className="font-semibold my-3 text-lg">
          Выберите картинку и отправьте ее
        </div>
        <label
          className="h-auto flex flex-wrap justify-center cursor-pointer"
          htmlFor="upload_photo"
        >
          <Lottie
            width={100}
            height={140}
            style={{ width: "210px", height: "220px" }}
            animationData={UploadAnimation}
          />
          <div className="w-full block">
            выберите изображения следующих типов:{" "}
            <span className="font-semibold">.png, jpeg, .jpg.</span>
          </div>
        </label>
        <hr />
        <input
          type="file"
          id="upload_photo"
          accept=".png, .jpeg, .jpg"
          className="hidden"
          onChange={(e) => ImageUpload(e.target.files)}
        />
      </Modal>
    </div>
  );
}

export default UploadPhotoCard;
{
  /* <div className="w-full h-full ml-3 grid grid-flow-col grid-rows-2 gap-3">
        <div className={`w-full bg-[#fff]`}>
          {
            testingprotocolTool?.toolDTOList?.map((item:toolDTOList,index:number)=>{
              return (
                <div className="w-auto my-2 flex" key={index}>
            <Select  placeholder="выбор продукта" options={program_data?.products?.map((item:{name:string,id:number})=>{
              return {label:item.name,value:item.id}
            })} className="ml-4 w-1/2" onChange={(value:number)=>handleChange(value,'nameOfProduct',0)}/> 
            <Select className="ml-4 mr-2 w-1/2" placeholder="требование выбрать" 
            options={program_data?.products?.filter((item:any)=>item.id===productID)?.testResults?.map((item:{requirement:{name:string,id:number}},index:number)=>{
            return {label:item?.requirement?.name,value:item?.requirement?.id}
            })}/>
          </div>
              )
            })
          }
          <div className="w-full h-11 text-center text-[#000] text-2xl cursor-pointer"><span className="text-2xl" onClick={add_image_card}><PlusCircleOutlined /></span></div>
        </div>
        <div className={`w-full bg-[#fff] ${style.uploadcard_photo}`}>
          <div className={`${style.uploadcard_photo_title} flex`}>Приложение:</div>
          <div className={`${style.uploadcard_photo_row}`}>
            <div className={style.uploadcard_photo_row_col_upload}>
              <div className={style.uploadcard_photo_row_col_upload_child}>
                <span>Перетащите файл или загрузите</span>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="upload_photo2"
                  accept="image/*"
                  onChange={(e)=>ImageUpload("bottom",e.target.files)}
                />
                <label htmlFor="upload_photo2" style={{ cursor: "pointer" }}>
                  Загрузить
                </label>
              </div>
            </div>
            {imageListlinkBottom.length > 0 ? imageListlinkBottom?.map((link: string, index: number) => {
              return (
                <div className={style.uploadcard_photo_row_col} key={index}>
                  <img src={link} alt="Rasm" />
                  <span onClick={()=>Delete("bottom", index)}><Cancel color="#646A72"/></span>
                </div>
              )
            }) : <Empty />
            }
          </div>
        </div>
      </div> */
}
// function add_image_card(){
//   const newTodoList=[...testingprotocolTool?.toolDTOList]
//   for (let i = 0; i < program_data?.products.length; i++) {
//     newTodoList.push({
//       requirementId: 0,
//       nameOfProduct: "",
//       attachmentIds: []
//     })
//   }
//   setTestingprotocolTool({...testingprotocolTool,toolDTOList:[...newTodoList]})
// }
