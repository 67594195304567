import "../Styledocx.css";
import Logo from "src/assets/image/unicon.png";
import table_logo from "src/assets/image/table_logo.png";
import { useEffect, useRef,useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import style from 'src/Component/Laborotry/TablesTestReport/TablesTestReportTwo/style.module.css'
import { RequestToken } from "src/Hook/Request/Request";
import moment from "moment";
import { appliances_item, fivethtable, fourthtable, normativ_list, seventhtable, sixthtable, testMethods } from "./type";
import { fiveTableObject, fourTableObject } from "src/Component/Laborotry/TableWizzard/TableWizzardType";
import { Switch } from "antd";
interface props {
  orderID: string|number|null;
  setDownload: (download: boolean) => void;
  setLoading:(loading:boolean)=>void
}
const space = (
  <>
    {" "}
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
  </>
);
const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
function TestingProtocol({ orderID,setDownload,setLoading}: props) {
  const componentRef: any = useRef();
  const [test_protocol,setTestProtocol]=useState<any>()
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => `
    body {
      background-color: red; /* O'zingiz istagan stilni qo'shing */
    }
  `,

    documentTitle: "СЕРТИФИКАТ_СООТВЕТСТВИЯ",
    onAfterPrint:()=>setDownload(false)
  });
  async function res_Testing_doc(){
    setLoading(false)
    const res_test_protocol_doc=await RequestToken(`/testingProtocol/doc/${orderID}`,"GET",null)
    if(res_test_protocol_doc.success){
      setTestProtocol(res_test_protocol_doc.object)
      setLoading(true)
      HandlePrint() 
    }
    else{
      setLoading(true)
    }
  }
  function Handle(){
  }
  useEffect(() => {
    function Handle(){
    }
    res_Testing_doc()
  },[]);
  // useEffect(()=>{
  //  res_Testing_doc()
  // },[orderID])
  return (
   <>
    <div className="h-auto" style={{ width: "594px" }} ref={componentRef}>
       <div className="w-full" style={{height:"849px"}}>
       <table className="w-full mt-1">
        <tbody>
          <tr>
            <td style={{ border: "2px solid #000" }}>
              <img
                style={{ width: "280px", height: "90px" }}
                src={table_logo}
                alt="logotip"
              />
            </td>
            <td
              style={{ border: "2px solid #000" }}
              className="font-semibold text-xs text-center analis_application"
            >
              Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
            </td>
            <td style={{ border: "2px solid #000" }}>
              <img src={Logo} alt="logotip" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-11/12 text-sm text-center mt-2 analis_application px_12">
        Общество с ограниченной ответственностью «UNICON.UZ» Сертификационный
        испытательный центр технических средств телекоммуникаций (СИЦ ТСТ)
        Свидетельство об аккредитации № O’ZAK.SL.0180 Адрес: 100114, г. Ташкент,
        ул. Сохибкор, 2а
      </div>
      <div className="w-full flex mt-3 justify-end">
        <div className="w-1/3">
          <div className="w-full analis_application text-xs my-2">
            «УТВЕРЖДАЮ»
          </div>
          <div className="w-full analis_application text-xs my-2">
            Начальник СИЦ ТСТ{" "}
          </div>
          <div className="w-full h-5 flex my-2">
            <div
              className="w-1/2 h-5"
              style={{ borderBottom: "1px solid #000" }}
            ></div>
            <div className="w-1/2 h-5 text-xs analis_application">
              Д.А. Сафаров
            </div>
          </div>
          <div className="w-full h-5  my-2">
            <div
              className="w-1/2 h-5"
              style={{ borderBottom: "1px solid #000" }}
            ></div>
          </div>
        </div>
      </div>
      {space}
      <div className="w-full font-bold text-xl mt-3 text-center analis_application px_12">
        ПРОТОКОЛ ИСПЫТАНИЙ № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYY')}-Лб
      </div>
      <div className="w-full text-center analis_application px_12">
        от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г. 
      </div>
      <div className="w-full text-center my-2 analis_application px_12">
        ОСНОВАНИЕ ДЛЯ ПРОВЕДЕНИЯ ИСПЫТАНИЙ: Решение ОС ТСТ №
        {test_protocol?.solutionNumber}-Р от {moment(test_protocol?.solutionDate).format('DD-MM-YYYY')} г.
      </div>
      <div className="w-full my-1 analis_application px_12">
        <div className="analis_application px_12"><span className="font-semibold analis_application px_12">
          {" "}
          1 ЗАЯВИТЕЛЬ:  </span>{" "}
        {test_protocol?.applicant}</div>
        <div className="analis_application px_12">Адрес: {test_protocol?.addressOfApplicant}</div>
      </div>
      <div className="w-full my-2 font-semibold analis_application px_12">2 ОБЪЕКТ ИСПЫТАНИЙ: </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12">
          {" "}
          {space_row}2.1 Наименование и тип:
        </span>{" "}{test_protocol?.productNames?.map((item:string|null)=>{
          return (
            <span className="analis_application px_12 mx-1">{item}</span>
          )
        })}. 
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12">
          {" "}
          {space_row}2.2 Предприятие-изготовитель: 
        </span>{" "}{test_protocol?.manufacturers?.map((item:string|null)=>{
          return (
            <span className="analis_application px_12 mx-1">{item}</span>
          )
        })}
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12">
          {" "}
          {space_row}2.3 Краткая характеристика:  
        </span>{" "}{test_protocol?.characteristics}
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12">
          {" "}
          {space_row}2.4 Количество образцов и серийные номера: 
        </span>{" "}Испытаниям подверглись двух образцов Оборудования согласно Акту отбора и иденти-фикации от {moment(test_protocol?.actOfSelectionDate).format('DD-MM-YYYY')} г. 
      </div>
      <div className="w-full my-1 analis_application px_12">Серийные  {test_protocol?.serialNumbers?.map((item:string|null)=>{
          return (
            <span className="analis_application px_12 mx-1">{item ? `№ ${item}`:''}</span>
          )
        })}</div>
      <div className="w-full my-1 analis_application px_12 font-semibold">
        <span className="font-semibold italic analis_application px_12">
          {" "}
          {space_row}Примечание:  
        </span>{" "}Оборудование является персональной электронной вычислительной машиной — микро-ЭВМ и имеет универсальные функциональные возможности. Напряжения питания от сети переменного тока с напряжением 220 V, 50Hz.
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12">
          {" "}
          {space_row}2.5 Дата получения образцов на испытание: 
        </span>{" "}{test_protocol?.dateOfReceiving ? moment(test_protocol?.dateOfReceiving).format('DD-MM-YYYY'):''} г 
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12 my-3">
          {" "}
          {space_row}3 ДАТА И МЕСТО ПРОВЕДЕНИЯ ИСПЫТАНИЙ: 
        </span>{" "}
        <div className="block analis_application  ">{test_protocol?.dateOfReceiving ? moment(test_protocol?.dateOfReceiving).format('DD-MM-YYYY'):''} г. в испытательной лаборатории {test_protocol?.laboratory}
</div>
      </div>
      <div className="w-full my-1 analis_application px_12">
        <span className="font-semibold analis_application px_12 my-3">
          {" "}
          {space_row}4 ЦЕЛЬ ИСПЫТАНИЙ:
        </span>{" "}
        <span className="block analis_application px_12">{space_row}Проверка параметров (характеристик) Оборудования на соответствие требованиям, установленным в:</span>
        {
          test_protocol?.normativeList?.map((item:normativ_list|null,index:number)=>{
            return (
              <div key={index} className="w-full">
                <span className="px_12 analis_application font-semibold">{space_row}{item?.normativeNumber} </span>
                <span className="px_12 analis_application">{item?.name}</span>
              </div>
            )
          })
        }
       </div>
       </div>
       {space}
       {space}
       {space}
       {space}
       <div className="w-full" style={{height:"849px"}}>
        <table className="w-full mt-1">
        <tbody>
          <tr>
            <td style={{ border: "2px solid #000" }}>
              <img
                style={{ width: "280px", height: "90px" }}
                src={table_logo}
                alt="logotip"
              />
            </td>
            <td
              style={{ border: "2px solid #000" }}
              className="font-semibold text-xs text-center analis_application"
            >
               Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
            </td>
            <td style={{ border: "2px solid #000" }}>
              <img src={Logo} alt="logotip" />
            </td>
          </tr>
        </tbody>
      </table>
        <div className="w-full my-2">
        <span className="block analis_application font-semibold px_12">{space_row}5 ПРОГРАММА И МЕТОДЫ ИСПЫТАНИЙ:</span>
        <span className="block analis_application  px_12">{space_row} Испытания Оборудования проводились в соответствии с «Программой сертификацион-ных испытаний 
        Интерактивная сенсорная панель модели YZ-H1-65 производства компании «Yiwu International Co Ltd.», Китай согласованной с Заявителем и утвержденной руководите-лем ОС ТСТ.
        </span>
        </div>
        {
          test_protocol?.testMethodList?.map((item:normativ_list|null,index:number)=>{
            return (
              <div className="w-full" key={index}>
              <span className="analis_application block px_12">{space_row}Испытания Оборудований проводились в соответствии с методами, приведенными в:</span>
              <span className="analis_application px_12">{space_row}<span className="analis_application font-semibold px_12">ГОСТ 12.1.006-84 </span>Система стандартов безопасности труда электромагнитные поля радиочастот. Допустимые уровни на рабочих местах и требования к проведению контроля.</span>
          </div>
            )
          })
        }
      
        <div className="w-full my-3  analis_application px_12">
         <div className="font-semibold my-3">{space_row}6 УСЛОВИЯ ПРОВЕДЕНИЯ ИСПЫТАНИЙ:</div>
         <div className="w-full">{space_row}Испытания по всем параметрам проводились в нормальных климатических условиях:</div>
         <div className="w-full">{space_row}- температура окружающего воздуха  23 С – 24 С;</div>
         <div className="w-full">{space_row}- относительная влажность  46 % – 48 %;</div>
         <div className="w-full">{space_row}- атмосферное давление 96,5 kPa.</div>
         <div className="w-full">{space_row}- Измерения климатических усл	овий приведены термогигрометом модель VICTOR-230A заводской номер 096243456, дата очередной поверки 09.2023 г.</div>
        </div>
       </div>
       {space}
       {space}
       {space}
       {space}
       <div className="w-full" style={{height:"849px"}}>
       <table className="w-full mt-1">
        <tbody>
          <tr>
            <td style={{ border: "2px solid #000" }}>
              <img
                style={{ width: "280px", height: "90px" }}
                src={table_logo}
                alt="logotip"
              />
            </td>
            <td
              style={{ border: "2px solid #000" }}
              className="font-semibold text-xs text-center analis_application"
            >
              Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
            </td>
            <td style={{ border: "2px solid #000" }}>
              <img src={Logo} alt="logotip" />
            </td>
          </tr>
        </tbody>
      </table>
         <div className="w-full font-semibold analis_application mt-2 px_12">
          {space_row}7 ПЕРЕЧЕНЬ ИСПЫТАТЕЛЬНОГО ОБОРУДОВАНИЯ (ИО) И СРЕДСТВ ИЗМЕРЕНИЙ (СИ) ИСПОЛЬЗОВАННЫХ ПРИ ИСПЫТАНИЯХ
         </div>
         <div className="w-full analis_application px_12">{space_row}Перечень ИО и СИ, использованных при испытаниях приведен в таблице 1.</div>
         <div className="w-full mt-4 analis_application px_12">Таблица 1</div>
         <table className="w-full">
            <thead>
                <tr>
                    <th className="italic text-center w-16" style={{border:'1px solid #000'}}>№</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Наименование СИ</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Тип (фирма изготовитель, для импортных)</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Заводской но-мер</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Дата оче-редной поверки СИ</th>
                </tr>
            </thead>
            <tbody>
              {
                test_protocol?.appliances?.map((item:appliances_item,index:number)=>{
                  return (
                    <tr key={index} >
                    <td className="text-center w-16 analis_application" style={{border:'1px solid #000'}}>{index+1}</td>
                    <td className="text-center analis_application" style={{border:'1px solid #000'}}>{item.name}</td>
                    <td className="text-center analis_application" style={{border:'1px solid #000'}}>{item.manufacturer}</td>
                    <td className="text-center analis_application" style={{border:'1px solid #000'}}>{item.serialNumber}</td>
                    <td className="text-center analis_application" style={{border:'1px solid #000'}}>{moment(item.dateOfCertification).format("DD-MM-YYYY")}</td>
                </tr>
                  )
                })
              }
            </tbody>
         </table>
         <div className="w-full my-3">
            <div className="analis_application px_12 font-semibold">{space_row}8 УЧАСТИЕ СУБПОДРЯДЧИКА ПО ИСПЫТАНИЯМ:</div>
            <div className="w-full analis_application px_12">{space_row}Субподряд не осуществлялся.</div>
         </div>
         <div className="w-full my-3">
            <div className="analis_application px_12 font-semibold">{space_row}9 РЕЗУЛЬТАТЫ ИСПЫТАНИЙ И ЗАКЛЮЧЕНИЕ О СООТВЕТСТВИИ</div>
            <div className="w-full analis_application px_12">{space_row}Заключение о соответствии результатов испытаний представлено согласно ИН СИЦ 01 «Правила 
            принятия решения и заявление о соответствии» (таб.1 ИН СИЦ 01: Специфический риск  5% вероятность ложного принятия)</div>
            <div className="w-full analis_application px_12">{space_row}Результаты испытаний Оборудования приведены в таблицах 2 – 8.</div>
         </div>
       </div>
       {space}
         {space}
         {space}
         {space}
       <div className="w-full" style={{height:"849px"}}>
       <table className="w-full mt-1">
        <tbody>
          <tr>
            <td style={{ border: "2px solid #000" }}>
              <img
                style={{ width: "280px", height: "90px" }}
                src={table_logo}
                alt="logotip"
              />
            </td>
            <td
              style={{ border: "2px solid #000" }}
              className="font-semibold text-xs text-center analis_application"
            >
              Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
            </td>
            <td style={{ border: "2px solid #000" }}>
              <img src={Logo} alt="logotip" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full analis_application px_12">
        {space_row}Таблица 2,3.
        <table className="w-full">
            <thead>
                <tr>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Наименование проверяемых па-раметров и их ха-рактеристик</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Пункты НД на требова-ния</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Пункты НД на методы испытания</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Требование, согласно НД</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Результат  испытаний</th>
                    <th className="italic text-center analis_application px_12" style={{border:'1px solid #000'}}>Соответ-ствие требо-ванию НД и/или № ис-пытанного образца</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                </tr>
                <tr>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                    <td className="text-center" style={{border:'1px solid #000'}}>da</td>
                </tr>
            </tbody>
         </table>
      </div>
       </div>
       {/* table 4 boshlandi  */}
      {
        test_protocol?.fourthTableList!==null && test_protocol?.fourthTableList?.length>0 && test_protocol?.fourthTableList[0]!==null ?   <> {space}
        {space}
        {space}
        {space}
        <div className="w-full h-auto">
        <table className="w-full mt-1">
       <tbody>
         <tr>
           <td style={{ border: "2px solid #000" }}>
             <img
               style={{ width: "280px", height: "90px" }}
               src={table_logo}
               alt="logotip"
             />
           </td>
           <td
             style={{ border: "2px solid #000" }}
             className="font-semibold text-xs text-center analis_application"
           >
             Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
           </td>
           <td style={{ border: "2px solid #000" }}>
             <img src={Logo} alt="logotip" />
           </td>
         </tr>
       </tbody>
     </table>
     <div className="w-full analis_application">
     Таблица 4.
     </div>
      <table className={'w-full'}>
               <thead className={style.TablesTestReportTwo_thead}>
                   <tr>
                       <th colSpan={4} className="analis_application">Наименование проверяемых параметров и их характеристики</th>
                       <th colSpan={4}><div className="w-full analis_application">
                           <label htmlFor="requirmentID " className="analis_application">YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                       
                           </div></th>
                   </tr>
               </thead>
               <tbody>
                   <tr>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на требования</td>
                       <td colSpan={4} className={style.TablesTestReportTwo_td}>
                           <div className={`w-full mt-2 h-auto flex flex-wrap analis_application ${style.TablesTestReportTwo_form}`}>
                                {test_protocol?.fourthTableList?.testMethods?.map((itemTest:testMethods,indexTest:number)=>{
                                  return (
                                    <span key={indexTest} className="analis_application px_12 ml-1">{itemTest.name},</span>  
                                  )
                                })}
                           </div>
                       </td>
                   </tr>
                   <tr>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на методы испытания</td>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>
                           <div className={`w-full mt-2 h-auto flex analis_application flex-wrap ${style.TablesTestReportTwo_form}`}>
                           {test_protocol?.fourthTableList?.normativeList?.map((itemTest:normativ_list,indexTest:number)=>{
                                  return (
                                    <span key={indexTest} className="analis_application px_12 ml-1">{itemTest.normativeNumber},</span>  
                                  )
                                })}
                           </div>
                       </td>
                   </tr>
                    <tr>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4} rowSpan={3}>
                           <div className={style.TablesTestReportTwo_image}>
                               <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                               <p className="w-full text-center analis_application">Форма разрядного тока испытательного генератора</p>
                           </div>
                       </td>
                    </tr>
                    <tr>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                       
                    </tr>
                    <tr>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                       <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                      
                    </tr>
                    <tr>
                       <td className={style.TablesTestReportTwo_td} colSpan={8}>
                           <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                               <p>Модель</p>
                           </div>
                       </td>
                    </tr>
               </tbody>
           </table>
        {
          test_protocol?.fourthTableList?.map((item:fourthtable,index:number)=>{
            return (
              <table style={{width:'594px'}}key={index}>
        <thead>
                   <tr>
                       <th colSpan={2} className="analis_application" rowSpan={2}>Вид испытаний и или воздействие</th>
                       <th colSpan={2} className="analis_application" rowSpan={2} >Степень жесткости испытаний</th>
                       <th colSpan={2} className="analis_application" rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                       <th colSpan={2} className="analis_application">Критерий качества функционарования</th>
                       <th colSpan={2} className="analis_application" rowSpan={2}>Результат испытания</th>
                   </tr>
                   <tr>
                       <th className="analis_application">По НДС</th>
                       <th className="analis_application">По НДС</th>
                   </tr>
               </thead>
              <tbody>
               <tr>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] text-sm analis_application">Контактный разряд</td>
               </tr>
               <tr>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">Непрямые воздействия</td>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{item?.directImpactResult}</td>
                  <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{item?.testImpactParametersDirectEffects}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">B</td>
                  <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{item?.levelOfTestingSeverityDirectImpacts}</td>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.directImpactIsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm analis_application">Непрямые воздействия</td>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm analis_application">{item?.indirectImpactResult}</td>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm analis_application">{item?.testImpactParametersIndirectEffects}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className="text-sm analis_application">B</td>
                  <td style={{border:'1px solid #DDE1E6'}} className="text-sm analis_application">{item?.levelOfTestingSeverityIndirectImpacts}</td>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.indirectImpactIsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
               <td  style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] text-sm p-3 analis_application">Воздушный разряд</td>
               </tr>
               <tr>
                 <td style={{border:'1px solid #DDE1E6'}} rowSpan={4}  colSpan={2} className=" p-3 text-sm analis_application">Непрямые воздействия</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3  analis_application">1</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className=" text-sm p-3  analis_application">2 kV (10 одиночных разрядов)</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" text-sm p-3  analis_application">B</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" text-sm p-3  analis_application">{item?.airDischarge1Result}</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.airDischarge1IsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="  p-3 text-sm analis_application">2</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className=" p-3  text-sm analis_application">2 kV (10 одиночных разрядов)</td>
                 <td style={{border:'1px solid #DDE1E6'}} className="  p-3 text-sm analis_application">B</td>
                 <td style={{border:'1px solid #DDE1E6'}} className="  p-3 text-sm analis_application">{item?.airDischarge2Result}</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.airDischarge2IsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className=" p-3  text-sm analis_application">3</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className=" p-3  text-sm analis_application">2 kV (10 одиночных разрядов)</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">B</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">{item?.airDischarge3Result}</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.airDischarge3IsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
                 <td colSpan={2} style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">4</td>
                 <td colSpan={2}  style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">2 kV (10 одиночных разрядов)</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">B</td>
                 <td style={{border:'1px solid #DDE1E6'}} className=" p-3  text-sm analis_application">{item?.airDischarge4Result}</td>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${item?.airDischarge4IsSuccess ? 'text-[#03fc30]':'text-[red]'}`}>{item?.product?.name}</td>
               </tr>
               <tr>
                 <td style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] p-3 text-sm analis_application">
                   <span className="w-full text-sm analis_application">Примечание</span>
                   <div className="w-full">
                     {item.remark}
                   </div>
                 </td>
               </tr>
              </tbody>
        </table>
            )
          })
        }
      </div>
      {space}
        {space}
        {space}
        {space}</>
        :null
      }
       {/* table 4 tugadi  */}
       {/* table 5 boshlandi */}
         {test_protocol?.fifthTableList!==null && test_protocol?.fifthTableList?.length>0 && test_protocol?.[0]!==null  ? <>
            <div className="w-full h-auto" >
         <table className="w-full mt-1">
        <tbody>
          <tr>
            <td style={{ border: "2px solid #000" }}>
              <img
                style={{ width: "280px", height: "90px" }}
                src={table_logo}
                alt="logotip"
              />
            </td>
            <td
              style={{ border: "2px solid #000" }}
              className="font-semibold text-xs text-center analis_application"
            >
              Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
            </td>
            <td style={{ border: "2px solid #000" }}>
              <img src={Logo} alt="logotip" />
            </td>
          </tr>
        </tbody>
      </table>
         <div className="w-full analis_application">
         Таблица 5.
         </div>
         <table className={'w-full'}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4} className="analis_application">Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><div className="w-full">
                            <label htmlFor="requirmentID " className="analis_application">YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                            </div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на требования</td>
                        <td colSpan={4} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap analis_application ${style.TablesTestReportTwo_form}`}>
                                 {
                                  test_protocol?.fifthTableList[0]?.testMethods?.map((itemTest:testMethods,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.name},</span>
                                    )
                                  })
                                 }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на методы испытания</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap analis_application ${style.TablesTestReportTwo_form}`}>
                            {
                                  test_protocol?.fifthTableList[0]?.normativeList?.map((itemTest:normativ_list,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.normativeNumber},</span>
                                    )
                                  })
                                 }
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4} rowSpan={3}>
                            <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center analis_application">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                        
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                       
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
         {
          test_protocol?.fifthTableList?.map((itemFive:fivethtable,indexFIve:number)=>{
            return (
              <table key={indexFIve}>
         <thead>
                    <tr>
                        <th colSpan={2} className="analis_application" rowSpan={2}>Вид испытаний и или воздействие</th>
                        <th colSpan={2} className="analis_application" rowSpan={2} >Степень жесткости испытаний</th>
                        <th colSpan={2} className="analis_application" rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                        <th colSpan={2} className="analis_application">Критерий качества функционарования</th>
                        <th colSpan={2} className="analis_application" rowSpan={2}>Результат испытания</th>
                    </tr>
                    <tr>
                        <th className="analis_application">По НДС</th>
                        <th className="analis_application">По НДС</th>
                    </tr>
                </thead>
               <tbody>
                <tr>
                  <td style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] text-sm analis_application">Порт электропитания 220V</td>
                </tr>
                <tr>
                   <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">Провод-Провод</td>
                   <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemFive?.levelOfTestingSeverityWire}</td>
                   <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemFive?.testImpactParametersWire}</td>
                   <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">B</td>
                   <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemFive?.wireResult}</td>
                   <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`text-sm p-3 analis_application ${itemFive?.wireIsSuccess ? "text-[#03fc30]":"text-[red]"}`}>{itemFive?.product?.name}</td>
                </tr>
               </tbody>
         </table>
            )
          })
         }
       </div>
       {space}
         {space}
         {space}
         {space}
            </>:null}
        { /* table 5 tugadi */}
        {/* table 6 boshlandi */}
       {test_protocol?.sixthTableList!==null && test_protocol?.sixthTableList?.length>0 && test_protocol?.sixthTableList[0]!==null ?  <>  <div className="w-full h-auto" >
          <table className="w-full mt-1">
         <tbody>
           <tr>
             <td style={{ border: "2px solid #000" }}>
               <img
                 style={{ width: "280px", height: "90px" }}
                 src={table_logo}
                 alt="logotip"
               />
             </td>
             <td
               style={{ border: "2px solid #000" }}
               className="font-semibold text-xs text-center analis_application"
             >
                Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
             </td>
             <td style={{ border: "2px solid #000" }}>
               <img src={Logo} alt="logotip" />
             </td>
           </tr>
         </tbody>
       </table>
          <div className="w-full analis_application">
          Таблица 6.
          </div>
          <table className={'w-full'}>
                 <thead className={style.TablesTestReportTwo_thead}>
                     <tr>
                         <th colSpan={4} className="analis_application">Наименование проверяемых параметров и их характеристики</th>
                         <th colSpan={4}><div className="w-full">
                             <label htmlFor="requirmentID" className="analis_application">YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                             </div></th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на требования</td>
                         <td colSpan={4} className={`${style.TablesTestReportTwo_td} analis_application`}>
                             <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                             {
                                  test_protocol?.sixthTableList[0]?.testMethods?.map((itemTest:testMethods,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.name},</span>
                                    )
                                  })
                                 }
                             </div>
                         </td>
                     </tr>
                     <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на методы испытания</td>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>
                             <div className={`w-full mt-2 h-auto flex flex-wrap analis_application ${style.TablesTestReportTwo_form}`}>
                             {
                                  test_protocol?.sixthTableList[0]?.normativeList?.map((itemTest:normativ_list,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.normativeNumber},</span>
                                    )
                                  })
                                 }
                             </div>
                         </td>
                     </tr>
                      <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4} rowSpan={3}>
                             <div className={style.TablesTestReportTwo_image}>
                                 <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                 <p className="w-full text-center">Форма разрядного тока испытательного генератора</p>
                             </div>
                         </td>
                      </tr>
                      <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                         
                      </tr>
                      <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                        
                      </tr>
                      
                 </tbody>
             </table>
         {
          test_protocol?.sixthTableList?.map((itemSix:sixthtable,indexSix:number)=>{
            return (
              <table style={{width:'594px'}} key={indexSix}>
              <thead>
                         <tr>
                             <th colSpan={2} className="analis_application" rowSpan={2}>Вид испытаний и или воздействие</th>
                             <th colSpan={2} className="analis_application" rowSpan={2} >Степень жесткости испытаний</th>
                             <th colSpan={2} className="analis_application" rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                             <th colSpan={2} className="analis_application">Критерий качества функционарования</th>
                             <th colSpan={2} className="analis_application" rowSpan={2}>Результат испытания</th>
                         </tr>
                         <tr>
                             <th className="analis_application">По НДС</th>
                             <th className="analis_application">По НДС</th>
                         </tr>
                         <tr>
                         <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={8}>
                             <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                 <p className="analis_application">{itemSix?.product?.name}</p>
                             </div>
                         </td>
                      </tr>
                     </thead>
                    <tbody>
                     <tr>
                       <td style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] text-sm analis_application">Порт электропитания 220V, AC</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} rowSpan={4} className="text-sm p-3 analis_application">Провод-Провод</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">0</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWire0}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWire0}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wire0Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wire0IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">90</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWire90}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWire90}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wire90Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wire90IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">180</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWire180}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWire180}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wire180Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wire180IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">270</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWire270}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWire270}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wire270Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wire270IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                       <td style={{border:'1px solid #DDE1E6'}} colSpan={10} className="bg-[#DDE1E6] text-sm analis_application">Порт электропитания 220V, AC</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} rowSpan={4} className="text-sm p-3 analis_application">Провод-Провод</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">0</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWireToGround0}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWireToGround0}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wireToGround0Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wireToGround0IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">90</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWireToGround90}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWireToGround90}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wireToGround90Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wireToGround90IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">180</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWireToGround180}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWireToGround180}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wireToGround180Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wireToGround180IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                     <tr>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={1} className="text-sm p-3 analis_application">270</td>
                        <td  style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.levelOfTestingSeverityWireToGround270}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className="text-sm p-3 analis_application">{itemSix?.testImpactParametersWireToGround270}</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">f</td>
                        <td style={{border:'1px solid #DDE1E6'}} className="text-sm p-3 analis_application">{itemSix?.wireToGround270Result}</td>
                        <td style={{border:'1px solid #DDE1E6'}} colSpan={2} className={`${itemSix?.wireToGround270IsSuccess ? 'text-[#03fc30]':'text-[red]'} text-sm p-3 analis_application`}>{itemSix?.product?.name}</td>
                     </tr>
                    </tbody>
              </table>
            )
          })
         }
        </div>
        {space}
          {space}
          {space}
          {space}
          </>:null}
       {/* table 6 tugadi */}
      {test_protocol?.seventhTableList!==null && test_protocol?.seventhTableList?.length>0 && test_protocol?.seventhTableList[0]!==null ?  <>
        <div className="w-full h-auto">
       <table className="w-full mt-1">
         <tbody>
           <tr>
             <td style={{ border: "2px solid #000" }}>
               <img
                 style={{ width: "280px", height: "90px" }}
                 src={table_logo}
                 alt="logotip"
               />
             </td>
             <td
               style={{ border: "2px solid #000" }}
               className="font-semibold text-xs text-center analis_application"
             >
                Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
             </td>
             <td style={{ border: "2px solid #000" }}>
               <img src={Logo} alt="logotip" />
             </td>
           </tr>
         </tbody>
       </table>
         <div className="w-full analis_application">
         Таблица 7.
         </div>
         <table className={'w-full'}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4} className="analis_application">Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><div className="w-full">
                            <label htmlFor="requirmentID" className="analis_application">YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                            </div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на требования</td>
                        <td colSpan={4} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap analis_application ${style.TablesTestReportTwo_form}`}>
                            {
                                  test_protocol?.seventhTableList[0]?.testMethods?.map((itemTest:testMethods,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.name},</span>
                                    )
                                  })
                                 }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>Пункты НД на методы испытания</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {
                                  test_protocol?.seventhTableList[0]?.normativeList?.map((itemTest:normativ_list,indexTest:number)=>{
                                    return (
                                      <span key={indexTest} className="analis_application px_12 ml-1">{itemTest?.normativeNumber},</span>
                                    )
                                  })
                                 }
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={4} rowSpan={3}>
                            <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center analis_application">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                        
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={1}>150 pF</td>
                       
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p className="analis_application">Модель</p>
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
         {
          test_protocol.seventhTableList?.map((itemSeven:seventhtable,indexSeven:number)=>{
            return (
              <table className={`${style.TablesTestReportTwo_two} mb-5`}>
              <thead>
                  <tr>
                      <th colSpan={2} className="analis_application" rowSpan={2}>Вид испытаний и или воздействие</th>
                      <th colSpan={2} className="analis_application" rowSpan={2} >Степень жесткости испытаний</th>
                      <th colSpan={2} className="analis_application" rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                      <th colSpan={2} className="analis_application">Критерий качества функционарования</th>
                      <th colSpan={2} className="analis_application" rowSpan={2}>Результат испытания</th>
                  </tr>
                  <tr>
                      <th className="analis_application">По НДС</th>
                      <th className="analis_application">По НДС</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td className={`${style.TablesTestReportTwo_td} analis_application bg-[#DDE1E6]`} colSpan={10}>Контактный разряд</td>
                  </tr>
                  <tr>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>Непрямые воздействия</td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>
                          <div className={`w-full flex flex-wrap items-center justify-center ${style.TablesTestReportTwo_two_form}`}>
                           {itemSeven?.levelOfTestingSeverityVoltageDropsForFirstPeriod}
                          </div>
                      </td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application `} colSpan={2}>
                          <div className={`w-full flex flex-wrap items-center justify-center ${style.TablesTestReportTwo_two_form}`}>
                               {itemSeven?.parametersOfTheTestStimulusFirstPeriod} kV
                              {/* <p>(10 одиночных разрядов)</p> */}
                          </div>
                      </td>
                      <td className={`${style.TablesTestReportTwo_td} text-center analis_application`}>B</td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                           {itemSeven?.voltageDropsForFirstPeriodResult}
                          </div></td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>
                          <div className="w-full flex items-center justify-center analis_application">
                              <span className='w-1/3'>{itemSeven?.voltageDropsForFirstPeriodIsSuccess}</span>
                              <span className='w-2/3 analis_application px_12'>Соответствует</span>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>Непрямые воздействия</td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>
                          <div className={`w-full flex flex-wrap analis_application px_12 ${style.TablesTestReportTwo_two_form}`}>
                            {itemSeven?.levelOfTestingSeverityVoltageDropsForSecondPeriod}
                          </div>
                      </td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>
                          <div className={`w-full flex flex-wrap analis_application ${style.TablesTestReportTwo_two_form}`}>
                              {itemSeven?.parametersOfTheTestStimulusSecondPeriod} kV
                              {/* <p className="analis_application px_12">(10 одиночных разрядов)</p> */}
                          </div>
                      </td>
                      <td className={`${style.TablesTestReportTwo_td} text-center`}>B</td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`}><div className={`w-full flex flex-wrap analis_application px_12 ${style.TablesTestReportTwo_two_form}`}>
                            {itemSeven?.voltageDropsForSecondPeriodResult}
                          </div></td>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>
                          <div className="w-full flex">
                              <span className='w-1/3'>{itemSeven?.voltageDropsForSecondPeriodIsSuccess}</span>
                              <span className='w-2/3 analis_application px_12'>Соответствует</span>
                          </div>
                      </td>
                  </tr>
                  <tr>
                      <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6] analis_application`} colSpan={10}>Воздушный разряд</td>
                  </tr>
                  {/* 2qator */}
                  <tr>
                      <td className={`${style.TablesTestReportTwo_td} analis_application`} colSpan={2}>Непрямые воздействия</td>
                      <td className={style.TablesTestReportTwo_td} colSpan={2}>
                          <div className={`w-full flex flex-wrap analis_application px_12 ${style.TablesTestReportTwo_two_form}`}>
                           {itemSeven?.levelOfTestingSeverityShortTermInterruptions}
                          </div>
                      </td>
                      <td className={style.TablesTestReportTwo_td} colSpan={2}>
                          <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <span className='w-full analis_application px_12'>2 kV</span>
                              {/* <p>(10 одиночных разрядов)</p> */}
                          </div>
                      </td>
                      <td className={`${style.TablesTestReportTwo_td} text-center`}>B</td>
                      <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap analis_application px_12 ${style.TablesTestReportTwo_two_form}`}>
                      {itemSeven?.parametersOfTheTestStimulusShortTermInterruptions}
                        </div></td>
                      <td className={style.TablesTestReportTwo_td} colSpan={2}>
                          <div className="w-full flex flex-wrap analis_application px_12">
                              <span className='w-1/3'>{itemSeven?.shortTermInterruptionsIsSuccess}</span>
                              <span className='w-2/3 analis_application px_12'>Соответствует</span>
                          </div>
                      </td>
                  </tr>
                  {/* oxiri */}
                  <tr >
                      <td  colSpan={10} className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`}>
                        <div className="w-full ">
                        <span className='w-full analis_application px_12 '>
                          Примечание:
                          </span>
                          <div className='w-1/3 analis_application px_12'>
                          {itemSeven?.shortTermInterruptionsResult}
                          </div>
                        </div>
                      </td>
                  </tr>
              </tbody>
          </table>
            )
          })
         }
       </div>
      </>:null}
         <div className="w-full h-auto" >
         
         <table className="w-full mt-1">
         <tbody>
           <tr>
             <td style={{ border: "2px solid #000" }}>
               <img
                 style={{ width: "280px", height: "90px" }}
                 src={table_logo}
                 alt="logotip"
               />
             </td>
             <td
               style={{ border: "2px solid #000" }}
               className="font-semibold text-xs text-center analis_application"
             >
                Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
             </td>
             <td style={{ border: "2px solid #000" }}>
               <img src={Logo} alt="logotip" />
             </td>
           </tr>
         </tbody>
       </table>
         <div className="w-full analis_application">
         Таблица 8. qilish kerak
         </div>
         <table className={style.TablesTestReportTwo} >
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><label htmlFor="test_eight" className='w-full'>YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            kdjaskldlkjkladjklasjdajldjaskldjklajdklajkldjalkdj
                             </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                           asdasdas
                            </div>
                        </td>
                    </tr>
                    {/* selectli td tugadi */}
                      {/* td header tuhashi */}
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Требования к текст</td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} rowSpan={2} colSpan={2}>Диапозон частот MHz</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={6}>Норма(предел) значения величины (dBuV)</td>
                     </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                    </tr>
                    {/* td header tuhashi */}
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56-43</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td}`} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                adasdad
                            </div>
                        </td>
                     </tr>
                     
                </tbody>
            </table>
            <table className={`${style.TablesTestReportTwo} mb-5`}>
                <thead>
                <tr>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={2}>Требование по НД</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Результат испытаний</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Соответветствует требованию НД</td>
                     </tr>
                </thead>
                        <tbody>
                        
                     <tr>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>Не более значений приведенных в таб №2 UzTR</td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div >
                              Не более значений приведенных в таб №2 UzTRНе более значений приведенных в таб
                              №2 UzTRНе более значений приведенных в таб 
                              №2 UzTRНе более значений приведенных в таб №2 UzTRНе более значений приведенных в таб №2 UzTR
                            </div>
                        </td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div className="w-full flex">
                                <span className='w-1/3'>zxczcfsdfdf nhnghmjhm</span>
                                <span className='w-2/3'>Соответствует</span>
                            </div></td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Расчет неопределенности измерений на самом максимальном пиковом значении напряжения ИРП</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>zxczxczxczxcxzckjzxjcllz </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div className='flex justify-between'><span>Квазипиковый детектор</span> </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>czczczxczxczxczczxczxc</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>zczxczczjczklxcjz</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>zczxczxczxczxczczxczxc</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div  className='flex justify-between'><span>Детектор средних значений</span></div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>zxcxzczczxcz</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>zxczx</td>
                     </tr>
                        </tbody>
                     </table>
       </div>
       <div className="w-full">
       <table className="w-full mt-1">
         <tbody>
           <tr>
             <td style={{ border: "2px solid #000" }}>
               <img
                 style={{ width: "280px", height: "90px" }}
                 src={table_logo}
                 alt="logotip"
               />
             </td>
             <td
               style={{ border: "2px solid #000" }}
               className="font-semibold text-xs text-center analis_application"
             >
                Протокол испытаний № {test_protocol?.testProtocolNumber}/{moment(test_protocol?.testProtocolDate).format('YYYY')}-Лб от {moment(test_protocol?.testProtocolDate).format('DD-MM-YYYY')} г.
             </td>
             <td style={{ border: "2px solid #000" }}>
               <img src={Logo} alt="logotip" />
             </td>
           </tr>
         </tbody>
       </table>
       Таблица 9
       <div className={style.TablesTestReportTwo_wrapper}>
             <table className={style.TablesTestReportTwo}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><label htmlFor="test_night" className='w-full'>YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                       adasdasdasdas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            adasdasdadasd
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            adadasdasd
                            </div>
                        </td>
                    </tr>
                    {/* selectli td tugadi */}
                      {/* td header tuhashi */}
                     <tr>
                        <td colSpan={8} className={`${style.TablesTestReportTwo_td_grey} text-left`}>Требования по НД:</td>
                     </tr>
                     <tr>
                       <td colSpan={8} className={`${style.TablesTestReportTwo_td_grey} text-center`}>Требования к текст тексттексттексттексттексттексттексттексттекст</td>
                     </tr>
                     <tr>
                        <td colSpan={2} rowSpan={2} className={`${style.TablesTestReportTwo_td_grey} text-center`}>Диапозон частот MHz</td>
                        <td colSpan={6} className={`${style.TablesTestReportTwo_td_grey} text-center`}>Норма(предел) значения величины (dBuV)</td>
                     </tr>
                     <tr>
                        <td colSpan={6} className={`${style.TablesTestReportTwo_td_grey} text-center`}>Квазипиковое значение</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>ada</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>ada</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>ada</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>ada</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>ada</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>ada</td>
                     </tr>
                    {/* td header tuhashi */}
                    {/* 2-chi td headerni boshlanishi */}
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Требования к текст тексттексттексттексттексттексттексттексттекст</td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} rowSpan={2} colSpan={2}>Диапозон частот MHz</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={6}>Норма(предел) значения величины (dBuV)</td>
                     </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                    </tr>
                    {/* 2-chi td headerni tugashi */}
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56-43</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td}`} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                                
                            </div>
                        </td>
                     </tr>
                    
                </tbody>
            </table>
            <table className={`${style.TablesTestReportTwo} mb-4`}>
                <thead>
                <tr>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={2}>Требование по НД</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Результат испытаний</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Соответветствует требованию НД</td>
                     </tr>
                </thead>
                <tbody>
                <tr>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>Не более значений приведенных в таб №2 UzTR</td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div style={{width:"430px"}}>
                              Не более значений приведенных в таб №2 UzTRНе более значений приведенных в таб
                              №2 UzTRНе более значений приведенных в таб 
                              №2 UzTRНе более значений приведенных в таб №2 UzTRНе более значений приведенных в таб №2 UzTR
                            </div>
                        </td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div className="w-full flex">
                                <span>isSuccess</span>
                                <span className='w-2/3'>Соответствует</span>
                            </div></td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Расчет неопределенности измерений на самом максимальном пиковом значении напряжения ИРП</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>quasiPeakDetectorFrequencyForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div className='w-full flex justify-between'>
                            <span className='w-2/3'>Квазипиковый детектор</span></div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>quasiPeakDetectorLevelOfStandardForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>quasiPeakDetectorMeasurementResultsForLast</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>peakDetectorFrequencyForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div className='w-full flex justify-between'>
                            <span className='w-2/3'>Детектор средних значений</span>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>peakDetectorLevelOfStandardForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>peakDetectorMeasurementResultsForLast</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>averageValueDetectorFrequencyForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div className='w-full flex justify-between'>
                            <span className='w-2/3'>Детектор средних значений</span></div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>averageValueDetectorLevelOfStandardForLast</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>averageValueDetectorMeasurementResultsForLast</td>
                     </tr>
                </tbody>
            </table>
        </div>
       </div>
    </div>
   </>
  );
}
export default TestingProtocol;
