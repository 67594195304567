import './Styledocx.css'
import { useEffect, useRef ,useState} from "react";
import { useReactToPrint } from "react-to-print";
import { RequestToken } from "src/Hook/Request/Request";
import {user_object,response_document_type,response_catagore_document} from "src/pages/steps/stepconclusion/indexType";
import moment from "moment";
const space = (
    <>
      {" "}
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
    </>
  );
  const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
  interface props {
    orderID: string;
    setDownload: (download: boolean) => void;
  }
function Consultion({ orderID, setDownload }: props){
    const [res_data, setRes_data] = useState<response_document_type[]>();
    const componentRef: any = useRef();
  async function getConclusion() {
    const response = await RequestToken(`/conclusion/${orderID}`, "GET", null);
    if (response.success) {
      setRes_data(response?.object?.map((item:any,index:number)=>{
       return {
        applicant:item?.applicant,
        testingProtocolDate:moment(item?.testingProtocolDate).format('DD-MM-YYYY'),
        productNames:item?.productNames,
        normativeNames:item?.normativeNames,
        certificationOrgans:item?.certificationOrgans,
        manufacturers:item?.manufacturers,
        schemeOfCertification:item?.schemeOfCertification,
        users:item?.users?.map((itemuser:{firstName:string,lastName:string,position:{name:string}})=>{return {firstName:itemuser.firstName,lastName:itemuser.lastName,position:itemuser.position?.name}}),
      } 
      }))
      HandlePrint();
    }
  }
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ЗАКЛЮЧЕНИЕ",
      onAfterPrint:()=>setDownload(false)
  });
  useEffect(() => {
    getConclusion()
  }, []);
    return (
        <div className="h-auto" style={{width:"594px"}} ref={componentRef}>
            {
              res_data?.map((item:response_document_type,index:number)=>{
                return (
                  <>
                  {space}
           <div className="w-full " style={{height:'860px'}}>
           <div className="w-full text-center analis_application text-base">Национальная система сертификации Республики Узбекистан
Система сертификации технических средств телекоммуникаций
</div>
       <div className="w-full text-base italic analis_application text-center">ОРГАН ПО СЕРТИФИКАЦИИ ТЕХНИЧЕСКИХ СРЕДСТВ ТЕЛЕКОММУНИКАЦИЙ (ОС ТСТ)</div>
       <div className="w-full flex justify-center">
         <div className="w-1/2 my-3">
            <div className="w-full font-semibold text-center text-base analis_application">З А К Л Ю Ч Е Н И Е ОС ТСТ</div>
            <div className="w-full font-semibold text-center text-base analis_application">от {item?.testingProtocolDate}г.</div>
         </div>
       </div>
        <div className="w-full analis_application">
        {space_row}Комиссия в составе: {item?.users?.map((item:user_object,index:number)=>{
            return (
                <span className='text-base analis_application px-2' key={index}>{item.position} {item.lastName} {item.firstName}</span>
            )
        })} проанализировала всю информацию и результаты, связанные с оцениванием продукции {item?.manufacturers?.map((item:string,index:number)=>{
            return (
                <span className='text-base analis_application px-2 font-semibold' key={index}>{item}</span>
            )
        })}{item?.productNames?.map((item:string,index:number)=>{
            return (
                <span className='text-base analis_application px-2' key={index}>{item}</span>
            )
        })} проведенных специалистами {item.certificationOrgans.map((item:response_catagore_document,index:number)=>{
            return (<span className='text-base analis_application px-2' key={index}>{item.nameOfCertificationOrgan}</span>)})}
        </div>
        
        <div className="w-full mt-4">
          <div className='italic text-base underline analis_application'>РЕЗУЛЬТАТЫ АНАЛИЗА ДАННЫХ:</div>
        </div>
        <div className="w-full text-base analis_application">{space_row}1. {item?.certificationOrgans?.map((item:response_catagore_document,index:number)=>{
            return (
                <span key={index}>{item.nameOfCertificationOrgan}</span>
            )
        })}</div>
        <div className="w-full text-base analis_application">{space_row}2. Сертификация ТСТ проведена по схеме № {item?.schemeOfCertification}</div>
        <div className="w-full text-base analis_application">{space_row}3. Принятое ОС решение по заявке содержит все основные условия сертификации согласно установленного порядка сертификации продукции.
         <span className="w-full block text-base analis_application">{space_row}В НД на продукцию отсутствуют гигиенические, ветеринарные, фитосанитарные, экологические нормы и требования.</span></div>
        <div className="w-full text-base analis_application">{space_row}4. Программа сертификационных испытаний учитывает все требования нормативного документа в области технического регулирования.</div>
        <div className="w-full text-base analis_application">{space_row}5. По результатам проведенной идентификации продукции каких-либо отклонений требований НД не обнаружено. Идентификация, отбор образцов выполнены в соответствии с установленными в ОС процедурами. Отклонений не выявлено.</div>
        <div className="w-full text-base analis_application">{space_row}6. После проведения сертификационных испытаний получены положительные результаты. 
Образцы вышеперечисленных ТСТ, подвергнутые испытаниям, соответствуют требованиям:
  <div className="w-full">
    {item?.normativeNames?.map((item:string,index:number)=>{
        return (
            <div className="w-full text-base analis_application" key={index}>{item}</div>
        )
    })}
  </div>
 </div>
 <div className="w-full text-base analis_application">{space_row}7. Полнота документов (решение по заявке, программа сертификационных испытаний, акт идентификации и отбора образцов, акт о списании (возврате) продукции, протокол испытаний, товаросопроводительные документы, образец маркировки продукции и др.), связанных с проведенной оценкой соответствия продукции обеспечивается.</div>
      
           </div>
           {space}
           {space}
           {space}
           {space}
           <div className="w-full" style={{height:'860px'}}>
            {space_row} <span className='underline italic text-base analis_application'>ВЫВОДЫ: Исходя из проведенного анализа данных вышеизложенного, комиссия принимает решение:
– выдать <span className='text-base analis_application font-semibold'>{item?.applicant}</span> сертификат соответствия установленного образца на партию на вышеперечисленных ТСТ, согласно схемы сертификации № <span className='font-semibold text-base analis_application'>{item?.schemeOfCertification}</span>, соответствующей регистрацией в Государственном реестре Национальной системы сертификации Республики Узбекистан
</span>
<div className="w-full flex my-4">
               <div className="w-1/3">
                <div className="w-full my-3">Председатель комиссии</div>
                <div className="w-full my-3 italic">Члены комиссии:</div>
               </div>
               <div className="w-2/3">
                {item?.users?.map((itemuser:user_object,index:number)=>{
                    return (<div className="w-full flex my-2" key={index}>
                    <div className="w-32 h-7 " style={{borderBottom:'1px solid black'}}></div>
                    <div className='flex h-8 items-end ml-2'>{itemuser.lastName} {itemuser.firstName}</div>
                </div>)
                })}
               </div>
           </div>
           </div>
      
                  </>
                )
              })
            }
        </div>
    )
}
export default Consultion;