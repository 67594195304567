import '../../form/FormStep.css'
import { useState,useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RequestToken, RequestTokenFile } from 'src/Hook/Request/Request'
import Swal from 'sweetalert2';
import DataPicker from 'src/Component/DatePickerFolder/DataPicker/DataPicker';
import {  DataStepTwo, DataStepTwo_GET, } from 'src/mockdata/AllData';
import {CloudUploadOutlined } from '@ant-design/icons';
import { Input,Button, Select,notification  } from 'antd';
import SelectIndicator from 'src/Component/Select/Select';
import { Objects,Props,data,Option, certificationOrgan,response_data} from './StepTwoType';
import Products from 'src/Component/data/product';
import moment from 'moment';
import InputMaskReact from 'src/Component/MaskInput/Inputmask';
import { UserContext } from 'src/App';
function Shag2({type,Id ,modalOpen,setOpenModal,product_List,normativ_list}:Props){
  const [api, contextHolder] = notification.useNotification();
    const navigate=useNavigate()
    const {id}=useParams()
    const {setheaderTitle}=useContext(UserContext)
    const [loading,setLoading]=useState<boolean>(true)
    const [filename,setFileName]=useState<string>('')
    const [product_list,setProduct_list]=useState<Option[]>([])
    const [organ_list,setOrgan_list]=useState<certificationOrgan[]>([])
    const [file, setFile] = useState<File | null>(null);
    // normativeIds , laboratoryIds
    const [data, setData] = useState<data>({
      date:'',
      number: '',
      normativeIds:[],
      certificationOrganIds: [],
      numberOfRegister: '',
      numberOfRegisters:[],
      typeOfPayment: '',
      productsName: [],
      rukoVoditel: '',
      ispolnitel: '',
      phoneNumber:'',
      typeOfSolutionName:'',
      attachmentId: 1,
      dateOfMail: '',
      numberOfMail:''
    });
    const [res_data,setResData]=useState<response_data>({
      date:'',
      number:'',
      normativeNames:[],
      certificationOrganNames:[],
      numberOfRegisters:[],
      typeOfPayment:'',
      productsName:[],
      rukoVoditel:'',
      ispolnitel:'',
      phoneNumber:'',
      typeOfSolutionName:'',
      attachment: {
        id:0,
        fileOriginalName:'',
        name:''
      },
      dateOfMail:'',
      numberOfMail:''
    })
    function Handle(Data:number[]|string[],name:string){
      console.log(Data)
      setData({...data,[name]:[...Data]})
     if (name === 'certificationOrganIds' && typeof Data[0]==='number') {
      let newNumberRegister = Data
        .map((item: number | string) => organ_list.find(itemOrgan => itemOrgan.id === item)?.numberRegister) // Filter out undefined values
        .join(',');
  
      setData({ ...data, numberOfRegister: newNumberRegister,certificationOrganIds:Data as number[] });
    }
    }
    function HandleChange(param: string, name:string) {
      if (data) {
        setData({...data,[name]:param})
      }
    }
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length>0) {
        setFile(event.target.files[0]);
        UploadFile()
      }
      else{}
    };
    const UploadFile=async()=>{
      if(file){
        const formData = new FormData();
        formData.append('file', file);
   
        const response = await RequestToken('/attachment/upload','POST',formData,'multipart/form-data')
        if(response.success){
          setData({...data,attachmentId:response.object})
          api.success({
            message: `Успех`,
            description:response?.message,
          });
        }
        else{
          api.error({
            message: `Ошибка `,
            description:response.response?.data?.message||response.response?.message
          });
        }
      }
    }
    const Dowland=async(id:number)=>{
      const res_download=await RequestTokenFile(`/attachment/downloadFile/${id}`,"GET",null)
      const blob = new Blob([res_download], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = res_data.attachment.fileOriginalName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);;

    }
    function RenderSwitch(key:string,type:string,options:Objects[]|undefined){
      switch (type) {
        case 'input':
          return <Input style={{width:"350px",height:'45px'}} 
           onChange={(e)=>HandleChange(e.target.value,key)} 
           value={(data as any)[key]}
           />
        case 'select':
          return <SelectIndicator HandleChange={HandleChange} data={options}
          style={{width:"350px",height:'45px'}} name={key}  />
        case 'addbox':
          return <Select mode='multiple' options={normativ_list?.map((item:any)=>{return {label:item.label,value:item.id}})} style={{width:"350px",height:'45px'}}  value={(data as any)[key]} onChange={(value)=>Handle(value,key)}/>
        case 'product':
          return <Products data={product_List} Handle={Handle} name={key} id={id? parseInt(id):1} type={"string"}/>
        case 'laboratory':
          return <Select mode='multiple' options={organ_list?.map((item:certificationOrgan)=>{return {label:item.nameOfCertificationOrgan,value:item.id}})} style={{width:"350px",height:'45px'}} 
           onChange={(value)=>Handle(value,key)}/> 
        case 'date':
          return <DataPicker name={key} handleChange={HandleChange}/> 
        case 'upload':
          return <div className='w-full'>
          <span className="mx-4 w-full block text-xs">DOCX,PDF,DOC,PNG,JPEG,JPG</span>
           <input type="file" className='hidden' id='file_Upload' accept='.docx,.pdf,.doc,.xlsx' onChange={handleFileChange}/>
           <label htmlFor="file_Upload" className='text-3xl cursor-pointer'><CloudUploadOutlined /></label>
           <div className="w-full"><button className='px-3 py-2 rounded-md bg-[#4096ff] text-[#fff]' onClick={UploadFile}>Отправлять</button></div>
          </div> 
        case 'phone_input':
          return <InputMaskReact name={key} handleChange={HandleChange} value={(data as any)[key]}/>
        default:                        
          break;
      }
    }
    async function Save(){
      const newData={
        date:data.date,
      number: data.number,
      normativeIds:data.normativeIds,
      certificationOrganIds:data.certificationOrganIds,
      numberOfRegister: data.numberOfRegister,
      typeOfPayment: data.typeOfPayment,
      productsName: data.productsName,
      rukoVoditel: data.rukoVoditel,
      ispolnitel: data.ispolnitel,
      phoneNumber:data.phoneNumber,
      typeOfSolutionName:data.typeOfSolutionName,
      attachmentId: data.attachmentId,
      dateOfMail: data.dateOfMail,
      }
      const Data=await RequestToken(Id,"POST",newData)
      if(Data.success){
       Swal.fire({
         icon: 'success',
         title: ' Cохранен',
         text: 'Теперь вы можете смотреть со страницы задач!',
       })
       navigate(`/home/${id}`)
     }
     else if(Data.message==='Network Error'){
       navigate('/error/500')
     }
     else {
       Swal.fire({
         icon: 'error',
         title: 'He Cохранен',
         text:Data.response?.data?.message||Data.response?.message||Data?.message,
       })
     }
     }
     async function getData(){
       const response=await RequestToken(Id,"GET",null)
       if(response.success ){
        setResData({
          attachment:{
            fileOriginalName:response.object?.attachment?.fileOriginalName,
            name:response.object?.attachment?.name,
            id:response.object?.attachment?.id,
          },
          certificationOrganNames:response?.object?.certificationOrganNames,
          number:response?.object?.number,
          date:moment(response?.object?.date).format('DD-MM-YYYY'),
          normativeNames:response?.object?.normativeNames,
          numberOfRegisters:response?.object?.numberOfRegisters,
          typeOfPayment:response?.object?.typeOfPayment,
          productsName:response?.object?.productsName,
          rukoVoditel:response?.object?.rukoVoditel,
          ispolnitel:response?.object?.ispolnitel,
          phoneNumber:response?.object?.phoneNumber,
          typeOfSolutionName:response?.object?.typeOfSolutionName,
          dateOfMail:moment(response?.object?.dateOfMail).format('DD-MM-YYYY'),
          numberOfMail:response?.object?.numberOfMail,
        }) 
        setProduct_list(product_List)
        setFileName(response.object.attachment?.name)
       }
      }
    async function get_organ_list(){
      const response_organ_list=await RequestToken('/certificationOrgan?page=0&size=100',"GET",null)
      if(response_organ_list.success){
        setOrgan_list(response_organ_list.object)
      }
      else{
        Swal.fire({
          title:response_organ_list.response?.data.message||response_organ_list.response?.message,
          icon:'error'
        })
      }
    }
      useEffect(()=>{
        if(type==='edit'){
          get_organ_list()
        }else{getData()}
        setheaderTitle('Решение')
      },[])
      console.log()
      return (
        <div className='formlist_wrapper'>
           {contextHolder}
          {
          (type==='edit' ?  DataStepTwo:DataStepTwo_GET)?.map(item=>{
            if(item.tip==='all'){
              return (
               item.hasOwnProperty('title') ? <div className="StepThree_wrapper_row_left_title">
                 {item.title}
               </div>:
               <div className="formlist_wrapper_row">
               <div className="formlist_wrapper_row_left">
                  {item.label}
               </div>
               <div className="formlist_wrapper_row_right">
                 {
                   type==="edit" ? RenderSwitch(item.key,item.type,item.options):(res_data as any)[item.key] ? Array.isArray((res_data as any)[item.key]) ?
                    (res_data as any)[item.key]?.length>0 ? (res_data as any)[item.key]?.map((item:string|number,index:number)=>{
                      return (
                        <div key={index} className='w-full my-2'>{item}</div>
                      )
                    }):"Нет в наличии"
                   :(res_data as any)[item.key]:'Нет в наличии'
                 }
               </div>
             </div>
               ) 
            }
          })         
        }
        {
                DataStepTwo.map(item=>{
               if(type==='edit') {
                if(item.tip===data.typeOfSolutionName){
                  return (
                    <div className="formlist_wrapper_row">
                      <div className="formlist_wrapper_row_left">
                         {item.label}
                      </div>
                      <div className="formlist_wrapper_row_right">
                        {
                          type==="edit" ? RenderSwitch(item.key,item.type,item.options):
                          (res_data as any)[item.key]? Array.isArray((res_data as any)[item.key]) ? (res_data as any)[item.key]?.length>0 ? (res_data as any)[item.key]?.map((item:string|number,index:number)=>{
                            return (
                              <div key={index} className='w-full my-2'>{item}</div>
                            )
                          }):"Нет в наличии":
                          item.key==='attachment'? <button className='px-3 py-2 bg-[#4096ff] rounded-md text-[#fff]' onClick={()=>Dowland(res_data.attachment.id)}>Скачать</button>
                          :(res_data as any)[item.key]
                          :"Нет в наличии"
                        }
                        {/*  */}
                      </div>
                    </div>
                   )
                }
               } else{
                if(item.tip===res_data.typeOfSolutionName){
                  return (
                    <div className="formlist_wrapper_row">
                      <div className="formlist_wrapper_row_left">
                         {item.label}
                      </div>
                      <div className="formlist_wrapper_row_right">
                        {
                          type==="edit" ? RenderSwitch(item.key,item.type,item.options):
                          (res_data as any)[item.key]? Array.isArray((res_data as any)[item.key]) ? (res_data as any)[item.key]?.length>0 ? (res_data as any)[item.key]?.map((item:string|number,index:number)=>{
                            return (
                              <div key={index} className='w-full my-2'>{item}</div>
                            )
                          }):"Нет в наличии":
                          item.key==='attachment'? <button className='px-3 py-2 bg-[#4096ff] rounded-md text-[#fff]' onClick={()=>Dowland(res_data.attachment.id)}>Скачать</button>
                          :(res_data as any)[item.key]
                          :"Нет в наличии"
                        }
                        {/*  */}
                      </div>
                    </div>
                   )
                }
               }            
            })
        }
         <div className={type==="edit" ? "StepThree_wrapper_row ":'hidden'}>
        <div className="StepThree_wrapper_row_left"></div>
        <div className="StepThree_wrapper_row_right ">
          <Button style={{ height: "45px" }} className="px-5 mx-3">
            Отмена
          </Button>
          <Button
            type="primary"
            style={{ height: "45px" }}
            className="px-3"
            onClick={Save}
          >
            Cохранить
          </Button>
        </div>
      </div>
        </div>
    )
}
export default Shag2;
// res_data[item.key as keyof res_data] ? 
// Array.isArray(res_data[item.key as keyof res_data]) ? (res_data as any)[item.key].length>0 ? (res_data as any)[item.key].map((listitem:any,index:number)=>{
//   return (
//     <div key={index} className='formlist_wrapper_row_right_gost'>{listitem}</div>
//   )
//  }):"Hет в наличии":item.key.toLocaleLowerCase().includes('date') ? moment(data[item.key as keyof data]).format('DD-MM-YYYY'):item.key.toLowerCase().includes('attachment') ? 
//  <button className='px-3 py-2 bg-[#4096ff] rounded-md text-[#fff]' onClick={()=>Dowland(2)}>Скачать</button>:data[item.key as keyof data]
// :"Hет в наличии"