import style from './style.module.css'
import styleDrawer from 'src/index.module.css'
import {useState,useEffect} from 'react'
import { DatePicker, Empty, Input, Switch,Drawer ,Select} from 'antd'
import {program_data, programma_change_product} from 'src/pages/laboratory/program/indexData'
import {data,dataType,AllStepType,SampleStepListType,history} from 'src/mockdata/laboratoryData/index'
import DataPicker from 'src/Component/DatePickerFolder/DataPicker/DataPicker'
import SelectIndicator from 'src/Component/Select/Select'
import TextArea from 'src/Component/textarea/textArea'
import { Wait,Success } from 'src/assets/icons/laboratory'
import FormChart from 'src/Component/formchart/FormChartCard'
import { RequestToken } from 'src/Hook/Request/Request'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ListRegis } from 'src/Component/wizzard/indexdata';
import Loading from 'src/Component/Loading/Loading';
import { HistoryOutlined } from '@mui/icons-material';
import Product from 'src/pages/steps/stepone/stepone'
interface options {value:number,label:string}
interface props {
  SampleName:string
  API?:string
  catagore?:options[]
  podCatagorie?:options[]
  requirment_list?:options[]
  normativ_IDs?:options[]
  product_List?:options[],
  programma_data?:program_data
  listRegisData?:ListRegis|null
  list?:any,
  setShowWizzard:(showWizzard:boolean)=>void,
  AddProgram?:(parm:string,id?:number)=>void
  DataOrganaztionList?:{id:number,name:string}[],
  get_list_data:()=>void,
  handleChange:(parm: string|boolean|number[], name: string, id: number,type?:string,stepID?:number)=>void
}
function Sample({SampleName,catagore,listRegisData,handleChange,setShowWizzard,get_list_data,API,DataOrganaztionList,podCatagorie,requirment_list,normativ_IDs,programma_data,product_List,list,AddProgram}:props){
    const navigate=useNavigate()
    const SampleData= data[SampleName as keyof dataType]
    const [stepID,setStepId]=useState<number>(1)
    const [loading,setLoading]=useState<boolean>(true)
    const [openDrawer,setOpenDrawer]=useState<boolean>(false)
   const SampleObject={
    programSample:programma_data,
    listRegis:listRegisData
   }
    const [userlist,setuserList]=useState<{label:string,value:number}[]>([])
    async function Save () {
     if(stepID===SampleData.length){
      setLoading(false)
       const responseData=await RequestToken(API,"POST",(SampleObject as any)[SampleName])
       if(responseData.success){
        setLoading(true)
        Swal.fire({title: `Данные успешно сохранены`,icon:"success"})
        setStepId(1)
        setShowWizzard(false)
        get_list_data()
        navigate(SampleName==='programSample' ? '/laborotory/programa':'/laborotory/listregis')
      }
       else if(responseData.response?.status===403){
        navigate('/')
       }
       else{
        setLoading(true)
        Swal.fire({
          title:'Ошибка',
          text:responseData.response.data.message||responseData.response.message,
          icon:'error'
        })
       }
     }
     else{
      setStepId(prev=>prev+1)
     }
    };
    function HandleChange(parm: string|boolean|  number[], name: string, id: number,type?:string){
     handleChange(parm,name, id,type,stepID)     
    }
    function HandleChangetext(parm: string, name: string){
     let id=3
      handleChange(parm,name,id,"string",stepID)
    }
    function HandleChangeModeTagsSelect(parm:number[]){
      handleChange(parm,'productIds', 1,'',stepID) 
    }
    function Renderform(type:string,label:string,key:string,id:number){
      switch (type) {
        case "semi_input": 
          return <div className=' w-1/2 p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <Input  style={{width:"100%",height:"48px"}} type='text' 
            onChange={(e)=>{HandleChange(e.target.value,key,id)}} value={((SampleObject as any)[SampleName] as any)[key]} allowClear/></div>
        case "input":
          return <div className=' w-full p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
            <Input  onChange={(e)=>{HandleChange(e.target.value,key,id)}}  
           type='text' style={{width:"100%",height:"48px"}} value={((SampleObject as any)[SampleName] as any)[key]} allowClear/></div>
        case 'numberinput':
          return  <div className=' w-full p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <Input type='number' onChange={(e)=>{HandleChange(e.target.value,key,id,'number')}} style={{width:"100%",height:"48px"}} 
          value={((SampleObject as any)[SampleName] as any)[key]} allowClear/></div>
        case "date":
          return <div className=' w-1/2 p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <DataPicker handleChange={HandleChange} name={key} style={{width:"100%",height:"48px"}} placeholder='Выбрать дату'/></div>
        case "product":
          return  <Select mode="multiple" id={key} style={{width:'100%',height:"50px"}} options={product_List} onChange={HandleChangeModeTagsSelect}/>
          case "change_product":
            return <div className='w-full'>
              {
                programma_data?.productInTestProtocolList?.map((item:programma_change_product,index:number)=>{
                  return (
                    <div className="w-full flex my-2 px-1">
                    <div className="w-1/2 pr-1">
                      <label htmlFor="productId">Название продукта в приложении</label>
                      <Input id="productId" className='w-full h-11' value={product_List?.filter((itemProduct:options)=>itemProduct.value===item.productId)[0]?.label}/></div>
                    <div className="w-1/2 pl-1">
                      <label htmlFor="productNameInTestProtocol">Стандартное название продукта</label>
                      <Input id="productNameInTestProtocol" className='w-full h-11' value={item.productNameInTestProtocol} onChange={(e)=>handleChange(e.target.value,"productNameInTestProtocol",index)}/></div>
                   </div>
                  )
                })
              }  <div className="w-full flex flex-wrap justify-center px-1">
                <label htmlFor={key} className='w-full text-left'>Выберите продукт</label>
                 <Select  id={key} style={{width:'100%',height:"50px"}} options={product_List} onChange={(value:number)=>handleChange(value.toString(),'productId',1,'add')}/>
              </div>
            </div>
       case "select":
          return <div className='w-full p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <SelectIndicator HandleChange={HandleChange} name={key} style={{width:'100%',height:"48px"}} value={((SampleObject as any)[SampleName] as any)[key]} data={userlist} /></div>
        case "userlist":
          return <div className='w-full p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <Select mode='multiple' onChange={(value:number[])=>HandleChange(value,key,id)} options={userlist} style={{width:"100%",height:"48px"}}/></div>
        case 'textarea':
          return <div className='w-full p-1 my-1'>
            <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
          <TextArea HandleChange={HandleChangetext} name={key}  style={{width:"100%",height:"96px"}} /></div>
        case "mode_tags_select":
          return <div className='w-full p-1 my-1'>
          <label className={`${style.sample_wrapper_step_component_form_label}`}>{label}</label>
        <Select mode="multiple" id={key} style={{width:'100%',height:"50px"}} options={product_List?.map((item:options)=>{return {label:item.label,value:item.label}})} onChange={HandleChangeModeTagsSelect}/></div>
        default:
          break;
      }
    }
    function OnChangeSwitch(checked:boolean){
       HandleChange(checked,'isAccepted',1)
    }
    async function getUserList(){
      setLoading(false)
      const responseData=await RequestToken('/user/getUsersWithoutSuperAdmin','GET',null)
      if(responseData.success){
        setLoading(true)
        setuserList(responseData.object.map((item:{firstName:string,lastName:string,id:number})=>{
          return {label:item.lastName+" "+item.firstName,value:item.id}
        }))
      }
      else if(responseData.response?.status===403 || responseData.response?.status===401){
        navigate("/")
      }
      else {
        setLoading(true)
        Swal.fire({
          title:responseData.reponse.data.message||responseData.reponse.message,
          icon:'error'
        })
      }
    }
    useEffect(()=>{
     if(SampleData.filter(item=>item.stepLabel==='4.Заключение').length>0){
      getUserList()
     }
    },[])
    return(
        <div className={style.sample_wrapper}>
        { loading ? <div className={style.sample_wrapper_box}>
            <div className={`${style.sample_wrapper_step} gap-4 `}>
              {
                data[SampleName as keyof dataType].map((item:AllStepType,index:number)=>{
                    return (
                       <div key={index}
                       className={stepID===item.id ? (`bg-[#fff] w-${(1/SampleData.length).toString()} ${style.sample_wrapper_step_box}`):`
                       w-${(1/SampleData.length).toString()} ${style.sample_wrapper_step_box}`}
                      >
                         <span className={stepID>item.id ? style.success_icon:style.waiting_icon}>{stepID>item.id ? <Success/>:<Wait/>}</span>{item.stepLabel}    
                       </div>
                    )
                })
              }
            </div>
            <div className={`${style.sample_wrapper_step_component} bg-[#fff]`}>
            {
               SampleData?.filter((item:AllStepType,index:number)=>item.id===stepID).length>0 ? 
               SampleData?.filter((item:AllStepType,index:number)=>item.id===stepID).map((item:AllStepType,index:number)=>{
                    return (
                      <div className='w-full h-auto flex flex-wrap justify-center' key={index}>
                      {
                        item.component.formType==="full" ? <div className={`w-full h-full overflow-hidden`}>
                         {item.component.formTypeCatagorie==="add_programma" ?  <div style={{height:'25px'}} className='w-full flex items-cnter justify-between px-3 pt-1'>
                           <div className="w-2/3"></div>
                            <div className="w-1/3 flex justify-end"><span className='rounded-full cursor-pointer w-7 h-7 flex items-center justify-center' onClick={()=>setOpenDrawer(true)}><HistoryOutlined/></span></div>
                          </div>:""}
                         <div className={style.full_sample} style={item.component.formTypeCatagorie==="add_programma" ? {height:'650px'}:{height:"670px"}}>
                         <div className={item.component.formTypeCatagorie==='half' ? `${style.full_sample_left_catagorie} w-1/2 `:`${style.full_sample_left} w-1/4`}>
                          <FormChart list={list} AddProgram={AddProgram} DataOrganaztionList={DataOrganaztionList} handleChange={HandleChange}  catagore={catagore} podCatagorie={podCatagorie} 
                          title={item.component.title1} rows_number={item.id===3 ? "show_select":""} data={item.component.listcatagorie}/>
                          </div>
                          <div className={item.component.formTypeCatagorie==='half' ? `${style.full_sample_right} w-1/2 `:`${style.full_sample_right} w-3/4`}>
                          <FormChart list={list} AddProgram={AddProgram} product_List={product_List} handleChange={HandleChange} type={`${item.component.formTypeCatagorie}`} rows_number={item.component.rows_number} 
                            title={item.component.title} data={item.component.list} requirment_list={requirment_list} normativ_IDs={normativ_IDs}/>
                          </div>
                         </div>                         
                        </div>:
                        <div key={index} className={`${style.sample_wrapper_step_component_box} `}>
                        <div className={style.sample_wrapper_step_component_box_title}>
                          {item.component.title}
                        </div>
                         <div  className={style.sample_wrapper_step_component_form} >
                             <div className="w-full flex flex-wrap justify-start">
                             {
                                item.component.continue ?
                                <>
                                <div className='w-full my-2'>Действие</div>
                                <Switch className='mb-2' style={{backgroundColor:`${listRegisData?.isAccepted ? "#BEBEBE":"#27E066"}`}} onChange={OnChangeSwitch}/>
                                <span className='mx-2 mb-2'>{listRegisData?.isAccepted ? "Отменить":"Принять"}</span>
                                </>:""
                              }
                             </div>
                           {item.component.list.map((listitem:SampleStepListType,index:number)=>{
                            return(
                              <>
                              {
                               item.component.continue ? <>
                               { (listRegisData as any)["isAccepted"]===false ? "":Renderform(listitem.type,listitem.label,listitem.key,listitem.id)}
                               </>:  Renderform(listitem.type,listitem.label,listitem.key,listitem.id)
                              }
                              </>
                            )
                           })}
                        
                         </div>
                       </div>
                      }
                        <div className={`${style.sample_wrapper_step_component_box_rows} py-1`}>
                          {
                            stepID>1 ? <button type='button' onClick={()=>{ setStepId((prev)=>prev-1)}}
                            className={`${style.sample_wrapper_step_component_box_row_button} rounded-md`}>Hазад</button>:<div></div>
                          }
                         <button type='button' onClick={Save}
                           className={`${style.sample_wrapper_step_component_box_row_button} rounded-md`} 
                          >{stepID===SampleData.length ? "Сохранить":"Далее"}</button>
                          </div>
                      </div>
                    )
                }):<Empty/>
              }
            </div>
            </div>:<Loading/>}
            <Drawer title="История" placement="right" onClose={()=>setOpenDrawer(false)} open={openDrawer}>
            <div className={`w-full  ${styleDrawer.drawer_description}`}>
               История ранее использованных приборов по типу модели
            </div>
            <div className={styleDrawer.drawer_cards}>
            {
              history.map((item:string,index:number)=>{
                return (
                  <div className={styleDrawer.drawer_card}>
                  <div className="w-full h-1/2 mb-2  flex">
                  <div className={`w-2/3 h-full ${styleDrawer.drawer_card_title_left}`}>
                      Тип продукции
                   </div>
                   <div className={`w-1/3 h-full ${styleDrawer.drawer_card_title_right}`}>
                      Приборы
                   </div>
                  </div>
                  <div className="w-full h-1/2 flex">
                   <div className={`w-2/3 h-full ${styleDrawer.drawer_card_text_left}`}>
                     {item}
                   </div>
                   <div className={`w-1/3 h-full ${styleDrawer.drawer_card_text_right}`}>
                     10
                   </div>
                  </div>
                  </div>
                )
              })
            }
            </div>
      </Drawer>
        </div>
    )
}
export default Sample;