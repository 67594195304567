import TestingProtocol from 'src/Component/document/Laboratory/testprotocol';
import style from './style.module.css'
import { useContext, useEffect, useState } from 'react';
import { RequestToken } from 'src/Hook/Request/Request';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {testing_protocol_list} from './indexData'
import {DownloadOutlined} from '@ant-design/icons'
import moment from 'moment';
import { Empty } from 'antd';
import { UserContext } from 'src/App';
import Loading from 'src/Component/Loading/Loading';
function StepFour(){
    const {id}=useParams()
    const {setheaderTitle}=useContext(UserContext)
    const [download,setDownload]=useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true);
    const [orderId,setOrderId] = useState<string|null>(null)
    const [testing_protocol_list,setTestingProcotol_list]=useState<testing_protocol_list[]>([])
    function error(response:any){
      if(response?.response?.status===404){}
        else{
        Swal.fire({
            title:"Ошибка",
            text:response?.response?.data?.message||response?.response?.message,
            icon:'error'
          })
      }
    }
    function openDoc (id:number) {
        setDownload(true)
        setOrderId(id?.toString())
    }
    async function get_testing_protocol_list(){
        const response=await RequestToken(`/testingProtocol/byOrderId/${id}`,"GET",null)
        if(response.success){
              setTestingProcotol_list(response.object)
        }else{error(response)}
    }
    useEffect(()=>{
     get_testing_protocol_list()
     setheaderTitle('Акт испытания')
    },[])
    return (
        <>
        {
            loading ? <div className="w-full h-full mt-3 flex justify-center items-start">
            <table className="h-auto " style={{width:'97%'}}>
             <thead>
                 <tr>
                     <th className={style.table_th_id}>№</th>
                     <th className={style.table_th}>Заявитель</th>
                     <th className={style.table_th}>Hомер решения</th>
                     <th className={style.table_th}>Hомер протокола</th>
                     <th className={style.table_th}>Дата</th>
                     <th className={style.table_th_id}></th>
                 </tr>
             </thead>
             <tbody>
                 {
                     testing_protocol_list?.length>0 ? testing_protocol_list?.map((item:testing_protocol_list,index:number)=>{
                         return (
                             <tr key={index}>
                     <td className={style.table_td_id}>{item.id}</td>
                     <td className={style.table_td}>{item.applicant}</td>
                     <td className={style.table_td}>{item.numberOfProtocol}</td>
                     <td className={style.table_td}>{item.solutionNumber}</td>
                     <td className={style.table_td}>{moment(item.date).format('DD-MM-YYYY')}</td>
                     <td className={`${style.table_td_id} cursor-pointer`} onClick={()=>openDoc(item.id)}><DownloadOutlined /></td>
                 </tr>
                         )
                     }):<tr>
                         <td className='text-center' colSpan={6}><Empty description="Нет в наличии"/></td>
                     </tr>
                 }
             </tbody>
            </table>
            <div className="w-0 h-0 hidden">
             {download ?   <TestingProtocol orderID={orderId} setDownload={setDownload} setLoading={setLoading}/>:null}
            </div>
         </div>:<Loading/>
        }
        </>
    )
}
export default StepFour;