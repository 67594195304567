import style from "./style.module.css";
import { useState } from "react";
import { Minus, Plus } from "src/assets/icons/laboratory";
import { message , Input, Select, Switch } from "antd";
import { tenTableDTOList } from "../../TableWizzard/TableWizzardType";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;
interface props {
  program_data?:any,
  
  setLoading:(loading:boolean)=>void
  namesProducts:{label:string,value:number}[]
}
function TablesTestReportSeven({program_data,namesProducts,setLoading}:props) {
  const navigate=useNavigate()
  // const [list_users, setList_users] = useState<string[]>(["1"]);
  const [tenTableDTOList,setTenTableDTOList]=useState<tenTableDTOList[]>([  {
    productId: 0,
    isSuccess: false
  }
])
function HandleChange(value:number|boolean,keyname:string,id:number){
  setTenTableDTOList(tenTableDTOList.map((item:tenTableDTOList,indexTable:number)=>{
    if(indexTable===id){
      return {...item,[keyname]:value};
    }
    else{
      return item;
    }
  }))
}
function error(response_data: any) {
  setLoading(true);
  if (
    response_data.response.status === "403" &&
    response_data.response.status === 401
  ) {
    navigate("/");
  } else {
    Swal.fire({
      icon: "error",
      title:
        response_data.response.data.message || response_data.response.message,
    });
  }
}
async function Saved() {
      setLoading(false)
    const response = await RequestToken(
      "/testingProtocol/conclusion",
      "POST",
      tenTableDTOList
    );
    if (response.success) {
      setLoading(true)
      Swal.fire({
        title: response?.message || response.response.message,
        icon: "success",
      });
    } else {
      error(response);
    }
  } 

  return (
    <div
      className={`w-full h-auto ${style.table_seven} flex flex-wrap justify-center`}
    >
      <div className="w-11/12 my-5 flex">
        <div className="w-3/4 pr-3">
        <span className="w-full block">Модель</span>
        <Select
        className="w-full"
         options={namesProducts}
          placeholder="Все модели"
          onChange={(value:number)=>HandleChange(value,'productId',0)}
        />
        </div>
        <div className="w-1/4 flex items-center">
      
        </div>
      </div>
      <div className={`${style.Conclusion_box} w-11/12`}>
        <div className={style.Conclusion_box_title}>
          <div className="w-1/2">Заключение</div>
          <div className="w-1/2 flex justify-end items-center">
          <Switch className="bg-[#BEBEBE] " onChange={(checked:boolean)=>HandleChange(checked,'isSuccess',0)}/>
        <span>Соответствует</span>
            
          </div>
        </div>
        <div className={style.Conclusion_box_input}>
          <label htmlFor="Conclusion_box_input">Заключение</label>
          <Input className="w-full h-11" />
        </div>
        <div className={style.Conclusion_box_input}>
          <label htmlFor="Conclusion_box_input">Пункты</label>
          <TextArea rows={6} className="w-full h-20" />
        </div>
      </div>
      <div className={`${style.Conclusion_box_form} w-11/12`}>
        <div className={style.Conclusion_box_form_title}>
         
          <div className={style.Conclusion_box_form_title_right}>Сотрудник</div>
        </div>
              <div  className={style.Conclusion_box_form_row}>
                <div className="w-3/4">
                  {
                    program_data?.users?.map((item:{firstName:string,lastName:string,id:number},index:number)=>{return <div key={index}>
                      {item.lastName} {item.firstName}
                    </div>})
                  }
                </div>
            </div>
      </div>
      <div className={`${style.Conclusion_box_results} w-11/12`}>
         <div className={style.Conclusion_box_results_title}>
            Результаты рассмотрены согласно ИН СИЦ 01:
         </div>
         <div className={style.Conclusion_box_results_form}>
            <Select className="w-full" />
         </div>
      </div>
      <div className="w-11/12 flex justify-end my-2">
                <button
                  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
                  onClick={Saved}
                >
                  Сохранить
                </button>
      </div>
    </div>
  );
}
export default TablesTestReportSeven;
