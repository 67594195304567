import "./Styledocx.css";
import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
interface props {
  setDownload: (download: boolean) => void;
  analistics_application_document?:analistics_application_document
}
export interface analistics_application_document {
  orderNumber:string
  orderDate:string
  productNames:string[]
  applicant:string
  invoiceNumber: null|string;
  invoiceDate: null|string;
  deliveryNote: null|string;
  productCodes: string[];
  testingThirdPartyLaboratory:boolean;
  schemeOfCertification:string;
  user:any
}
const space = (
  <>
    {" "}
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
  </>
);
const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
function Analtics({ setDownload,analistics_application_document }: props) {
  const componentRef: any = useRef();
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Анализ заявки",
    onAfterPrint:()=>setDownload(false)
  });
  useEffect(() => {
    HandlePrint();
  }, []);
  console.log(analistics_application_document)
  return (
    <div className="h-auto" style={{ width: "594px" }} ref={componentRef}>
      {space}
      <div className="w-full">
        <div className="w-full text-center my-3">
          <div className="text-sm font-semibold analis_application">
            Результаты анализа
          </div>
        </div>
        <div className="w-full text-center text-sm analis_application">
          заявки № {analistics_application_document?.orderNumber} от {analistics_application_document?.orderDate} г.
        </div>
        <div className="w-full my-4">
          {
            analistics_application_document?.productNames?.map((item:string,index:number)=>{
                return (
                    <span key={index} className="w-full font-semibold text-sm analis_application">{item}</span>
                )
            })
          }
          <span
            style={{ fontSize: "10px" }}
            className="block w-full text-center analis_application"
          >
            (наименование ТСТ в соответствии Заявки)
          </span>
        </div>
        <div className="w-full text-sm my-2 analis_application">
          Заявитель:{" "}
          <span className="font-semibold text-sm underline analis_application">
            {" "}
            {analistics_application_document?.applicant}
          </span>
          <span
            className="block w-full text-center analis_application"
            style={{ fontSize: "10px" }}
          >
            (наименование заявителя)
          </span>
        </div>
        <div className="w-full text-sm analis_application">
          Заявителем предоставлены следующие документы:
        </div>
        <div className="w-full text-sm analis_application font-semibold">
          Инвойс №{analistics_application_document?.invoiceNumber} от {analistics_application_document?.invoiceDate}г. CMR-накладная № {analistics_application_document?.deliveryNote}.
        </div>
        <div className="w-full text-sm analis_application">
          По результатам анализа специалисты ОС ТСТ пришли к следующему
          заключению:
        </div>
        <div className="w-full text-sm analis_application">
          <span className="text-sm analis_application">-</span> &nbsp;&nbsp;
          наличие рассматриваемой продукции ТСТ в действующей области
        </div>
        <div className="w-full flex">
          <div className="w-1/4 text-sm analis_application">
            аккредитации ОС ТСТ{" "}
          </div>
          <div
            className="w-3/4 analis_application text-sm pb-1 border-b-2 italic border-[black] text-center"
            style={{ minHeight: "18px", maxHeight: "auto" }}
          >имеется</div>
        </div>
        <div className="w-full flex justify-between my-2">
          <div className="w-1/4 text-sm analis_application">
            - код ТНВЭД продукции
          </div>
          <div className="w-3/4 h-5 pb-1 border-b-2 border-[black] text-center">{analistics_application_document?.productCodes?.map((item:string,index:number)=>{
            return (
                <span key={index} className="px-3 py-2 italic">{item}</span>
            )
          })}</div>
        </div>
        <div className="w-full flex justify-between my-2">
          <div className="w-1/2 text-sm analis_application">
            - возможность проведения испытаний в{" "}
          </div>
          <div className="w-1/2 h-5 pb-1 border-b-2 border-[black] text-center italic">СИЦ ТСТ</div>
        </div>
        <div className="w-full flex justify-between my-2">
          <div className="w-1/3 text-sm analis_application">
            - испытание в сторонних ИЛ
          </div>
          <div className="w-2/3 h-5 pb-1 border-b-2 text-center border-[black]">{analistics_application_document?.testingThirdPartyLaboratory? "Проводится":'Не проводится'}</div>
        </div>
        <div className="w-full flex justify-between my-2">
          <div className="w-2/3 text-sm analis_application">
            - выбор предполагаемой схемы сертификации{" "}
          </div>
          <div className="w-1/3 h-5 pb-1 text-center border-b-2 border-[black] italic">{analistics_application_document?.schemeOfCertification}</div>
        </div>
        <div className="w-full ">
          <span className="text-sm analis_application">
            Результаты переговоров с Заявителем:
          </span>{" "}
          <span className="underline italic text-sm analis_application">
            По результатам обсуждения и анализа заявки со стороны ОС и Заявителя
            вопросов и возражений не возникло и достигнуто согласие на
            проведение сертификационных работ.
          </span>
        </div>
        <div className="w-full mt-3 flex">
          <div className="w-2/4 analis_application text-sm">
            Начальник ОПС ОС ТСТ
          </div>
          <div className="w-1/4 px-2">
            <div className="w-full h-5 pb-1 border-b-2 border-[black]"></div>
            <div className="w-full text-center" style={{ fontSize: "10px" }}>
              (подпись)
            </div>
          </div>
          <div className="w-1/4 px-2">
            <div className="w-full h-5 pb-1 border-b-2 text-center border-[black] text-sm">
              {analistics_application_document?.user}
            </div>
            <div className="w-full text-center" style={{ fontSize: "10px" }}>
              (Ф.И.О.)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Analtics;
