import './style.css'
import InputMask from 'react-input-mask'
interface props {
    value:string,
    name:string,
    className?:string
    handleChange:(parm:string,name:string,id?:number)=>void
}
function InputMaskReact({value,name,handleChange,className}:props){
    const handlePhoneChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
       handleChange(event.target.value,name)
      };
    return(
        <> <InputMask 
        mask="+\9\98 99 999 99 99" 
        maskChar="_" 
        onChange={handlePhoneChange}
        value={value}
        className={`${className} phone_input`||'phone_input'}
        placeholder="+998 99 999 99 99"
      /></>
    )
}
export default InputMaskReact