import './task.css';
import { useContext, useEffect, useState } from "react";
import CardItem from "../../Component/Cards/Cardbox/CardItem";
import { RequestToken } from '../../Hook/Request/Request';
import Loading from '../../Component/Loading/Loading';
import Search from '../../Component/Search/Search';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { Select, Empty,Input } from 'antd';
import Filter from 'src/Component/filter/Filter';
function Tasks() {
  const navigate =useNavigate()
  const _user=JSON.parse(localStorage.getItem('_user')||"")
  const {setheaderTitle}=useContext(UserContext)
  const [data, setData] = useState<any>([]);
  const [user_list, setUser_List] = useState<any>([]);
  const [filter_username,setFilter_username]=useState('all')
  const [loading, setLoading] = useState(true);
  async function getData(){
    setLoading(false)
    const responseUserList=await RequestToken('/user/getUsersWithoutSuperAdmin',"GET",null)
   if(responseUserList.success){
    const user=responseUserList.object.find((item:{id:number,firstName:string,lastName:string})=>{
      if(item.firstName===_user.firstName && item.lastName===_user.lastName){
        return item
      }
    })
    if(user.id){
      const Data=await RequestToken(`/order/byUser/${user.id}`,"GET",null)
      if(Data.success){
       setData([...Data.object])
        setheaderTitle("Мои задания")
        setLoading(true)
        setUser_List([...responseUserList.object.map((item:any)=>{
          return {label:item.lastName+" "+item.firstName,value:item.id}
        })])
        // setUser_List((prev:any)=>[...prev,{label:"Bсе",value:"all"}])
      }
      else if(Data.message || responseUserList.message==='Network Error'){
        navigate('/error/500')
      }
    }
   }    
 }
 function HandleChange(parm:string,name:string){
   setFilter_username(parm)
 }
 async function SearchApplicant(parm:string,type?:string){
  if(parm){
    const response=await RequestToken(`/order/byApplicant/${parm}`,"GET",null)
    if(response.success){
      setData(response.object)
    }
  }
  else{
    getData()
  }
}
async function Searchuser(parm:number){
  if(parm){
    const response=await RequestToken(`/order/byUser/${parm}`,"GET",null)
  if(response.success){
    setData(response.object)
  }
  }else{getData()}
}
 useEffect(()=>{
   getData()
 },[])
 console.log(data)
//  useEffect(()=>{
//   setData(data.filter(item=>item.user.length>0)
//   .filter(item1=>{
//    let checkingUsername=item1.user.filter(item2=>{
//     if(filter_username=='all'){
//       return item2
//     }
//     else if(item2.username===filter_username){
//       return item2
//     }
//    } )
//    if(checkingUsername.length>0){
//      return item1
//    }
//   }))
//  })

  return (
    <div className="vazifalar">
      <div className="vazifalar_header">
        <Input placeholder="Поиск" onChange={(e)=>SearchApplicant(e.target.value)} className="ml-2.5" style={{width:"260px",height:'44px'}}/>
        <Select allowClear placeholder="Сотрудники" options={user_list} style={{width:'260px'}} className="h-11 ml-2.5" onChange={(value:number)=>Searchuser(value)}/>
         {/* <DatePicker style={{width:"220px",height:"45px"}} className='mx-2.5'/> */}
      </div>
      <>
        {loading ? <div className="vazifar_wrapper_row">
        {data.length>0 && data ? <div className="vazifa_row grid  grid-cols-4">
          {
           data.map((item:any, index:number) => {
              return (
                  <CardItem location={{pathname:'/dashboard',hash:'',search:''}} 
                   data={item} key={index}/>
              );
            })
          }
        </div>:<Empty description="Hет в наличии"/>
        }</div>:<Loading/>}
      </>
    </div>
  );
}

export default Tasks;
