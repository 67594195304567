import "./style.css";
import { useEffect, useState } from "react";
import { SampleStepListType } from "src/mockdata/laboratoryData";
import { Input, Select } from "antd";
import SelectIndicator from "src/Component/Select/Select";
import { Minus, Plus } from "src/assets/icons/laboratory";
import { RequestToken } from "src/Hook/Request/Request";
import {options,optionsbackend,props} from './indexdata'
const {Option}=Select

function FormChart({
  catagore,
  handleChange,
  data,
  title,
  rows_number,
  type,
  podCatagorie,
  product_List,
  requirment_list,
  list,
  AddProgram,
  normativ_IDs,
  DataOrganaztionList
}: props) {
  const title_step_3="Данные организации"
  console.log(requirment_list)
  console.log(normativ_IDs)
  const [Optionskey, setOptionsKey] = useState<string>("name");
  const [dataOrganazation,setDataOrganazation]=useState({
  nameOfCertificationOrgan: "",
  numberRegister: "",
  address: "",
  phone: "",
  email: "",
  website: ""
  })
  const [OptionsData,setOptionsData]=useState({
    name:catagore,
    parent:podCatagorie,
  })
  function HandleChange(parm: string|number|string[]|number[], name: string, id: number) {
     if(typeof parm==='string'){
      handleChange(parm,name,id)
     }
     else if(name==='name'||"parent"){
      handleChange(parm.toString(),name,123)
     }
  }
  
  function HandleChangeModeTagsSelect(parm:number[]){
    handleChange(parm,'productIds', 1) 
  }
  function RenderPage(type: string,key:string, placeholder?: string,options?:string) {
    switch (type) {
      case "input":
        return <Input style={{ height: "42px" }} placeholder={placeholder} />;
      case "select":
        return (
          <div className="render_page_box w-full flex flex-wrap">
            <SelectIndicator
              HandleChange={HandleChange}
              data={options==='parent' ? podCatagorie:(OptionsData as any)[key]}
              name={key}
              style={{
                width: "100%",
                minHeight: "42px",
                maxHeight: "60px",
                display: "flex",
                flexWrap: "wrap",
              }}
              placeholder={placeholder}
            />
          </div>
        );
      case "product":
          return  <Select mode="multiple" id={key} style={{width:'100%',height:"50px"}} options={product_List} onChange={HandleChangeModeTagsSelect}/>
      default:
        break;
    }
  }
  function RenderPageDataOrganazation(type: string,key:string, placeholder?: string) {
    switch (type) {
      case "input":
        return <Input className="cursor-no-drop" style={{height: "42px"}} placeholder={placeholder} value={(dataOrganazation as any)[key]}/>;
      case "select":
        return (
          <div className="render_page_box w-full flex flex-wrap">
            <Select  style={{width:'100%'}} className="cursor-no-drop" value={(dataOrganazation as any)[key]}/>
          </div>
        );
      default:
        break;
    }
  }
  async function HandleChangeSelect(value:number){
      const responseDataOrganaztion=await RequestToken(`/certificationOrgan/${value}`,"GET")
      if(responseDataOrganaztion.success){
        setDataOrganazation(responseDataOrganaztion.object)
        handleChange(value.toLocaleString(),'certificationOrganId',1)
      }   
  }
  function Add_select(parm: string, name: string, id: number){
   handleChange(parm,name,id)
  }
  return (
    <div className="FormChart_wrapper ">
      <div className="FormChart_wrapper_header">
        <div className="FormChart_wrapper_header_title">
           {title}
        </div>
        {rows_number==="show_select" ? <div><Select placeholder={title} style={{width:"200px"}} onChange={HandleChangeSelect}>
         {DataOrganaztionList?.map((item:any,index:number)=>{
          return (
            <Option value={item.id}>{item.name}</Option>
          )
         })}
        </Select ></div>:""}
      </div>
      <div
        className={
          rows_number === "5"
            ? `FormChart_wrapper_body w-full grid grid-flow-col gap-y-2 gap-x-5 grid-rows-5`
            : "FormChart_wrapper_body w-full grid grid-flow-col gap-y-2 gap-x-5 grid-rows-4 overflow-auto"
        }>
          
        {type === "add_programma" ? (
          <>
            {list?.map((listItem:any, index: number) => {
              return (
                <div className="flex  flex-wrap w-full h-auto" key={index}>
                    <div
                      className={"flex flex-wrap "}
                      style={{ width: "85%" }}
                      key={index}
                    >
                      <div className="FormChart_wrapper_body_card_form my-2">
                       <SelectIndicator HandleChange={Add_select} id={index} data={requirment_list} name="requirementId" style={{width:'100%'}}/>               
                      </div>
                      <div className="FormChart_wrapper_body_card_form">
                        {/* <SelectIndicator HandleChange={Add_select} id={index}  data={normativ_IDs} name="normativeIds" style={{width:'100%'}}/> */}
                        <Select mode="multiple" options={normativ_IDs}  style={{width:'100%'}} onChange={(value:number[])=>handleChange(value,"normativeIds",index)}/>
                      </div>
                    </div>
                    <span className="h-full flex items-center ">
                      <button
                        className="h-11 px-3 bg-[#ccc] rounded-md"
                        onClick={() => {if(AddProgram){AddProgram("remove_program", index)}} }
                      >
                        <Minus />
                      </button>
                    </span>
                  </div>
              )
            })}
            <div className="w-full">
              <button
                className="px-3 py-2 bg-[blue] rounded-md"
                onClick={() => {if(AddProgram){AddProgram("add_program")}} }
              >
                <Plus />
              </button>
            </div>
          </>
        ) : (
          data?.map((item: SampleStepListType, index: number) => {
            return (
              <div className="FormChart_wrapper_body_card" key={index}>
                <div className="FormChart_wrapper_body_card_label">
                  {item.label}
                </div>
                <div className={"FormChart_wrapper_body_card_form"}>
                  {title===title_step_3 ? RenderPageDataOrganazation(item.type,item.key, item.placholder):
                  RenderPage(item.type,item.key, item.placholder,item.options)}
                </div>
                <div
                  className={
                    item.decription
                      ? "FormChart_wrapper_body_card_description"
                      : "hidden"
                  }
                >
                  {item.decription}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
export default FormChart;
