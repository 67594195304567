import { useContext, useEffect, useState } from "react";
import { Tabs } from "antd"
import type { TabsProps } from 'antd';
import Measurment from "src/Component/Organ/standart/measurment";
import { UserContext } from "src/App";
import { RequestToken } from "src/Hook/Request/Request";
import { measurment_list_type, warehouse_list_type } from "./indexType";
import Loading from "src/Component/Loading/Loading";
import Warehouse from "src/Component/Organ/standart/warehouse";
function OrganStandart(){
    const {setheaderTitle}=useContext(UserContext)
    const [activeKey,setActiveKey]=useState<string>('1')
    const [loading,setLoading]=useState<boolean>(true)
    const [measurment_list,setMeasurment_list]=useState<measurment_list_type[]>()
    const [warehouse_list,setWarehouse_list]=useState<warehouse_list_type[]>()
    const items: TabsProps['items'] = [
        {
          key: '1',
          label: <div className="text-base">Единицы измерения</div>,
          children: <Measurment get_measurment_list={get_standarts} measurment_list={measurment_list}/>,
        },
        {
          key: '2',
          label: <div className="text-base">Cклад</div>,
          children:<Warehouse loading={loading} get_measurment_list={get_standarts} warehouse_list={warehouse_list}/>,
        },
        
      ];
      const onChange = (key: string) => {
        setActiveKey(key)
      };
    async function get_standarts(){
        setLoading(false)
        if(activeKey==='1'){
            const res_measurment_list=await RequestToken('/measurement?page=0&size=100','GET',null)
        if(res_measurment_list.success){
         setMeasurment_list(res_measurment_list.object?.map((item:measurment_list_type)=>{return {id:item.id,name:item.name}}))
         setLoading(true)
        }
        }
        else if(activeKey==='2'){
            const res_measurment_list=await RequestToken('/warehouse','GET',null)
            if(res_measurment_list.success){
             setWarehouse_list(res_measurment_list.object?.map((item:warehouse_list_type)=>{return {id:item.id,name:item.name,address:item.address}}))
             setLoading(true)
            }
        }
    }
    useEffect(()=>{
        get_standarts()
    setheaderTitle('Стандарты добавлены сотрудниками organ Unicon')
    },[activeKey])
    return (
       <div className="w-full h-full flex items-center justify-center">
         {
            loading ? <div className="w-full h-full">
            <Tabs defaultActiveKey={activeKey} items={items} onChange={onChange} />
       </div>:<Loading/>
         }
       </div>
    )
}
export default OrganStandart