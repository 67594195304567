export interface modalcontentObject {
    id:number,
    placholder:string,
    label:string,
    name:string,
    type:string,
    requirmend:boolean
}
export interface data {
    nameOfCertificationOrgan: string,
    numberRegister: string,
    address: string,
    phone: string,
    email: string,
    website: string
    name:string
  }
export interface data_res {
    id:number
    nameOfCertificationOrgan: string,
    numberRegister: string,
    address: string,
    phone: string,
    email: string,
    website: string
    name:string
}
export const remove_key_list=["createAt","updateAt","createdBy","updatedBy","isActive"]

export const ModalContent:modalcontentObject[]=[
    
    {
        id:2,
        placholder:"unicon.uz@gmail.com",
        label:"Электронная почта",
        name:"email",
        type:'email',
        requirmend:true
    },
    {
        id:3,
        placholder:"+998 71 100 00 00",
        label:"Номер телефона",
        name:"phone",
        type:'text',
        requirmend:true
    },
    {
        id:4,
        placholder:"Minbuloq ko'chasi,38-uy",
        label:"Адрес",
        name:"address",
        type:'text',
        requirmend:true
    },
    {
        id:5,
        placholder:"https://unicon.uz",
        label:"Веб-сайт",
        name:"website",
        type:'text',
        requirmend:true
    },
    {
        id:6,
        placholder:"12",
        label:"Регистр номера",
        name:"numberRegister",
        type:'text',
        requirmend:true
    },
    {
        id:1,
        placholder:"Unicon.uz MCHJ",
        label:"Название органа по сертификации",
        name:"nameOfCertificationOrgan",
        type:'text',
        requirmend:true
    },
]