import { user_object } from "../stepconclusion/indexType";

export interface data {
  id: number;
}
export interface props {
  type: string;
}
export interface user_type {
  label: string;
  value: number;
}
export interface Step_seven_page_type {
  id: number;
  label: string;
  key: string;
  type: string;
  value: string;
  description?: string;
  options?: string;
}
export interface Step_seven_type {
  id: number;
  label: string;
  key: string;
}
export interface productInFormOfCertificateObject {
  productId: number;
  productNameInFormOfCertificate: string;
}
export interface certificateDTO {
  productInFormOfCertificateList: productInFormOfCertificateObject[];
  isValidUntil: boolean;
  fromDate: string;
  validUntil: string | null;
  amount: string;
  laboratoryId: number;
  // actOfSelectionId:number,
  infoAboutActOfInspectionOfProduction: string;
  inspectionControl: string;
  specialMarks: string;
  markOfConformity: string;
  numberOfBlank: string;
  registrationOfGovernmentRegistry: string;
  visibleNumberInCertificate: boolean;
  application: boolean;
}
export interface organazation_list_type {
  nameOfCertificationOrgan: string;
  numberRegister: string;
  id: number;
}
export interface step_seven_data_type {
  certificationOrganId: number;
  orderId: number;
  directorId: number;
  expertId: number;
  certificateDTOList: certificateDTO[];
}

export const Step_seven_page: Step_seven_page_type[] = [
  {
    id: 1,
    label: "Наименование ОС",
    key: "certificationOrganId",
    type: "select",
    value: "",
    options: "organazation",
  },
  {
    id: 2,
    label: "Номер свидетельства об аккредитации",
    key: "numberOfRegistir",
    value: "auto",
    type: "input",
  },
  {
    id: 3,
    label: "Наименование производителя",
    key: "manufacturer",
    value: "auto",
    type: "mode_tags",
  },
  {
    id: 4,
    label: "Схема сертификации",
    key: "schemeCertification",
    value: "auto",
    type: "input",
  },
  {
    id: 5,
    label: "Заявитель",
    key: "applicant",
    value: "auto",
    type: "input",
  },
  {
    id: 6,
    label: "Адрес",
    key: "applicantAddress",
    value: "auto",
    type: "input",
  },
  {
    id: 6,
    label: "Руководитель органа по сертификации",
    key: "directorId",
    value: "",
    type: "select",
    options: "user_list",
  },
  {
    id: 6,
    label: "Експерт",
    key: "expertId",
    value: "",
    type: "select",
    options: "user_list",
  },
];
export const new_certificat_obj = {
  productInFormOfCertificateList: [],
  fromDate: "",
  validUntil: "",
  amount: "",
  // actOfSelectionId: 0,
  laboratoryId: 0,
  infoAboutActOfInspectionOfProduction: "",
  inspectionControl: "",
  specialMarks: "",
  isValidUntil: false,
  markOfConformity: "",
  numberOfBlank: "",
  registrationOfGovernmentRegistry: "",
  visibleNumberInCertificate: false,
  application: false,
};
export const Step_seven_page_certification: Step_seven_page_type[] = [
  {
    id: 1,
    label: "Заявка",
    key: "numberOrder",
    value: "auto",
    type: "input",
  },
  {
    id: 2,
    label: "",
    key: "application",
    value: "",
    type: "checkbox",
    description: "Приложенае",
  },
  {
    id: 3,
    label: "",
    key: "visibleNumberInCertificate",
    value: "",
    type: "checkbox",
    description: "Показате кол-во в сертификате",
  },
  {
    id: 4,
    label: "Наименование продукции",
    key: "productId",
    value: "",
    type: "product",
  },
  {
    id: 5,
    label: "Код ТН ВЕД",
    key: "codeProduct",
    value: "auto",
    type: "input",
  },
  {
    id: 6,
    label: "От",
    key: "fromDate",
    value: "",
    type: "date",
  },
  {
    id: 7,
    label: "",
    key: "isValidUntil",
    value: "",
    type: "checkbox",
    description: "Без срока",
  },
  {
    id: 8,
    label: "Действителен до",
    key: "validUntil",
    value: "",
    type: "unlockdate",
  },
  {
    id: 9,
    label: "Количество или серийное производство",
    key: "amount",
    value: "",
    type: "input",
  },
  {
    id: 10,
    label: "НД",
    key: "normativ",
    value: "auto",
    type: "mode_tags",
    options: "normativ",
  },
  // nomrativ tepasidagi
  {
    id: 11,
    label: "а) Документов",
    key: "characteristics",
    value: "auto",
    type: "input",
  },
  {
    id: 12,
    label: "б) Испытания образцов",
    key: "laboratoryId",
    value: "",
    type: "laboratory",
  },
  {
    id: 13,
    label: "с) Акта проверки производства",
    key: "infoAboutActOfInspectionOfProduction",
    value: "",
    type: "input",
  },
  {
    id: 14,
    label: "Инспекционный контроль осуществляется с периодичностъю",
    key: "inspectionControl",
    value: "",
    type: "input",
  },
  {
    id: 15,
    label: "Особые отметки",
    key: "specialMarks",
    value: "",
    type: "input",
  },
  {
    id: 16,
    label: "Знак соответствия проставляется",
    key: "markOfConformity",
    value: "",
    type: "input",
  },
  {
    id: 17,
    label: "N бланка (проставленный)",
    key: "numberOfBlank",
    value: "",
    type: "input",
  },
  {
    id: 18,
    label: "Зарегистрирован в Государственном реестре",
    key: "registrationOfGovernmentRegistry",
    value: "",
    type: "input",
  },
];
export const Step_seven_page_cert: Step_seven_type[] = [
  {
    id: 1,
    label: "Наименование ОС",
    key: "nameOfOrganization",
  },
  {
    id: 1,
    label: "Номер свидетельства об аккредитации",
    key: "numberRegister",
  },
  {
    id: 1,
    label: "Наименование производителя",
    key: "manufacturer",
  },
  //   {
  //     id: 1,
  //     label: "Схема сертификации",
  //     key: "nameOfOrganization",
  //   },
  {
    id: 1,
    label: "Заявитель",
    key: "applicant",
  },
  {
    id: 1,
    label: "Адрес",
    key: "addressOfApplicant",
  },
  {
    id: 1,
    label: "Руководитель органа по сертификации",
    key: "directorOfCertificationOrgan",
  },
  {
    id: 1,
    label: "Експерт",
    key: "expert",
  },
  //   {
  //     id: 1,
  //     label: "Заявка",
  //     key: "nameOfOrganization",
  //   },

  {
    id: 1,
    label: "Наименование продукции",
    key: "namesOfProducts",
  },
  {
    id: 1,
    label: "От",
    key: "fromDate",
  },
  {
    id: 1,
    label: "Действителен до",
    key: "validUntil",
  },
  {
    id: 1,
    label: "Количество или серийное производство",
    key: "amount",
  },
  {
    id: 1,
    label: "НД",
    key: "normativeList",
  },
  {
    id: 1,
    label: "а) Документов",
    key: "document",
  },
  {
    id: 1,
    label: "б) Испытания образцов",
    key: "laboratoryName",
  },
  {
    id: 1,
    label: "с) Акта проверки производства",
    key: "infoAboutActOfInspectionOfProduction",
  },
  {
    id: 1,
    label: "Инспекционный контроль осуществляется с периодичностъю",
    key: "inspectionControl",
  },
  {
    id: 1,
    label: "Особые отметки",
    key: "specialMarks",
  },
  {
    id: 1,
    label: "Знак соответствия проставляется",
    key: "markOfConformity",
  },
  {
    id: 1,
    label: "N бланка (проставленный)",
    key: "numberOfBlank",
  },
  {
    id: 1,
    label: "Зарегистрирован в Государственном реестре",
    key: "registrationOfGovernmentRegistry",
  },
];
export interface response_cert_list {
  nameOfOrganization: string;
  numberOfBlank: string;
  registrationOfGovernmentRegistry: string;
  isApplication: boolean;
  namesOfProducts: string[];
  codesOfProducts: string[];
  fromDate: null | string;
  validUntil: null | string;
  numberRegister: string;
  manufacturer: string[];
  normativeList: any;
  schemeOfCertification: string;
  applicant: string;
  addressOfApplicant: string;
  document: string;
  laboratoryName: string;
  infoAboutActOfInspectionOfProduction: string;
  inspectionControl: string;
  specialMarks: string;
  markOfConformity: string;
  directorOfCertificationOrgan: string;
  expert: string;
}
export interface response_document_cert_organ {
  nameOfCertificationOrgan:string;
  numberRegister:string;
  address:string; 
  phone:string;
  email:string;
  website:string;
}
export interface response_document_certificat {
  certificationOrgan: response_document_cert_organ;
  givingDate: string;
  registrationOfGovernmentRegistry: string|null;
  productCodes: string[];
  applicant:string;
  manufacturers: string[];
  productNames: string[];
  normativeNames: string[];
  amounts: number[];
  schemeOfCertification: string;
  applicantAddress:string;
  actOfSelectionDate:string;
  invoiceNumber:string;
  invoiceDate:string;
  deliveryNote:string;
  testingProtocolNumber:string;
  testingProtocolDate:string;
  director:user_object
  expert: user_object
}
