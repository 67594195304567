import style from '../TablesTestReportTwo/style.module.css'
import {useState} from 'react'
import { Input, Select, Switch,notification} from 'antd';
import { Minus, Plus } from 'src/assets/icons/laboratory';
import { normativ, requirment, sevenTableDTOList, testMethods } from '../../TableWizzard/TableWizzardType';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RequestToken } from 'src/Hook/Request/Request';
interface props {    requirment_list:requirment[]
    namesProducts?:{label:string,value:number}[],
    progremID:number
    setLoading:(loading:boolean)=>void}
function TablesTestReportFour({namesProducts,progremID,setLoading,requirment_list}:props){
    const navigate=useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [writeTable, setWriteTable] = useState<boolean>(false);
    const [product_id,setProduct_ID]=useState<number>(0)
    const [sevenTableDTOList,setSevenTableDTOList]=useState<sevenTableDTOList[]>([
        {
          productId: 0,
          requirementId: 0,
          normativeIds: [],
          testMethodIds: [],
          levelOfTestingSeverityVoltageDropsForFirstPeriod: 0,
          parametersOfTheTestStimulusFirstPeriod: "",
          voltageDropsForFirstPeriodResult: "",
          voltageDropsForFirstPeriodIsSuccess: false,
          levelOfTestingSeverityVoltageDropsForSecondPeriod: 0,
          parametersOfTheTestStimulusSecondPeriod: "",
          voltageDropsForSecondPeriodResult: "",
          voltageDropsForSecondPeriodIsSuccess: false,
          levelOfTestingSeverityShortTermInterruptions: 0,
          parametersOfTheTestStimulusShortTermInterruptions: "",
          shortTermInterruptionsResult: "",
          shortTermInterruptionsIsSuccess: false
        }
      ])
      function error(response_data: any) {
        setLoading(true);
        if (
          response_data.response.status === "403" &&
          response_data.response.status === 401
        ) {
          navigate("/");
        } else {
          Swal.fire({
            icon: "error",
            title:
              response_data.response.data.message || response_data.response.message,
          });
        }
      }
      async function Saved() {
        if (writeTable) {
            setLoading(false)
          const response = await RequestToken(
            "/testingProtocol/seventhTable",
            "POST",
            sevenTableDTOList
          );
          if (response.success) {
            setLoading(true)
            Swal.fire({
              title: response?.message || response.response.message,
              icon: "success",
            });
          } else {
            error(response);
          }
        } else {
          errorNotifaction();
        }
      }
      function errorNotifaction() {
        api.error({
          message: "Ошибка",
          description: "Вы не можете изменить расписание",
        });
      }
      function HandleChange(parm:string|number|boolean,keyname:string,id:number,type?:string){
        if(writeTable){
            setSevenTableDTOList(sevenTableDTOList.map((item:sevenTableDTOList,indexTable:number)=>{
              if(keyname==='normativeIds' || keyname==='testMethodIds'){
                return {...item,[keyname]:[...(item as any)[keyname],parm]}
              }
              else if(keyname==='requirementId' && typeof parm==='number'){
                return {...item,requirementId:parm}
              }
              else{
                if(item.productId===product_id){
                  if(type==='number_input' && typeof parm==='string'){
                    return {...item,[keyname]:parseInt(parm)}
                   }
                   else{
                    return {...item,[keyname]:parm}
                   }
               }
               else {
                   return item
               }
              }
          }))
        }
        else{errorNotifaction()}
        }
    function WriteTableFunc(checked: boolean) {
        setWriteTable(checked);
        if (checked && namesProducts!==undefined) {
          api.success({
            message: `Успех`,
            description: "Вы подтвердили заполнение таблицы",
          });
          if(namesProducts?.length>1){
            const new_arr=[]
            for (let i = 0; i < namesProducts.length; i++) {
               new_arr.push({
                productId: namesProducts[i].value,
                requirementId: 0,
                normativeIds: [],
                testMethodIds: [],
                levelOfTestingSeverityVoltageDropsForFirstPeriod: 0,
                parametersOfTheTestStimulusFirstPeriod: "",
                voltageDropsForFirstPeriodResult: "",
                voltageDropsForFirstPeriodIsSuccess: false,
                levelOfTestingSeverityVoltageDropsForSecondPeriod: 0,
                parametersOfTheTestStimulusSecondPeriod: "",
                voltageDropsForSecondPeriodResult: "",
                voltageDropsForSecondPeriodIsSuccess: false,
                levelOfTestingSeverityShortTermInterruptions: 0,
                parametersOfTheTestStimulusShortTermInterruptions: "",
                shortTermInterruptionsResult: "",
                shortTermInterruptionsIsSuccess: false
              })
            }
            setSevenTableDTOList(new_arr)
            setProduct_ID(namesProducts[0].value)
        }
        } else {
          api.info({
            message: `Подтвержденный`,
            description: `Хотите заполнить таблицу "Заполнить таблицу" нажать на кнопкуы`,
          });
        }
       
      }
      function RemoveItem(id: number, keyname: string, index: number) {
        setSevenTableDTOList(
            sevenTableDTOList.map(
            (item: sevenTableDTOList, indexFourthTable: number) => {
              if (index === indexFourthTable) {
                if (Array.isArray((item as any)[keyname])) {
                  return {
                    ...item,
                    [keyname]: (item as any)[keyname].filter(
                      (item1: number, index1: number) => item1 !== id
                    ),
                  };
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }
          )
        );
      }
      
      const standarts=requirment_list.filter((item:requirment,index:number)=>item.id===sevenTableDTOList[0].requirementId)[0]
  
    return (
    <div className={style.TablesTestReportTwo_wrapper}>
          {contextHolder}
      <div className="w-full my-1">
        <Switch
          className="bg-[#ccc]"
          checked={writeTable}
          id="write_table"
          onChange={(checked: boolean) => WriteTableFunc(checked)}
        />
        <label htmlFor="write_table" className="ml-2">Заполнить таблицу?</label>
      </div>
        <table className={style.TablesTestReportTwo}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={5}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={5}><label htmlFor="test_seveb" className='w-full'>YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                        <Select className='w-full' options={requirment_list?.map((itemRequir:requirment)=>{
                            return {label:itemRequir.name,value:itemRequir.id}
                        })} onChange={(value:number)=>HandleChange(value,'requirementId',0)}/></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                                {
                                    sevenTableDTOList[0].testMethodIds?.map((itemTestMethodId:number)=>{
                                        if(standarts?.testMethods.filter((itemTest:testMethods)=>itemTest.id===itemTestMethodId).length>0){
                                            return (  <div className={`w-full my-2 flex `}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.testMethods?.filter((itemTest:testMethods,index:number)=>itemTest.id===itemTestMethodId)[0].name}</div>
                                            <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(itemTestMethodId,'testMethodIds',0)}><Minus/></button></span>
                                        </div>)
                                        }
                                    })
                                }
                                <Select className='w-full' options={standarts?.testMethods.map((itemTest:testMethods)=>{return {label:itemTest.name,value:itemTest.id}})}
                                onChange={(value:number)=>HandleChange(value,'testMethodIds',0)}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {
                                    sevenTableDTOList[0].normativeIds?.map((itemNormativId:number)=>{
                                        if(standarts?.normativeList.filter((itemNormativ:normativ)=>itemNormativ.id===itemNormativId).length>0){
                                            return (  <div className={`w-full my-2 flex `}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.normativeList?.filter((itemNormativ:normativ,index:number)=>itemNormativ.id===itemNormativId)[0].normativeNumber}</div>
                                            <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(itemNormativId,'normativeIds',0)}><Minus/></button></span>
                                        </div>)
                                        }
                                    })
                                }
                                <Select className='w-full' options={standarts?.normativeList.map((itemNorm:normativ)=>{return {label:itemNorm.normativeNumber,value:itemNorm.id}})}
                                onChange={(value:number)=>HandleChange(value,'normativeIds',0)}/>
                            </div>
                        </td>
                    </tr>
                      <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>
                        <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={5}>
                        <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                      </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                                <Select className={style.TablesTestReportTwo_model_select} options={namesProducts} onChange={(value:number)=>setProduct_ID(value)}/>
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
            {sevenTableDTOList.filter((item:sevenTableDTOList)=>item.productId===product_id).map((item:sevenTableDTOList,indexTable:number)=>{
                return (
                    <table className={`${style.TablesTestReportTwo_two} mb-5`}>
                <thead>
                    <tr>
                        <th colSpan={2} rowSpan={2}>Вид испытаний и или воздействие</th>
                        <th colSpan={2} rowSpan={2} >Степень жесткости испытаний</th>
                        <th colSpan={2} rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                        <th colSpan={2}>Критерий качества функционарования</th>
                        <th colSpan={2} rowSpan={2}>Результат испытания</th>
                    </tr>
                    <tr>
                        <th>По НДС</th>
                        <th>По НДС</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`} colSpan={10}>Контактный разряд</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>Непрямые воздействия</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px'}} value={item.levelOfTestingSeverityVoltageDropsForFirstPeriod} type='number'
                              onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityVoltageDropsForFirstPeriod',indexTable,'number_input')}/>
                            </div>
                        </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                                <Input className='w-3/4' style={{height:'45px'}} value={item.parametersOfTheTestStimulusFirstPeriod}
                                 onChange={(e)=>HandleChange(e.target.value,'parametersOfTheTestStimulusFirstPeriod',indexTable)}/> <span className='w-1/4 pl-2'>kV</span>
                                <p>(10 одиночных разрядов)</p>
                            </div>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`}>B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} value={item.voltageDropsForFirstPeriodResult}
                              onChange={(e)=>HandleChange(e.target.value,'voltageDropsForFirstPeriodResult',indexTable)}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.voltageDropsForFirstPeriodIsSuccess}
                                 onChange={(checked)=>HandleChange(checked,'voltageDropsForFirstPeriodIsSuccess',indexTable)}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>Непрямые воздействия</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px'}} type='number' value={item.levelOfTestingSeverityVoltageDropsForSecondPeriod}
                              onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityVoltageDropsForSecondPeriod',indexTable,'number_input')}/>
                            </div>
                        </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                                <Input className='w-3/4' style={{height:'45px'}} value={item.parametersOfTheTestStimulusSecondPeriod}
                                onChange={(e)=>HandleChange(e.target.value,'parametersOfTheTestStimulusSecondPeriod',indexTable)}/> <span className='w-1/4 pl-2'>kV</span>
                                <p>(10 одиночных разрядов)</p>
                            </div>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`}>B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} value={item.voltageDropsForSecondPeriodResult}
                              onChange={(e)=>HandleChange(e.target.value,'voltageDropsForSecondPeriodResult',indexTable)}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.voltageDropsForSecondPeriodIsSuccess}
                                onChange={(checked)=>HandleChange(checked,'voltageDropsForSecondPeriodIsSuccess',indexTable)}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`} colSpan={10}>Воздушный разряд</td>
                    </tr>
                    {/* 2qator */}
                    <tr>
                        <td className={style.TablesTestReportTwo_td}  colSpan={2}>Непрямые воздействия</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px'}} type='number' value={item.levelOfTestingSeverityShortTermInterruptions}
                              onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityShortTermInterruptions',indexTable,'number_input')} />
                            </div>
                        </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                                <span className='w-full'>2 kV</span>
                                <p>(10 одиночных разрядов)</p>
                            </div>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`}>B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} value={item.parametersOfTheTestStimulusShortTermInterruptions}
                               onChange={(e)=>HandleChange(e.target.value,'parametersOfTheTestStimulusShortTermInterruptions',indexTable)}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.shortTermInterruptionsIsSuccess}
                                onChange={(checked)=>HandleChange(checked,'shortTermInterruptionsIsSuccess',indexTable)}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    {/* oxiri */}
                    <tr >
                        <td  colSpan={10} className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`}>
                          <div className="w-full ">
                          <span className='w-full '>
                            Примечание:
                            </span>
                            <div className='w-1/3 '>
                            <Input value={item.shortTermInterruptionsResult} onChange={(e)=>HandleChange(e.target.value,'shortTermInterruptionsResult',indexTable)}/>
                            </div>
                          </div>
                        </td>
                    </tr>
                </tbody>
            </table>
                )
            })}
              <div
                className="w-full  flex items-end justify-end"
                style={{ height: "10%" }}
              >
                <button className="px-3 py-2 rounded-lg border-2 mr-2">
                  Отменить
                </button>
                <button
                  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
                  onClick={Saved}
                  disabled={!writeTable}
                >
                  Сохранить
                </button>
              </div>
    </div>
    )
}
export default TablesTestReportFour;