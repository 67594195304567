import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  normativ_list_type,
  test_method_list,
} from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal,Input, Switch, DatePicker} from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import { UserContext } from "src/App";
interface props {
  test_method_list?: normativ_list_type[];
}
interface position {
  name: string;
}
interface position_list {
    id:number,
    name:string
}
const { TextArea } = Input;
function Position({ test_method_list }: props) {
  const navigate=useNavigate()
  const {setheaderTitle}=useContext(UserContext)
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number|null>(null);
  const [position_list,setPosition_list]=useState<position_list[]>([])
  const [new_position,setNew_position]=useState<position>({
    name:'',
  })
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotifaction(id:number) {
    Swal.fire({
      title: "Осторожность",
      icon:'warning',
      text:'Вы уверены, что хотите удалить эту позицию?',
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText:'Отмена'
    }).then((result) => {
      if (result.isConfirmed) {
       Delete(id)
      } 
    });
  }
  function Cancel() {
    setOpenModal(false);
    setNew_position({
      ...new_position,
      name: "",
    });
  }
  function UpdateopenModal(id:number){
   setNew_position(position_list?.filter((item:position_list)=>item.id===id)[0])
   setOpenModal(true)
   setUpdateId(id)
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_data = await RequestToken(updateId ? `/position/${updateId}`:`/position`, updateId ? "PUT":"POST", new_position);
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      get_position_list()
    } else {
      error(res_data);
    }
  }
  async function Delete(id:number){
    const res_list=await RequestToken(`/position/${id}`,"DELETE",null)
    if(res_list.success){
        get_position_list()
        Swal.fire("Удалено!", "", "success");
    }else{error(res_list)}
  }
  async function get_position_list(){
    const res_position_list=await RequestToken('/position?page=0&size=100','GET',null)
    if(res_position_list.success){
        setPosition_list(res_position_list.object?.map((item:position_list,index:number)=>{
            return {
                id:item.id,
                name:item.name
            }
        }))
    }
    else{error(res_position_list)}
  }
  useEffect(()=>{
    setheaderTitle('Список должностей')
    get_position_list()
  },[])
  return (
    <div className="w-full pl-7 pr-8 h-full overflow-auto">
      <Modal centered open={openModal} footer={null} onCancel={Cancel}>
        <div className="w-full font-semibold text-2xl">
          {updateId? 'Изменить положение':"Добавить позиция"}
        </div>
        <form action="test_method" onSubmit={Saved}>
            <label className="w-full block mt-2" htmlFor="name">Название позиция</label>
            <Input id="name" required className="h-11" placeholder="" value={new_position.name}  onChange={(e)=>setNew_position({...new_position,name:e.target.value})}/>
            <div className="w-full flex justify-end my-2">
                <button onClick={Cancel} type="button" className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md">Отмена</button>
                <input type="submit" id="test_method" value={'Сохранить'} className="bg-[#1677FF] rounded-md text-[#fff] px-3 py-2"/>
            </div>
        </form>
      </Modal>
      <div className="w-full mb-2">
        <button
          className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
          onClick={() => setOpenModal(true)}>Добавить позицию<Add />
        </button>
      </div>
      <table className="w-full h-auto">
        <thead>
          <tr>
            <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">№</th>
            <th className="bg-[#F2F4F8] text-left p-3">Названия должностей</th>
            <th className="bg-[#F2F4F8] p-3" style={{ width: "60px" }}
            ></th>
            <th className="bg-[#F2F4F8] p-3" style={{ width: "60px" }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {position_list ? (
            position_list?.map((item: position_list, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {index+1}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.name}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="text-center p-3">
                    <span className="text-xl text-[red] cursor-pointer" onClick={()=>DeleteNotifaction(item.id)}>
                      <DeleteOutlined />
                    </span>
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="text-center p-3">
                    <span className="text-xl cursor-pointer" onClick={()=>UpdateopenModal(item.id)}>
                      <EditOutlined />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default Position;
