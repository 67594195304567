import { useState } from "react";
import { Modal, Input, Empty } from "antd";
import Swal from "sweetalert2";
import { DeleteOutlined,EditOutlined } from "@ant-design/icons";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate } from "react-router-dom";
import { measurment_list_type, warehouse_list_type } from "src/pages/standart/organ/indexType";
import Loading from "src/Component/Loading/Loading";
interface new_warehouse {
  name: string;
  address: string;
}
interface props {
  loading?:boolean
    warehouse_list?: warehouse_list_type[];
  get_measurment_list: () => void;
}
function Warehouse({ get_measurment_list, warehouse_list,loading}: props) {
  const navigate = useNavigate();
  const _role = localStorage.getItem("_role");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [update_ware_house,setUpdateWareHouse]=useState<number|null>(null)
  const [new_warehouse, setWareHouse] = useState<new_warehouse>({
    name: "",
    address:""
  });
  function DeleteNotification(id: number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите отключить этот измерение?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  }
  async function Delete(id: number) {
    const res_test_method_list = await RequestToken(
      `/warehouse/${id}`,
      "DELETE",
      null
    );
    if (res_test_method_list.success) {
      get_measurment_list();
      Swal.fire("Удалено!", "", "success");
    } else {
      error(res_test_method_list);
    }
  }
  function Cancel() {
    setWareHouse({ ...new_warehouse, name: "" });
    setIsOpenModal(false);
    setUpdateWareHouse(null)
  }
  function UpdateModal(id:number){
    if(warehouse_list && warehouse_list?.length > 0){
      const selectedItem = warehouse_list?.find((item: warehouse_list_type) => item.id === id);
      if (selectedItem) {
        setWareHouse({
          name: selectedItem.name,
          address: selectedItem.address,
        });
      }
      setUpdateWareHouse(id)
      setIsOpenModal(true)
    }
  }
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_measurment = await RequestToken(
        update_ware_house? `/warehouse/${update_ware_house}`:"/warehouse",
        update_ware_house ? "PUT":"POST",
      new_warehouse
    );
    if (res_measurment.success) {
      get_measurment_list();
      Cancel();
      Swal.fire("Успех", res_measurment?.message, "success");
    } else {
      error(res_measurment);
    }
  }
  console.log(loading)
  return (
   <div className="w-full h-full flex items-center justify-center">
     {
      loading ? <div className="w-full h-full">
    
      <div className={_role === "EMPLOYEE_AGENCY" || _role === "EMPLOYEE_LABORATORY" ? "w-full mb-2" : "hidden"}>
        <button
          className="bg-[#1677FF] text-[#fff] px-3 py-2 rounded-md"
          onClick={() => setIsOpenModal(true)}
        >
          Добавить cклад
        </button>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th
              className="bg-[#F2F4F8] text-left p-3"
              style={{ width: "50px" }}
            >
              №
            </th>
            <th className="bg-[#F2F4F8] text-left p-3">Название склада</th>
            <th className="bg-[#F2F4F8] text-left p-3">Адрес склада</th>
            <th className={_role === "ADMIN_OF_AGENCY" || _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8]" : "hidden"} style={{ width: "50px" }}></th>
            <th className={_role === "ADMIN_OF_AGENCY" || _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8]" : "hidden"} style={{ width: "50px" }}></th>
          </tr>
        </thead>
        <tbody>
          {warehouse_list !== undefined ? (
            warehouse_list?.map(
              (item: warehouse_list_type, index: number) => {
                return (
                  <tr key={index}>
                    <td
                      className="bg-[#fff] p-3"
                      style={{ border: "1px solid #DDE1E6", width: "50px" }}
                    >
                      {index + 1}
                    </td>
                    <td className="bg-[#fff] p-3" style={{ border: "1px solid #DDE1E6" }}>{item.name}</td>
                    <td className="bg-[#fff] p-3" style={{ border: "1px solid #DDE1E6" }}>{item.address}</td>
                    <td className={_role === "ADMIN_OF_AGENCY" || _role === "ADMIN_OF_LABORATORY" ? "bg-[#fff] p-3" : "hidden"} style={{ border: "1px solid #DDE1E6", width: "50px" }}>
                      <span className="text-xl text-[red] cursor-pointer" onClick={() => DeleteNotification(item.id)}>
                        <DeleteOutlined />
                      </span>
                    </td>
                    <td className={_role === "ADMIN_OF_AGENCY" || _role === "ADMIN_OF_LABORATORY" ? "bg-[#fff] p-3" : "hidden"} style={{ border: "1px solid #DDE1E6", width: "50px" }}>
                    <span className="text-xl cursor-pointer cursor-pointer" onClick={()=>UpdateModal(item.id)}>
                      <EditOutlined />
                    </span>
                    </td>
                  </tr>
                );
              }
            )
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_AGENCY" || _role === "ADMIN_OF_LABORATORY" ? 3 : 2}>
                <Empty description="Нет в наличии" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>:<Loading/>
     }
      <Modal open={isOpenModal} footer={null} onCancel={Cancel}>
      <div className="w-full my-2 font-semibold text-2xl">
      {update_ware_house ? "Изменить информацию о складе":'Добавить склад'}  
      </div>
      <form action="form" onSubmit={Saved}>
        <label htmlFor="name">Название склада</label>
        <Input
          id="name"
          required className="h-11"
          value={new_warehouse.name}
          onChange={(e) =>
              setWareHouse({ ...new_warehouse, name: e.target.value })
          }
        />
         <label htmlFor="address">Адрес склада</label>
        <Input
          id="address"
          required className="h-11"
          value={new_warehouse.address}
          onChange={(e) =>
              setWareHouse({ ...new_warehouse, address: e.target.value })
          }
        />
        <div className="w-full flex justify-end">
          <div className="w-full flex justify-end my-2">
            <button
              onClick={Cancel}
              type="button"
              className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
            >
              Отмена
            </button>
            <input
              type="submit"
              id="form"
              value={"Сохранить"}
              className="bg-[#1677FF] cursor-pointer rounded-md text-[#fff] px-3 py-2"
            />
          </div>
        </div>
      </form>
    </Modal>
   </div>
  );
}
export default Warehouse;
