import { useContext, useEffect, useState } from "react";
import style from "./style.module.css";
import { res_testing_protocol_List, programID_data,user_list, search_obj} from "./indexdata";
import CollapseComponent from "src/Component/Collapse/Collapse";
import UpdateModal from "src/Component/Modal/UpdateModal";
// import Navbar from "src/Component/navbar";
import moment  from "moment"
import TableWizzard from "src/Component/Laborotry/TableWizzard/TableWizzard";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
import Swal from "sweetalert2";
import { UserContext } from "src/App";
import { Empty, Input, Select } from "antd";
import {DownloadOutlined} from '@ant-design/icons'
import { Add } from "src/assets/icons/laboratory";
import TestingProtocol from "src/Component/document/Laboratory/testprotocol";
function Testreport() {
  const {setheaderTitle}=useContext(UserContext)
  const _role=localStorage.getItem('_role')
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(true);
  const [download, setDownload] = useState<boolean>(true);
  const [tablewriteChecked, setTableWrite] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [openDrawer,setOpenDrawer]=useState<boolean>(false)
  const [tabIdName,setTabName]=useState<string>('Основание')
  const [tabsID,setTabId]=useState<number>(1)
  const [testProtocol_ID,setTestProtocolID]=useState<number>(0)
  const [programID,setProgramId]=useState<number>(0)
  const [user_list,setUser_list]=useState<user_list[]>([])
  const [programID_data,setProgramID_data]=useState<any>()
  const [test_protocol_list,setTest_Protocol_list]=useState<res_testing_protocol_List[]>([])
  const [stateTime,setTimeState]=useState<{dateFrom:string,dateUntil:string,dateOfReceiving:string}>({
    dateFrom:'',
    dateUntil:'',
    dateOfReceiving:''
  })
  const [search_obj,setSearch_Obj]=useState<search_obj>({
    user:null
  })
  const [program_list,setProgram_List]=useState<{label:string,value:string}[]>([])
  const [search_data,setSearch_data]=useState({
    user:"/testingProtocol/byUser/",
    search_id:"/testingProtocol/bySolutionNumber/", 
    date:"/testingProtocol/byDate/",
    user_value:1,
    search_value:'',
    date_value:''
  })
  const tabchildList=[
    {
      id:1,
      component:<CollapseComponent setTimeState={setTimeState} stateTime={stateTime}  program_ID={programID_data} setProgramID={setProgramID_data}/>
    }
  ]
  function Changetabs(parm:string){
   if(stateTime.dateFrom && stateTime.dateOfReceiving && stateTime.dateUntil){
    setTabName(parm)
   }
   else{
    Swal.fire('Пожалуйста, введите время!','Необходимо ввести дату поставки товара, а также даты начала и окончания тестирования товара.','warning')
   }
  }
  const Save = async (data:string) => {
   setLoading(false)
    if (data) {
      const res=await RequestToken(`/program/doc/${data}`,"GET",null)
      if(res){
        setLoading(true)
        setOpenModal(false)
        setProgramId(parseInt(data))
        if(res.success){ setShowTable(false);
          setProgramID_data(res.object)
        }
        else{
          Swal.fire({
            icon:'error',
            title:res.response.data.message||res.response.message
          })
        }
      }
    }
  };
  async function HandleChangeSearch(
    parm: string|number,
    keyname: string,
    valueName:string
  ) {
   if((parm!=='')){
    setLoading(false)
    setSearch_Obj({...search_data,user:parm})
    const response_data=await RequestToken((search_data as any)[keyname]+parm,"GET",null)
    if(response_data.success){
     setLoading(true)
     setTest_Protocol_list(response_data.object)
    }
    else {error(response_data)}
   }
   else{
    get_test_protocol()
   }
  }
  function error(res_data:any){
    setLoading(true)
    Swal.fire({
      icon:'error',
      title:"Ошибка",
      text:res_data.response?.data?.message||res_data?.response?.message||res_data?.message
    })
  }
  function DownloadTestProtocol(id:number){
    setDownload(true)
    setTestProtocolID(id)
  }
  async function get_test_protocol(){
    setLoading(false)
    const response_test_protocol_list=await RequestToken('/testingProtocol?page=0&size=100',"GET",null)
    if(response_test_protocol_list){
      setTest_Protocol_list(response_test_protocol_list.object)
      setLoading(true)
    }else{error(response_test_protocol_list)}    
  }
  async function get_user_list(){
    setLoading(false)
    const response_user_list=await RequestToken('/user/getUsersWithoutSuperAdmin',"GET",null)
    if(response_user_list.success){
       get_test_protocol()
         get_solution_number_list()
       setUser_list(response_user_list.object.map((item:{firstName:string,lastName:string,id:number})=>{return {label:item.lastName+" "+item.firstName,value:item.id}}))
    }
    else{error(response_user_list)}
  }
  async function get_solution_number_list(){
    const res_solution_number_list=await RequestToken('/program/all','GET',null)
    if(res_solution_number_list.success){
      setProgram_List(res_solution_number_list.object?.map((item:{applicant:string,programId:number,solutionNumber:string})=>{return {label:("Нумер решения: "+item.solutionNumber+" "+" Заявитель: "+item.applicant),value:item.programId.toString()}}))   
    }
  }
  useEffect(()=>{
    setheaderTitle("Протокол испытаний")
   get_user_list()
  },[])
  return (
    <div className="w-full h-full flex justify-center items-center">
     {loading ?  <div className="w-full h-full px-5"><div className={`${style.test_report_navbar} mb-2`}>
        <div className="w-full flex">
          <div className="w-4/5 ">
            <div className="w-full text-xl text-[#1F2A37] font-bold">ПРОГРАММА Сертификационных испытаний оборудования</div>
            <div className="w-full flex">
              {
                !showTable ? <div className="w-64 flex"><div className={tabIdName==='Основание' ? "cursor-pointer rounded-t-lg py-2.5 px-5 text-[#3D5AF1] font-semibold bg-[#fff] flex ":"cursor-pointer bg-[#F3F3F3] text-[#C0C0C0] px-5 text-xs font-light flex justify-center items-center"} onClick={()=>Changetabs('Основание')}>Основание</div>
                <div className={tabIdName==='Таблицы' ? "cursor-pointer rounded-t-lg py-2.5 px-5 text-[#3D5AF1] font-semibold bg-[#fff] flex ":"cursor-pointer bg-[#F3F3F3] text-[#C0C0C0] px-5 text-xs font-light flex justify-center items-center"} onClick={()=>Changetabs('Таблицы')}>Таблицы</div>
                </div>:null
              }
              {showTable ? <><div className="w-64 pr-1">
                <label className="block w-full text-sm" htmlFor="user"></label>
                <Select className="w-full" value={search_obj?.user} options={user_list} onChange={(value:number|string)=>HandleChangeSearch(value,'user','')}/></div>
              <div className="w-64 px-1">
                <label className="block w-full text-sm" htmlFor="search_value"></label>
                <Input className="w-full" id="search_value" style={{height:'45px'}}/>
              </div></>:null}
            </div>
          </div>
          <div className="w-1/5 flex justify-end items-center" style={{paddingRight:"5px"}}><button className=" h-auto flex items-center text-[#FFFFFF] rounded-lg bg-[#1A56DB] p-3 text-sm" onClick={()=>setOpenModal(true)}>Добавить ТСТ <span className="mx-1"><Add/></span></button></div>
        </div>
      </div>
      <div className={`${style.test_report_body}`}>
        {showTable ? (
        <table className="w-full h-auto">
        <thead>
           <tr>
               <th className={`${style.table_th_id} text-left`}>№</th>
               <th className={style.table_th}>Заявитель</th>
               <th className={style.table_th}>Hомер решения</th>
               <th className={style.table_th}>Hомер протокола</th>
               <th className={style.table_th}>Дата</th>
               <th className={style.table_th_id}></th>
           </tr>
        </thead>
        <tbody>
         {
          test_protocol_list?.length > 0 ? test_protocol_list?.map((item:res_testing_protocol_List,index:number)=>{
               return (     <tr key={index}>
                   <td className={style.table_td_id}>{item.id}</td>
    <td className={style.table_td}>{item.applicant}</td>
    <td className={style.table_td}>{item.solutionNumber}</td>
    <td className={style.table_td}>{item.numberOfProtocol}</td>
    <td className={style.table_td}>{moment(item.date).format('DD-MM-YYYY')}</td>
    <td className={style.table_td}><span className="p-3 cursor-pointer" onClick={()=>DownloadTestProtocol(item.id)}><DownloadOutlined /></span></td>
                </tr>)
           }):<tr>
               <td colSpan={6} className='text-center'><Empty description="Нет в наличии"/></td>
           </tr>
         }                     
        </tbody>
      </table>
        ) : (
       <div className={style.test_report_body_wizzard}>
            <div className={showTable && tabIdName==="Таблицы" ? style.test_report_body_wizzard_title:'hidden'}>
              Информация о заявителе 
            </div>
            <div className={style.test_report_body_wizzard_component} style={showTable || tabIdName==="Таблицы" ? {height:"100%"}:{height:"100%"}}>
               {
                 tabIdName==="Основание" ?  tabchildList.filter(item=>item.id===tabsID)?.map((item:{id:number,component:JSX.Element},index:number)=>{
                  return (
                    <div className={`w-full ${style.test_report_body_wizzard_component_element}`} key={index}>
                      {item.component}
                    </div>
                  )
                }):<TableWizzard stateTime={stateTime} setTimeState={setTimeState} programID={programID} program_data={programID_data} 
                user_list={user_list} tablewriteChecked={tablewriteChecked}/>  
                }
            </div>
          </div>
        )}
      </div> 
      <UpdateModal
      solution_list={program_list}
        Save={Save}
        isOpenModal={openModal}
        setIsOpenModal={setOpenModal}
        listkey={["number_one", "number_two"]}
      />
      {
        download ? <div className="w-0 h-0 hidden"><TestingProtocol orderID={testProtocol_ID} setDownload={setDownload} setLoading={setLoading}/></div>:null
      }
      {/* <TestingProtocol orderID={testProtocol_ID} setDownload={setDownload} setLoading={setLoading}/> */}
      </div>:<Loading/>}
    </div>
  );
}
export default Testreport;
