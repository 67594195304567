import { useEffect } from "react";
import { Route,Routes,useNavigate } from "react-router-dom";
import Home from "./pages/Home/index";
import Deadline from "./pages/Deadline/index";
import Dashboard from "./pages/dashboard/index";
import Authorization from "./pages/Authorization/index";
import Tasks from "./pages/task/index";
import Statment from "./pages/statement/index";
import DashboardSuperAdmin from "./pages/Admin/pages/Dashboard/Dashboard";
import Analtical from "./pages/Admin/pages/Analytical/Analytical";
import FormStep from "./pages/form/FormStep";
import Error from "./pages/error/Error";
import AddAriza from "./pages/labTest/addAriza";
import Assigment from "./pages/Admin/pages/Assigment/Assigment";
import Monitoring from "src/pages/Admin/pages/monitoring/index";
import AddData from "./pages/Admin/pages/addstandart/index";
import Newuser from "./pages/Admin/pages/newuser/newUser";
import Error404 from "./pages/error/Error404";
import RenderDocx from "./Component/document/renderDocx";
import LayOut from './Layout/LayOut'
import ListRegis from "./pages/laboratory/listRegis/index";
import Program from "./pages/laboratory/program/index";
import Testreport from "./pages/laboratory/testreport/index";
import OrganizationData from "./pages/laboratory/organizationdata/index";
import AcceptStatment from "./pages/Admin/pages/acceptstatement";
import Numbered from "./pages/laboratory/numbered";
import LaboratoryStandart from "./pages/standart/laboratory";
import Position from "./pages/Admin/pages/standart/position";
import OrganStandart from "./pages/standart/organ";
function AppAssisent(){
    const navigate=useNavigate()
    const token = localStorage.getItem("_token") ? localStorage.getItem("_token"):"";
    const roles = localStorage.getItem("_role") ? localStorage.getItem("_role"):'';
    useEffect(()=>{
       if(roles!==' ' || roles){}
      else{
        navigate("/")
      }
    },[])
    return (
        <Routes>
        <Route path="/" element={<Authorization />} />
        {token && (
          <>
            {roles === "EMPLOYEE_AGENCY" && (
              <>
                <Route element={<LayOut />}>
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                  <Route path="/home/:id" element={<Home/>}></Route>
                  <Route path="/home/edit/:id" element={<FormStep />}></Route>
                  <Route path="/deadline" element={<Deadline />}></Route>
                  <Route path="/vazifalar" element={<Tasks />}></Route>
                  
                  <Route path="/arizalar" element={<Statment/>}></Route>
                  <Route path="/standart/organ" element={<OrganStandart/>}></Route>
                  <Route path="/addariza" element={<AddAriza/>}></Route>
                  {/* <Route path="/docx/:docxID" element={<RenderDocx/>}></Route> */}
                <Route path="/error/:id" element={<Error />} ></Route>
                </Route>
              </>
            )}
            {roles === "SUPER_ADMIN" && (
              <>
                <Route element={<LayOut/>}>
                <Route path="/dashboard/:id" element={<Dashboard/>}></Route>
                  <Route
                    path="/admin/dashboard"
                    element={<DashboardSuperAdmin />}
                  ></Route>
                  <Route
                    path="/admin/monitoring"
                    element={<Monitoring/>}
                  ></Route>
                 
                   <Route
                    path="/admin/standart/position"
                    element={<Position/>}
                  ></Route>
                  <Route
                    path="/admin/arizalar"
                    element={<Statment/>}
                  ></Route>
                    <Route
                    path="admin/Settings"
                    element={<Newuser/>}
                  ></Route>
                <Route path="admin/Analytios" element={<Analtical />}></Route>
                </Route>
                <Route path="/error/:id" element={<Error />} />
              </>
            )}
            {
              roles==="EMPLOYEE_LABORATORY" && (
                <>
                 <Route element={<LayOut/>}>
                <Route path="/dashboard/:id" element={<Dashboard/>}></Route>
                  <Route
                    path="/laborotory/deadline"
                    element={<Deadline/>}
                  ></Route>
                  <Route
                    path="/admin/addData"
                    element={<AddData/>}
                  ></Route>
                   <Route path="/home/:id" element={<Home/>}></Route>
                  <Route
                    path="/laborotory/vazifalar"
                    element={<Tasks/>}
                  ></Route>
                   <Route
                    path="/laborotory/listregis"
                    element={<ListRegis/>}
                  ></Route>
                  <Route
                    path="/laborotory/programa"
                    element={<Program/>}
                  ></Route>
                    <Route
                    path="laborotory/prtokol"
                    element={<Testreport/>}
                  ></Route>
                  <Route path="/standart/laboratory" element={<LaboratoryStandart/>}></Route>
                  <Route
                    path="laborotory/organizationdata"
                    element={<OrganizationData/>}
                  ></Route>
                </Route>
                <Route path="/error/:id" element={<Error />} />
                </>
              )
            }
            {roles === "ADMIN_OF_AGENCY" && (
              <>
                <Route element={<LayOut />}>
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                  <Route path="/home/:id" element={<Home/>}></Route>
                  <Route path="/standart/organ" element={<OrganStandart/>}></Route>
                  <Route path="/home/edit/:id" element={<FormStep />}></Route>
                  <Route path="/deadline" element={<Deadline />}></Route>
                  <Route path="/vazifalar" element={<Tasks />}></Route>
                  <Route path="/arizalar" element={<Statment/>}></Route>
                  {/* <Route path="/list" element={<List/>}></Route> */}
                  <Route path="/accept" element={<AcceptStatment/>}></Route>
                  <Route
                    path="/admin/Appointment"
                    element={<Assigment/>}
                  ></Route>
                  
                  {/* <Route path="/docx/:docxID" element={<RenderDocx/>}></Route> */}
                <Route path="/error/:id" element={<Error />} ></Route>
                </Route>
              </>
            )}
               {
              roles==="ADMIN_OF_LABORATORY" && (
                <>
                 <Route element={<LayOut/>}>
                <Route path="/dashboard/:id" element={<Dashboard/>}></Route>
                  <Route
                    path="/laborotory/deadline"
                    element={<Deadline/>}
                  ></Route>
                   <Route path="/home/:id" element={<Home/>}></Route>
                   <Route path="/admin/addData" element={<AddData/>}></Route>
                  <Route
                    path="/laborotory/vazifalar"
                    element={<Tasks/>}
                  ></Route>
                  <Route path="/standart/laboratory" element={<LaboratoryStandart/>}></Route>
                   <Route
                    path="/laborotory/listregis"
                    element={<ListRegis/>}
                  ></Route>
                  <Route
                    path="/laborotory/programa"
                    element={<Program/>}
                  ></Route>
                    <Route
                    path="laborotory/prtokol"
                    element={<Testreport/>}
                  ></Route>
                  <Route
                    path="laborotory/organizationdata"
                    element={<OrganizationData/>}
                  ></Route>
                      <Route
                    path="/laborotory/numbered"
                    element={<Numbered/>}
                  ></Route>
                </Route>
                <Route path="/error/:id" element={<Error />} />
                </>
              )
            }
          </>
        )
        }
        
        <Route path="/error/:id" element={<Error />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    )
}
export default AppAssisent;