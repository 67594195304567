import "./addData.css";
import style from "./style.module.css";
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import { Modal, Input, Switch, Select, DatePicker } from "antd";
import TableUsers from "src/Component/AdminComponent/Table/Table";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loading from "src/Component/Loading/Loading";
import DataPicker from "src/Component/DatePickerFolder/DataPicker/DataPicker";
import AddBox from "src/Component/data/addbox/addbox";
import { UserContext } from "src/App";
interface dataheader {
  title: string;
  dataIndex: string;
  width: number;
  maxWidth: number;
}
interface catagoire_id_object {
  id: number;
  name: string;
}
interface data {
  gost: {
    normativeNumber: string;
    name: string;
    description: string;
  };
  measurment: {
    name: string;
    isActive: boolean;
  };
  warehouse: {
    name: string;
    address: string;
  };
  test_method: {
    name:string,
    fullName:string,
    description:string,
    entryYear: string|null,
    validUntil:string|null,
    isValid: boolean,
  };
  requirement: {
    name: string;
    testCode: string;
    testMethodIds: number[];
    normativeIds: number[];
    categoryId: number;
  };
  appliance: {
    name: string;
    manufacturer: string;
    serialNumber: string;
    dateOfCertification: string;
  };
  category: {
    parentId: number | null;
    name: string;
  };
  laboratory: {
    name: string;
    address: string;
    isActive: boolean;
  };
  position: {
    name: string;
  };
}
interface test_method_list {
  id: number;
  name: string;
  description: string;
}
const { Option } = Select;
const { TextArea } = Input;
function AddData() {
  const { setheaderTitle } = useContext(UserContext);
  const navigate = useNavigate();
  const role = localStorage.getItem("_role");
  const [modal, setModal] = useState<boolean>(false);
  const [updateStandart, setUpdateStandart] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [new_cataogrie, setNew_Catagorie] = useState<boolean>(false);
  const [count, setCount] = useState<number>(100);
  const [standartId, setStandartId] = useState<number>(0);
  const [catagore_ids_list, setCatagore_ids_list] = useState<
    catagoire_id_object[]
  >([]);
  const [podcatagore_ids_list, setPodCatagore_ids_list] = useState<
    catagoire_id_object[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [resdata, setResData] = useState<any>([]);
  const [test_method_list, setTest_method_list] = useState<test_method_list[]>(
    []
  );
  const [data, setData] = useState<data>({
    gost: {
      normativeNumber: "",
      name: "",
      description: "",
    },
    measurment: {
      name: "",
      isActive: true,
    },
    warehouse: {
      name: "",
      address: "",
    },
    test_method: {
      name: "",
      fullName: "",
      description: "",
      entryYear: "",
      validUntil: "",
      isValid: true,
    },
    requirement: {
      name: "",
      testCode: "",
      testMethodIds: [],
      normativeIds: [],
      categoryId: 0,
    },
    appliance: {
      name: "",
      manufacturer: "",
      serialNumber: "",
      dateOfCertification: "",
    },
    category: {
      parentId: null,
      name: "",
    },
    laboratory: {
      name: "",
      address: "",
      isActive: true,
    },
    position: {
      name: "",
    },
  });
  //EMPLOYEE_LABORATORY
  const EMPLOYEE_LABORATORY: string[] = [
    "gost",
    "test_method",
    "requirement",
    "category",
    "warehouse",
    "laboratory",
    "appliance",
  ];
  const ADMIN_OF_LABORATORY: string[] = [
    "gost",
    "test_method",
    "requirement",
    "category",
    "warehouse",
    "laboratory",
    "appliance",
  ];
  const EMPLOYEE_AGENCY: string[] = ["measurment", "warehouse"];
  const ADMIN_OF_AGENCY: string[] = ["measurment", "warehouse"];
  const SUPER_ADMIN: string[] = ["position"];
  const all: string[] = [
    "gost",
    "test_method",
    "requirement",
    "category",
    "warehouse",
    "laboratory",
    "measurment",
    "warehouse",
    "position",
    "appliance",
  ];
  const laboratory_list = {
    EMPLOYEE_LABORATORY: EMPLOYEE_LABORATORY,
    EMPLOYEE_AGENCY: EMPLOYEE_AGENCY,
    SUPER_ADMIN: SUPER_ADMIN,
    all: all,
  };
  // requirment,normativ,testmethodni,catagoriyani,
  const [ActiveKey, setActiveKey] = useState<string>("1");
  type items = {
    key: string;
    object: string;
    label: JSX.Element;
    children: {
      api: string;
      saveApi: string;
    };
    dataheader: dataheader[];
    modalConent: JSX.Element;
  };
  async function Save(obejctName: string, api: string, id?: number) {
    setLoading(false);
    const resdata = await (id !== undefined
      ? RequestToken(`${api}/${id}`, "PUT", data[obejctName as keyof data])
      : RequestToken(api, "POST", data[obejctName as keyof data]));
    console.log(resdata);
    if (resdata.success) {
      setIsOpenModal(false);
      GetData();
      setLoading(true);
      Swal.fire({
        title: resdata.message,
        icon: "success",
      });
    } else if (resdata.success === false) {
      console.log("dasjdk");
      setLoading(true);
      Swal.fire({
        title: resdata.message,
        icon: "success",
      });
    } else if (
      resdata?.response?.status === 403 ||
      resdata?.response?.status === 401
    ) {
      setLoading(true);
      navigate("/");
    } else {
      setLoading(true);
      Swal.fire({
        title: resdata.response.data.message || resdata.response.message,
        icon: "error",
      });
    }
  }
  const handleSwitchChange = (checked: boolean) => {
    setNew_Catagorie(checked);
    if (new_cataogrie) {
      setData({ ...data, category: { ...data.category, parentId: null } });
    }
  };
  const items: items[] = [
    {
      key: "1",
      object: "gost",
      label: <div className="AddData_wrapper_tabs_header">Hовые ГОСТ</div>,
      children: {
        api: "/normative?page=0&size=30",
        saveApi: "/normative",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        {
          title: "Нормативный номер",
          dataIndex: "normativeNumber",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Нормативное название",
          dataIndex: "name",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Нормативное описание",
          dataIndex: "description",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <>
          <label htmlFor="decription_normativ">Нормативный номер</label>
          <Input
            className="mb-4 h-11 text-base w-full"
            onChange={(e) =>
              HandleChange("gost", "normativeNumber", e.target.value)
            }
            value={updateStandart ? data.warehouse.name : undefined}
          />
          <label htmlFor="name_normativ">Нормативное название</label>
          <TextArea
            rows={6}
            id="name_normativ"
            style={{ width: "750px", height: "120px" }}
            onChange={(e) => HandleChange("gost", "name", e.target.value)}
            className="text-base"
            value={updateStandart ? data.gost.name : undefined}
          />
          <label htmlFor="decription_normativ">Нормативное описание</label>
          <TextArea
            rows={6}
            id="name_normativ"
            style={{ width: "750px", height: "120px" }}
            onChange={(e) =>
              HandleChange("gost", "description", e.target.value)
            }
            className="text-base"
            value={updateStandart ? data.gost.description : undefined}
          />
        </>
      ),
    },
    {
      key: "2",
      object: "measurment",
      label: (
        <div className="AddData_wrapper_tabs_header">Eдиницу измерения</div>
      ),
      children: {
        api: `/measurement?page=0&size=${count}`,
        saveApi: "/measurement",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "ГОСТ", dataIndex: "name", width: 350, maxWidth: 550 },
      ],
      modalConent: (
        <>
          <TextArea
            style={{ width: "100%", height: "120px" }}
            className="text-base my-1"
            onChange={(e) => HandleChange("measurment", "name", e.target.value)}
            value={updateStandart ? data.measurment.name : undefined}
          />
        </>
      ),
    },
    {
      key: "3",
      object: "warehouse",
      label: <div className="AddData_wrapper_tabs_header">Cклад</div>,
      children: {
        api: "/warehouse",
        saveApi: "/warehouse",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "Имя склада", dataIndex: "name", width: 350, maxWidth: 550 },
        {
          title: "Aдрес склада",
          dataIndex: "address",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_organiztion" className="text-grey-500 ">
            Название склада
          </label>
          <Input
            id="name_organiztion"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="имя"
            onChange={(e) => HandleChange("warehouse", "name", e.target.value)}
            value={updateStandart ? data.warehouse.name : undefined}
          />
          <label htmlFor="adress_organiztion" className="text-grey-500 ">
            Адрес склада
          </label>
          <Input
            className="h-11 text-base w-full"
            id="adress_organiztion"
            required
            placeholder="адрес"
            onChange={(e) =>
              HandleChange("warehouse", "address", e.target.value)
            }
            value={updateStandart ? data.warehouse.address : undefined}
          />
        </div>
      ),
    },
    {
      key: "4",
      object: "test_method",
      label: <div className="AddData_wrapper_tabs_header">Mетод испытания</div>,
      children: {
        api: `/testMethod?page=0&size=${count}`,
        saveApi: "/testMethod",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "ГОСТ", dataIndex: "name", width: 350, maxWidth: 550 },
        {
          title: "Oписание",
          dataIndex: "description",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_test_method" className="text-grey-500 ">
            Hазвание метода тестирования
          </label>
          <Input
            id="name_test_method"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="ГОСТ 3345-1976"
            onChange={(e) =>
              HandleChange("test_method", "name", e.target.value)
            }
            value={updateStandart ? data.test_method.name : undefined}
          />
          <label htmlFor="name_test_method" className="text-grey-500 ">
            Полное название метода испытаний
          </label>
          <TextArea
          rows={5}
            id="name_test_method"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Кабели, провода и шнуры. Метод определения электрического сопротивления изоляции
            1-XI-1981, 2-X-1988"
            onChange={(e) =>
              HandleChange("test_method", "fullName", e.target.value)
            }
            value={updateStandart ? data.test_method.fullName : undefined}
          />
          <div className="w-full">
          <label htmlFor="description_test_method" className="text-grey-500 ">
            Oписание метода испытаний
          </label><Switch className="bg-[#ccc]"/>  
          </div>          
        </div>
      ),
    },
    {
      key: "5",
      object: "requirement",
      label: <div className="AddData_wrapper_tabs_header">Требование</div>,
      children: {
        api: `/requirement?page=0&size=${count}`,
        saveApi: "/requirement",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "ГОСТ", dataIndex: "name", width: 350, maxWidth: 550 },
        { title: "Oписание", dataIndex: "testCode", width: 350, maxWidth: 550 },
        {
          title: "Mетод испытания",
          dataIndex: "testMethods",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Нормативный список",
          dataIndex: "normativeList",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="requirement_name" className="text-grey-500 ">
            Hазвание метода тестирования
          </label>
          <Input
            id="requirement_name"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="имя"
            onChange={(e) =>
              HandleChange("requirement", "name", e.target.value)
            }
            value={updateStandart ? data.requirement.name : undefined}
          />
          <label htmlFor="requirement_testCode" className="text-grey-500 ">
            описание метода испытаний
          </label>
          <Input
            className="h-11 text-base w-full"
            id="requirement_testCode"
            required
            placeholder="адрес"
            onChange={(e) =>
              HandleChange("requirement", "testCode", e.target.value)
            }
            value={updateStandart ? data.requirement.testCode : undefined}
          />
          <label htmlFor="requirement_testCodeIDs" className="text-grey-500 ">
            описание метода испытаний
          </label>
          <Select
            className="h-11 text-base w-full"
            mode="multiple"
            onChange={HandleChangeSelect}
            id="requirement_testCodeIDs"
          >
            {test_method_list.map((item: test_method_list, index: number) => {
              return (
                <Option value={item.id} key={index}>
                  <div className="test_method_list_name">{item.name}</div>
                  <div className="test_method_list_description">
                    {item.description}
                  </div>
                </Option>
              );
            })}
          </Select>
          <label htmlFor="normativ" className="text-grey-500 ">
            описание метода испытаний
          </label>
          <div className="w-full">
            <AddBox
              className="h-11 text-base w-full"
              Handle={HandleTagsModeSelectNormativ}
              name="categoryId"
            />
          </div>
          <label htmlFor="catagore_id" className="text-grey-500 w-full">
            Какую категорию вы добавляете?
          </label>
          <Select
            className="h-11 text-base w-full"
            id="catagore_id"
            onChange={GetSubCatagorie}
          >
            {catagore_ids_list?.map(
              (item: catagoire_id_object, index: number) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                );
              }
            )}
          </Select>
          <label htmlFor="catagore_parent_id" className="text-grey-500 ">
            Какую подкатегорию вы добавите?
          </label>
          <Select
            className="h-11 text-base w-full"
            id="catagore_parent_id"
            onChange={HandleChangeSelectNoMode}
          >
            {podcatagore_ids_list.map(
              (item: catagoire_id_object, index: number) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                );
              }
            )}
          </Select>
        </div>
      ),
    },
    {
      key: "6",
      object: "appliance",
      label: <div className="AddData_wrapper_tabs_header">Прибор</div>,
      children: {
        api: `/appliance?page=0&size=${count}`,
        saveApi: "/appliance",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "Имя", dataIndex: "name", width: 350, maxWidth: 550 },
        {
          title: "Производитель",
          dataIndex: "manufacturer",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Серийный номер",
          dataIndex: "serialNumber",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Дата Сертификации",
          dataIndex: "dateOfCertification",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_appliance" className="text-grey-500 ">
            Hазвание метода тестирования
          </label>
          <Input
            id="name_appliance"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Имя"
            onChange={(e) => HandleChange("appliance", "name", e.target.value)}
            value={updateStandart ? data.appliance.name : undefined}
          />
          <label htmlFor="manufacturer_appliance" className="text-grey-500 ">
            Производитель
          </label>
          <Input
            className="h-11 text-base w-full"
            id="manufacturer_appliance"
            required
            placeholder="Производитель"
            onChange={(e) =>
              HandleChange("appliance", "manufacturer", e.target.value)
            }
            value={updateStandart ? data.appliance.manufacturer : undefined}
          />
          <label htmlFor="serialNumber_appliance" className="text-grey-500 ">
            Серийный номер
          </label>
          <Input
            className="h-11 text-base w-full"
            id="serialNumber_appliance"
            required
            placeholder="Серийный номер"
            onChange={(e) =>
              HandleChange("appliance", "serialNumber", e.target.value)
            }
            value={updateStandart ? data.appliance.serialNumber : undefined}
          />
          <label htmlFor="dateOfCertification" className="text-grey-500 ">
            Дата сертификации
          </label>
          <DataPicker
            handleChange={HandleChangeDate}
            name="dateOfCertification"
            style={{ height: "44px", width: "100%" }}
            placeholder="Дата Сертификации"
          />
        </div>
      ),
    },
    {
      key: "7",
      object: "category",
      label: <div className="AddData_wrapper_tabs_header">Kатегория</div>,
      children: {
        api: `/category?page=0&size=${count}`,
        saveApi: "/category",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        {
          title: "Hазвание категории",
          dataIndex: "name",
          width: 350,
          maxWidth: 550,
        },
        {
          title: "Подкатегории",
          dataIndex: "parent",
          width: 350,
          maxWidth: 550,
        },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_appliance" className="text-grey-500 ">
            Hазвание категории
          </label>
          <Input
            id="name_appliance"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Hазвание категории"
            onChange={(e) => HandleChange("category", "name", e.target.value)}
            value={updateStandart ? data.category.name : undefined}
          />
          <div className="w-full my-3">
            <Switch className="bg-[#ccc]" onChange={handleSwitchChange} />{" "}
            <span className="text-md ml-3">
              Хотите добавить новую категорию?
            </span>
          </div>
          <label htmlFor="manufacturer_appliance" className="text-grey-500 ">
            Подкатегории
          </label>
          <Input
            disabled={new_cataogrie}
            className="h-11 text-base w-full"
            id="manufacturer_appliance"
            type="text"
            required
            placeholder="Подкатегории"
            onChange={(e) =>
              HandleChange("category", "parentId", e.target.value)
            }
            value={data.category.parentId || ""}
          />
        </div>
      ),
    },
    {
      key: "8",
      object: "laboratory",
      label: <div className="AddData_wrapper_tabs_header">Лаборатория</div>,
      children: {
        api: `/laboratory?page=0&size=${count}`,
        saveApi: "/laboratory",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "Имя", dataIndex: "name", width: 350, maxWidth: 550 },
        { title: "Адресс", dataIndex: "address", width: 350, maxWidth: 550 },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_laboratory" className="text-grey-500 ">
            Hазвание лаборатория
          </label>
          <Input
            id="name_laboratory"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Имя"
            onChange={(e) => HandleChange("laboratory", "name", e.target.value)}
            value={updateStandart ? data.laboratory.name : undefined}
          />
          <label htmlFor="adrres_laboratory" className="text-grey-500 ">
            Адрес лаборатория
          </label>
          <Input
            id="adrres_laboratory"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Имя"
            onChange={(e) =>
              HandleChange("laboratory", "address", e.target.value)
            }
            value={updateStandart ? data.laboratory.name : undefined}
          />
        </div>
      ),
    },
    {
      key: "9",
      object: "position",
      label: <div className="AddData_wrapper_tabs_header">Позиция</div>,
      children: {
        api: `/position?page=0&size=${count}`,
        saveApi: "/position",
      },
      dataheader: [
        { title: "№", dataIndex: "id", width: 50, maxWidth: 150 },
        { title: "Имя", dataIndex: "name", width: 350, maxWidth: 550 },
      ],
      modalConent: (
        <div className="w-full">
          <label htmlFor="name_position" className="text-grey-500 ">
            Hазвание Позиция
          </label>
          <Input
            id="name_position"
            className="mb-4 h-11 text-base w-full"
            required
            placeholder="Имя"
            onChange={(e) => HandleChange("position", "name", e.target.value)}
            value={updateStandart ? data.position.name : undefined}
          />
        </div>
      ),
    },
  ];
  function HandleTagsModeSelectNormativ(
    Data: number[],
    name: string,
    id?: number
  ) {
    setData({
      ...data,
      requirement: {
        ...data.requirement,
        normativeIds: Data, // Yangi normativeIds massivini o'zgartiring
      },
    });
  }
  function HandleChange(
    object: string,
    key: string,
    parm: string | boolean,
    id?: number
  ) {
    if (key !== "parentId") {
      setData({
        ...data,
        [object as keyof data]: {
          ...data[object as keyof data],
          [key as keyof data]: parm,
        },
      });
    } else if (typeof parm !== "boolean") {
      const cleanInput = parm.replace(/[^0-9]/g, "");
      setData({
        ...data,
        [object as keyof data]: {
          ...data[object as keyof data],
          [key as keyof data]: parseInt(cleanInput),
        },
      });
    }
  }
  function HandleChangeSelectNoMode(value: number) {
    setData({
      ...data,
      requirement: { ...data.requirement, categoryId: value },
    });
  }
  function HandleChangeSelect(value: number[]) {
    setData({
      ...data,
      requirement: { ...data.requirement, testMethodIds: value },
    });
  }
  function HandleChangeDate(parm: string, name: string, id: number) {
    setData({
      ...data,
      appliance: { ...data.appliance, dateOfCertification: parm },
    });
  }
  async function GetSubCatagorie(id: number) {
    console.log(id);
    const responseData = await RequestToken(
      `/category/subcategory/${id}`,
      "GET"
    );
    if (responseData.success) {
      setPodCatagore_ids_list(
        responseData.object.map((item: any) => {
          return { id: item.parent.id, name: item.parent.name };
        })
      );
    }
  }
  async function GetData() {
    const responseData = await RequestToken(
      items.filter((item: items, index: number) => {
        if (item.key === ActiveKey) {
          return item.children.api;
        }
      })[0].children.api,
      "GET",
      null
    );
    if (responseData.success) {
      setResData(responseData.object);
    } else if (
      responseData?.response?.status === 403 ||
      responseData?.response?.status === 401
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text:responseData?.response?.data?.message||responseData?.response?.message,
        icon: "error",  
      });
    }
    if (ActiveKey === "5") {
      const responseTest_Method = await RequestToken(
        `/testMethod?page=0&size=${count}`,
        "GET",
        null
      );
      const responseCatagoreIds = await RequestToken(
        items[6].children.api,
        "GET",
        null
      );
      if (responseTest_Method.success || responseCatagoreIds.success) {
        setTest_method_list(
          responseTest_Method.object?.map((item: test_method_list) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
            };
          })
        );
        setCatagore_ids_list(
          responseCatagoreIds.object?.filter((item: any) => {
            if (item.parent === null) {
              return { id: item.id, name: item.name };
            }
          })
        );
      }
    }
  }
  async function Delete(saveApi: string, id: number) {
    setLoading(false);
    const responseData = await RequestToken(`${saveApi}/${id}`, "DELETE", null);
    if (responseData.success) {
      setLoading(true);
      GetData();
      Swal.fire({
        title: responseData.message,
        timer: 2000,
      });
    } else if (
      responseData.response?.status === 401 &&
      responseData.response?.status === 403
    ) {
      setLoading(true);
      navigate("/");
    } else {
      setLoading(true);
      navigate("/error/500");
    }
  }
  function Edit(id: number) {
    setStandartId(id);
    setUpdateStandart(true);
    setIsOpenModal(true);
    let new_key_list = items.filter((item) => item.key === ActiveKey)[0].object;
    let editobject = resdata.filter((item: any) => item.id === id)[0];
    for (const key in (data as any)[new_key_list]) {
      if (editobject.hasOwnProperty(key)) {
        (data as any)[new_key_list][key] = editobject[key];
      }
    }
    setData({ ...data });
  }
  useEffect(() => {
    GetData();
  }, [ActiveKey]);
  useEffect(() => {
    // items.map((item: items, index: number) => {
    //   if (item.object === (laboratory_list as any)[role || "all"][0]) {
    //     setActiveKey(item.key);
    //   }
    // });
    setheaderTitle("Стандартное дополнение");
  }, []);
  console.log(items.filter((item1) => {if (
        ((laboratory_list as any)[role || "all"] || []).includes(
          item1.object
        )
      ) {
        return item1;
      }
    }))
  return (
    <div className="w-full h-full flex items-center justify-center">
      {loading ? (
        <div className="AddData_wrapper">
          <Modal
            open={isOpenModal}
            centered
            closeIcon={
              <CloseOutlined
                style={{ fontSize: "20px" }}
                onClick={() => setIsOpenModal(false)}
              />
            }
            footer={null}
          >
            <div className="Modal_wrapper_box">
              <div className="Modal_wrapper_box_header">
                <div>
                  Добавить нового{" "}
                  {
                    items
                      .filter((item) => {
                        if (item.key === ActiveKey) {
                          if (
                            (
                              (laboratory_list as any)[role || "all"] || []
                            ).includes(item.object)
                          ) {
                            return item;
                          }
                        }
                      })
                      .map((item) => {
                        return item;
                      })[0]?.label
                  }
                </div>
              </div>
              <div className="Modal_wrapper_box_body">
                <div className="w-full flex flex-wrap ">
                  {items
                    .filter((item) => {
                      if (item.key === ActiveKey) {
                        if (
                          (
                            (laboratory_list as any)[role || "all"] || []
                          ).includes(item.object)
                        ) {
                          return item;
                        }
                      }
                    })
                    .map((item) => {
                      return (
                        <>
                          {item.modalConent}
                          <div
                            className="w-full py-2 grid gap-4 grid-cols-4 "
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="bg-[#64748b] hover:bg-[#64748b] text-[#fff] hover:text-[#fff] px-2 py-2 rounded-md w-auto"
                              onClick={() => setIsOpenModal(false)}
                            >
                              Отмена
                            </button>
                            <button
                              onClick={() =>
                                Save(
                                  item.object,
                                  item.children.saveApi,
                                  standartId !== 0 ? standartId : undefined
                                )
                              }
                              className="bg-[#1a3489] hover:bg-[#1a3489] text-[#fff] hover:text-[#fff] px-2 py-2 rounded-md"
                            >
                              {standartId !== 0 ? "Pедактировать" : "Сохранить"}
                            </button>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </Modal>
          <div className="AddData_wrapper_add_button">
            <button
              onClick={() => setIsOpenModal(true)}
              className="text-xl bg-[#1a3489] btn rounded-xl px-5 py-3 text-[#fff]"
            >
              Добавить новый стандарт
            </button>
          </div>
          <div className="AddData_wrapper_header_buttons ">
            {items
              .filter((item1) => {
                if (
                  ((laboratory_list as any)[role || "all"] || []).includes(
                    item1.object
                  )
                ) {
                  return item1;
                }
              })
              .map((item, index) => {
                return (
                  <div
                    onClick={() => setActiveKey(item.key)}
                    key={index}
                    className={
                      item.key === ActiveKey
                        ? "AddData_wrapper_header_buttons_Active"
                        : "AddData_wrapper_header_buttons_noActive"
                    }
                  >
                    {item.label}
                  </div>
                );
              })}
          </div>
          <div className="AddData_wrapper_tabs">
            {items
              .filter((item) => {
                if (item.key === ActiveKey) {
                  if (
                    ((laboratory_list as any)[role || "all"] || []).includes(
                      item.object
                    )
                  ) {
                    return item;
                  }
                }
              })?.map((item, index) => {
                return (
                  <div key={index}>
                    {ActiveKey !== "7" ? (
                      <TableUsers
                        Delete={true}
                        update={true}
                        OpenModal={setModal}
                        dataheader={item.dataheader}
                        count={count}
                        data={resdata}
                        openModal={modal}
                        minwidth="1400px"
                        saveApi={item.children.saveApi}
                        setCount={setCount}
                        type="table"
                        width="1700px"
                        api={item.children.api}
                        typeModal={item.key}
                        key={null}
                        types="ad"
                        Edit={Edit}
                        DeleteFunction={Delete}
                      />
                    ) : (
                      <table className="w-full h-auto">
                        <thead>
                          <tr>
                            <th className={style.Table_th_id}>№</th>
                            <th className={style.Table_th}>
                              Hазвание категории
                            </th>
                            <th className={style.Table_th}>Подкатегории</th>
                            <th className={style.Table_th_id}></th>
                            <th className={style.Table_th_id}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {resdata.map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td className={style.Table_td_id}>{item.id}</td>
                                <td className={style.Table_td}>
                                  {item.parent === null
                                    ? item.name
                                    : item.parent?.name}
                                </td>
                                <td className={style.Table_td}>
                                  {item.parent === null
                                    ? "Нет в наличии"
                                    : item.name}
                                </td>
                                <td className={style.Table_td_id}>
                                  <span
                                    className="text-lg text-[red] cursor-pointer"
                                    onClick={() => Delete("/category", item.id)}
                                  >
                                    <DeleteOutlined />
                                  </span>
                                </td>
                                <td className={style.Table_td_id}>
                                  <span
                                    className="text-lg text-[#d7bb08] cursor-pointer"
                                    onClick={() => Edit(item.id)}
                                  >
                                    <EditOutlined />
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default AddData;
