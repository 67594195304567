import "./style.css";
import style from './style.module.css'
import { UserContext } from "src/App";
import Navbar from "src/Component/navbar";
import { useNavigate } from "react-router-dom";
import Sample from "src/Component/Laborotry/Sample";
import { useContext, useEffect, useState, useRef, ReactElement } from "react";
import UpdateModal from "src/Component/Modal/UpdateModal";
import {DownloadOutlined} from "@ant-design/icons";
import ProgramDocument from "src/Component/document/Laboratory/program";
import {
  dataTablePrograma,
  catagore,
  requirmentDTOList,
  DataOrganaztionType,
  requirment_list,
  optionbackend,
  program_data,
  res_program_list,
  create_programa,
  response_programa_object,programma_change_product
} from "./indexData";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import moment from "moment";
import Loading from "src/Component/Loading/Loading";
import { Empty, Select } from "antd";
import { Add, Plus } from "src/assets/icons/laboratory";
import ProgrammaStepOne from "src/Component/Laborotry/ProgrammaStep/ProgrammaStepOne";
import ProgrammaStepTwo from "src/Component/Laborotry/ProgrammaStep/ProgrammaStepTwo";
import ProgrammaStepThree from "src/Component/Laborotry/ProgrammaStep/ProgrammaThree";
import ProgrammaStepFour from "src/Component/Laborotry/ProgrammaStep/ProgrammaStepFour";

function Program() {
  const navigate = useNavigate();
  const _role = localStorage.getItem("_role");
  const { setheaderTitle } = useContext(UserContext);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [size, setSize] = useState<number>(50);
  const [activeFormProgram,setActiveFormProgram]=useState(1)
  const [search_user,setSearch_user]=useState('')
  const [search_solution_number,setSearch_solution_number]=useState('')
  const [solution_list,setSolution_List]=useState<{label:string,value:string}[]>([])
  const [showTableProgram, setShowTableProgram] = useState<boolean>(true);
  const [download, setDownload] = useState<boolean>(false);
  const [programma_id,setProgramma_ID]=useState<number|null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const [product_List, setProduct_List] = useState<catagore[]>([]);
  const [user_list, setUser_List] = useState<catagore[]>([]);
  const [solution_data,setSolution_Data]=useState()
  const [programma_List, setProgramma] = useState<res_program_list[]>([]);
  const [programmaData, setProgrammaData] = useState<program_data>({
    productInTestProtocolList: [],
    productType: "",
    solutionNumber: "",
    certificationOrganId: 0,
    userIds: [],
    requirementForProgramDTOList: [
      {
        requirementId: 0,
        normativeIds: [],
        testMethodIds: null,
      },
    ],
  });
  const sample_programa_form=[
    {
      id:1,
      component:<ProgrammaStepOne product_list={product_List} programma_data={programmaData} setProgramma_data={setProgrammaData} setActiveFormProgram={setActiveFormProgram}/>
    },
    {
      id:2,
      component:<ProgrammaStepTwo setActiveFormProgram={setActiveFormProgram} programma_data={programmaData} setProgramma_data={setProgrammaData}/>
    },
    {
      id:3,
      component:<ProgrammaStepThree setActiveFormProgram={setActiveFormProgram} solution_data={solution_data} programma_data={programmaData} setProgramma_data={setProgrammaData}/>
    },
    {
      id:4,
      component:<ProgrammaStepFour setActiveFormProgram={setActiveFormProgram}  programma_data={programmaData} setProgramma_data={setProgrammaData}/>
    },
  ]
  const SearchSolutionNumber = async (parm: string|number,search_type?:string) => {
    setLoading(false)
    if (parm && typeof parm ==='string') {
      setSearch_solution_number(parm)
      const responseSolutionNumber = await RequestToken(
        `/solution/byNumber/${parm}`
      );
      if (responseSolutionNumber.success) {
        setSolution_Data(responseSolutionNumber.object)
        setProgrammaData({...programmaData,solutionNumber:parm})
        setLoading(true)
        const new_arr=[]
        if(search_type){
          new_arr.push({
            programId: responseSolutionNumber.object.id,
          nameOfProduct: responseSolutionNumber.object.order.products.map((item:{productNameInTestProtocol:string,manufacturer:string})=>{return item.productNameInTestProtocol}),
          productType: responseSolutionNumber.object.order.products.map((item:{productNameInTestProtocol:string,manufacturer:string,type:string})=>{return item.type}),
          manufacturer: responseSolutionNumber.object.order.products.map((item:{productNameInTestProtocol:string,manufacturer:string})=>{return item.manufacturer}),
          applicant:responseSolutionNumber.object?.solution?.applicant,
          date: responseSolutionNumber.object.date ? moment(responseSolutionNumber.object.date).format('DD-MM-YYYY'):'Нет в наличии',
        })
        setProgramma(new_arr)
        }
        else{
          setShowTableProgram(false);
        setOpenModal(false);
        
        setProgrammaData({ ...programmaData, solutionNumber: parm });
        console.log(responseSolutionNumber.object);
        setProduct_List(
          responseSolutionNumber.object.order.products.map(
            (item: { id: number; name: string }) => {
              return { label: item.name, value: item.id };
            }
          )
        );
        }
      } else {
        setLoading(true)
        Swal.fire({
          title:
            responseSolutionNumber.response.data.message ||
            responseSolutionNumber.response.message,
          icon: "error",
        });
      }
    }
    else{
      setSearch_solution_number('')
      GetProgrammaList()
    }
  };
  function Programma_download(id:number){
    console.log(id)
    setProgramma_ID(id)
    setDownload(true)
  }
  async function GetProgrammaList() {
    setLoading(false)
    const responseProgrammaList = await RequestToken(
      `/program?page=0&size=${size}`,
      "GET"
    );
    if (responseProgrammaList.success) {
      setProgramma(
        responseProgrammaList.object.map((item: res_program_list) => {
          return {
            programId: item.programId,
            nameOfProduct: item.nameOfProduct,
            productType: item.productType,
            manufacturer: item.manufacturer,
            applicant:item.applicant,
            date: item.date ? moment(item.date).format('DD-MM-YYYY'):'Нет в наличии',
          };
        })
      );
      setLoading(true)
    } else if (
      responseProgrammaList.response?.status === 403 ||
      responseProgrammaList.response?.status === 401
    ) {
      setLoading(true)
      navigate("/");
    } else {
      setLoading(true)
      Swal.fire({
        title:
          responseProgrammaList.response.data.message ||
          responseProgrammaList.response.message,
        icon: "error",
      });
    }
  }
  async function get_User_List() {
    const response_user_list = await RequestToken("/user/getUsersWithoutSuperAdmin", "GET", null);
    if (response_user_list.success) {
      GetProgrammaList();
      setUser_List(
        response_user_list.object.map(
          (item: { lastName: string,firstName:string, id: number }) => {
            return { label: item.lastName+" "+item.firstName, value: item.id };
          }
        )
      );
    }
  }
  async function HandleChangeSearchUser(parm:string|number) {
    if (parm) {
      setSearch_user(user_list.find((item:{label:string,value:number})=>item.value===parm)?.label||"")
      setLoading(false);
     const response_data=await RequestToken(`/program/byUser/${parm}`,'GET',null)
      if (response_data.success) {
        setLoading(true);
        setProgramma(
          response_data.object.map((item: any) => {
            return {
              programId: item.id,
            nameOfProduct: item.products.map((item:{productNameInTestProtocol:string,manufacturer:string})=>{return item.productNameInTestProtocol}),
            productType: item.products.map((item:{productNameInTestProtocol:string,manufacturer:string,type:string})=>{return item.type}),
            manufacturer: item.products.map((item:{productNameInTestProtocol:string,manufacturer:string})=>{return item.manufacturer}),
            applicant:item?.solution?.applicant,
            date: item.date ? moment(item.date).format('DD-MM-YYYY'):'Нет в наличии',
            };
          })
        );
      } else {
        error(response_data);
      }
    } else {
      setSearch_user('')
      GetProgrammaList();
    }
  }
  async function get_solution_number_list(){
    setLoading(false)
    const res_solution_number_list=await RequestToken('/solution','GET',null)
    if(res_solution_number_list.success){
    get_User_List();
    // _get_Product_Type_List()
      setSolution_List(res_solution_number_list.object?.map((item:{applicant:string,solutionNumber:string})=>{return {label:("Нумер решения: "+item.solutionNumber+" "+" Заявитель: "+item.applicant),value:item.solutionNumber}}))   
    }
  }
  function error(res_data: any) {
    setLoading(true);
    Swal.fire({
      icon: "error",
      title:
        res_data.response.data.message ||
        res_data.response.message ||
        res_data.message,
    });
  }
  useEffect(() => {
    get_solution_number_list()
    setheaderTitle("Программа");
  }, []);
  return (
   <div className="w-full h-full flex items-center justify-center">
     {
      loading ?  <>{showTableProgram ? <div className="w-full h-full bg-[#fff] py-5">
      <div className="program_wrapper_header">
        <div className="program_wrapper_header_top pl-2 pr-5 flex">
          <div className="w-4/5 pl-5 ">
            <div className="w-full text-xl text-[#1F2A37] font-bold">Список программ сертификационных испытаний оборудований</div>
            {showTableProgram && _role==='ADMIN_OF_LABORATORY' ? <div className="w-full flex">
            <div className=""><label className="block" htmlFor="search_user">Поиск по сотрудникам:</label>
            <Select className="w-80" id="search_user" allowClear value={search_user} placeholder={"Поиск по сотрудникам"} options={user_list}
             onChange={(value:string|number)=>HandleChangeSearchUser(value)}/>
            </div>
            <div className="">
              <label htmlFor="solution_list" className="block mx-2">Поиск по номеру решения:</label>
            <Select className="w-80 mx-2" id="solution_list" allowClear placeholder={"Поиск по номеру решения"} options={solution_list}
             onChange={(value:string|number)=>SearchSolutionNumber(value,'table_search')} value={search_solution_number}/>
            </div>
             </div>:null}
          </div>
         {
          showTableProgram && _role==='EMPLOYEE_LABORATORY' ?  <div className="w-1/5 flex justify-end items-center" style={{paddingRight:"12px"}}>
          <button className="px-5 py-2 bg-[#1A56DB] text-[#fff] text-sm font-medium rounded-lg text-center flex items-center" onClick={()=>setOpenModal(true)}>Добавить испытание <span className="mx-2"><Add/></span></button>
        </div>:null
         }
        </div>
      </div>
       <div className="program_wrapper_body pl-7 pr-8">
   <table className="w-full h-auto">
      <thead>
        <tr>
          <th className={style.table_th_id}>№</th>
          <th className={style.table_th}>Наименование Продукта</th>
          <th className={style.table_th}>Тип изделия</th>
          <th className={style.table_th}>Фирма производитель</th>
          <th className={style.table_th}>Заявитель</th>
          <th className={style.table_th}>Дата и время поступления образцов</th>
          <th className={style.table_th}></th>
        </tr>
      </thead>
      <tbody>
        {programma_List?.length>0 && programmaData ? programma_List?.map((item:res_program_list,indexProgramm:number)=>{
          return (
            <tr key={indexProgramm}>
            <td className={style.table_td_id}>{indexProgramm+1}</td>
            <td className={style.table_td_no_padding}>{item.nameOfProduct?.map((itemProductName:string,index:number)=>{
              return (
                <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
              )
            })}</td>
            <td className={style.table_td_no_padding}>{item.productType?.map((itemProductName:string,index:number)=>{
              return (
                <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
              )
            })}</td>
             <td className={style.table_td_no_padding}>{item.manufacturer?.map((itemProductName:string,index:number)=>{
              return (
                <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
              )
            })}</td>
            <td className={style.table_td_no_padding}><div className={style.table_td_no_padding_div}>{item.applicant}</div></td>
            <td className={style.table_td_no_padding}><div className={style.table_td_no_padding_div}>{item.date}</div></td>
            <td className={style.table_td_no_padding}><span onClick={()=>Programma_download(item.programId)} className="p-2 cursor-pointer"><DownloadOutlined /></span></td>
          </tr>
          )
        }):<tr>
          <td colSpan={7}><Empty description="Нет в наличии"/></td>
          </tr>}
      </tbody>
    </table>
    </div>
    </div>:sample_programa_form[activeFormProgram-1].component}
   <div className="w-0 h-0 hidden">
   {download ? <div><ProgramDocument setDownload={setDownload} programmaDocs={programma_id}/></div>:null}
   </div></>:<Loading/>
     }
     <UpdateModal
solution_list={solution_list}
  listkey={["number_one", "number_two"]}
  Save={SearchSolutionNumber}
  isOpenModal={isOpenModal}
  setIsOpenModal={setOpenModal}
/>
   </div>
  );
}
export default Program;
// const [subCatagorieID, setSubCatagorieID] = useState<any>([]);
//   const [DataOrganaztionList, setDataOrganaztionList] = useState<DataOrganaztionType[]>([]);
//   const [catagoreList, setCatagorieList] = useState<catagore[]>([]);
//   const [podCatagorie, setPodCatagorie] = useState<catagore[]>([]);
//   const [normativ_IDs, setNormativ_IDs] = useState<catagore[]>([]);
//   const [SubCatagorie_Data, setSubCatagorie_Data] = useState<any>([]);
//   const [requirment_list, setRequirment_list] = useState<requirment_list[]>([]);
{/* <UpdateModal
solution_list={solution_list}
  listkey={["number_one", "number_two"]}
  Save={SearchSolutionNumber}
  isOpenModal={isOpenModal}
  setIsOpenModal={setOpenModal}
/> */}
{/* <div className="program_wrapper_header">
          <div className="program_wrapper_header_top pl-2 flex">
            <div className="w-4/5">
              <div className="w-full text-xl text-[#1F2A37] font-bold">Список программ сертификационных испытаний оборудований</div>
              <div className="w-fullm flex">
              <div className=""><label className="block" htmlFor="search_user">Поиск по сотрудникам:</label>
              <Select className="w-80" id="search_user" allowClear value={search_user} placeholder={"Поиск по сотрудникам"} options={user_list}
               onChange={(value:string|number)=>HandleChangeSearchUser(value)}/>
              </div>
              <div className="">
                <label htmlFor="solution_list" className="block mx-2">Поиск по номеру решения:</label>
              <Select className="w-80 mx-2" id="solution_list" allowClear placeholder={"Поиск по номеру решения"} options={solution_list}
               onChange={(value:string|number)=>SearchSolutionNumber(value,'table_search')} value={search_solution_number}/>
              </div>
               </div>
            </div>
            <div className="w-1/5 flex justify-end items-center" style={{paddingRight:"12px"}}>
              <button className="px-5 py-2 bg-[#1A56DB] text-[#fff] text-sm font-medium rounded-lg text-center flex items-center" onClick={()=>setOpenModal(true)}>Добавить испытание <span className="mx-2"><Add/></span></button>
            </div>
          </div>
        </div> */}
{/* <table className="w-full h-auto">
            <thead>
              <tr>
                <th className={style.table_th_id}>№</th>
                <th className={style.table_th}>Наименование Продукта</th>
                <th className={style.table_th}>Тип изделия</th>
                <th className={style.table_th}>Фирма производитель</th>
                <th className={style.table_th}>Заявитель</th>
                <th className={style.table_th}>Дата и время поступления образцов</th>
                <th className={style.table_th}></th>
              </tr>
            </thead>
            <tbody>
              {programma_List?.length>0 && programmaData ? programma_List?.map((item:res_program_list,indexProgramm:number)=>{
                return (
                  <tr key={indexProgramm}>
                  <td className={style.table_td_id}>{indexProgramm+1}</td>
                  <td className={style.table_td_no_padding}>{item.nameOfProduct?.map((itemProductName:string,index:number)=>{
                    return (
                      <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
                    )
                  })}</td>
                  <td className={style.table_td_no_padding}>{item.productType?.map((itemProductName:string,index:number)=>{
                    return (
                      <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
                    )
                  })}</td>
                   <td className={style.table_td_no_padding}>{item.manufacturer?.map((itemProductName:string,index:number)=>{
                    return (
                      <div className={index===0 ? style.table_td_no_padding_div:style.table_td_padding_div} key={index}>{itemProductName||"Нет в наличии"}</div>
                    )
                  })}</td>
                  <td className={style.table_td_no_padding}><div className={style.table_td_no_padding_div}>{item.applicant}</div></td>
                  <td className={style.table_td_no_padding}><div className={style.table_td_no_padding_div}>{item.date}</div></td>
                  <td className={style.table_td_no_padding}><span onClick={()=>Programma_download(item.programId)} className="p-2 cursor-pointer"><DownloadOutlined /></span></td>
                </tr>
                )
              }):<tr>
                <td colSpan={7}><Empty description="Нет в наличии"/></td>
                </tr>}
            </tbody>
          </table> */}