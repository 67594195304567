import { Result,Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
type ResultStatusType = "success" | "error" | "info" | "warning" | "404"|"500"|"403";
function Error(){
  const { id } = useParams<{ id: ResultStatusType }>();
  const navigate=useNavigate()
  const token=localStorage.getItem("_token")
  const ChangePage=()=>{
   if(token){
    if(id==="403"){
      navigate("/")
    }
    else {
      navigate('/')
    }    
   }
   else{
    navigate("/")
   }
  }
  return (
        <Result
        status={id}
        title={id}
        subTitle="Настройка облачного сервера занимает 1–5 минут, подождите."
        extra={[
          <Button onClick={ChangePage} type="primary" key="console">
           Домашняя страница
          </Button>,
          <Button key="buy">Cнова</Button>,
        ]}
      />
    )
}
export default Error;