import "./Styledocx.css";
import { useEffect, useState, useRef } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import { useReactToPrint } from "react-to-print";
import { ApplicantDocx } from "./renderDocsType";
import moment from "moment";
interface props {
  orderID: number | string;
  setDownload: (download: boolean) => void;
}
interface SolutionDoc {
  applicant:string;
  namesOfProducts: string[];
  schemeOfCertificate:string;
  namesOfLaboratories: string[];
  addressesOfLaboratories: string[];
  normativeNames: string[];
  paymentType: null;
  extraInfo: null;
}
const space = (
  <>
    {" "}
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
  </>
);
const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
function StepTwoDocs({ orderID, setDownload }: props) {
  const [res_data, setResData] = useState<SolutionDoc | undefined>();
  async function get_applicant() {
    const response = await RequestToken(
      `/solution/doc/${orderID}`,
      "GET",
      null
    );
    if (response.success) {
      setResData(response.object);
      HandlePrint();
    }
  }
  const componentRef: any = useRef();
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "решение",
    onAfterPrint: () => setDownload(false),
  });
  useEffect(() => {
    get_applicant();
  }, []);
  return (
    <div className="h-auto" style={{ width: "594px" }} ref={componentRef}>
      {space}
      {space}
      <div className="w-full flex justify-center mb-4">
        <div className="w-2/3 text-xs analis_application text-center">
          Национальная система сертификации Республики Узбекистан Система
          сертификации технических средств телекоммуникаций
        </div>
      </div>
      <div className="w-full flex" style={{ justifyContent: "space-between" }}>
        <div className="h-auto" style={{ width: "45%" }}>
          <div
            className="w-full text-center analis_application pt-2"
            style={{ fontSize: "13px" }}>
            ОРГАН ПО СЕРТИФИКАЦИИ ТЕХНИЧЕСКИХ СРЕДСТВ ТЕЛЕКОММУНИКАЦИЙ
          </div>
          <div className="w-full">
            <div
              className="h-5 w-full"
              style={{ borderBottom: "1px solid black" }}
            ></div>
            <div className="w-full italic analis_application" style={{ fontSize: "11px" }}>
              100187, г. Тошкент, Карасу-3, ул. Мингбулак, 38
            </div>
            <div className="w-full italic analis_application" style={{ fontSize: "11px" }}>
              Тел.: + 998 (71) 208-82-39;{" "}
            </div>
            <div className="w-full italic analis_application" style={{ fontSize: "11px" }}>
              Факс:
            </div>
          </div>
        </div>
        <div className="h-auto pl-3" style={{ width: "45%" }}>
          <div className="my-3 w-full block font-semibold analis_application ">
            У Т В Е Р Ж Д А Ю
          </div>
          <span className="my-3 w-full block font-semibold analis_application">
            Руководитель ОС ТСТ
          </span>
          <span className="my-3 w-full flex font-semibold analis_application">
            <div
              className="w-24 h-5"
              style={{ borderBottom: "1px solid black" }}
            ></div>
            <div className="w-auto h-5 font-semibold analis_application">М.М. Махмудов</div>
          </span>
        </div>
      </div>
      <div className="w-full mt-3">
        <div
          className="w-full text-center font-semibold analis_application"
          style={{ fontSize: "13px" }}
        >
          Р Е Ш Е Н И Е
        </div>
        <div
          className="w-full text-center font-semibold analis_application"
          style={{ fontSize: "12px" }}
        >
          по заявке на проведение сертификации
        </div>
        <div
          className="w-full text-center font-semibold analis_application"
          style={{ fontSize: "12px" }}
        >
          № 012023200898586001250-Р от{" "}
          17.10.2023 г.
        </div>
      </div>
      <div className="w-full mb-1 analis_application">Рассмотрев заявку </div>
      <div className="w-full">
        <span
          className="w-full h-7 flex items-end text-center analis_application"
          style={{ borderBottom: "1px solid black" }}
        >
          {res_data?.applicant}
        </span>
        <span className="w-full text-center analis_application">
          (наименование предприятия-изготовителя, поставщика)
        </span>
      </div>
      <div className="w-full">
        <span
          className="w-full h-7 flex items-end text-center analis_application"
          style={{ borderBottom: "1px solid black" }}
        >
          на сертификацию{" "}
          {res_data?.namesOfProducts?.map((item: string, index: number) => {
            return (
              <span key={index} className="px-2 analis_application">
                {item}
              </span>
            );
          })}
          ,
          {res_data?.namesOfProducts?.map((item: string, index: number) => {
            return (
              <span key={index} className="px-2 analis_application">
                {item}
              </span>
            );
          })}
        </span>
        <span className="w-full text-center analis_application">
          (наименование предприятия-изготовителя, поставщика)
        </span>
      </div>
      <div className="w-full my-1 analis_application">сообщаем, что:</div>
      <div className="w-full flex py-1 h-auto">
        <div className="w-1/2 h-auto analis_application">
          1. Сертификация будет проведена по схеме
        </div>
        <div className="w-1/2 h-6 analis_application" style={{ borderBottom: "1px solid black" }}>
          {res_data?.schemeOfCertificate}
        </div>
      </div>
      <div className="w-full flex py-1 h-auto">
        <div className="w-1/2 h-auto analis_application">
          2. Испытания для сертификации следует провести в
        </div>
        <div
          className="w-1/2 h-5 flex analis_application"
          style={{ borderBottom: "1px solid black" }}
        > расположенном по адресу:</div>
      </div>
      <div className="w-full h-5" style={{ borderBottom: "1px solid black" }}>
        {res_data?.addressesOfLaboratories?.map((item:string,index:number)=>{
          return (
            <span key={index} className="px-2 analis_application">{item}</span>
          )
        })}
      </div>
      <div className="w-full flex py-1">
        <div className="w-1/2 h-auto analis_application">
          3. Сертификация будет проведена на соответствие требованиям:
        </div>
        <div
          className="w-1/2 h-6 analis_application"
          style={{ borderBottom: "1px solid black" }}
        >{res_data?.normativeNames?.map((item:string,index:number)=>{
          return (
            <span className="px-2 analis_application " key={index}>{item}</span>
          )
        })}</div>
      </div>
      <div
        className="w-full h-5 analis_application"
        style={{ borderBottom: "1px solid black" }}
      ></div>
      <div className="w-full flex py-1 h-auto">
        <div className="w-1/3 h-auto analis_application">4. Обследование производства </div>
        <div
          className="w-2/3 h-6 analis_application"
          style={{ borderBottom: "1px solid black" }}
        >{res_data?.namesOfLaboratories?.map((item:string,index:number)=>{
          return (
            <span key={index} className="px-2 analis_application">{item}</span>
          )
        })}</div>
      </div>
      <div className="w-full flex py-1 h-auto analis_application">
        <div className="w-1/3 h-auto analis_application">5. Форма оплаты работ</div>
        <div
          className="w-2/3 h-6 analis_application"
          style={{ borderBottom: "1px solid black" }}
        >{res_data?.paymentType||"-"}</div>
      </div>
      <div className="w-full flex py-1 h-auto">
        <div className="w-1/2 h-auto analis_application">
          6. Для целей сертификации необходимо представить
        </div>
        <div
          className="w-1/2 h-6 analis_application"
          style={{ borderBottom: "1px solid black" }}
        >{res_data?.extraInfo||"-"}</div>
      </div>
      <div
        className="w-full h-5 analis_application"
        style={{ borderBottom: "1px solid black" }}
      ></div>
      <div className="w-full flex my-2">
        <div className="w-1/2 analis_application">Начальник отдела процедур сертификации </div>
        <div className="w-1/2 text-center analis_application">Б. Юнусов </div>
      </div>
    </div>
  );
}
export default StepTwoDocs;
