import { Select,Empty } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "src/App";
import 'src/pages/steps/stepthree/StepThree.css'
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import moment from "moment";
import {response_catagore_document,response_document_type,user_object} from './indexType'
interface props {
  type?: string;
  user_list: { label: string; value: number }[];
}
interface consultion {
    orderId:number,
    testProtocolId:number,
    userIds:number[]
}
function Stepconclusion({ user_list, type }: props) {
  const navigate=useNavigate()
  const {id}=useParams()
  const { setheaderTitle } = useContext(UserContext);
  const [test_protocol_list,setTest_protocol_list]=useState<{label:string,value:number}[]>([])
  const [res_data, setRes_data] = useState<response_document_type[]>([]);
  const [consultion,setConsultion] = useState<consultion>({
    orderId: 0,
    testProtocolId:0,
    userIds: [],
  });
  function error(response:any) {
    if(response.response.status===401 && response.response.status===403){
      navigate('/')
    }
    else{
        Swal.fire({
            title:response.response?.data.message||response.response?.message,
            icon:'error'
        })
    }
  }
  async function SavedConsulution() {
    const response_consulution = await RequestToken("/conclusion","POST",consultion);
    if(response_consulution.success){
        Swal.fire({
            title:response_consulution?.message,
            icon:'success'
        })
        navigate(`/home/${id}`)
    }
    else{error(response_consulution)}
  }
  async function get_consultion(){
    const response_consulution=await RequestToken(`/conclusion/${id}`,"GET",null)
    
  }
  async function getConsulution() {
    const response = await RequestToken(`/conclusion/${id}`, "GET", null);
    if (response.success) {
      setRes_data(response.object?.map((item:response_document_type,index:number)=>{
        return {
          applicant:item?.applicant,
          testingProtocolDate:moment(item?.testingProtocolDate).format('DD-MM-YYYY'),
          productNames:item?.productNames,
          normativeNames:item?.normativeNames,
          certificationOrgans:item?.certificationOrgans,
          manufacturers:item?.manufacturers,
          schemeOfCertification:item?.schemeOfCertification,
          users:item?.users?.map((itemuser:{firstName:string,lastName:string})=>{return {firstName:itemuser.firstName,lastName:itemuser.lastName}}),
        }
      }))
    }
  }
  async function get_test_protocol_list(){
    const response_consulution=await RequestToken(`/testingProtocol/byOrderId/${id}`,"GET",null)
    if(response_consulution.success){
      setTest_protocol_list(response_consulution.object?.map((item:{numberOfProtocol:string,id:number},index:number)=>{
        return {label:item.numberOfProtocol,value:item.id}
      }))
    }
    else{error(response_consulution)}
  }
  useEffect(() => {
    setheaderTitle("Заключение");
    if(type!=='edit'){
       get_consultion()
    }
    else{
      get_test_protocol_list()
      setConsultion({...consultion,orderId:parseInt(id||"1")})
    }
    getConsulution()
  }, []);
  return (
    <div className="w-full h-full" style={{ height: "620px" }}>
      <div className="w-full pt-2" style={{ height: "90%" }}>
        <div className="StepThree_wrapper_row my-2">
        <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2 border-[#ccc]"
            style={{ width: "40%" }}
          >
            От изготовителя (заказчика)
          </div>
          <div className=" flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
            {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={user_list}
                mode="multiple"
                onChange={(value: number[]) =>
                  setConsultion({ ...consultion, userIds: value })
                }
              />
            ) : res_data?.length>0 ? res_data?.map((item:response_document_type,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):"ad"
             }):<Empty description="Нет в наличии"/>}
          </div>
        </div>
        {
          type==='edit' ? <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2 border-[#ccc]"
            style={{ width: "40%" }}
          >
            Пожалуйста, выберите протокол тестирования:
          </div>
          <div className=" flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
          {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={test_protocol_list}
                onChange={(value:number)=>setConsultion({...consultion,testProtocolId:value})}
              />
            ) : (
              res_data?.length>0 ? res_data?.map((item:response_document_type,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):"ad"
             }):<Empty description="Нет в наличии"/>
            )}
          </div>
        </div>:null
        }
        </div>
      </div>
      <div className="w-full flex justify-end px-3" style={{ height: "8%" }}>
        <button
          className="px-3 py-2 bg-[#4096ff] rounded-md text-[#fff]"
          onClick={SavedConsulution}
        >
          Сохранять
        </button>
      </div>
    </div>
  );
}
export default Stepconclusion;
