import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import style from "./style.module.css";
import { StepFiveDocumentType } from "./renderDocsType";
import { RequestToken } from "src/Hook/Request/Request";
interface props {
  orderID: string;
  setDownload: (download: boolean) => void;
}
export default function StepFiveDocs({ orderID, setDownload }: props) {
  const [res_data, setRes_Data] = useState<StepFiveDocumentType>({
    solutionNumber: "",
    solutionDate: "",
    schemeOfCertification: "",
    nameOfOrganization: "",
    productNames: [],
    normativeNames: [],
    decisionOfDistributionOrRefusal: true,
    reasonOfRefusing: "",
  });
  const componenetRef: any = useRef();
  async function Get_Five_document() {
    const response_docs = await RequestToken(
      `/decision/${orderID}`,
      "GET",
      null
    );
    if (response_docs.success) {
        // setRes_Data(response_docs.object);
      HandlePrint();
    }
  }
  const HandlePrint = useReactToPrint({
    content: () => componenetRef.current,
    documentTitle: "РЕКОМЕНДАЦИИ",
    onAfterPrint: () => setDownload(false),
  });
  useEffect(() => {
    Get_Five_document();
  }, []);
  return (
    <div className="h-auto" style={{ width: "594px" }} ref={componenetRef}>
      <table className={style.Table_five}>
        <thead></thead>
        <tbody>
          <tr>
            <td colSpan={3} className={style.table_five_td_center}>
              Государственное унитарное предприятие «UNICON.UZ» Система
              сертификации технических средств телекоммуникаций
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td_center}>
              ОРГАН ПО СЕРТИФИКАЦИИ ТЕХНИЧЕСКИХ СРЕДСТВ ТЕЛЕКОММУНИКАЦИЙ (ОС
              ТСТ)
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td}>
              <div className={style.adress}>
                100187, г. Тошкент, Карасу-3, ул. Мингбулак, 38
              </div>
              <div className={style.adress}> Тел.: + 998 (71) 208-82-39; </div>
              <div className={style.adress}>Факс: </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td_center}>
              <div style={{ fontWeight: "600" }} className="mb-4">
                <p style={{ fontFamily: "Times New Roman  !important" }}>AKT</p>
                <p style={{ fontFamily: "Times New Roman  !important" }}>
                  О СПИСАНИИ (ВОЗВРАТЕ) ПРОДУКЦИИ
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={`${style.table_five_td_center}`}>
              <span
                style={{ fontSize: "11px", borderBottom: "1px solid #6b6b6b" }}
              >
                от 05.01.2023_г.
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td_center}>
              <div className={`${style.company} mb-4`}>
                <p>
                  <span>OOO «STAR SITY SENTER»</span>
                </p>
                <p>(наименование юридического и физического лица)</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td_center}>
              <div className={`${style.company} mb-4`}>
                <p>
                  <span>№ 30781570420220000006 от 27.12.2022 г</span>
                </p>
                <p>(дата и номер заявки)</p>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td}>
              Настоящий акт составлен о том, что образцы продукции, отобранные в
              соответствии с актом отбора образцов (проб)
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td}>
              были полностью разрушены в период испытания
            </td>
          </tr>
          <tr>
            <td colSpan={3} className={style.table_five_td}>
              не подвергшиеся разрушению или частично разрушенные в период
              испытаний возвращены заявителю
            </td>
          </tr>
          <tr>
            <td className={style.table_five_td}>От изготовителя (заказчика)</td>
            <td className={style.table_five_td}>
              <div className={style.Imzo}>
                <span></span>
                <span>подпись</span>
              </div>
            </td>
            <td className={style.table_five_td}>
              <div className={style.Imzo}>
                <span></span>
                <span>‎Ф.И.О.</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.table_five_td}>От изготовителя (заказчика)</td>
            <td className={style.table_five_td}>
              <div className={style.Imzo}>
                <span></span>
                <span>подпись</span>
              </div>
            </td>
            <td className={style.table_five_td}>
              <div className={style.Imzo}>
                <span></span>
                <span>‎Ф.И.О.</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
