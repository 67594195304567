import style from "./style.module.css";
import Swal from "sweetalert2";
import { useState } from "react";
import { Collapse, DatePicker, Input, Select } from "antd";
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import AddBox from "../data/addbox/addbox";
import { programID_data,product_list} from "src/pages/laboratory/testreport/indexdata";
const { RangePicker } = DatePicker
interface props {
  program_ID?:programID_data,
  setProgramID:(programID_data:programID_data)=>void
}
interface props_three{
  program_ID?:programID_data,
  setProgramID:(programID_data:programID_data)=>void
  setTimeState:(stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string})=>void
  stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string}
}
export function Collapse_Option_One({setProgramID,program_ID}:props) {
  const [option_data,setOptionData]=useState<{applicantName:string,adress:string}>({
    applicantName:'',
    adress:''
  })
  function HandleChange(name:string,parm:string){
    setOptionData({...option_data,[name]:parm})
  }
  return (
    <div className={style.collapse}>
      <div className={style.collapse_title}></div>
      <div className={style.collapse_form}>
        <div className="w-full">Заявитель</div>
        <div className="w-full h-auto flex ">
          <Input className={`${style.collapse_form_input} cursor-no-drop`} value={program_ID?.solution?.order.applicant}  allowClear/>
        </div>
        <div className="w-full">Адрес</div>
        <div className="w-full h-auto flex ">
          <Input className={`${style.collapse_form_input} cursor-no-drop`} value={program_ID?.solution?.order.applicantAddress}  allowClear/>
        </div>
      </div>
    </div>
  );
}
export function Collapse_Option_Two({setProgramID,program_ID}:props) {
  
  return (
    <div className={style.collapse}>
      <div className={style.collapse_title}>2.1 Наименование и тип:</div>
      <div className={style.collapse_form}>
       
        <table className="w-10/12  mt-2">
      <thead>
        <tr className={style.collapse_tr}>
          <th className={style.collapse_th}>№</th>
          <th className={style.collapse_th}>Наименование Продукта</th>
          <th className={style.collapse_th}>Тип изделия </th>
        </tr>
      </thead>
      <tbody>
        {program_ID?.solution?.order?.products?.map((item: product_list, index: number) => {
          return (
            <tr className={style.collapse_tr} key={index}>
              <td className={style.collapse_td}>{index+1}</td>
              <td className={style.collapse_td}>{item.name}</td>
              <td className={style.collapse_td}>{item.type}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
      </div>
    </div>
  );
}
export function Collapse_Option_Three({setProgramID,program_ID,setTimeState,stateTime}:props_three) {
    const [option_data,setOptionData]=useState<{dateFrom:string,dateUntil:string,dateOfReceiving:string}>({
      dateFrom:'',
      dateUntil:'',
      dateOfReceiving:""
    })
    function HandleChange(parm:string){
      setTimeState({...stateTime,dateOfReceiving:parm})
    }
    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
      ) => {
       setTimeState({...stateTime,dateFrom:dateString[0],dateUntil:dateString[1]})
      };
      
      const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
       // console.log('onOk: ', value);
      };
    return (
      <div className={style.collapse}>
        <div className={style.collapse_title}></div>
        <div className={style.collapse_form}>
          <div className="w-full">Показывать время начала и окончания теста</div>
        
    <RangePicker
      format="YYYY-MM-DD"
      onChange={onChange}
      onOk={onOk}
      allowClear
      placeholder={['Дата начала', 'Дата окончания']}
    />
        </div>
        <div className={style.collapse_form}>
          <div className="w-full">Дата получения</div>
          <DatePicker placeholder="Затраченное время" onChange={(date, dateStrings)=>HandleChange(dateStrings)}/>
        </div>
        
      </div>
    );
}
export function Collapse_Option_Four({setProgramID,program_ID}:props) {
    function handleChange(data: number[], name: string, id?: number | undefined){}
    return (
      <div className={style.collapse}>
        <div className={style.collapse_title}></div>
        <div className={style.collapse_form}>
          <div className="w-full">Заявитель</div>
          <AddBox name="a" Handle={handleChange} />
        </div>
      </div>
    );
}
export function Collapse_Option_Five({setProgramID,program_ID}:props) {
    function handleChange(data: number[], name: string, id?: number | undefined){}
    return (
      <div className={style.collapse}>
        <div className={style.collapse_title}></div>
        <div className={style.collapse_form}>
          <div className="w-full">Заявитель</div>
          <AddBox name="a" Handle={handleChange} />
        </div>
      </div>
    );
}


