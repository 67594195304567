import "./home.css";
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import { Button, Modal, Select, Switch, message } from "antd";
import Shag2 from "../steps/steptwo/StepTwo";
import Stepone from "../steps/stepone/stepone";
import StepThree from "../steps/stepthree/StepThree";
import StepSix from "../steps/stepsix/StepSix";
import type { StepsProps } from "antd";
import { Popover, Steps } from "antd";
import StepFive from "../steps/stepfive/StepFive";
import Steprecomdacion from "../steps/steprecomdacion";
import SelectIndicator from "src/Component/Select/Select";
import StepSeven from "../steps/stepseven/StepSeven";
import StepFour from "../steps/stepfour";
import StepActOfLaw from "../steps/stepact";
import { UserContext } from "src/App";
import RenderDocx from "src/Component/document/renderDocx";
import Swal from "sweetalert2";
import Analtics from "src/Component/document/analytics";
import Stepconclusion from "../steps/stepconclusion";
import { analistics_application_document } from "src/Component/document/analytics";
import moment from "moment";
import { response_statement } from "../steps/stepone/steponedata";
import Loading from "src/Component/Loading/Loading";
interface analis_application {
  testingThirdPartyLaboratory:boolean;
  orderId:number;
  userId:number;
}
interface option {
  label: string;
  value: string;
}
interface user {
  label:string,
  value:number
}
const customDot: StepsProps["progressDot"] = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        Шаг {index + 1} положение дел: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
function Home() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setStepID, stepID } = useContext(UserContext);
  const roles = localStorage.getItem("_role");
  const [download, setDownload] = useState<boolean>(false);
  const [loading,setLoading]=useState<boolean>(false)
  const [downloadAnalis, setDownloadAnalis] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<option[]>([]);
  const [user_list, setUser_list] = useState<user[]>([]);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [orderData,setOrderData]=useState<any>()
  const [analistics_application_document,setAnalistics_application_document]=useState<analistics_application_document|undefined>()
  const [analytics_application, setAnalytics_application] = useState<analis_application>({
    testingThirdPartyLaboratory: false,
    orderId: 0,
    userId: 0,
  });
  const step = [
    {
      id: 1,
      API: "/order/acceptedOrders?page=0&size=100",
      title: (
        <div
          className={"step_navlink "}
          onClick={() => {
            setStepID(1);
          }}
        >
          Основная информация
        </div>
      ),
      component: (
        <div className="dashboard_ariza">
          <Stepone
            Id={`/order/${id}`}
            type={"info"}
            modalOpen={modalOpen}
            setOpenModal={setModalOpen}
          />
        </div>
      ),
    },
    {
      id: 2,
      API: "/solution",
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(2);
          }}
        >
          Решение
        </div>
      ),
      component: (
        <div className="dashboard_ariza">
          <Shag2
            type={"shag2"}
            Id={`/solution/${id}`}
            product_List={productsData}
            modalOpen={modalOpen}
            setOpenModal={setModalOpen}
          />
        </div>
      ),
    },
    {
      id: 3,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(3);
          }}
        >
          Акт отбора
        </div>
      ),
      component: (
        <StepThree
          products_list={productsData}
          type={"view"}
          modalOpen={modalOpen}
          setOpenModal={setModalOpen}
          order={orderData}
        />
      ),
    },
    {
      id: 4,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(4);
          }}
        >
          Акт испытания
        </div>
      ),
      component: <StepFour />,
    },
    {
      id: 5,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(5);
          }}
        >
          Акт о списании
        </div>
      ),
      component: <StepActOfLaw  user_list={user_list}/>,
    },
    {
      id: 6,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(6);
          }}
        >
          Решение о выдаче
        </div>
      ),
      component: (
        <StepFive
          products_list={productsData}
          type=""
          orderData={orderData}
          modalOpen={modalOpen}
          setOpenModal={setModalOpen}
        />
      ),
    },
    {
      id: 7,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(7);
          }}
        >
          Заявка для сетификата
        </div>
      ),
      component: <StepSix type=" s" />,
    },
    {
      id: 8,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(8);
          }}
        >
          Заключение
        </div>
      ),
      component:<Stepconclusion user_list={user_list}/>,
    },
    {
      id: 9,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(9);
          }}
        >
          Рекомендации
        </div>
      ),
      component:<Steprecomdacion user_list={user_list} type=""/>,
    },
    {
      id: 10,
      title: (
        <div
          className={"step_navlink"}
          onClick={() => {
            setStepID(10);
          }}
        >
          Форма сертификата
        </div>
      ),
      component: <StepSeven type="" />,
    },
  ];
  function Refersh() {
    navigate(`/home/edit/` + id);
  }
  function Handle(data: number[], name: string) {
    console.log(data, name);
  }
  function handleChange(parm: string, name: string, id: number) {
    productsData.filter((item: { label: string }) => {
      if (item.label === parm) {
        setIsModalOpen(true);
      }
    });
  }
  function print() {
    setDownload(true);
  }
  function error(response:any){
    setLoading(true)
    if(response.response.status===401 || response.response.status===403){
      navigate('/')
    }
    else{
      Swal.fire({
        title:response.response?.data.message||response.response.message||response?.message,
        icon:'error'
      })
    }
  }
  async function Get_application() {
    setLoading(false)
    const responseData = await RequestToken(`/order/${id}`, "GET", null);
    if (responseData.success) {
      setOrderData(responseData.object)
      setProductsData(
        responseData.object.products.map((item: { name: string }) => {
          return { label: item.name, value: item.name };
        })
      );
     setLoading(true)
    }
  }
  async function get_user_list(){
    setLoading(false)
    const response_user_list=await RequestToken('/user/getUsersWithoutSuperAdmin','GET',null)
    if(response_user_list.success){
      setLoading(true)
      setUser_list(response_user_list.object.map((item:{firstName:string,lastName:string,id:number})=>{
        return {label:item.lastName+" "+item.firstName,value:item.id}
      }))
    }
  }
  async function AnalisApplication(e:React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    setIsOpenModal(false)
    const response_analis_application = await RequestToken(
      "/applicationAnalysis",
      "POST",analytics_application
    );
    if(response_analis_application.success){
      Swal.fire({
        title:response_analis_application?.message||response_analis_application.response?.message,
        icon:'success'
      })
      get_Analis_Application()
    }
    else{error(response_analis_application)}
  }
  async function get_Analis_Application(){
    setLoading(false)
    const response_get_analytics:{success:boolean,object:analistics_application_document}=await RequestToken(`/applicationAnalysis/${id}`,"GET",null)
    if(response_get_analytics.success){
      setAnalistics_application_document({
       applicant:response_get_analytics.object.applicant,
       deliveryNote:response_get_analytics.object.deliveryNote,
       invoiceNumber:response_get_analytics.object.invoiceNumber,
       invoiceDate:response_get_analytics.object.invoiceDate ? moment(response_get_analytics.object.invoiceDate).format('DD-MM-YYYY'):"-",
       orderDate:response_get_analytics.object.orderDate ? moment(response_get_analytics.object.orderDate).format('DD-MM-YYYY'):"-",
       orderNumber:response_get_analytics.object.orderNumber,
       productCodes:response_get_analytics.object.productCodes,
       productNames:response_get_analytics.object.productNames,
       schemeOfCertification:response_get_analytics.object.schemeOfCertification,
       testingThirdPartyLaboratory:response_get_analytics.object.testingThirdPartyLaboratory,
       user:response_get_analytics.object.user.lastName+" "+response_get_analytics.object.user.firstName,
      })
      setLoading(true)
      setDownloadAnalis(true)
    }
    else{
     error(response_get_analytics)
    }
  }
  useEffect(() => {
   if(stepID!==1){
    Get_application();
  }
   setAnalytics_application({
    ...analytics_application,
    orderId: parseInt(id || "1"),
  });
   get_user_list()
   
  }, []);
  useEffect(()=>{
    if(stepID===3){
      Get_application();
     }
  },[stepID])
  return (
    <>
      <div className="w-0 h-0 overflow-auto">
        {download ? (
          <div className="w-auto h-auto">
            <RenderDocx
              orderID={id || "1"}
              setDownload={setDownload}
              docxID={stepID}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="w-full h-full flex items-center justify-center">
      {
        loading ? <div className="dashboard">
        <div className="dashboard_header_box">
          <div className="dashboard-steps">
            <Steps
              direction="vertical"
              current={stepID - 1}
              progressDot={customDot}
              items={step}
            />
          </div>
        </div>
        <div className="dashboard-step">
          <div className="dashboard_button_group">
            <div className="dashboard_button_group-box">
             {stepID===4 || stepID===7 || stepID===6 ? null: <Button
                className="mx-2 dashboard_button_group_button"
                type={"primary"}
                onClick={print}
              >
                печатать
              </Button>}
              {stepID===1 ?  <Button
                className="mx-2 dashboard_button_group_button"
                type={"primary"}
                onClick={get_Analis_Application}
              >
                Анализа заявка печатать
              </Button>:null}
              <Button
                className={
                  stepID === 1 && roles === "EMPLOYEE_AGENCY"
                    ? "mx-2 dashboard_button_group_button"
                    : "hidden"
                }
                type={"primary"}
                onClick={() => setIsOpenModal(true)}
              >
                Анализа заявка
              </Button>
              <Button
                onClick={Refersh}
                className={
                  stepID !== 1 && stepID !== 4 && roles === "EMPLOYEE_AGENCY"
                    ? "mx-2 dashboard_button_group_button"
                    : "hidden"
                }
                type={"primary"}
              >
                заполнитьъ форму
              </Button>
              <Button
                className={
                  stepID !== 1 && stepID !== 4 && roles === "EMPLOYEE_AGENCY"
                    ? "mx-2 dashboard_button_group_button"
                    : "hidden"
                }
                type={"primary"}
              >
                Изменить
              </Button>
              <div>
              {/* {
                stepID===4 ? null:  <SelectIndicator
                HandleChange={handleChange}
                data={productsData}
                name="Products"
                style={{ width: "350px" }}
                value={""}
              />
              } */}
              </div>
            </div>
          </div>
          <div className="dashboard-step-component">
            {step
              .filter((items: any) => {
                if (items.id === stepID) {
                  return items;
                }
              })
              .map((item: any) => {
                return item.component;
              })}
          </div>
          <Modal
            open={ismodalOpen}
            onCancel={() => setIsModalOpen(false)}
            centered
            okText="Понятно"
            cancelText={"Отмена"}
          >
            <div className="w-full mb-3 mt-1 "></div>
            <div className="w-full">
              {productsData.map((item: any, index: number) => {
                return (
                  <div className="Modal_wrapper_info_body_product_row">
                    <div className="Modal_wrapper_info_body_product_row_left">
                      Наименование товара
                    </div>
                    <div className="Modal_wrapper_info_body_product_row_right">
                      {item.label}
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal>
          {/* <Tab Data={step} Save={Save} type={"view"} /> */}
        </div>
      </div>:<Loading/>
      }
      </div>
      <Modal
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={null}
        centered
      >
        <div className="w-full mb-3 ">
          <div className="text-xl font-semibold w-full mb-4">
            Результаты анализа
          </div>
          <form action="analis_save" onSubmit={AnalisApplication}>
            <div className="w-full flex">
              <div className="w-1/2">
                <label htmlFor="more_organation">
                  Испытание в сторонних ИЛ:
                </label>
              </div>
              <div className="w-1/2 flex justify-end">
                <Switch id="more_organation" className="bg-[#ccc]" checked={analytics_application.testingThirdPartyLaboratory}
                onChange={(checked:boolean)=>setAnalytics_application({...analytics_application,testingThirdPartyLaboratory:checked})}/>
              </div>
            </div>
            <div className="w-full my-3 flex">
              <div className="w-1/2">
                <label htmlFor="more_organation1">Начальник ОПС ОС ТСТ:</label>
              </div>
              <div className="w-1/2 flex justify-end">
                <Select className="w-full h-11 " onChange={(value:number)=>setAnalytics_application({...analytics_application,userId:value})} options={user_list}/>
              </div>
            </div>
            <div className="w-full flex justify-end my-4">
              <button type="button" onClick={()=>setIsOpenModal(false)} className="px-3 py-2 border-2 border-[#ccc] rounded-md mx-2">
                Отмена
              </button>
              <input
                type="submit"
                id="analis_save"
                className="px-3 py-2 text-[#fff] border-2 rounded-md bg-[#4096ff]"
                value={"Сохранять"}
              />
            </div>
          </form>
        </div>
      </Modal>
      {downloadAnalis ? <Analtics analistics_application_document={analistics_application_document} setDownload={setDownloadAnalis}/>:""}
    </>
  );
}
export default Home;
