import "./Styledocx.css";
import style from "./style.module.css";
import { useEffect, useRef ,useState} from "react";
import { useReactToPrint } from "react-to-print";
import { RequestToken } from "src/Hook/Request/Request";
import { res_act_data_type, userObject } from "src/pages/steps/stepact/indexData";
import moment from "moment";
interface props {
  orderID: string;
  setDownload: (download: boolean) => void;
}
const space = (
  <>
    {" "}
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
  </>
);
const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
function StepActDocs({ orderID, setDownload }: props) {
    const [res_data, setRes_data] = useState<res_act_data_type[]>();
    const componentRef: any = useRef();
  async function getActOfWrite() {
    const response = await RequestToken(`/actOfWriteOff/${orderID}`, "GET", null);
    if (response.success) {
      setRes_data(response?.object?.map((item:res_act_data_type,index:number)=>{
        return {
          applicant:item?.applicant,
          orderDate:moment(item?.orderDate).format('DD-MM-YYYY'),
          actOfSelectionDate:moment(item?.actOfSelectionDate).format('DD-MM-YYYY'),
          testingProtocolDate:moment(item?.testingProtocolDate).format('DD-MM-YYYY'),
          statusOfProduct:item?.statusOfProduct,
          orderNumber:item?.orderNumber,
          users:item?.users?.map((item:{firstName:string,lastName:string})=>{return {firstName:item.firstName,lastName:item.lastName}}),
        }
      }))
      HandlePrint();
    }
  }
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Акт о списании",
   onAfterPrint:()=>setDownload(false)
  });
  useEffect(() => {
   getActOfWrite()
  }, []);
  return (
    <div ref={componentRef} style={{ width: "594px" }} className="h-auto">
      {
        res_data?.map((item:res_act_data_type,index:number)=>{
          return (
            <>
            {" "}
      {space}
      <div
        className="w-full analis_application text-center pb-1"
        style={{ fontSize: "15px", borderBottom: "1px solid black" }}>
        Общество с ограниченной ответственностью Центр научно-технических и
        маркетинговых исследований «UNICON.UZ» Система сертификации технических
        средств телекоммуникаций
      </div>
      <div className="w-full flex justify-center my-4">
        <div
          className="w-1/3 text-center analis_application"
          style={{ fontSize: "11px", borderBottom: "1px solid black" }}
        >
          ОРГАН ПО СЕРТИФИКАЦИИ ТЕХНИЧЕСКИХ СРЕДСТВ ТЕЛЕКОММУНИКАЦИЙ (ОС ТСТ)
        </div>
      </div>
      <div className="w-full analis_application " style={{ fontSize: "11px" }}>
        {" "}
        100187, г. Тошкент, Карасу-3, ул. Мингбулак, 38
      </div>
      <div className="w-full analis_application " style={{ fontSize: "11px" }}>
        {" "}
        Тел.: + 998 (71) 208-82-39;{" "}
      </div>
      <div
        className="w-full analis_application mb-3"
        style={{ fontSize: "11px" }}
      >
        {" "}
        Факс:{" "}
      </div>
      <div className="w-full">
        <div className="w-full flex justify-center">
          <div className="w-1/3 analis_application font-semibold text-xs text-center">
            АКТ
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-1/3 analis_application font-semibold text-xs text-center">
            О СПИСАНИИ (ВОЗВРАТЕ) ПРОДУКЦИИ
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-1/3 analis_application  text-xs text-center underline">
            от {item?.actOfSelectionDate} _г.
          </div>
        </div>
      </div>
      <div className="w-full mb-3">
        <div className="w-full font-semibold text-sm text-center">
          {item?.applicant}
        </div>
        <div className="w-full text-center" style={{ fontSize: "12px" }}>
          (наименование юридического и физического лица)
        </div>
      </div>
      <div className="w-full mt-3 underline text-center">
        № {item?.orderNumber} от {item?.orderDate} г
      </div>
      <div className="w-full text-center mt-3" style={{ fontSize: "10px" }}>
        (дата и номер заявки)
      </div>
      <div className="w-full my-1" style={{ fontSize: "13px" }}>
        Настоящий акт составлен о том, что образцы продукции, отобранные в
        соответствии с актом отбора образцов (проб) от {item?.testingProtocolDate} г.:
      </div>
      <div className="w-full my-1" style={{ fontSize: "13px" }}>
        были полностью разрушены в период испытания;
      </div>
      <div className="w-full my-1" style={{ fontSize: "13px" }}>
         не подвергшиеся разрушению или частично разрушенные в период испытаний
        возвращены заявителю.
      </div>
      {
        item?.users?.map((item:userObject,index:number)=>{
            return (
                <div className="w-full flex mt-4 mb-3" key={index}>
        <div className="w-1/3 flex items-center" style={{ fontSize: "13px" }}>
          От изготовителя (заказчика)
        </div>
        <div className="w-1/3 " style={{ fontSize: "13px" }}>
          <div
            className="w-5/6 h-7 pb-1"
            style={{ borderBottom: "1px solid black" }}
          ></div>
          <div className="w-5/6 text-center" style={{ fontSize: "11px" }}>
            подпись
          </div>
        </div>
        <div className="w-1/3 " style={{ fontSize: "13px" }}>
          <div
            className="w-5/6 h-7 pb-1 flex items-end"
            style={{ borderBottom: "1px solid black" }}
          >{item?.firstName} {item?.lastName}</div>
          <div className="w-5/6 text-center" style={{ fontSize: "11px" }}>
            Ф.И.О.
          </div>
        </div>
      </div>
            )
        })
      }
            </>
          )
        })
      }     
    </div>
  );
}
export default StepActDocs;
