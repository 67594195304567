import "./StepThree.css";
import 'src/index.scss'
import { Input, Button,Select, Switch,message, Upload,Image  } from "antd";
import type { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { DataStepThree, DataStepThreeProduct } from "src/mockdata/AllData";
import { useState, useEffect, useContext } from "react";
import DataPicker from "src/Component/DatePickerFolder/DataPicker/DataPicker";
import SelectIndicator from "src/Component/Select/Select";
import TextArea from "src/Component/textarea/textArea";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Data,
  Objects,
  props,
  product,
  warehouse_option,
  res_data,
} from "./StepThreeType";
import MeasurmentID from "src/Component/data/measurment";
import moment from "moment";
import { UserContext } from "src/App";
const { Dragger } = Upload;
function StepThree({
  type,
  modalOpen,
  setOpenModal,
  products_list,
  normativ_list,
  nameOfApplicant,
  order
}: props) {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const { setheaderTitle } = useContext(UserContext);
  const [warehouse_List, setWarehouse_list] = useState<warehouse_option[]>([]);
  const [nomrative_list, setNormative_list] = useState<warehouse_option[]>([]);
  const [nameOfOrganization, setnameOfOrganization] = useState("");
  const [data, setData] = useState<Data>({
    nameOfApplicant: "",
    dateOfSelection: "",
    nameOfOrganizationId: 0,
    result: "",
    fromCustomer: "",
    fromOrganization: "",
    inspector: "",
    statusOfPacked: "",
    purpose: "",
    characteristics: "",
    storageConditions: "",
    extraInformation: "",
    normative: [],
    invoiceNumber: "",
    invoiceDate: "",
    deliveryNote: "",
    productIdentificationDTOList: [],
    orderId: id ? parseInt(id) : 1,
  });
  function HandleChange(
    Data: string,
    name: string,
    id?: number,
    type?: string
  ) {
    if (type === "number") {
      setData({ ...data, [name]: parseInt(Data) });
    } else {
      setData({ ...data, [name]: Data });
    }
  }
  function RenderSwitch(
    parm: string,
    key: string,
    options: Objects[] | undefined
  ) {
    switch (parm) {
      case "input":
        return (
          <Input
            type="text"
            name={key}
            onChange={(e) => HandleChange(e.target.value, key)}
            style={{ width: "350px", height: "45px" }}
            placeholder=""
            required
            value={(data as any)[key]}
          />
        );

      case "date":
        return <DataPicker name={key} handleChange={HandleChange} />;
      case "Switch":
        return <Switch className="bg-[#ccc]" />;
      case "select":
        return (
          <SelectIndicator
            HandleChange={HandleChange}
            data={options}
            name={key}
            style={{ width: "350px", height: "45px" }}
            value={(data as any)[key]}
          />
        );
      case "textarea":
        return (
          <TextArea
            value={(data as any)[key]}
            name={key}
            HandleChange={HandleChange}
          />
        );
      case "warehouse_select":
        return (
          <Select
            options={warehouse_List}
            onChange={(value: string) =>
              HandleChange(value, key, undefined, "number")
            }
            style={{ width: "350px", height: "45px" }}
          />
        );
      case "addbox":
        return (
          <Select
            mode="multiple"
            options={nomrative_list}
            onChange={(value: string[]) => {
              setData({ ...data, normative: value });
            }}
            style={{ width: "350px", height: "45px" }}
          />
        );
      default:
        break;
    }
  }
  function handleInputChange(
    parm: string,
    name: string,
    id?: number,
    type?: string
  ) {
    if (id !== undefined) {
      let newArray = data.productIdentificationDTOList?.map(
        (item: product, index) => {
          if (index === id) {
            if (type === "number") {
              return { ...item, [name]: parseInt(parm) };
            } else {
              return { ...item, [name]: parm };
            }
          } else {
            return item;
          }
        }
      );
      setData({ ...data, productIdentificationDTOList: [...newArray] });
    } else {
      setData({ ...data, [name]: parseInt(parm) });
    }
  }
  function HandleSwitchChange(id: number, parm: boolean) {
    let new_arr=data.productIdentificationDTOList?.map((item:product,index:number)=>{
      if(index===id){
        if(parm){
          return {...item,isValid:parm,expiryWithValidDate:null}
        }
        else{
          return {...item,isValid:parm}
        }
      }
      else{
        return item
      }
    })
    setData({ ...data, productIdentificationDTOList: [...new_arr] });
  }
  function RenderProductSwitch(
    parm: string,
    key: string,
    id: number,
    options: Objects[] | undefined,
    disabled?: boolean
  ) {
    switch (parm) {
      case "input":
        return (
          <Input
            type="text"
            name={key}
            onChange={(e) => handleInputChange(e.target.value, key, id)}
            style={{ width: "350px", height: "45px" }}
            placeholder=""
            value={(data.productIdentificationDTOList[id] as any)[key]}
          />
        );
      case "numberinput":
        return (
          <Input
            type="number"
            name={key}
            onChange={(e) =>
              handleInputChange(e.target.value, key, id, "number")
            }
            style={{ width: "350px", height: "45px" }}
            placeholder=""
            required
            value={(data.productIdentificationDTOList[id] as any)[key]}
          />
        );
      case "date":
        return (
          <DataPicker name={key} handleChange={handleInputChange} id={id} disabled={data.productIdentificationDTOList[id].isValid && key==='expiryWithValidDate'? true:false}/>
        );
      case "Switch":
        return (
          <Switch
            className="bg-[#ccc]"
            onChange={(checked: boolean) => HandleSwitchChange(id, checked)}
          />
        );
      case "select":
        return (
          <SelectIndicator
            HandleChange={handleInputChange}
            data={options}
            name={key}
            id={id}
            style={{ width: "350px", height: "45px" }}
            // value={(data.productIdentificationDTOList[id] as any)[key]}
          />
        );
      case "textarea":
        return <TextArea name={key} HandleChange={HandleChange} />;
      case "measurmentId":
        return (
          <MeasurmentID
            name={key}
            type={"number"}
            handleChange={handleSelectChange}
            id={id}
            value={(data.productIdentificationDTOList[id] as any)[key]}
          />
        );
      case "radio":
        return (
          <div className="w-full">
            <Switch className="bg-[#ccc]" />
            <span className="ml-2">Срок годности с прописью</span>
          </div>
        );
      
      default:
        break;
    }
  }
  function handleSelectChange(
    value: string,
    name: string,
    id?: number | undefined,
    type?: string | undefined
  ) {
    let newArray = data.productIdentificationDTOList?.map(
      (item: product, index) => {
        if (index === id) {
          (item as any)[name as keyof product] = parseInt(value);
          return item;
        } else {
          return item;
        }
      }
    );
    setData({ ...data, productIdentificationDTOList: newArray });
  }
  async function get_normativ() {
    const res_normativ = await RequestToken("/normative?page=0&size=100");
    if (res_normativ.success) {
      setNormative_list(
        res_normativ.object.map(
          (item: { normativeNumber: string; id: number }) => {
            return { label: item.normativeNumber, value: item.normativeNumber };
          }
        )
      );
    }
  }
  async function Get_warehouse_list() {
    const response_ware_house_list = await RequestToken(`/warehouse`);
    if (response_ware_house_list.success) {
      get_normativ();
      setWarehouse_list(
        response_ware_house_list.object.map(
          (item: { name: string; id: number }) => {
            return { label: item.name, value: item.id.toString() };
          }
        )
      );
    }
  }
  async function getData() {
    if (type === "edit") {
      Get_warehouse_list();
    }
    const response_act_of = await RequestToken(
      `/actOfSelection/${id}`,
      "GET",
      null
    );
    if (response_act_of.success) {
      setData(response_act_of.object);
      setnameOfOrganization(response_act_of.object.nameOfOrganization);
    }
  }
  async function Save() {
    const Data = await RequestToken(`/actOfSelection`, "POST", data);
    if (Data.success) {
      Swal.fire({
        icon: "success",
        title: " Cохранен",
        text: "Теперь вы можете смотреть со страницы задач!",
      });
      navigate(`/home/${id}`);
    } else if (Data.message === "Network Error") {
      navigate("/error/500");
    } else {
      Swal.fire({
        icon: "error",
        text: Data.response.data.message || Data.response.message,
        title: "Ошибка",
      });
    }
  }
  useEffect(() => {
    getData();
    const newprodcutList = [...data.productIdentificationDTOList];
    console.log(order)
    for (let i = 0; i < order?.products?.length; i++) {
      if (data.productIdentificationDTOList?.length <= products_list?.length) {
        console.log("newprodcutList")
        newprodcutList.push({
          codeProduct: order.products[i].codeProduct,
          name: order.products[i].name,
          manufacturer: order.products[i].manufacturer,
          amount: order.products[i].amount,
          measurementId: order.products[i].id,
          dateOfProducing: "",
          expiryDate: order.p,
          isValid: false,
          expiryWithValidDate: "",
          mainInformation: "",
          extraInformation: order.products[i].extraInformation,
          numberOfSamplesTaken:order.products[i].numberOfSamplesTaken,
          numberOfForTesting: order.products[i].numberOfForTesting,
          perMeasurementId:order.products[i].id,
        });
      }
    }
    
    setData({
      ...data,
      productIdentificationDTOList: [...newprodcutList],
      nameOfApplicant: nameOfApplicant || "",
    });
    setheaderTitle("Акт отбора");
  }, [type]);
  // const props: UploadProps = {
  //   name: 'file',
  //   multiple: true,
  //   accept:'.JPG, .JPEG, .PNG, .HEIC',
  //   headers: {
  //     authorization: `Bearer ${localStorage.getItem('_token')}`,
  //   },
  //   action: `${process.env.REACT_APP_BASE_URL}/attachment/upload`,
  //   onChange(info:any) {
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //       console.log(info?.file?.response?.object);
  //       if (info?.file?.response?.object) {
  //         setData({...data,attachmentIds:[...data?.attachmentIds,info?.file?.response?.object]})
  //       }
  //     }
  //     if (status === 'done') {
  //       message.success(`${info.file.name} ${info?.file?.response?.message}.`);
  //     } else if (status === 'error') {
  //       message.error(`${info.file.name} не удалось загрузить файл.`);
  //     }
  //   },
  //   onDrop(e) {
  //     console.log('Dropped files', e.dataTransfer.files);
  //   },
  // };  
  return (
    <div className="StepThree_wrapper">
      {DataStepThree?.map((item) => {
        return (
          <>
            {item.hasOwnProperty("title") ? (
              <div className="StepThree_wrapper_row " key={item.id}>
                <div className="StepThree_wrapper_row_title">{item.title}</div>
              </div>
            ) : (
              <div className="StepThree_wrapper_row my-2 " key={item.id}>
                <div className="StepThree_wrapper_row_left">{item.label}</div>
                <div className="StepThree_wrapper_row_right">
                  {type === "edit"
                    ? RenderSwitch(item.type, item.key, item.options)
                    : (data as any)[item.key as keyof Data]
                    ? Array.isArray((data as any)[item.key as keyof Data])
                      ? (data as any)[item.key as keyof Data].map(
                          (item: string) => {
                            return (
                              <div className="block w-full my-2 border-b-2 border-[#ccc]">
                                {item}
                              </div>
                            );
                          }
                        )
                      : item.key.toLocaleLowerCase().includes("date")
                      ? moment((data as any)[item.key]).format("DD-MM-YYYY")
                      : (data as any)[item.key]
                    : item.key === "nameOfOrganizationId"
                    ? nameOfOrganization
                    : "нет в наличии"}
                </div>
              </div>
            )}
          </>
        );
      })}
      <div className="px-4 my-4 w-full">
        {/* {
          type === 'edit' ? <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл в эту область, чтобы загрузить</p>
          <p className="ant-upload-hint">
            Загрузите фотографию товара, прибывшего на таможню. Обратите внимание на формат изображения!
          </p>
        </Dragger>:
        <>
          <div className="StepThree_wrapper_row_left">Фотографии товара, сделанные на таможне</div>
            {
              data?.attachmentIds?.map((item:number)=>{
                return <Image key={item} className="m-3"
                  width={200}
                  src={process.env.REACT_APP_BASE_URL + `/attachment/downloadFile/${item}`}
                />
              })
            }
        </>
        } */}
      </div>
      <div className="StepThree_wrapper_title">
      <div>Идентификация продукции:</div>
      </div>
      {type === "edit"
        ? data.productIdentificationDTOList?.map((item: product, index) => {
            return (
              <div
                className="StepThree_wrapper_row product_wrapper_box"
                key={index}
              >
                <div className="product_wrapper_box_header">
                  <div>Продукт {index + 1}</div>
                </div>
                {DataStepThreeProduct.map((arrayItem) => {
                  if (arrayItem.label) {
                    return (
                      <div
                        className="StepThree_wrapper_row my-2"
                        key={arrayItem.id}
                      >
                        <div className="StepThree_wrapper_row_left pl-2.5">
                          {arrayItem.label}
                        </div>
                        <div className="StepThree_wrapper_row_right">
                          {type === "edit"
                            ? RenderProductSwitch(
                                arrayItem.type,
                                arrayItem.key,
                                index,
                                arrayItem.options,
                                arrayItem.disabled
                              )
                            : item[arrayItem.key as keyof product]
                            ? Array.isArray(
                                item[arrayItem.key as keyof product]
                              )
                              ? (item as any)[arrayItem.key].map(
                                  (val: any, index: number) => {
                                    <div className="formlist_wrapper_row_right_gost">
                                      {val}
                                    </div>;
                                  }
                                )
                              : item[arrayItem.key as keyof product]
                            : "нет в наличии"}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })
        : data.productIdentificationDTOList?.map((item: product, index) => {
            return (
              <div
                className="StepThree_wrapper_row product_wrapper_box"
                key={index}
              >
                <div className="product_wrapper_box_header">
                  <div>Продуст {index+1}</div>
                </div>
                {DataStepThreeProduct.map((arrayItem) => {
                  if (arrayItem.label) {
                    return (
                      <div
                        className="StepThree_wrapper_row my-2"
                        key={arrayItem.id}
                      >
                        <div className="StepThree_wrapper_row_left">
                          {arrayItem.label}
                        </div>
                        <div className="StepThree_wrapper_row_right">
                          {type === "edit"
                            ? RenderProductSwitch(
                                arrayItem.type,
                                arrayItem.key,
                                index,
                                arrayItem.options,
                                arrayItem.disabled
                              )
                            : item[arrayItem.key as keyof product]
                            ? Array.isArray(
                                item[arrayItem.key as keyof product]
                              )
                              ? (item as any)[arrayItem.key].map(
                                  (val: any, index: number) => {
                                    <div className="formlist_wrapper_row_right_gost">
                                      {val}
                                    </div>;
                                  }
                                )
                              : item[arrayItem.key as keyof product]
                            : "нет в наличии"}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
      <div className="StepThree_wrapper_row ">
        <div className="StepThree_wrapper_row_left"></div>
        <div className="StepThree_wrapper_row_right">
          {type === "edit" ? (
            <>
              <Button style={{ height: "45px" }} className="px-5 mx-3">
                Отмена
              </Button>
              <Button
                type="primary"
                style={{ height: "45px" }}
                className="px-3"
                onClick={Save}
              >
                Cохранить
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
export default StepThree;
