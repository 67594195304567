import  Textarea  from '@mui/joy/Textarea';
interface props {
    name:string,
    value?:string,
    style?:object
    HandleChange:(parm:string,name:string)=>void
}
function TextArea({HandleChange,name,value,style}:props){
    function handleChange(parm:string){
        HandleChange(parm,name)
    }
    return (
        <>
            <Textarea minRows={2} 
            sx={style ? style:{
                width:"350px",
                height:'210px'
            }}
            value={value}
            placeholder='Заполнить его'
            onChange={(e)=>handleChange(e.target.value)}
            />
        </>
    )
}
export default TextArea;