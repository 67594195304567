import style from './style.module.css'
import {Report,UploadPhoto} from 'src/assets/icons/laboratory/index'
interface props {
  number?:number
  list?:{id:number,component:JSX.Element}[]
  ChangeTabId:(parm:string,value:number)=>void;
}
function VerticalNavigationsitebar({number,list,ChangeTabId}:props){
    return (
      <div className={`w-full h-full ${style.Navigationsitebar}`}>
          <div className={style.Navigationsitebar_title_box}>
          <div className={style.Navigationsitebar_title}>
            Выбор таблицы
          </div>
          <div className={style.Navigationsitebar_description}>
            После заполнения формы данные отобразятся в виде <span>.pdf</span> файла 
          </div>
          </div>
          <div className={style.Navigationsitebar_cols}>
            {/* Navigationsitebar_cols */}
            
            <div className={number===1 ? style.Navigationsitebar_row_active:style.Navigationsitebar_row} onClick={()=>ChangeTabId("",1)}>Таблица 1<span className='text-[red] '>*</span></div>
            <div className={number===2 ? style.Navigationsitebar_row_active:style.Navigationsitebar_row} onClick={()=>ChangeTabId("",2)}>Таблица 2,3<span className='text-[red] '>*</span></div>
            {
              list?.map((item:{id:number},index:number)=>{
                return (
                 index<2 || item.id===10 || item.id===11 ? null:<div className={number===item.id ? style.Navigationsitebar_row_active:style.Navigationsitebar_row} 
                 onClick={()=>ChangeTabId("",item.id)}>Таблица {item.id}</div>
                )
              })
            }
            {/* <div className={style.Navigationsitebar_row }>Таблица 3</div>
            <div className={style.Navigationsitebar_row }>Таблица 4</div>
            <div className={style.Navigationsitebar_row }>Таблица 5</div>
            <div className={style.Navigationsitebar_row }>Таблица 6</div>
            <div className={style.Navigationsitebar_row }>Таблица 7</div> */}
           
          </div>
          <div className={style.Navigationsitebar_update_box}>
          <span className={style.Navigationsitebar_cols_span}></span>
               <div className={ number===11 ? `w-full flex bg-[#0F62FE] rounded-md justify-center items-center h-11 text-[#fff]`:`${style.Navigationsitebar_row}`} onClick={()=>ChangeTabId("",11)}>
                 <div><UploadPhoto color={number===11 ? '#fff':"#000"}/></div>
                 <div className='mx-2'>Приложение</div>
               </div>
               <span className={style.Navigationsitebar_cols_span}></span>
               <div className={number===10 ? style.Navigationsitebar_upload_photo_active:style.Navigationsitebar_upload_photo} onClick={()=>ChangeTabId('',10)}>
                <div><Report color={"#fff"}/></div>
                <div>Заключение</div>
               </div>
          </div>
      </div>
    )
}
export default VerticalNavigationsitebar