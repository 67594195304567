import GridViewIcon from "@mui/icons-material/GridView";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {UserAddOutlined} from "@ant-design/icons";
import BarChartIcon from "@mui/icons-material/BarChart";
import {FileAddOutlined} from '@ant-design/icons';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Plus, Protokol } from "src/assets/icons/laboratory";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Jurnal,Program,Task,Statment } from "src/assets/icons/laboratory";
import { Add } from "@mui/icons-material";
export const roleslist=['SOHIBKOR',"SUPER_ADMIN","USER"]
 const  SUPER_ADMIN = [
    {
      key: "admin/dashboard",
      label: "Панель приборов",
      path: "admin/dashboard",
      icon: <GridViewIcon />,
    },
    {
      key: "admin/monitoring",
      label: "Мониторинг сотрудников",
      path: "admin/monitoring",
      icon: <EventAvailableIcon />,
    },
   
    // {
    //   key:"admin/Message",
    //   label:'Сообщение',
    //   path:'admin/Message',
    //   icon:<MessageIcon/>
    // },
    // {
    //   key:"admin/Payment",
    //   label:'Оплата',
    //   path:'admin/Payment',
    //   icon:<PaymentIcon/>
    // },
    {
      key: "admin/Settings",
      label: "Добавить пользователя",
      path: "admin/Settings",
      icon: <UserAddOutlined />,
    },
    // {
    //   key: "admin/Analytios",
    //   label: "Аналитиос",
    //   path: "admin/Analytios",
    //   icon: <BarChartIcon />,
    // },
    {
      key: "admin/standart/position",
      label: "Добавить позиция",
      path: "admin/standart/position",
      icon: <BarChartIcon />,
    },
    {
      key: "/admin/arizalar",
      label: "Bсе заявления",
      path: "/admin/arizalar",
      icon: <BarChartIcon />,
    },
  ];
  const EMPLOYEE_AGENCY= [
    { key:'1',
      label:'Панель приборов',
      path:'dashboard',
      icon:<GridViewIcon/>
    },
    { 
      key:'2',
      label:'Крайний срок',
      path:'deadline',
      className:'NavLink',
      icon:<AccessTimeOutlinedIcon/>},
    {
      key:'3',
      label:'Задания',
      path:'vazifalar',
      icon:<Task/>,
    },
    {
      key: "standart/organ",
      label: "Стандартное дополнение",
      path: "standart/organ",
      icon: <BarChartIcon />,
    },
    {
      key:'6',
      label:'Заявления',
      path:'arizalar',
      icon:<SettingsIcon/>,
    },
    {
      key:'7',
      label:"добавить заявление",
      path:'addariza',
      icon:<Add/>,
    },
    // {
    //   key:'8',
    //   label:"Обзор заявления",
    //   path:'home/',
    //   icon:<SettingsIcon/>,
    // }
  ]
  const EMPLOYEE_LABORATORY= [
    // { key:'1',
    //   label:'Панель приборов',
    //   path:'',
    //   icon:<GridViewIcon/>
    // },
    { 
      key:'2',
      label:'Крайний срок',
      path:'laborotory/deadline',
      className:'NavLink',
      icon:<AccessTimeOutlinedIcon/>},
    {
      key:'3',
      label:'Задания',
      path:'laborotory/vazifalar',
      icon:<Task/>,
    },
    {
      key: "standart/laboratory",
      label: "Стандартное дополнение",
      path: "standart/laboratory",
      icon: <BarChartIcon />,
    },
    {
      key:'5',
      label:"Журнал регистрации",
      path:'laborotory/listregis',
      icon:<Jurnal/>,
    },
    {
      key:'6',
      label:"Программа",
      path:'laborotory/programa',
      icon:<Program />,
    },
    {
      key:'7',
      label:"Протокол испытаний",
      path:'laborotory/prtokol',
      icon:<Protokol/>,
    },
    {
      key:'8',
      label:"Данные организации",
      path:'laborotory/organizationdata',
      icon:<Plus/>,
    },
  ]
  const ADMIN_OF_LABORATORY= [
    // { key:'1',
    //   label:'Панель приборов',
    //   path:'',
    //   icon:<GridViewIcon/>
    // },
    { 
      key:'2',
      label:'Крайний срок',
      path:'laborotory/deadline',
      className:'NavLink',
      icon:<AccessTimeOutlinedIcon/>},
    // {
    //   key:'3',
    //   label:'Задания',
    //   path:'laborotory/vazifalar',
    //   icon:<Task/>,
    // },
    {
      key:'4',
      label:'Изменение номера',
      path:'laborotory/numbered',
      icon:<AssignmentOutlinedIcon/>,
    },
    {
      key:'5',
      label:"Журнал регистрации",
      path:'laborotory/listregis',
      icon:<Jurnal />,
    },
    {
      key:'6',
      label:"Программа",
      path:'laborotory/programa',
      icon:<Program />,
    },
    {
      key:'7',
      label:"Протокол испытаний",
      path:'laborotory/prtokol',
      icon:<Protokol/>,
    },
    {
      key: "standart/laboratory",
      label: "Посмотреть стандарты",
      path: "standart/laboratory",
      icon: <BarChartIcon />,
    },
    {
      key:'8',
      label:"Данные организации",
      path:'laborotory/organizationdata',
      icon:<Plus/>,
    },
  ]
  const ADMIN_OF_AGENCY=[
    { key:'1',
      label:'Панель приборов',
      path:'dashboard',
      icon:<GridViewIcon/>
    },
    { 
      key:'2',
      label:'Крайний срок',
      path:'deadline',
      className:'NavLink',
      icon:<AccessTimeOutlinedIcon/>},
    // {
    //   key:'3',
    //   label:'Задания',
    //   path:'vazifalar',
    //   icon:<AssignmentOutlinedIcon/>,
    // },
    {
      key: "standart/organ",
      label: "Посмотреть стандарты",
      path: "standart/organ",
      icon: <BarChartIcon />,
    },
   
    {
      key:'6',
      label:'Все заявления',
      path:'arizalar',
      icon:<SettingsIcon/>,
    },
    {
      key:'7',
      label:"Принятие заявления",
      path:'accept',
      icon:<FileAddOutlined />,
    },
    // {
    //   key:'8',
    //   label:"Обзор заявления",
    //   path:'home/',
    //   icon:<SettingsIcon/>,
    // },
    {
      key: "admin/Appointment",
      label: "Управление сотрудников",
      path: "admin/Appointment",
      icon: <AssignmentIcon />,
    },
  ]
export const role_data={
  EMPLOYEE_AGENCY:EMPLOYEE_AGENCY,
  SUPER_ADMIN:SUPER_ADMIN,
  EMPLOYEE_LABORATORY:EMPLOYEE_LABORATORY,
  ADMIN_OF_AGENCY:ADMIN_OF_AGENCY,
  ADMIN_OF_LABORATORY:ADMIN_OF_LABORATORY
}