import {useState} from 'react'
import style from '../TablesTestReportTwo/style.module.css'
import style1 from './style.module.css'
import { Minus,Plus } from 'src/assets/icons/laboratory';
import {Select,Input,Switch,notification} from 'antd'
import { normativ, requirment ,sixTableDTOList, testMethods} from '../../TableWizzard/TableWizzardType';
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
interface props {    requirment_list:requirment[]
    namesProducts?:{label:string,value:number}[],
    progremID:number
    setLoading:(loading:boolean)=>void}
function TablesTestReportFourPart({progremID,requirment_list,setLoading,namesProducts}:props){
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [writeTable, setWriteTable] = useState<boolean>(false);
    const [product_id,setProduct_ID]=useState<number>(0)
    const [sixTableDTOList,setSixTableDTOList]=useState<sixTableDTOList[]>([
        {
          productId: 0,
          requirementId: 0,
          normativeIds: [],
          testMethodIds: [],
          levelOfTestingSeverityWire0: 0,
          testImpactParametersWire0: "",
          wire0Result: "",
          wire0IsSuccess: false,
          levelOfTestingSeverityWire90: 0,
          testImpactParametersWire90: "",
          wire90Result: "",
          wire90IsSuccess: false,
          levelOfTestingSeverityWire180: 0,
          testImpactParametersWire180: "",
          wire180Result: "",
          wire180IsSuccess: false,
          levelOfTestingSeverityWire270: 0,
          testImpactParametersWire270: "",
          wire270Result: "",
          wire270IsSuccess: false,
          levelOfTestingSeverityWireToGround0: 0,
          testImpactParametersWireToGround0: "",
          wireToGround0Result: "",
          wireToGround0IsSuccess: false,
          levelOfTestingSeverityWireToGround90: 0,
          testImpactParametersWireToGround90: "",
          wireToGround90Result: "",
          wireToGround90IsSuccess: false,
          levelOfTestingSeverityWireToGround180: 0,
          testImpactParametersWireToGround180: "",
          wireToGround180Result: "",
          wireToGround180IsSuccess: false,
          levelOfTestingSeverityWireToGround270: 0,
          testImpactParametersWireToGround270: "",
          wireToGround270Result: "",
          wireToGround270IsSuccess: false
        }
      ])
      function HandleChange(parm:string|number|boolean,keyname:string,id:number,type?:string){
      if(writeTable){
        setSixTableDTOList(sixTableDTOList.map((item:sixTableDTOList,indexTable:number)=>{
           if(keyname==='normativeIds' || keyname==='testMethodIds'){
            return {...item,[keyname]:[...(item as any)[keyname],parm]}
           }
           else if(keyname==='requirementId' && typeof parm==='number'){
              return {...item,requirementId:parm}
           }
           else{
             if(item.productId===product_id){
              if(type==='number_input' && typeof parm==='string'){
                return {...item,[keyname]:parseInt(parm)}
               }
               else{
                return {...item,[keyname]:parm}
               }
             }
             else{
              return item
             }
           }
        }))
      }
      else{errorNotifaction()}
      }
      function errorNotifaction() {
        api.error({
          message: "Ошибка",
          description: "Вы не можете изменить расписание",
        });
      }
    function WriteTableFunc(checked: boolean) {
        setWriteTable(checked);
        if (checked && namesProducts!==undefined) {
          api.success({
            message: `Успех`,
            description: "Вы подтвердили заполнение таблицы",
          });
          if(namesProducts?.length>1){
            const new_arr=[]
            for (let i = 0; i < namesProducts.length; i++) {
               new_arr.push({ productId: namesProducts[i].value,
                requirementId: 0,
                normativeIds: [],
                testMethodIds: [],
                levelOfTestingSeverityWire0: 0,
                testImpactParametersWire0: "",
                wire0Result: "",
                wire0IsSuccess: false,
                levelOfTestingSeverityWire90: 0,
                testImpactParametersWire90: "",
                wire90Result: "",
                wire90IsSuccess: false,
                levelOfTestingSeverityWire180: 0,
                testImpactParametersWire180: "",
                wire180Result: "",
                wire180IsSuccess: false,
                levelOfTestingSeverityWire270: 0,
                testImpactParametersWire270: "",
                wire270Result: "",
                wire270IsSuccess: false,
                levelOfTestingSeverityWireToGround0: 0,
                testImpactParametersWireToGround0: "",
                wireToGround0Result: "",
                wireToGround0IsSuccess: false,
                levelOfTestingSeverityWireToGround90: 0,
                testImpactParametersWireToGround90: "",
                wireToGround90Result: "",
                wireToGround90IsSuccess: false,
                levelOfTestingSeverityWireToGround180: 0,
                testImpactParametersWireToGround180: "",
                wireToGround180Result: "",
                wireToGround180IsSuccess: false,
                levelOfTestingSeverityWireToGround270: 0,
                testImpactParametersWireToGround270: "",
                wireToGround270Result: "",
                wireToGround270IsSuccess: false})
            }
            setSixTableDTOList(new_arr)
            setProduct_ID(namesProducts[0].value)
        }
        } else {
          api.info({
            message: `Подтвержденный`,
            description: `Хотите заполнить таблицу "Заполнить таблицу" нажать на кнопкуы`,
          });
        }
       
      }
      function error(response_data: any) {
        setLoading(true);
        if (
          response_data.response.status === "403" &&
          response_data.response.status === 401
        ) {
          navigate("/");
        } else {
          Swal.fire({
            icon: "error",
            title:
              response_data.response.data.message || response_data.response.message,
          });
        }
      }
      async function Saved() {
        setLoading(false)
        if (writeTable) {
          const response = await RequestToken(
            "/testingProtocol/sixthTable",
            "POST",
            sixTableDTOList
          );
          if (response.success) {
           setLoading(true)
            Swal.fire({
              title: response?.message || response.response.message,
              icon: "success",
            });
          } else {
            error(response);
          }
        } else {
          errorNotifaction();
        }
      }
      function RemoveItem(id: number, keyname: string, index: number) {
        setSixTableDTOList(
            sixTableDTOList.map(
            (item: sixTableDTOList, indexFourthTable: number) => {
              if (index === indexFourthTable) {
                if (Array.isArray((item as any)[keyname])) {
                  return {
                    ...item,
                    [keyname]: (item as any)[keyname].filter(
                      (item1: number, index1: number) => item1 !== id
                    ),
                  };
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }
          )
        );
      }
      const standarts=requirment_list.filter((item:requirment,index:number)=>item.id===sixTableDTOList[0].requirementId)[0]
      console.log(sixTableDTOList)
    return (
        <div className={style.TablesTestReportTwo_wrapper}>
              {contextHolder}
             <div className="w-full my-1"><Switch className='bg-[#ccc]' id='write_table' checked={writeTable} onChange={(checked:boolean)=>WriteTableFunc(checked)}/>
            <label htmlFor='write_table' className='ml-2'>Заполнить таблицу?</label></div>
            <table className={style.TablesTestReportTwo}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><label htmlFor="requirment_six" className='w-full'>YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                        <Select className='w-full' id='requirment_six' options={requirment_list.map((item:requirment,index:number)=>{
                            return {label:item.name,value:item.id}
                        })} onChange={(value:number)=>HandleChange(value,'requirementId',0)}/></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td colSpan={4} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                                {sixTableDTOList[0].testMethodIds.map((item:number,index)=>{
                                   if(standarts?.testMethods.filter((itemTest:testMethods,index:number)=>itemTest.id===item).length>0){
                                    return (
                                        <div className={`w-full my-2 flex `}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.testMethods?.filter((itemTest:testMethods,index:number)=>itemTest.id===item)[0].name}</div>
                                            <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(item,'testMethodIds',0)}><Minus/></button></span>
                                        </div>
                                    )
                                   }
                                })}
                               <Select className='w-full' options={standarts?.testMethods.map((item:testMethods,indexTest:number)=>{
                                 return {label:item.name,value:item.id}
                               })} onChange={(value:number)=>HandleChange(value,'testMethodIds',0)}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {sixTableDTOList[0].normativeIds.map((item:number,index)=>{ 
                                   if(standarts?.normativeList.filter((itemTest:normativ,index:number)=>itemTest.id===item).length>0){
                                    return (
                                        <div className={`w-full my-2 flex ${style.TablesTestReportTwo_form}`}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.normativeList?.filter((itemTest:normativ,index:number)=>itemTest.id===item)[0].normativeNumber}</div>
                                            <span className='w-1/4 pl-2 '><button className='text-[#000]' onClick={()=>RemoveItem(item,'normativeIds',0)}><Minus/></button></span>
                                        </div>
                                    )
                                   }
                                })}
                               <Select className='w-full' options={standarts?.normativeList.map((item:normativ,indexTest:number)=>{
                                 return {label:item.normativeNumber,value:item.id}
                               })} onChange={(value:number)=>HandleChange(value,'normativeIds',0)}/>
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4} rowSpan={4}>
                            <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                        
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                       
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>положительная / отричцательная</td>
                       
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                                <Select className={style.TablesTestReportTwo_model_select} options={namesProducts} onChange={(value:number)=>setProduct_ID(value)}/>
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
           {
            sixTableDTOList.filter((item:sixTableDTOList)=>item.productId===product_id)?.map((item:sixTableDTOList,indexTable:number)=>{
                return (
                    <table key={indexTable}>
                    <thead>
                            <tr>
                                <th className={style1.table_th}  colSpan={2} rowSpan={2}>Вид испытаний и или воздействие {item.productId}</th>
                                <th className={style1.table_th} colSpan={2} rowSpan={2} >Степень жесткости испытаний</th>
                                <th className={style1.table_th} colSpan={2} rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                                <th className={style1.table_th} colSpan={2}>Критерий качества функционарования</th>
                                <th className={style1.table_th} colSpan={2} rowSpan={2}>Результат испытания</th>
                            </tr>
                            <tr>
                                <th className={style1.table_th}>По НДС</th>
                                <th className={style1.table_th}>По НДС</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={10} className='bg-[#DDE1E6] p-2 font-semibold '>Порт электропитания 220V</td>
                            </tr>
                            <tr>
                                <td className={style1.table_th} colSpan={1} rowSpan={4}>Провод-провод</td>
                                <td className={`${style1.table_th} text-center`} colSpan={1}>0</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWire0} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWire0',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWire0} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWire0',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1} rowSpan={4}>f</td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wire0Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wire0Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wire0IsSuccess} id='0' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wire0IsSuccess',item.productId)}/> <label htmlFor='0' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`} colSpan={1}>90</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWire90} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWire90',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWire90} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWire90',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wire90Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wire90Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wire90IsSuccess} id='90' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wire90IsSuccess',item.productId)}/> <label htmlFor='90' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`} colSpan={1}>180</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWire180} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWire180',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWire180} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWire180',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wire180Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wire180Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wire180IsSuccess} id='180' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wire180IsSuccess',item.productId)}/> <label htmlFor='180' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`}  colSpan={1}>270</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWire270} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWire270',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWire270} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWire270',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wire270Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wire270Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wire270IsSuccess} id='270' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wire270IsSuccess',item.productId)}/> <label htmlFor='270' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            {/* qishki jadval */}
                            <tr>
                                <td className={style1.table_th} colSpan={1} rowSpan={4}>Провод-земля</td>
                                <td className={`${style1.table_th} text-center`} colSpan={1}>0</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWireToGround0} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWireToGround0',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWireToGround0} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWireToGround0',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1} rowSpan={4}>f</td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wireToGround0Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wireToGround0Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch id='0' checked={item.wireToGround0IsSuccess} className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wireToGround0IsSuccess',item.productId)}/> <label htmlFor='0' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`} colSpan={1}>90</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWireToGround90} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWireToGround90',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWireToGround90} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWireToGround90',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wireToGround90Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wireToGround90Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wireToGround90IsSuccess} id='90' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wireToGround90IsSuccess',item.productId)}/> <label htmlFor='90' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`} colSpan={1}>180</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWireToGround180} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWireToGround180',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWireToGround180} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWireToGround180',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wireToGround180Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wireToGround180Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wireToGround180IsSuccess} id='180' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wireToGround180IsSuccess',item.productId)}/> <label htmlFor='180' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                            <tr>
                                {/* <td className={style1.table_th} colSpan={1} rowSpan={4}>s</td> */}
                                <td className={`${style1.table_th} text-center`} colSpan={1}>270</td>
                                <td className={style1.table_th} colSpan={2} ><Input value={item.levelOfTestingSeverityWireToGround270} className='w-full h-11' type='number' onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityWireToGround270',item.productId,'number_input')}/></td>
                                <td className={style1.table_th} colSpan={2}><Input value={item.testImpactParametersWireToGround270} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'testImpactParametersWireToGround270',item.productId)}/></td>
                                <td className={style1.table_th} colSpan={1}><Input value={item.wireToGround270Result} className='w-full h-11' onChange={(e)=>HandleChange(e.target.value,'wireToGround270Result',item.productId)}/></td>
                                <td className={`${style1.table_th} flex`} colSpan={2}><Switch checked={item.wireToGround270IsSuccess} id='270' className='bg-[#ccc]' onChange={(checked:boolean)=>HandleChange(checked,'wireToGround270IsSuccess',item.productId)}/> <label htmlFor='270' className='mx-2 cursor-pointer'>Несоответствует</label></td>
                            </tr>
                        </tbody>
                    </table>
                )
            })
           }
            <div
                className="w-full  flex items-end justify-end"
                style={{ height: "10%" }}
              >
                <button className="px-3 py-2 rounded-lg border-2 mr-2">
                  Отменить
                </button>
                <button
                  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
                  onClick={Saved}
                  disabled={!writeTable}
                >
                  Сохранить
                </button>
              </div>
        </div>
    )
}
export default TablesTestReportFourPart;