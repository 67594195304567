import "src/pages/task/task.css";
import * as React from "react";
import { Card } from "antd";
import Time from "../../Time/Time";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { FilesIcon } from "src/assets/icons/pagesicon/pagesicon";

interface data{
  id:number;
  dateOrder:string,
  dateOfReceiveTemplate:string,
  updateAt:string
}
interface props {
  data:data
}
export default function CardItem({ data, location, page, footer }:any) {
  const originalDate = data.updateAt;
  const formattedDate = moment(originalDate).format('HH:mm:ss');

  return (
    <NavLink to={`/home/${data.id}`} className="Card mx-2 my-2">
      <div className="Card_image">
        <FilesIcon />
      </div>
      <div className="Card_time ">
        <div>
          <span className="text-xs text-grey-400 block text-right">03-08-2023</span>
            {formattedDate}
        </div>
      </div>
      <div className="Card_body">
       <span className="text-grey-500 mr-2 text-xl">Kомпания:</span>
       <span className="text-black-500 mr-2 text-xl">{data.company}</span>
      </div>
      <div className="Card_footer">
        <div className="w-full">
          <span className="text-sm font-normal mr-1">Оставшееся время:</span>{(<Time start={data.dateOrder} end={data.dateOfReceiveTemplate}/>)} <span className="text-sm mx-1">часов</span>
        </div>
      </div>
    </NavLink>
  );
}
