import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  catagorie_list,
  laboratory_list,
} from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal, Input, Switch, DatePicker, Select } from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
import { catagore } from "src/pages/laboratory/program/indexData";
interface props {
  catagorie_list?: catagorie_list[];
  get_catagoire_list: () => void;
  loading:boolean
}
interface new_category {
  parentId: number|null;
  name: string;
}
function Catagorie({ catagorie_list, get_catagoire_list,loading}: props) {
  const navigate = useNavigate();
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [without_podcatagore_list,setWithout_podcatagore_list]=useState<any>([])
  const [new_catagory_name, setNew_catagory_name] = useState<boolean>(false);
  const [update_laboratory, setUpdate_Laboratory] = useState<number | null>(null);
  const [new_category, setNew_category] = useState<new_category>({
    name: "",
    parentId: 0,
  });
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotification(id: number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите отключить этот лаборатория?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  }
  function UpdateModal(id: number) {
    const new_obj = catagorie_list?.find(
      (item: catagorie_list) => item.id === id
    );
    console.log(new_obj)
    if (new_obj?.children) {
      // setNew_category({
      //   ...new_category,
      //   parentId:new_obj?.children?.id,
      //   name: new_obj.name,
      // });
    }else if(new_obj){  setNew_catagory_name(true)
      setNew_catagory_name(true)
      setNew_category({
        ...new_category,
        name: new_obj?.name,
        parentId:null,
      });}
    setUpdate_Laboratory(id);
    setOpenModal(true);
  }
  function Cancel() {
    setOpenModal(false);
    setNew_category({
      ...new_category,
      name: "",
      parentId: 0,
    });
    setUpdate_Laboratory(null);
    setNew_catagory_name(false)
  }
  function HandleCatagorie(checked:boolean){
    setNew_catagory_name(checked)
    setNew_category({...new_category,parentId:null})
  }
  function HandleChange(value:string|number){
    if(typeof value==='number'){
      setNew_category({...new_category,parentId:value})
    }
  }
  async function Delete(id: number) {
    const res_test_method_list = await RequestToken(
      `/category/${id}`,
      "DELETE",
      null
    );
    if (res_test_method_list.success) {
      get_catagoire_list();
      Swal.fire("Удалено!", "", "success");
    } else {
      error(res_test_method_list);
    }
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_data = await RequestToken(
      update_laboratory ? `/category/${update_laboratory}` : "/category",
      update_laboratory ? "PUT" : "POST",
      new_category
    );
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      get_catagoire_list();
    } else {
      error(res_data);
    }
  }
  // const without_podcatagore_list=catagorie_list?.filter((item:catagorie_list)=>{if(item.parent){return item}})?.map((item:catagorie_list)=>{
  //   return {label:item?.parent?.name,value:item?.parent?.name}
  // })
  async function Get_without_categorey_list(){
    const res_data=await RequestToken('/category?page=0&size=150',"GET",null)
       if(res_data.success){
          (res_data?.object?.map((item:{id:number,name:string})=>{
          return {
            label:item.name,
            value:item.id
          }
        }))
       }
  }
  useEffect(()=>{
    Get_without_categorey_list()
  },[])
  console.log(catagorie_list)
  return (
    <div className="w-full h-full flex items-center justify-center">
     {
      loading ?  <div className="w-full h-full overflow-auto">
      <div
        className={_role === "ADMIN_OF_LABORATORY" ? "hidden" : "w-full mb-2"}
      >
        <button
          className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
          onClick={() => setOpenModal(true)}
        >
          Добавить новую категория <Add />
        </button>
      </div>
      <table className="w-full h-auto">
        <thead>
          <tr>
            <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">
              №
            </th>
            <th className="bg-[#F2F4F8] p-3">Категории</th>
            <th className="bg-[#F2F4F8] p-3">Категории модулей</th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {catagorie_list ? (
            catagorie_list?.map((item: catagorie_list, index: number) => {
              return (
                <tr>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {index+1}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.name}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6",paddingLeft:'0px' }} >
                    {item.children?.map((itemChildren:{id:number,name:string},indexChildren:number)=>{
                     return (
                      <div key={indexChildren} className="p-3 " style={indexChildren===0 ? {}:{borderTop:'1px solid #DDE1E6'}}>{itemChildren?.name}</div>
                     ) 
                    })}
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl text-[red] cursor-pointer"
                      onClick={() => DeleteNotification(item.id)}
                    >
                      <DeleteOutlined />
                    </span>
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl cursor-pointer"
                      onClick={() => UpdateModal(item.id)}
                    >
                      <EditOutlined />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>:<Loading/>
     }
     <Modal centered open={openModal} footer={null} onCancel={Cancel} >
     <div className="w-full font-semibold text-2xl mb-3">
       {update_laboratory ? "Редактирование категории":"Добавить новую категория"}
     </div>
     <div className="w-full flex">
         <label className="w-1/2" htmlFor="new_category_name">Хотите добавить новую категорию?</label>
          <span className="w-1/2 flex"><Switch className="bg-[#ccc]" checked={new_catagory_name} onChange={(checked:boolean)=>HandleCatagorie(checked)}/></span>
       </div>
      {new_catagory_name ?   <form action="test_method" onSubmit={Saved}>
       <label className="w-full block mt-2" htmlFor="name">
       Новая категория записи
       </label>
       <Input
         id="name"
         required
         className="h-11"
         placeholder="ТРЕБОВАНИЯ К ТЕХНИЧЕСКОЙ СОВМЕСТИМОСТИ ТСТ"
         value={new_category.name}
         onChange={(e) =>
           setNew_category({ ...new_category, name: e.target.value })
         }/>
       <div className="w-full flex justify-end my-2">
         <button
           onClick={Cancel}
           type="button"
           className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
         >
           Отмена
         </button>
         <input
           type="submit"
           id="test_method"
           value={"Сохранить"}
           className="bg-[#1677FF] rounded-md text-[#fff] px-3 py-2"
         />
       </div>
     </form>:<form action="test_method" onSubmit={Saved}>
       <label className="w-full block mt-2" htmlFor="name">
          Введите название подкатегории
       </label>
       <Input
         id="name"
         required
         className="h-11"
         placeholder="ТРЕБОВАНИЯ К ТЕХНИЧЕСКОЙ СОВМЕСТИМОСТИ ТСТ"
         value={new_category.name}
         onChange={(e) =>
           setNew_category({ ...new_category, name: e.target.value })
         }
       />
       
       <label className="w-full block mt-2" htmlFor="parentID">
       Выберите категорию
       </label>
       <Select value={catagorie_list?.find((item:catagorie_list)=>item.id===new_category.parentId)?.name} className="w-full h-11" options={catagorie_list?.map((item:any)=>{return {label:item.name,value:item.id}})} onChange={(value:string)=>HandleChange(value)}
       />
       <div className="w-full flex justify-end my-2">
         <button
           onClick={Cancel}
           type="button"
           className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
         >
           Отмена
         </button>
         <input
           type="submit"
           id="test_method"
           value={"Сохранить"}
           className="bg-[#1677FF] rounded-md text-[#fff] px-3 py-2"
         />
       </div>
     </form>}
   </Modal>
    </div>
  );
}
export default Catagorie;
