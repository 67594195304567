import './home.css'
import {useState,useEffect,useContext} from 'react'
import {RightOutlined} from '@ant-design/icons';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
import {AnalyticsGraph,AnalyticsPieChart} from 'src/Component/chart/Chart';
import { File,Analytic,KirimChiqim,Users, FilterIcon } from 'src/assets/icons/ListItemIcon';
import { UserContext } from 'src/App';
import { Input, Modal, Progress, TimePicker,DatePicker } from 'antd';
import type { DatePickerProps, TimePickerProps } from 'antd';
import { dashboard_data } from './indexType';
import { RequestToken } from 'src/Hook/Request/Request';
import Loading from 'src/Component/Loading/Loading';
type PickerType = 'time' | 'date';

const PickerWithType = ({
  type,
  onChange,
}: {
  type: PickerType;
  onChange: TimePickerProps['onChange'] | DatePickerProps['onChange'];
}) => {
  if (type === 'time') return <TimePicker onChange={onChange} />;
  if (type === 'date') return <DatePicker onChange={onChange} />;
  return <DatePicker picker={type} onChange={onChange} />;
};

  const Dashboard = () => {
    const [data,setData]=useState([])
    const [yearID,setYearID]=useState<string>('2023')
    const [dataID,setDataID]=useState(1)
    const [applicantStatis,setApplicantStatis]=useState<dashboard_data>()
    const [isOpenModal,setIsOpenModal]=useState<boolean>(false)
    const [loading,setLoading]=useState<boolean>(true)
    const {setheaderTitle}=useContext(UserContext)
    function HandleMonth(parm:string){
      console.log(parm)
    }
 async function Get_Dashboard_Data(){
  setLoading(false)
  const res_dashboad_data=await RequestToken('/order/amountOfOrdersByStatus','GET',null)

  if(res_dashboad_data.success){
    setLoading(true)
    setApplicantStatis(res_dashboad_data.object)
  }
  else{
    error(res_dashboad_data)
  }
 }
 
 const dataType=[
  {
    id:1,
    icon:<File/>,
    labelOne:"Общее количество заявок",
    labelTwo:applicantStatis?.amountOfAll,
    bgcolor:'#FF6A00'
  },
  {
    id:2,
    icon:<KirimChiqim/>,
    labelOne:"Заявки в процессе",
    labelTwo:applicantStatis?.amountOfInProcess,
    bgcolor:'#551FFF'
  },
  {
    id:3,
    icon:<Users/>,
    labelOne:"Завершенные заявки",
    labelTwo:applicantStatis?.amountOfIsDone,
    bgcolor:'#FD2254'
  },
  {
    id:4,
    icon:<Analytic/>,
    labelOne:"Просроченные",
    labelTwo:applicantStatis?.amountOfExpired,
    bgcolor:'#FD2254'
  }
]
 function error(error_data:any){
   setLoading(true)
 }
  useEffect(()=>{
    Get_Dashboard_Data()
    setheaderTitle("Анализ для отчетов")
  },[]) 
    return (
      <div className="w-full h-full flex items-center justify-center">
       {
        loading ? <div className="home">
        <Modal open={isOpenModal} onCancel={()=>setIsOpenModal(false)}>
          <div className="w-full text-xl font-medium ">
           Заявления поиск
          </div>
          <div className="w-full">
            <Input.Search className='my-2'/>
          </div>
          <div className="w-full">
            Результаты:
          </div>
          <div className="w-full h-60 overflow-auto"></div>
        </Modal>
        <div className="home-main-chart">
        <div className="home-header-analytic ">
          {
            dataType.map(item=>{
              return (
                <div className={dataID===item.id ? "home-header-analytic-box ":"home-header-analytic-box"} style={{backgroundColor:item.id===dataID ? "rgb(120 116 191)":''}} onClick={()=>setDataID(item.id)}>
                <div className="home-header-analytic-box-icon" style={{backgroundColor:"#FFF2E9"}}>
                  {item.icon}
                </div>
                <div className={dataID==item.id ? "home-header-analytic-box-title-active ":"home-header-analytic-box-title"}>
                  <div>{item.labelOne}</div>
                  <div>{item.labelTwo}</div>
                </div>
              </div>
              )
            })
          }
        </div>
        <div className="home-main-big-chart">
          <AnalyticsGraph dataID={dataID}/>
        </div>
        <div className="home-header-analytic">
           {
            dataType.map((item,index)=>{
              return (
                <div className="home-header-analytic-box" onClick={()=>setIsOpenModal(true)} key={index}>
                <div className="home-header-analytic-box-icon" style={{backgroundColor:"#FFF2E9"}}>
                  {item.icon}
                </div>
                <div className="home-header-analytic-box-title">
                  <div>{item.labelOne}</div>
                  <div>{item.labelTwo}</div>
                </div>
              </div>
              )
            })
           }
        </div>
        </div>
        <div className="home-comain-chart">
          <div className="home-comain-chart-box">
               <div className="home-comain-chart-box-header">
                <div className="home-comain-chart-box-header-title">
                  <div>Текущий план</div>
                  <div>Информация и использование вашего текущего плана</div>
                </div>
                <div className="home-comain-chart-box-header-icon">
                  <FilterIcon/>
                </div>
               </div>
               <div className="home-comain-chart-box-user">
               <DatePicker picker='year'/>
               </div>
               <div className="home-comain-chart-box-static">
                 <div className="home-comain-chart-box-static-box">
                     <div className="home-comain-chart-box-static-box-title">
                      <span>Projects</span>
                      <span>1/3</span>
                     </div>
                     <Progress percent={30} showInfo={false} strokeColor={'#FF6A00'}/>
                 </div>
                 <div className="home-comain-chart-box-static-box">
                     <div className="home-comain-chart-box-static-box-title">
                      <span>Users</span>
                      <span>1/5</span>
                     </div>
                     <Progress percent={78} showInfo={false} strokeColor={'#551FFF'}/>
                 </div>
                 <div className="home-comain-chart-box-static-box">
                     <div className="home-comain-chart-box-static-box-title">
                      <span>Requests/day</span>
                      <span>7.5k/10k</span>
                     </div>
                     <Progress percent={54} showInfo={false} strokeColor={'#FD2254'}/>
                 </div>
               </div>
          </div>
          <div className="home-comain-piechart-box">
             {/* this is header chart */}
               <div className="home-comain-chart-box-header piechart-headers">
                <div className="home-comain-chart-box-header-title ">
                  <div>Current Plan</div>
                  <div>Information and usages of your current plan</div>
                </div>
                <div className="home-comain-chart-box-header-icon">
                  <FilterIcon/>
                </div>
               </div>
               {/* this is piechart */}
               <div className="home-comain-piechart-box-piechart">
               
                <div className="w-full flex items-center justify-center ">
                  <AnalyticsPieChart/>
                </div>
               </div>
          </div>
        </div>
     </div>:<Loading/>
       }
      </div>
    );
  }
export default Dashboard 