import SelectIndicator from "../Select/Select";
const list_type_statement = [
  {
    label: "Мои высказывания",
    value: "me_statement",
  },
  {
    label: "Все заявления",
    value: "all",
  },
  {
    label:"просроченные заявления",
    value:'over_time_statment'
  }
];
interface props{
  style?:object
    data:{label:string,value:string}[]
    HandleChange:(parm: string, name: string)=>void;
}
function Filter({HandleChange,data,style}:props) {
  function handleChange(parm: string, name: string, id: number) {
     HandleChange(parm,name)
  }
  return (
    <SelectIndicator
      placeholder="Мои высказывания"
      HandleChange={handleChange}
      name="filter_type"
      style={style ? style:{ width: "220px",marginLeft:"10px"}}
      data={data}
    />
  );
}
export default Filter;
