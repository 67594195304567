import { SampleStepListType,AllStepType,SampleStepType } from "src/mockdata/laboratoryData";
import { dataheaderRow } from "../Laborotry/Table/indexData";
export interface ListRegis {
    solutionNumber: string,
    nameOfOrganization: string,
    dateOfReceiving: string,
    nameOfPassingPerson: string,
    nameOfProduct: string,
    typeOfProduct: string,
    manufacturer: string,
    serialNumber: string,
    amount: number,
    nameOfReceivingPerson: string,
    dateOfIssuing: string,
    isAccepted: boolean,
    reasonOfRejection: string,
    anotherNotes: string
}
interface listregis_key_list {
  listRegsKey:string,
  statment_key:string
}
export const reshiniya_key_list:listregis_key_list[]=[
  {
    listRegsKey:"solutionNumber",
    statment_key:'solutionNumber'
  },
  {
   listRegsKey:"nameOfOrganization",
   statment_key:'applicant' 
  },
  {
    listRegsKey:"serialNumber",
    statment_key:'schemeCertification'
  },
  {
    listRegsKey:"dateOfReceiving",
    statment_key:'dateOfReceiveTemplate'
  },
  {
    listRegsKey:"serialNumber",
    statment_key:'schemeCertification'
  },
  {
    listRegsKey:"serialNumber",
    statment_key:'schemeCertification'
  },
]
export const SampleStepOne: SampleStepType = {
  title: "Заявитель",
  list: [
    {
      id: 1,
      label: "Наименование организации",
      type: "semi_input",
      key: "nameOfOrganization",
    },
    {
      id: 2,
      label: "Дата и время поступления образцов",
      type: "date",
      key: "dateOfReceiving",
    },
    {
      id: 3,
      label: "Ф.И.О и подпись сдавшего лица:",
      type: "input",
      key: "nameOfPassingPerson",
    },
  ],
};
export const SampleStepTwo: SampleStepType = {
  title: "Изделие",
  list: [
    {
      id: 1,
      label: "Наименование изделия",
      type: "semi_input",
      key: "nameOfProduct",
    },
    {
      id: 2,
      label: "Тип изделия",
      type: "semi_input",
      key: "typeOfProduct",
    },
    {
      id: 3,
      label: "Фирма-производитель",
      type: "input",
      key: "manufacturer",
    },
    {
      id: 3,
      label: "Серийный номер ТСТ",
      type: "input",
      key: "serialNumber",
    },
    {
      id: 3,
      label: "Количество",
      type: "numberinput",
      key: "amount",
    },
  ],
};
export const SampleStepThree: SampleStepType = {
  title: "Получатели",
  list: [
    {
      id: 1,
      label: "Ф.И.О. и подписи ответственного за приемку",
      type: "semi_input",
      key: "nameOfReceivingPerson",
    },
    {
      id: 2,
      label: "Дата и время выдачи образцов заявителю",
      type: "date",
      key: "dateOfIssuing",
    },
    // {
    //   id: 3,
    //   label: "Ф.И.О. и подписи получателя образца ТСТ для испытаний",
    //   type: "input",
    //   key: "",
    // },
    
  ],
};
export const SampleStepFour: SampleStepType = {
  title: "Получатели",
  continue:true,
  list: [
    {
      id: 1,
      label: "Сведения об отклонении образца от заданных условий",
      type: "input",
      key: "reasonOfRejection",
    },
    {
      id: 2,
      label: "Другие примечания",
      type: "textarea",
      key: "anotherNotes",
    },    
  ],
};
export const AllSampleStepRegis:AllStepType[] = [
  {
    id: 1,
    stepLabel: "1. Заявитель",
    component: SampleStepOne,
  },
  {
    id: 2,
    stepLabel: "2. Изделие",
    component: SampleStepTwo,
  },
  {
    id: 3,
    stepLabel: "3. Получение",
    component: SampleStepThree,
  },
  {
    id: 4,
    stepLabel: "4. Примечания",
    component: SampleStepFour,
  },
  
];
export const dataTableListRegis:dataheaderRow[]=[
  {
    body:"id",
    header:"Рег.№ п/п"
  },
  {
    body:"id",
    header:"Наименование ТСТ"
  },
  {
    body:"typeOfProduct",
    header:"Тип изделия "
  },
  {
    body:"manufacturer",
    header:"Фирма производитель"
  },
  {
    body:"nameOfOrganization",
    header:"Заявитель"
  },
  {
    body:"nameOfPassingPerson",
    header:"Получатель"
  },
  {
    body:"dateOfIssuing",
    header:"Дата и время поступления образцов"
  },
  {
    body:"isAccepted",
    header:"Статус"
  },
  {
    body:"id",
    header:""
  },
]