import { useEffect, useState,useRef} from 'react';
import './style.css'
import style from './style.module.css'
import { RequestToken } from 'src/Hook/Request/Request';
import {useReactToPrint} from 'react-to-print'
import { Height } from '@mui/icons-material';
interface props {
  programmaDocs?:number|null,
  setDownload:(download:boolean)=>void
}
function ProgramDocument ({programmaDocs,setDownload}:props){
     const [resProgramma,setResProgramma]=useState<any>()
     const [normativList,setNormativList]=useState<string[]>([])
     const [testMethodList,setTestMethodList]=useState<string[]>([])
    const space = (
        <>
          {" "}
          <p className="MsoNormal">
            <span lang="RU" style={{ fontSize: "12.0pt" }}>
              &nbsp;
            </span>
          </p>
          <p className="MsoNormal">
            <span lang="RU" style={{ fontSize: "12.0pt" }}>
              &nbsp;
            </span>
          </p>
          <p className="MsoNormal">
            <span lang="RU" style={{ fontSize: "12.0pt" }}>
              &nbsp;
            </span>
          </p>
        </>
      );
      const semispace = (
        <>
          {" "}
          <p className="MsoNormal">
            <span lang="RU" style={{ fontSize: "12.0pt" }}>
              &nbsp;
            </span>
          </p>
          <p className="MsoNormal">
            <span lang="RU" style={{ fontSize: "12.0pt" }}>
              &nbsp;
            </span>
          </p>
        </>
      );
      const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
      const borderColor='#27272a'
    async function Get_Product(){
      const response_programma_docs=await RequestToken(`/program/${programmaDocs}`,'GET',null)
      if(response_programma_docs.success){
        setResProgramma(response_programma_docs.object)
        const newNormativList=[...normativList]
        const newTestMethodList=[...testMethodList]
        for (let i = 0; i < response_programma_docs.object.requirementForProgramGetOneDTOList.length; i++) {
          const element = response_programma_docs.object.requirementForProgramGetOneDTOList[i];
          for (let j = 0; j < element.normativeList.length; j++) {
             newNormativList.push(element.normativeList[j].normativeNumber)           
          }
          for (let j = 0; j < element.requirement.testMethods.length; j++) {
            newTestMethodList.push(element.requirement.testMethods[j].name)           
         }
        }
        setNormativList(newNormativList)
        setTestMethodList(newTestMethodList)
        HandlePrint()
      }
    }
    const componentRef:any=useRef()
    const HandlePrint=useReactToPrint({
      content:()=>componentRef.current,
      documentTitle:"programa",
      onAfterPrint:(()=>{
        setDownload(false)
      })
    })
    useEffect( ()=>{
      Get_Product()
    },[])
    return (
    <div className={`w-full h-full overflow-auto ${style.progroma_document}`} >
       <div style={{width:'594px'}} className="h-auto" ref={componentRef}>
        <div className="w-full" style={{height:'1122px'}}>
        {space}
         <div className="w-full text-center text-xs">
         Общество с ограниченной ответственностью «UNICON.UZ»
Орган по сертификации технических средств телекоммуникаций (ОС ТСТ)
         </div>
         {semispace}
         <div className="w-full flex justify-end text-xs">
            <div className="w-60">
                <div>УТВЕРЖДАЮ</div>
                <div>Руководитель ОС ТСТ</div>
                <div className='w-full h-6 flex'>
                    <div className={`w-1/3 h-6 border-b-2 border-[${borderColor}]`}></div>
                    <div className="w-2/3 h-6 flex items-end">Руководитель ОС ТСТ</div>
                </div>
                <div className="w-full h-6">
                    <div className={`w-2/3 h-6 border-b-2 border-[${borderColor}]`}>
                        
                    </div>
                </div>
            </div>
         </div>
         {space}
         <div className="w-full">
            <div className="w-full text-center text-base my-3">ПРОГРАММА</div>
            <div className="w-full text-center text-xs">СЕРТИФИКАЦИОННЫХ ИСПЫТАНИЙ</div>
            <div className="w-full text-xs text-center">Носимого абонентского станции сотовой системы телекоммуникаций GSM/GPRS/EDGE 900/1800, универсальной мобильной системы телекоммуникаций UMTS/HSDPA 2100 MHz и LTE 2600 MHz моделей HONOR X7A PLUS
производства компании «HONOR DEVICE Co., Ltd», Китай.</div>
         </div>
         {semispace}
         {semispace}
         <div className="w-full flex mb-10">
            <div className="w-1/2">
                <div className='w-full my-2 text-xs'>СОГЛАСОВАНО</div>
                <div className={`w-full mb-2 text-xs`}>Представитель</div>
                <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
                <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
            </div>
            <div className="w-1/2">
                <div className="w-full">
                <div className='w-full my-2 h-6 text-xs'>Начальник ОПС</div>
                <div className={`w-full flex h-6 mb-2`}>
                    <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
                    <div className="w-1/2 h-6 flex items-end text-xs">Б.К. Юнусов</div>
                </div>
                <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
                </div>
                <div className="w-full mt-4">
                <div className='w-full my-2 h-6 text-xs'>Начальник ИЛ-02 СИЦ ТСТ</div>
                <div className={`w-full flex h-6 mb-2`}>
                    <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
                    <div className="w-1/2 h-6 flex items-end text-xs"> А.Д. Юсупов</div>
                </div>
                <div className={`w-1/2 h-6 border-b-2 border-[${borderColor}]`}></div>
                </div>
            </div>
         </div>
         {space}
         {space}
         {space}
         {space}
         {space}
        </div>
        {space}
         {space}
         <div className="w-full" style={{height:'1122px'}}>
         <div className="w-full text-center">
            <b className='w-full text-center block my-2 text-xs'>ПРОГРАММА </b>
            <b className='my-1 text-xs'>Сертификационных испытаний оборудования</b>
         </div>
        <div className="w-full h-auto">
          <div className="row w-full flex">
          <div className="w-1/2 h-full">
           <b className='text-xs'>Наименование Органа по сертификации продукции</b>
          </div>
          <div className="w-1/2 italic">
             <b className='w-full text-xs'>{resProgramma?.nameOfCertificationOrgan}</b>
             <div className="w-full text-xs my-2">
              <b className='text-xs mr-2'>Davlat reestr raqami:</b>{resProgramma?.numberRegister}
             </div>
             <div className="w-full text-xs my-2">
              <b className='text-xs mr-2'>Yuridik manzil:</b>{resProgramma?.address}
             </div>
             <div className="w-full text-xs my-2">
              <b className='text-xs mr-2'>Telefon:</b>{resProgramma?.phone}
             </div>
             <div className="w-full text-xs my-2">
              <b className='text-xs mr-2'>Elektron pochta:</b>{resProgramma?.email}
             </div>
             <div className="w-full text-xs my-2">
              <b className='text-xs mr-2'>Veb-sayt:</b>{resProgramma?.website}
             </div>
          </div>
          </div>
          <div className="row flex">
          <div className="w-1/2 h-full">
           <b className='text-xs'>Наименование продукции:</b>
          </div>
          <div className="w-1/2 ">{resProgramma?.namesOfProducts?.map((item:string,index:number)=>{
            return (
              <div className="px-3 py-2 my-1" key={index}>{item}</div>
            )
          })}</div>
          </div>
          <div className="row flex">
           <div className="w-1/2 h-full">
            <b className='text-xs'>Наименование НД на продукцию:</b>
           </div>
           <div className="w-1/2 h-full">
            {normativList.map((item:string,index:number)=>{
              return (
                <span className="px-3 py-1" key={index}>{item}</span>
              )
            })}
           </div>
          </div>
          <div className="row flex">
           <div className="w-1/2 h-full">
            <b className='text-xs'>Наименование предприятия заявителя:</b>
           </div>
           <div className="w-1/2 h-full">
           {resProgramma?.applicant}
           </div>
          </div>
          <div className="row flex my-2">
           <div className="w-1/2 h-full">
            <b className='text-xs'>Номер схемы сертификации:</b>
           </div>
           <div className="w-1/2 h-full">
           {resProgramma?.schemeOfCertification}
           </div>
          </div>
          <div className="row flex">
           <div className="w-1/2 h-full">
            <b className='text-xs'>Метод испытаний по</b>
           </div>
           <div className="w-1/2 h-full">
           {testMethodList.map((item:string,index:number)=>{
              return (
                <span className="px-3" key={index}>{item}</span>
              )
            })}
           </div>
          </div>
        </div>
         </div>
       <div className="w-full">
       <div className="w-full my-3"><b>Определяемые показатели.</b></div>
        <table className='w-full' >
          <thead>
            <tr>
              <th className={style.table_program_th_id}>№ п.п.</th>
              <th className={style.table_program_th}>Наименование проверяемых параметров и характеристик</th>
              <th className={style.table_program_th}>Обозначение НД, на соответствие которым проводятся испытания</th>
              <th className={style.table_program_th}>Место проведения испытаний (наименование,адрес АИЛ)</th>
            </tr>
            <tr>
              <th className={style.table_program_th_id}>1</th>
              <th className={style.table_program_th}>2</th>
              <th className={style.table_program_th}>3</th>
              <th className={style.table_program_th}>4</th>
            </tr>
            <tr>
              <th colSpan={4} className={`text-left ${style.table_program_th}`}>Требования по безопасности:</th>
            </tr>
          </thead>
          <tbody>
            {
              resProgramma?.requirementForProgramGetOneDTOList?.map((item:any,index:number)=>{
                return (
                  <tr key={index}>
                        <td className={style.table_program_td_id}>{item.requirement.id}</td>
              <td className={style.table_program_td}>{item.requirement.name}</td>
              <td className={style.table_program_td}>{item.requirement.normativeList.map((itemNormativ:any,indexNormativ:number)=>{
                return (
                  <span key={indexNormativ} className='px-3 py-1'>{itemNormativ.normativeNumber}</span>
                )
              })}</td>
              {
                index===0 ?   <td className={style.table_program_td} rowSpan={resProgramma?.requirementForProgramGetOneDTOList?.length}>“UNICON.UZ - Fan-texnika va marketing tadqiqotlari markazi” davlat unitar korxonasi Telekommunikatsiya texnik vositalarini sertifikatlashtirish sinov markazi (laboratoriyasi)
                Davlat reestr raqami: O‘ZAK.SL.0180.
                Joylashuvi, akkreditatsiya obyekti indeksi: 100114, Toshken shahri, Yunusobod tumani, Sohibkor ko‘chasi, 2 “A”.</td>:""
              }
                  </tr>
                )
              })
            }
            <tr>
              <td colSpan={4} className={style.table_program_td}>Примечание:<span className='italic text-xs pl-2'>Результаты испытаний оформляются в протоколе испытаний в двух экземплярах, один из которых после подписания и утверждения передается в Орган по сертификации технических средств телекоммуникаций (ОС ТСТ).</span></td>
            </tr>
          </tbody>
        </table>
       </div>
       </div>
    </div> 
)
}
export default ProgramDocument;