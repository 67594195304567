import {useEffect} from 'react'
import { Result,Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
type ResultStatusType = "success" | "error" | "info" | "warning" | "404"|"500"|"403";
function Error404(){
  const { id } = useParams<{ id: ResultStatusType }>();
  const navigate=useNavigate()
  const ChangePage=()=>{
      navigate("/")    
  }

  useEffect(()=>{
      if(!localStorage.getItem("_token")){
        console.log("ajdkljakl  ")
        navigate("/")
      }
  },[])
  return (
        <Result
        status='404'
        subTitle="К сожалению, мы не можем найти эту страницу. На главной странице вы найдете много интересного."
        extra={[
          <Button onClick={ChangePage} type="primary" key="console">
           Домашняя страница
          </Button>,
          <Button key="buy">Cнова</Button>,
        ]}
      />
    )
}
export default Error404;