import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    PieChart, Pie, Cell, Legend} from 'recharts';
interface props {
  dataID:number
}
export  const AnalyticsGraph = ({dataID}:props) => {
  const [data,setdata]=useState<any>([])
  const Data = [
    {
      id:1,
      data:[
        { name: 'Январь', uv: 8000,  },
        { name: 'Февраль', uv: 2000,},
        { name: 'Март', uv: 4400,},
        { name: 'Апрель', uv: 2000,},
        { name: 'Май', uv: 4950,},
        { name: 'Июнь', uv: 5360,},
        { name: 'Июль', uv: 5560,},
        { name: 'Август', uv: 560,},
        { name: 'Сентябрь', uv: 9560,   },
        { name: 'Октябрь', uv: 560,   },
        { name: 'Ноябрь', uv: 560,},
        { name: 'Декабрь', uv: 560,   },
      ]
    },
    {
      id:2,
      data:[
        { name: 'Январь', uv: 8000,  },
        { name: 'Февраль', uv: 3000,   },
        { name: 'Март', uv: 3400,   },
        { name: 'Апрель', uv: 12000,   },
        { name: 'Май', uv: 4050,   },
        { name: 'Июнь', uv: 5560,   },
        { name: 'Июль', uv: 5560,   },
        { name: 'Август', uv: 5560,   },
        { name: 'Сентябрь', uv: 5560,   },
        { name: 'Октябрь', uv: 5560,   },
        { name: 'Ноябрь', uv: 5560,   },
        { name: 'Декабрь', uv: 5560,   },
      ]
    },
    {
      id:3,
      data:[
        { name: 'Январь', uv: 8000,  },
        { name: 'Февраль', uv: 3000,   },
        { name: 'Март', uv: 3400,   },
        { name: 'Апрель', uv: 12000,   },
        { name: 'Май', uv: 4050,   },
        { name: 'Июнь', uv: 5760,   },
        { name: 'Июль', uv: 2560,   },
        { name: 'Август', uv: 8560,   },
        { name: 'Сентябрь', uv: 9560,   },
        { name: 'Октябрь', uv: 560,   },
        { name: 'Ноябрь', uv: 7660,   },
        { name: 'Декабрь', uv: 3560,   },
        // ...
      ]
    },
    {
      id:4,
      data:[
        { name: 'Январь', uv: 8000,  },
        { name: 'Февраль', uv: 3000,   },
        { name: 'Март', uv: 3400,   },
        { name: 'Апрель', uv: 12000,   },
        { name: 'Май', uv: 4050,   },
        { name: 'Июнь', uv: 12560,   },
        { name: 'Июль', uv: 5560,   },
        { name: 'Август', uv:9560,   },
        { name: 'Сентябрь', uv: 2560,   },
        { name: 'Октябрь', uv: 7560,   },
        { name: 'Ноябрь', uv: 560,   },
        { name: 'Декабрь', uv: 15560,   },
      ]
    },
  ]
  useEffect(()=>{
    Data.filter(item=>{
      if(item.id===dataID){
        setdata(item.data)
      }
    })
  },[dataID])
  return (
    <AreaChart width={1000} height={410} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
    

      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="url(#colorUv)" />
      <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="url(#colorPv)" />
    </AreaChart>
  );
}



export const  AnalyticsPieChart = () => {
  const data = [
    { name: 'Category A', value: 400 },
    { name: 'Category B', value: 300 },
    { name: 'Category C', value: 200 },
    { name: 'Category D', value: 100 },
  ];

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

  return (
    <PieChart width={160} height={200}>
      <Pie
        dataKey="value"
        data={data}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"

      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}



// import React, { useState, useEffect } from 'react';
// import { Area } from '@ant-design/charts';
// import { Bar } from '@ant-design/charts';
// import { Pie } from '@ant-design/plots';
// export const AnalyticsArea = ({data}) => {
//   console.log( data[2])
//   const config = {
//     data,
//     xField: 'Date',
//     yField: 'scales',
//     xAxis: {
//       range: [0, 1],
//       tickCount: 5,
//     },
//     areaStyle: () => {
//       return {
//         fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
//       };
//     },
//   };

//   const chartStyle = {
//     width: '100%',
//     height: '400px', // O'zingizning hohlagan balandlikni belgilang
//   };

//   return <Area {...config} style={chartStyle} />;
// };


// export const BarChart = ({ data }) => {
  
//   const config = {
//     data,
//     xField: 'Date',
//     yField: 'scales',
//     label: {
//       position: 'middle',
//       style: {
//         fill: '#FFFFFF',
//         opacity: 0.6,
//       },
//     },
//     xAxis: {
//       label: {
//         autoHide: true,
//         autoRotate: false,
//       },
//     },
//     meta: {
//       Date: {
//         alias: 'Дата',
//       },
//       scales: {
//         alias: 'Масштабы',
//       },
//     },
//   };
//   const chartStyle = {
//     width: '85%',
//     height: '85%', // O'zingizning hohlagan balandlikni belgilang
//   };
//   return <Bar {...config} style={chartStyle}/>;
// };


// export const DemoPie = ({data}) => {
  
//   const config = {
//     appendPadding: 10,
//     data,
//     angleField: 'Date',
//     colorField: 'scales',
//     radius: 0.9,
//     label: {
//       type: 'inner',
//       offset: '-30%',
//       content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
//       style: {
//         fontSize: 14,
//         textAlign: 'center',
//       },
//     },
//     interactions: [
//       {
//         type: 'element-active',
//       },
//     ],
//   };
//   const chartStyle = {
//     width: '85%',
//     height: '85%', // O'zingizning hohlagan balandlikni belgilang
//   };
//   return <Pie {...config} style={chartStyle}/>;
// };

