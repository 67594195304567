import Swal from "sweetalert2";
import style from "./style.module.css";
import { useState, useEffect } from "react";
import { normativ, applance, requirment,user_list} from "./TableWizzardType";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import TablesTestReportOne from "../TablesTestReport/TablesTestReportOne/TablesTestReportOne";
import TablesTestReportTwo from "../TablesTestReport/TablesTestReportTwo/TablesTestReportTwo";
import TablesTestReportThree from "../TablesTestReport/TablesTestReportThree/TablesTestReportThree";
import TablesTestReportFour from "../TablesTestReport/TablesTestReportFour/TablesTestReportFour";
import TablesTestReportFive from "../TablesTestReport/TablesTestReportFive/TablesTestReportFive";
import TablesTestReportSix from "../TablesTestReport/TablesTestReportSix/TablesTestReportSix";
import VerticalNavigationsitebar from "src/Component/Navigationsitebar/Navigationsitebar";
import TablesTestReportSeven from "../TablesTestReport/TablesTestReportSeven/TablesTestReportSeven";
import TablestestReportFirst from "../TablesTestReport/TablestestReportFirst/TablestestReportFirst";
import TablesTestReportFourPart from "../TablesTestReport/TablesTestReportFourPart/TablesTestReportFourPart";
import UploadPhotoCard from "../UploadPhotoCard/UploadPhotoCard";
import Loading from "src/Component/Loading/Loading";
import { programID_data } from "src/pages/laboratory/testreport/indexdata";
interface props {
  programID: number;
  program_data?: any;
  tablewriteChecked: boolean;
  stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string},
   setTimeState:(stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string})=>void
  user_list:user_list[]
}
function TableWizzard({ programID, program_data, tablewriteChecked,setTimeState,stateTime,user_list }: props) {
  const navigate = useNavigate();
  const [tableId, setTableId] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [applance_list, setApplance_list] = useState<applance[]>([]);
  const [normativ_list, setNormativ_list] = useState<normativ[]>([]);
  const [requirement_list, setRequirment_list] = useState<requirment[]>([]);
  const [test_protocol, setTest_Protocol] = useState<any>({
    programId: 0,
    testResultDTOList: [],
    userIds: [],
    applianceIds: [],
  });
  const tableList = [
    {
      id: 1,
      component: (
        <TablestestReportFirst
          test_protocol={test_protocol}
          setTestProtocol={setTest_Protocol}
          applance_list={applance_list}
          setTableId={setTableId}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 2,
      component: (
        <TablesTestReportOne
        program_data={program_data}
          nameproducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          setLoading={setLoading}
          test_protocol={test_protocol}
          setTestProtocol={setTest_Protocol}
          requirment_list={requirement_list}
          user_list={user_list}        
        />
      ),
    },
    {
      id: 4,
      component: (
        <TablesTestReportTwo
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          tablewriteChecked={tablewriteChecked}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 5,
      component: (
        <TablesTestReportThree
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 6,
      component: (
        <TablesTestReportFourPart
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 7,
      component: (
        <TablesTestReportFour
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 8,
      component: (
        <TablesTestReportFive
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 9,
      component: (
        <TablesTestReportSix
          progremID={programID}
          namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}
          requirment_list={requirement_list}
          setLoading={setLoading}
        />
      ),
    },
    {
      id: 10,
      component: <TablesTestReportSeven program_data={program_data} setLoading={setLoading} 
      namesProducts={program_data?.products.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}})}/>,
    },
    {
     id:11,
     component:<UploadPhotoCard program_data={program_data} setLoading={setLoading}  />
    },
  ];
  function ChangeTabId(parm: string, value?: number) {
    if (tableId < tableList.length && parm === "next") {
      setTableId((prev) => prev + 1);
    } else if (tableId > 1 && parm === "back") {
      setTableId((prev) => prev - 1);
    } else if (value) {
      setTableId(value);
    }
  }
  function error(parm: any) {
    if (parm.response?.status === 401 || parm.response?.status === 403) {
      navigate("/");
    } else {
      Swal.fire({
        title: parm.response?.data?.message || parm.response?.message,
        icon: "error",
      });
    }
  }
  async function Get_Requirment() {
    setLoading(false);
    const res_requirment_list = await RequestToken(
      "/requirement?page=0&size=150",
      "GET",
      null
    );
    if (res_requirment_list.success) {
      setLoading(true);
      setRequirment_list(res_requirment_list.object);
    } else {
      setLoading(true);
      error(res_requirment_list);
    }
  }
  async function getApplanse_list() {
    setLoading(false);
    const res_applance_list = await RequestToken("/appliance?page=0&size=100");
    if (res_applance_list.success) {
      setLoading(true);
      setApplance_list(res_applance_list.object);
    } else {
      setLoading(true);
      error(res_applance_list);
    }
  }
  async function getNormativ_List() {
    setLoading(false);
    const res_normativ_list = await RequestToken("/normative?page=0&size=120");
    if (res_normativ_list.success) {
      setLoading(true);
      setNormativ_list(res_normativ_list.object);
    } else {
      setLoading(true);
      error(res_normativ_list);
    }
  }
  useEffect(() => {
    if (tableId === 1) {
      getApplanse_list();
      setTest_Protocol({ ...test_protocol, programId: programID });
      // setTest_Protocol((prevData:any)=>{
      //   const newarr=[...prevData.testResultDTOList]
      //   for (let i = 0; i < program_data.products.length; i++) {
      //      for (let j = 0; j < program_data.products[i].testResults.length; j++) {
      //       newarr.push({
      //         testResultId: program_data.products[i].testResults[j].id,
      //         result: "string",
      //         isSuccess: false,
      //         requirement:program_data.products[i].testResults[j].requirement,
      //         normativeList:program_data.products[i].testResults[j].normativeList,
      //         testMethods: [],
      //         nameOfProduct:program_data.products[i].name,
      //        })
      //      }         
      //   }
      //   return { ...prevData, testResultDTOList: [...newarr] };
      // })
    }
    setTest_Protocol({...test_protocol,dateOfReceiving:stateTime.dateOfReceiving,dateFrom:stateTime.dateFrom,dateUntil:stateTime.dateUntil})
  }, []);
  useEffect(() => {
    if (tableId === 2) {
      Get_Requirment();
    } else if (
      tableId === 4 ||
      tableId === 5 ||
      tableId === 6 ||
      tableId === 7 ||
      tableId === 8 ||
      tableId === 9
    ) {
      Get_Requirment();
    } 
  }, [tableId]);
  return (
    <div className="w-full h-full flex justify-between ">
      <div style={{ width: "19%" }} className="h-full">
        <VerticalNavigationsitebar
          number={tableId}
          list={tableList}
          ChangeTabId={ChangeTabId}
        />
      </div>
      <div
        style={{ width: "80%" }}
        className={`${style.Table_component} h-full flex justify-center items-center`}
      >
        {loading ? (
          <div className="w-full h-full overflow-auto">
            {tableList
              .filter((item) => item.id === tableId)
              ?.map((item, index) => {
                return item.component;
              })}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
export default TableWizzard;
