import "./FormStep.css";
import {useContext, useEffect,useState} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "src/App";

import StepThree from "../steps/stepthree/StepThree";
import Shag2 from "../steps/steptwo/StepTwo";
import StepSix from "../steps/stepsix/StepSix";
import StepFive from "../steps/stepfive/StepFive";
import SelectIndicator from "src/Component/Select/Select";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
import StepSeven from "../steps/stepseven/StepSeven";
import StepActOfLaw from "../steps/stepact";
import Swal from "sweetalert2";
import Stepconclusion from "../steps/stepconclusion";
import Steprecomdacion from "../steps/steprecomdacion";
import { response_statement } from "../steps/stepone/steponedata";
interface options {
  label:string,
  value:string
}
interface normative {
  id:number,
  label:string,
  value:string
}
function FormStep() {
  const { id } = useParams();
  const navigate=useNavigate()
  const {setheaderTitle,setStepID,stepID}=useContext(UserContext)
  const [modalOpen,setOpenModal]=useState<boolean>(false) 
  const [loading,setLoading]=useState<boolean>(true)
  const [orderData,setOrderData]=useState<response_statement>()
  const [user_list,setUserList]=useState<{label:string,value:number}[]>([])
  const [normative_list,setNormativList]=useState<normative[]>([])
  const [productsData,setProductsData]=useState<options[]>([])
  const [order,setOrder]=useState()
  const Pages=[ 
    {
      id:"2",
      API:`/solution/${id}`,
      title:"Р Е Ш Е Н И Е по заявке на проведение сертификации",
      component:<Shag2 product_List={productsData} normativ_list={normative_list} Id={`/solution/${id}`} type="edit" 
        modalOpen={modalOpen} setOpenModal={setOpenModal}/>
    },
    {
      id:"3",
      API:'',
      title:"АКТ идентификации и отбора образцов ТСТ",
      component:<StepThree  nameOfApplicant={orderData?.applicantFullName||""} type="edit" products_list={productsData}
      modalOpen={modalOpen} setOpenModal={setOpenModal} order={order}/>
    },
    {
      id:"5",
      API:'',
      title:"АКТ идентификации и отбора образцов ТСТ",
      component:<StepActOfLaw user_list={user_list} type="edit"/>
    },
    {
      id:"6",
      API:'',
      title:"AKT O СПИСАНИИ (ВОЗВРАТЕ) ПРОДУКЦИИ)",
      component:<StepFive type="edit"  normativ_list={normative_list} products_list={productsData}
      modalOpen={modalOpen} setOpenModal={setOpenModal} orderData={orderData}/>
    },
    {
      id:"7",
      API:'',
      title:"З А К Л Ю Ч Е Н И Е  ОС  ТСТ",
      component:<StepSix  type="edit"/>
    },
    {
      id:"8",
      API:'',
      title:"З А К Л Ю Ч Е Н И Е  ОС  ТСТ",
      component:<Stepconclusion type="edit" user_list={user_list}/>
    },
    {
      id:"9",
      API:'',
      title:"Рекомендации",
      component:<Steprecomdacion type="edit" user_list={user_list}/>
    },
    {
      id:"10",
      API:'',
      title:"РЕКОМЕНДАЦИИ",
      component:<StepSeven  type="edit"/>
    }
  ] 
  function handleChange(parm: string, name: string, id: number){
    console.log(parm)
  }
  async function Get_Normativ_List(){
    const response_normativ_List=await RequestToken('/normative?page=0&size=100',"GET",null)
    if(response_normativ_List.success){
      setNormativList(response_normativ_List.object.map((item:{id:number,normativeNumber:string})=>{
        return {id:item.id,label:item.normativeNumber,value:item.normativeNumber}
      }))
    }
  }
  async function GetProducts(){
    setLoading(false)
   const responseData=await RequestToken(`/order/${id}`,"GET",null)
   if(responseData.success){
    setOrder(responseData.object)
     const response_user_list=await RequestToken('/user/getUsersWithoutSuperAdmin','GET',null)
     if(response_user_list.success){
      setOrderData(responseData.object)
      setProductsData(responseData.object?.products?.map((item:{name:string})=>{
        return {label:item.name,value:item.name}
      }))
      setUserList(response_user_list.object?.map((item:{lastName:string,firstName:string,id:number})=>{return {label:item.lastName+""+item.firstName,value:item.id}}))
      setLoading(true)
     }
     else{
      Swal.fire({
        title:response_user_list?.message||response_user_list.response?.data.message,
        icon:'error'
      })
     }
   }
  }
  useEffect(()=>{
    setheaderTitle(Pages.filter((item:any)=>{
          if(item.id===(stepID===1 ? 2:stepID).toString()){
            return item}
    })[0]?.title)
  },[])
  useEffect(()=>{
   if(stepID!==3|| stepID===4 || stepID===7){
    Get_Normativ_List()
   }
   else {
   }
   GetProducts()
  },[stepID])
  console.log(orderData)
  return (
    <div className="formstep-wrapper">
     {
      loading ?  <div className="formstep-wrapper-info">
      <div className="formstep-wrapper-info-table">
        {
          Pages.filter(item=>item.id===(stepID===1 ? 2:stepID).toString()).map((item,index)=>{
              return (item.component)
          })
        }
      </div>
    </div>:<Loading/>
     }

    </div>
  );
}
export default FormStep;
