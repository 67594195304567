import './Acount.css'
import acount from "src/assets/icons/HeaderIcon/acount.svg"
import Notifacion from '../Notifaction/Notifacion';
import {Dropdown } from 'antd'
import {UserOutlined,LogoutOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { translateUtil } from 'src/lang/translationUtils';
import { Role } from 'src/assets/icons/HeaderIcon/HeaderIcon';
import { Exit } from 'src/assets/icons/laboratory';
function Acount() {
  const roles=localStorage.getItem("_role")
  const user:{firstName:string|null,lastName:string|null}|null=JSON.parse(localStorage.getItem("_user")||"null")
  const navigate=useNavigate()
  const exit=()=>{
    localStorage.clear()
      navigate("/")
  }
  const items = [
    {
      key: '1',
      label: <div className='w-full exit_label flex block text-[#000]'>
       <span style={{width:'15%'}} className='text-2xl flex justify-start'><UserOutlined/></span> <span style={{minWidth:'180px',maxWidth:'400px'}}>{user?.firstName} {user?.lastName}</span>
      </div>
    },
    {
      key: '2',
      label: <div  className='w-full h-auto  exit_label flex block text-[#000]'>
         <span style={{width:'15%'}} className='text-2xl  flex justify-start'><Role/></span><span style={{minWidth:'180px',maxWidth:'400px'}}>{translateUtil(roles)}</span>
        <hr />
      </div>
    },
    {
      key: '3',
      label: <div onClick={exit} className='exit_label flex'>
        <span style={{width:'15%'}} className='text-xl  flex justify-start pl-1'><LogoutOutlined /></span><span style={{minWidth:'180px',maxWidth:'400px'}}>Выход</span>
      </div>
    },
    
  ];
  
  return (
   <div className="acount">
      <Notifacion/>
      
    <Dropdown menu={{ items }} trigger={['click']}>
      <div className="username">
        <div className="username-icon">
          <img src={acount} alt="acount" />
        </div>
      </div>
      </Dropdown>
    </div>

    
  );
}
export default Acount;
