import { useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import AppAssisent from "./AppAssisent";
export const UserContext = createContext();
function App() {
  const [headerTitle, setheaderTitle] = useState("");
  const [AdminheaderTitle, setAdminHeaderTitle] = useState("");
  const [stepID, setStepID] = useState(1);
  const [dangerApplicant,setDangerApplicant]=useState()
  const [Token, setToken] = useState("");
  const [role, setRole] = useState("");
  function ChangeRole(parm){      
    setRole(parm)
  } 
  return (
    <UserContext.Provider
      value={{ headerTitle, setheaderTitle, ChangeRole, setDangerApplicant,dangerApplicant,
      AdminheaderTitle,setAdminHeaderTitle,setToken,setRole,stepID,setStepID}}>
       <AppAssisent />
    </UserContext.Provider>
  );
}
export default App;
