import { Input, Form, Select, Button, Space } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
const { Option } = Select;
interface organaztion_obj {
  id: number;
  nameOfCertificationOrgan: string;
  numberRegister: string;
  address: string;
  phone: string;
  email: string;
  website: string;
}
interface res_organaztion_obj {
  id:number;
  createAt:string;
  updateAt:string;
  createdBy:number;
  updatedBy:number;
  isActive:boolean;
  nameOfCertificationOrgan:string;
  numberRegister: string;
  address:string;
  phone:string;
  email:string;
  website:string;
}
interface requirmentDTOList {
  requirementId: number;
  normativeIds: number[];
  testMethodIds: null;
}
interface programma_change_product {
  productId: number;
  productNameInTestProtocol: string;
}
interface program_data {
  productInTestProtocolList: programma_change_product[];
  productType: string;
  solutionNumber: string;
  certificationOrganId: number;
  userIds: number[];
  requirementForProgramDTOList: requirmentDTOList[];
}

interface props {
  solution_data:any
  programma_data:program_data,
  setProgramma_data:(programma_data:program_data)=>void
  setActiveFormProgram: (activeFormProgram: number) => void;
}
const ProgrammaStepThree:React.FC<props>=({solution_data,programma_data,setProgramma_data, setActiveFormProgram})=>{
  const [organaztion_list, setOrganaztion_List] = useState<organaztion_obj[]>();
  const [organaztion_id,setOrganaztion_id]=useState<number|null>(null)
  const Get_Organaztion_List = async () => {
    const res_data = await RequestToken(
      "/certificationOrgan?page=0&size=100",
      "GET",
      null
    );
    if (res_data.success) {
      setOrganaztion_List(res_data.object?.map((item:res_organaztion_obj|null)=>{
        return {
            id:item?.id,
            address:item?.address,
            email:item?.email,
            nameOfCertificationOrgan:item?.nameOfCertificationOrgan,
            numberRegister:item?.numberRegister,
            phone:item?.phone,
            website:item?.website
        }
      }))
    }
  };
  const onFinish = (value: any) => {
    setActiveFormProgram(4)
  };
  const Back = () => {
    setActiveFormProgram(2);
  };
  const HandleChange=(parm:number|string)=>{
   if(typeof parm==='number'){
    setOrganaztion_id(parm)
    setProgramma_data({...programma_data,certificationOrganId:parm})
   }
  }
  useEffect(() => {
    Get_Organaztion_List();
  }, []);
  return (
    <div className="w-full bg-[#fff] h-full flex  flex-wrap justify-center items-center ">
      
      <Form
        layout="vertical"
        onFinish={onFinish}
        name="control-hooks"
        className="w-full h-full bg-[#fff] p-5"
       style={{paddingRight:'35px'}}
      >
        <Form.Item className="w-full text-xl text-gray-800 font-bold pl-2">
        Программа сертификационных испытаний оборудования
      </Form.Item>
        <div
          className="w-full flex  justify-between items-center"
          style={{ height: "85%" ,paddingLeft:'8px'}}
        >
          <div
            className="w-1/2 h-full bg-[#fff] rounded-md p-8  overflow-auto"
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10"}}
          >
            <Form.Item className="w-full">
              <div className="w-full flex">
                <div className="w-1/2 text-gray-900 text-2xl font-bold">
                  Данные организации
                </div>
                <div className="w-1/2">
                  <Select className="w-full" value={organaztion_list?.find((item:organaztion_obj)=>item.id===programma_data.certificationOrganId)?.nameOfCertificationOrgan} onChange={(value:number|string)=>HandleChange(value)}>
                    {
                        organaztion_list?.map((item:organaztion_obj,index:number)=>{
                            return (
                              <Option key={index} value={item.id}>{item.nameOfCertificationOrgan}</Option>
                            )
                        })
                    }
                  </Select>
                </div>
              </div>
            </Form.Item>
            <Form.Item label="Наименование Органа по сертификации">
              <Input value={organaztion_list?.find((item:organaztion_obj)=>item.id===organaztion_id)?.nameOfCertificationOrgan} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
            <Form.Item label="Номер реестра">
              <Input value={organaztion_list?.find((item:organaztion_obj)=>item.id===organaztion_id)?.numberRegister} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
            <Form.Item label="Телефон">
              <Input value={organaztion_list?.find((item:organaztion_obj)=>item.id===organaztion_id)?.phone} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
            <Form.Item label="Электрон почта">
              <Input value={organaztion_list?.find((item:organaztion_obj)=>item.id===organaztion_id)?.email} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
          </div>
          <div
            className="w-1/2 h-full bg-[#fff] ml-2 rounded-md p-8 overflow-auto"
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10" }}
          >
            <Form.Item className="w-full">
              <div className="w-full flex text-gray-900 text-2xl font-bold">Данные продукции</div>
            </Form.Item>
            <Form.Item label="Наименование продукции">
            <Select className="h-11 cursor-no-drop" disabled mode="multiple" value={solution_data?.order?.products.map((item:{name:string})=>{return item.name})} />
            </Form.Item>
            <Form.Item label="Наименование НД на продукцию">
              <Select className="h-11 cursor-no-drop" disabled mode="multiple" value={solution_data?.nameOfDocument?.map((item:{normativeNumber:string})=>{return item.normativeNumber})} />
            </Form.Item>
            <Form.Item label="Наименование предприятия заявителя">
              <Input value={solution_data?.order?.applicantFullName} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
            <Form.Item label="Номер схемы сертификации">
              <Input value={solution_data?.order?.schemeCertification} className="h-11 cursor-no-drop" disabled />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          style={{ height: "10%" }}
          className="flex items-center justify-end"
        >
          <Space>
            <button
              onClick={Back}
              className="p-3 px-3 bg-[#1A56DB] rounded-lg text-[#fff]"
            >
              Назад
            </button>
            <button
              type="submit"
              className="p-3 px-3 bg-[#1A56DB] rounded-lg text-[#fff]"
            >
              Далее
            </button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
export default ProgrammaStepThree;
