import style from "./style.module.css";
import { Select, Switch ,Input} from "antd";
import {DeleteOutlined,HistoryOutlined} from '@ant-design/icons'
import { useState } from "react";
import { Add } from "src/assets/icons/laboratory";
import DataPicker from "src/Component/DatePickerFolder/DataPicker/DataPicker";
import {props} from './indexdata'
const tabslist:string[] = ["Все", "Принятые", "Отменённые"];

function Navbar({
  showTitle,
  setOpenModal,
  setOpenDrawewr,
  setTableWrite,
  data,
  title,
  showFilter,
  showModal,
  showTabs,
  showTypeProduct,
  Tabslist,
  icons,
  tableWrite,
  searchValue,
  user_list,
  setTabName,
  HandleChangeSearch
}: props) {
  const ChangeTabID = (parm: string) => {
    setTabId(parm);
    if(setTabName){
      setTabName(parm)
    }
  };
  const [tabId, setTabId] = useState<string>(Tabslist!==undefined ? Tabslist[0]:"Все");
  const HandleChange = (parm: string|number, name: string, id: number) => {
    if(HandleChangeSearch){
      HandleChangeSearch(parm,name,'')
    }
  };
  const WriteTable=(value:boolean)=>{
   if(setTableWrite){
    setTableWrite(value)
   }
  }
  return (
    <div className={`w-full h-full flex flex-wrap `}>
      <div className={showTitle ? `w-4/5 ${style.navbar_title}`:'hidden'}>{title}</div>
      <div className={showModal ? `w-1/5 justify-end ${style.navbar_buttons}`:"hidden"}>
        <button onClick={() => setOpenModal(true)}>
          <span>Добавить ТСТ</span> <Add />
        </button>
      </div>
      <div className="w-full flex justify-between ">
       <div className="w-1/2 flex">
       <div className={`tabs_group_row h-full flex items-center`}>
          {showTabs ? (
            <div className={`tabs_group mr-3`}>
              {(Tabslist!==undefined ? Tabslist:tabslist)?.map((item: string, index: number) => {
                return (
                  <div
                  key={index}
                    className={
                      tabId === item
                        ? `tabs_group_element_active`
                        : `tabs_group_element`
                    }
                    onClick={() => ChangeTabID(item)}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {
            showTypeProduct ? <div style={{height:"34px"}} className="mr-3 flex items-center">
              <Select className={`${style.type_product}`} allowClear  placeholder="Тип продукта" style={{width:"220px",height:'50px'}}
              onChange={(value:number)=>HandleChange(value,'user',0)}
            options={user_list!==undefined ? user_list:[{label:'label',value:1}]}/>
            </div>:""
          }
        </div>
        {showFilter ? (
          <div className="w-full flex items-center navbar_header_date">
            {/* <DataPicker 
              handleChange={HandleChange}
              name="date" placeholder="Выбрать дату"
              style={{ width: "200px", height: "50px" }}
            /> */}
            <Input className="w-72 h-11 ml-2" allowClear style={{height:'50px'}} onChange={(e)=>HandleChange(e.target.value,'search_id',0)} placeholder="Поиск" />
          </div>
        ) : (
          ""
        )}
       
       </div>
       {/* <span className={tableWrite ? "":"hidden"}><Switch className="bg-[#ccc]" onChange={(value:boolean)=>WriteTable(value)}/><span>Наполнение</span></span> */}
       <div className={icons ? `w-1/2 h-full items-center flex justify-end ${style.navbar_icons}`:"hidden"}>
          <span><DeleteOutlined /></span>
          <span onClick={()=>{if(setOpenDrawewr){
            setOpenDrawewr(true)
          }}}><HistoryOutlined/></span>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
