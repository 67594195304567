import "./Assigment.css";
import { Select, Button, Modal, Empty } from "antd";
import { useEffect, useState } from "react";
import Loading from "src/Component/Loading/Loading";
import TableUsers from "src/Component/AdminComponent/Table/Table";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
interface dataheader {
  title: string;
  dataIndex: string;
  width: number;
  maxWidth: number;
  render?: any;
}
interface DataSourceItem {
  key: string;
  name: string;
  age: number;
  address: string;
  selectValue: string;
}
const { Option } = Select;
function Assigment() {
  const { id } = useParams();
  const navigate=useNavigate()
  const [loading, setLoading] = useState(true);
  const [OpenModal, setOpenModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(30);
  const [user_list,setUserList]=useState<{label:string,value:number}[]>([])
  const [orderID, setOrderID] = useState<number>(0);
  const [assigment_user_list,setAssigment_user_list]=useState<{
    orderId:number,
    userIds:number[]
  }>({
    orderId:0,
    userIds:[]
  })
  const [data,setdata] = useState([]);
  const [newuserlist, setNewUserlist] = useState<boolean>(false);
  function HandleChange(parm:string[]|number[]){
   parm?.forEach((item:number|string)=>{
    if(typeof item==='number'){
      setAssigment_user_list({...assigment_user_list,userIds:[...assigment_user_list.userIds,item]})
    }
   })
  }  
  async function GetData(){
    setLoading(false)
    const responseData=await RequestToken('/order/acceptedOrders?page=0&size=100','GET',null)
    if(responseData.success){
      setLoading(true)
      setdata(responseData.object)
    }
    else if(responseData.response?.status===403 || responseData.response?.status===401){
      navigate('/')
    }
    else{
      setLoading(true)
      Swal.fire({
        title: responseData.message,
        icon:"error"
      })
    }
  }
  async function getuserList(){
    setLoading(false)
    const responseData=await RequestToken('/user/getUsersWithoutSuperAdmin','GET',null)
    if(responseData.success){
      setLoading(true)
      setUserList(responseData.object.map((item:{firstName:string,lastName:string,id:number},index:number)=>{return {label:item.lastName +" "+item.firstName,value:item.id}}))
    }
    else if(responseData.response?.status===403 || responseData.response?.status===401){
      navigate('/')
    }
    else{
      setLoading(true)
      Swal.fire({
        title: responseData.message,
        icon:"error"
      })
    }
  }
  function OpenModalFunc(orderID:number){
    setAssigment_user_list({...assigment_user_list,orderId:orderID})
    setOpenModal(true)
    setNewUserlist(false)
  }
  function Cancel(){
    setOpenModal(false)
    setAssigment_user_list({...assigment_user_list,orderId:0,userIds:[]})
  }
  async function Save(){
    const response=await RequestToken('/assignment',"POST",assigment_user_list)
    if(response.success){
      Swal.fire({
        title:response?.message,
        icon:'success'
      })
      GetData()
      setOpenModal(false)
      setAssigment_user_list({...assigment_user_list,orderId:0,userIds:[]})
    }
    else{
      Swal.fire({
        title:response.response?.data?.message||response?.response?.message,
        icon:'error'
      })
    }
  }
  useEffect(() => {
   GetData()
   getuserList()
  }, []);
  return (
    <div className="Assigment">
       <Modal
            centered 
            open={OpenModal}
            style={{ width: "120px"}}
            onCancel={Cancel}
            okText={"Сохранить"}
            cancelText="Oтмена"
            onOk={Save}>           
          
        <div className="Modal_wrapper_box">
          <div className="Modal_wrapper_box_header">
            <div>Добавить нового сотрудника</div>
          </div>
          <div className="Modal_wrapper_box_body">
            <Select value={assigment_user_list.userIds} onChange={(value:number[]|string[])=>HandleChange(value)} mode="multiple" options={user_list} style={{width:'350px'}}/>           
          </div>
        </div>
  
          </Modal>
      { 
        loading? <div className="Assigment_wrapper_box">
        <table className="w-full">
          <thead>
            <tr>
              <th>№</th>
              <th>Kомпания</th>
              <th className="bg-[#F2F4F8]">Сотрудник Юникон</th>
              <th className="bg-[#F2F4F8]">Дата начала</th>
              <th className="bg-[#F2F4F8]">Дата окончания</th>
              <th className="bg-[#F2F4F8]">Oставшееся время</th>
              <th className="bg-[#F2F4F8]">Имя заявителя</th>
              <th className="bg-[#F2F4F8]">Лаборант</th>
              <th className="bg-[#F2F4F8]">Hа каком этапе</th>
            </tr>
          </thead>
          <tbody>
           {
              data?.length>0 ? data?.map((item:any,index:number)=>{
                return (
                  <tr key={index}>
                  <td style={{border:'1px solid #DDE1E6'}} className="p-3">{index+1}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{item.company}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{item.user?.length>0 ? item.user?.map((item:{firstName:string,lastName:string})=>{
                    return (
                      <div>{item.lastName} {item.firstName}</div>
                    )
                  }):<button className="px-3 py-2 text-[#fff] bg-[red] rounded-md" onClick={()=>OpenModalFunc(item.id)}>Собрание сотрудников</button>}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{moment(item.dateOrder).format('DD-MM-YYYY')}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{moment(item.expiryDate).format('DD-MM-YYYY')}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{item.days}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{item.applicantFullName}</td>
                  <td style={{border:"1px solid #DDE1E6"}} className="p-3">{item.company}</td>
                </tr>
                )
               }):<tr>
                <td colSpan={8}><Empty description="Не найдено"/></td>
               </tr>
           }
          </tbody>
        </table>
   
      </div>
      :<Loading/>
      }
    </div>
  );
}
export default Assigment;
