import { useEffect, useState,useRef } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import {useReactToPrint} from 'react-to-print'
import { ApplicantDocx } from "./renderDocsType";
import moment from "moment";
interface props {
  orderID: number | string;
  setDownload:(download:boolean)=>void
}
export default function StepOneDocx({ orderID ,setDownload}: props) {
  const style1Class: string =
    "w-full h-8 border-b-2 border-black text-center my-2 text-xs flex items-end justify-center";
  const style2ClassSpan = "w-full block text-center";
  const [res_data,setResData]=useState<ApplicantDocx|null|undefined>()
  async function get_applicant() {
    const response = await RequestToken(
      `/order/doc/application/${orderID}`,
      "GET",
      null
    );
    if (response.success) {
     setResData(response.object)
     HandlePrint()
    }
  }
  const componentRef:any=useRef()
      const HandlePrint=useReactToPrint({
        content:()=>componentRef.current,
        documentTitle:"emp",
        onAfterPrint:()=>setDownload(false)
      })
  useEffect(()=>{
   get_applicant()
  },[])
  return (
    <div className="h-auto" style={{width:'594px'}} ref={componentRef}>
          <div className="w-full py-8" >
      <div className="w-full border-b-2 border-black text-center my-2">
        «UNICON.UZ» - Государственное унитарное предприятие Центр
        научно-технических и маркетинговых исследований
      </div>
      <span className="w-full block text-center">
        (наименование органа по сертификации)
      </span>
      <div className="w-full border-b-2 border-black text-center ">
        100187, Ўзбекистон Республикаси Тошкент шаҳри, Мирзо Улуғбек тумани,
        Қорасув-3, Мингбулоқ кўчаси, 38-уй
      </div>
      <span className="w-full block text-center">
        (адрес органа по сертификации)
      </span>
      <div className="w-full flex justify-center my-5">
        <div className="w-2/3 text-center font-semibold">
          <div> ЗАЯВКА</div>
          на проведение сертификации продукции в Национальной системе
          сертификации Республики Узбекистан
        </div>
      </div>
      <div className="w-full border-b-2 border-black text-center">
        {res_data?.applicant}, ИНН {res_data?.inn}
      </div>
      <span className="w-full block text-center">
        наименование заявителя (предприятия-изготовителя, поставщика или Ф.И.О.
        индивидуального предпринимателя)
      </span>
      <div className="w-full border-b-2 border-black text-center  my-3">
        {res_data?.applicantAddress} {res_data?.phoneNumber}
      </div>
      <span className={style2ClassSpan}>(адрес заявителя)</span>
      <div className="w-full  flex my-5">
        <div className="w-1/6 pt-2">в лице</div>
        <div className="w-4/6">
          <div className={style1Class}>{res_data?.applicantFullName}</div>
          <div className={style2ClassSpan}>(Ф.И.О. руководителя)</div>
        </div>
        <div className="w-1/6">заявляет, что</div>
      </div>
      <div className={style1Class}>
       {res_data?.productNames?.map((item:string,index:number)=>{
        return (
          <span className="px-3 py-2 " key={index}>{item}</span>
        )
       })}
      </div>
      <span className={style2ClassSpan}>(наименование, вид продукции,)</span>
      <div className={style1Class}>{res_data?.productCodes?.map((item:string,index:number)=>{
        return (
          <div className="px-3 py-2 " key={index}>{item}</div>
        )
       })}</div>
      <span className={`${style2ClassSpan} mb-3 `}>
        код ТН ВЭД, выпускается серийно или партия, каждое изделие (при
        единичном производстве)
      </span>
      <div
        style={{ height: "2px" }}
        className="w-full bg-[#000] mb-2 mt-5"
      ></div>
      <div className="w-full flex my-5">
        <div className="w-1/6 text-center pt-2">выпускается по</div>
        <div className="w-5/6">
          <div className={style1Class}></div>
          <span className={style2ClassSpan}>
            (наименование документации изготовителя)
          </span>
        </div>
      </div>
      <div className="w-full flex my-5">
        <div className="w-1/6 text-center pt-2">соответствует требованиям</div>
        <div className="w-5/6">
          <div className={style1Class}></div>
          <div className={style2ClassSpan}>
            (наименование и номера нормативных документов)
          </div>
        </div>
      </div>
      <div className="w-full text-left h-8">
        и просит провести сертификацию данной продукции на соответствие
        требованиям указанных
      </div>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <div className="w-full ">
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span lang="RU" style={{ fontSize: "12.0pt" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span lang="RU" style={{ fontSize: "12.0pt" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span lang="RU" style={{ fontSize: "12.0pt" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span lang="RU" style={{ fontSize: "12.0pt" }}>
            &nbsp;
          </span>
        </p>
        <div className="w-full h-auto flex ">
          <div className="w-1/6 text-center pt-2">документов по схеме</div>
          <div className="w-5/6">
            <div className={style1Class}>{res_data?.schemeOfCertification}</div>
            <span className={style2ClassSpan}>(номер схемы сертификации)</span>
          </div>
        </div>
      </div>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <div className="w-full flex my-5">
        <div className="w-1/6 text-center pt-2">Дополнительные сведения</div>
        <div className="w-5/6">
          <div className={style1Class}>Гигиеническое заключение № от г.</div>
          <span className={style2ClassSpan}>
            (указывается наличие гигиенического сертификата и др. документ)
          </span>
        </div>
      </div>
      <div className="w-full h-auto py-2 border-b-2 border-black">
        <div className="w-full">образец маркировки;</div>
        <div className="w-full">
          (Tоварно-транспортная накладная, инвойс, счет-фактура) от 01.01.2000г
        </div>
      </div>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <div className="w-full flex">
        <div className="w-1/3">Заявитель</div>
        <div className="w-1/3">
          <div className="w-full px-1">
            <div className={style1Class}>{res_data?.applicantFullName}</div>
          </div>
          <span className={style2ClassSpan}>(Ф.И.О.)</span>
        </div>
        <div className="w-1/3">
          <div className="w-full px-1">
            <div className={style1Class}></div>
          </div>
          <span className={style2ClassSpan}>(подпись)</span>
        </div>
      </div>
      <div className="w-full">
      Дата: {moment(res_data?.dateOfReceivingTime).format('DD-MM-YYYY')}
      </div>
    </div>
    </div>
  );
}
