import style from "./style.module.css";
import { useState, useEffect } from "react";
import { Select, Input, Empty } from "antd";
import { applance } from "src/Component/Laborotry/TableWizzard/TableWizzardType";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import moment from "moment";
interface props {
  setTableId:(tableId:number)=>void
  test_protocol: any;
  setTestProtocol: (test_protocol: any) => void;
  applance_list: applance[];
  setLoading: (loading: boolean) => void;
}
function TablestestReportFirst({
  applance_list,
  setLoading,
  test_protocol,
  setTableId,
  setTestProtocol,
}: props) {
  const navigate = useNavigate();
  const [applance, setApplance] = useState<number[]>([]);
  function HandleChange(value: number) {
    setTestProtocol({
      ...test_protocol,
      applianceIds: [...test_protocol.applianceIds, value],
    });
  }
  function error(response_data: any) {
    if (
      response_data.response.status === 403 &&
      response_data.response.status === "401"
    ) {
      navigate("/");
    } else {
      Swal.fire({
        icon: "error",
        title:
          response_data.response.data?.message || response_data?.response.message,
      });
    }
  }
  async function Save() {
    setLoading(false);
    const res_applanse = await RequestToken(
      "/testingProtocol",
      "POST",
      test_protocol
    );
    if (res_applanse.success) {
      setLoading(true)
      Swal.fire({
        icon: "success",
        title: res_applanse.response?.data?.message||res_applanse.response?.message,
      });
    } else {
      setLoading(true);
      error(res_applanse);
    }
  }
  return (
    <div className="w-full h-full pt-2 px-2">
      <div className="w-full" style={{ minHeight: "90%", maxHeight: "auto" }}>
        <table className="w-full h-auto">
          <thead>
            <tr>
              <th className={style.table_th_id}>№</th>
              <th className={style.table_th_one}>Наименование СИ и ИО</th>
              <th className={style.table_th_two}>
                Тип (фирма изготовитель, для импортных){" "}
              </th>
              <th className={style.table_th_three}>Заводской номер</th>
              <th className={style.table_th_four}>
                Дата очередной поверки СИ и/или аттестации ИО
              </th>
            </tr>
          </thead>
          <tbody>
            {test_protocol.applianceIds.length > 0 ? 
              applance_list
                .filter((item1: applance, index: number) => {
                  if (test_protocol.applianceIds.includes(item1.id)) {
                    return item1;
                  }
                })
                .map((item: applance, index: number) => {
                    return (
                      <tr key={index}>
                        <td className={style.table_td_id}>{index+1}</td>
                        <td className={style.table_td}>{item.name}</td>
                        <td className={style.table_td}>{item.manufacturer}</td>
                        <td className={style.table_td}>{item.serialNumber}</td>
                        <td className={style.table_td}>
                          {moment(item.dateOfCertification).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                    );
                }) : (
              <tr>
                <td colSpan={5} className="text-center">
                  <Empty description={"Прибор не выбрано"} />
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={5} className={style.table_td}>
                <Select
                  className="w-1/3"
                  options={applance_list.map((item) => {
                    return { label: item.name, value: item.id };
                  })}
                  onChange={(value: number) => HandleChange(value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="w-full  flex items-end justify-end"
        style={{ height: "10%" }}
      >
        <button
          className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
          onClick={()=>setTableId(2)}>Далле</button>
      </div>
    </div>
  );
}
export default TablestestReportFirst;
