import "./Layout.css";
import { UserContext } from "src/App";
import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Button, theme,message, Empty } from 'antd';
 import { Outlet, useLocation} from "react-router-dom";
 import Acount from "../Component/acount/Acount";
 import { NavLink,useNavigate } from 'react-router-dom';
import { role_data } from "./LayOutData";
import logotip from 'src/assets/image/unicon.png'
import { RequestToken } from "src/Hook/Request/Request";
const { Header, Sider, Content } = Layout;
const LayOut= () => {
  const navigate=useNavigate()
  const roles=localStorage.getItem("_role") ? localStorage.getItem("_role"):" "
  const [messageApi, contextHolder] = message.useMessage();
  const {headerTitle,setDangerApplicant}=useContext(UserContext)
  const key = 'updatable';
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken()
  async function getDeadline(){
    const response=await RequestToken('/order/notification',"GET",null)
    if(response.success){
      setDangerApplicant(response.object)
    }
  }
   useEffect(()=>{
    getDeadline()
   },[])
  return (
    <Layout style={{height:'100vh'}}>
       {contextHolder}
       <Sider width={320}   style={{height:'100vh'}} trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical  bg-[#fff]  mb-5" >
           <img src={logotip} style={{width:"90%",height:"140px"}}/>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} style={{display:'flex',flexWrap:"wrap",justifyContent:"center"}}>
    { roles ? (role_data as any)[roles].map((item:any) => (
        <NavLink  className={({isActive,isPending})=>isPending ? "pending":`${
          isActive ? "admin-wrapper-sitebar-link_active" : "admin-wrapper-sitebar-link"
        } ${collapsed === true ? "collapsed" : ""}`}
           to={item.path}>
          <span className={collapsed===true ? 
            "admin-wrapper-sitebar-link_span_active_left":
            "admin-wrapper-sitebar-link_span_left"}>
            {item.icon}</span>
          <span className={collapsed===true ? 
            "admin-wrapper-sitebar-link_span_active_right":
            "admin-wrapper-sitebar-link_span_right"}>
            {collapsed!==true ?  item.label:""}</span>
        </NavLink>
    )):<Empty/>}
  </Menu>
      </Sider>
      <Layout  style={{height:'100vh'}}>
      <Header  style={{
         height:'85px', boxSizing:"border-box", 
         display:'flex',justifyContent:'center', background: colorBgContainer }}>
           <div className="w-full h-full flex">
            <div className="w-2/3 h-full flex items-center font-semibold text-xl">{headerTitle}</div>
            <div className="w-1/3 flex justify-end"><Acount/></div>
           </div>
        </Header>
        <Content
          style={{
            margin: '24px 24px',
            height: "80%",
          }}
        >
         <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayOut ;
