import { useContext,useState } from "react";
import { NavLink } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Bell from "../../assets/icons/HeaderIcon/Bell.svg"
import { UserContext } from "src/App";
import { Empty } from "antd";
export default function Notifacion({data}:any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {dangerApplicant}=useContext(UserContext)
  const open = Boolean(anchorEl);
  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="bell"
      >
        <img src={Bell} alt="bell" />
        {dangerApplicant?.length>0 ? <span>{dangerApplicant?.length}</span>:''}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
          {
            dangerApplicant?.length>0 ? dangerApplicant?.map((item:{id:number,days:number},index:number)=>{
              return (
                <MenuItem key={index} onClick={handleClose}><i><NavLink to={`/home/${item.id}`}>осталось {item?.days} дня </NavLink> </i> </MenuItem>
              )
            }):<MenuItem onClick={handleClose}>
              <Empty description="Hет в наличии"/>
               </MenuItem>
          }
      </Menu>
    </>
  );
}
