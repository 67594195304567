import {useState} from 'react'
import Input from "../Component/Input/Input"
import AddBox from "../Component/data/addbox/addbox"
import SelectIndicator from "../Component/Select/Select"
import { RequestToken } from '../Hook/Request/Request';
import { NamedTupleMember } from 'typescript';
import { type } from 'os';

interface FormItem {
    id: number;
    name: string;
    Requireds: boolean;
    value: string;
    form: JSX.Element;
}

interface TitleItem {
    id: number;
    value: string;
    text: string;
}

interface HeaderTitleItem {
    id: number;
    title: string;
}

interface StepData {
    data: FormItem[];
    data_title: TitleItem;
    data2: FormItem[];
    header_title: HeaderTitleItem;
}
interface tipdata {
    label:string,
    value:string
}
interface objects {
  id:number
  name:string
}
export const tip_data=[
    "...",
    "писъно ‚(после установки)",
    " Цемент",
   "Обычный"]

interface AIL_name {
  id:number
  label:string,
  value:string
}

export const  products=[
    {
      label:' продукт 1',
      value:' продукт 1',
    },
    {
      label:' продукт 2',
      value:' продукт 2',
    },
    {
      label:' продукт 3',
      value:' продукт 3',
    },
    {
      label:' продукт 4',
      value:' продукт 4',
    },
  ]
export const tip_ariza =[
  {label:"Продолжить",value:"..."},
  {label:"Обычный",value:"obichniy"},
  {label:"Письмо",value:"pismo"}
]
export const type_payment=[
  {
    label:"Узкард"  ,value:"Узкард"
  },
  {
    label:"оплата наличными"  ,value:"оплата наличными"
  },
  {
    label:"оплата через банк"  ,value:"оплата через банк"
  },
]
  interface DataStepTwo {
    id:number,
    label:string,
    type:string,
    key:string,
    title?:string,
    tip?:string,
    options?:tipdata[]
  }
const sexema_sertifikat=[
  {
    label:"1",value:'1'
  },
  {
    label:"2",value:'2'
  },
  {
    label:"3",value:'3'
  },
  {
    label:"4",value:'4'
  },
  {
    label:"5",value:'5'
  },

]
export const DataStepTwo:DataStepTwo[]=[
  // {
  //   id:1,
  //   label:'Схема cергтификация',
  //   key:'',
  //   type:'select',
  //   tip:'all',
  //   options:sexema_sertifikat
  // },
  {
    id:2,
    label:"Номер",
    key:'number',
    type:'input',
    tip:'all'
  },
  {
    id:3,
    label:'Дата',
    key:'date',
    type:'date',
    tip:'all'
  },
  {
    id:4,
    label:"Hаименование документации (ГОCT,O'zDst,TS) 751 и др.",
    type:'addbox',
    key:'normativeIds',
    tip:'all',
  },
  {
    id:5,
    label:'Hаименования АИЛ',
    type:'laboratory',
    key:'certificationOrganIds',
    tip:'all',
  },
  {
    id:6,
    label:'Номер в Госреестре НСС РУз',
    type:'input',
    key:'numberOfRegister',
    tip:'all'
  },
  {
    id:7,
    label:'Формы оплаты',
    type:'select',
    key:'typeOfPayment',
    tip:'all',
    options:type_payment
  },
  {
    id:8,
    label:'',
    type:'',
    key:'',
    title:'Сертификация будет осуществлятся после монтажа и установки оборудованна',
    tip:'all',
  },
  {
    id:9,
    label:'Тип решение',
    type:'select',
    key:'typeOfSolutionName',
    tip:'all',
    options:tip_ariza
  },
  {
    id:10,
    label:'№ письма',
    type:'input',
    key:'numberOfMail',
    tip:'obichniy'
  },
  {
    id:11,
    label:'Дата письма',
    type:'date',
    key:'dateOfMail',
    tip:"obichniy"
  },
  {
    id:12,
    label:'Приложите письмо',
    type:'upload',
    key:'attachment',
    tip:'obichniy'  
  },
  {
    id:13,
    label:'Выбирать продукты',
    type:'product',
    key:'productsName',
    tip:"pismo",
   
  },
  {
    id:14,
    label:'Руководитель',
    type:'select',
    key:'rukoVoditel',
    tip:"pismo",
    options:[{label:"M.M.Maxmudov",value:"M.M.Maxmudov"}]
  },
  {
    id:15,
    label:'Исполнитель',
    type:'input',
    key:'ispolnitel',
    tip:"pismo",
  },
  {
    id:16,
    label:'Телефон',
    type:'phone_input',
    key:'phoneNumber',
    tip:"pismo"
  },
]
export const DataStepTwo_GET:DataStepTwo[]=[
  // {
  //   id:1,
  //   label:'Схема cергтификация',
  //   key:'',
  //   type:'select',
  //   tip:'all',
  //   options:sexema_sertifikat
  // },
  {
    id:2,
    label:"Номер",
    key:'number',
    type:'input',
    tip:'all'
  },
  {
    id:3,
    label:'Дата',
    key:'date',
    type:'date',
    tip:'all'
  },
  {
    id:4,
    label:"Hаименование документации (ГОCT,O'zDst,TS) 751 и др.",
    type:'addbox',
    key:'normativeNames',
    tip:'all',
  },
  {
    id:5,
    label:'Hаименования АИЛ',
    type:'laboratory',
    key:'certificationOrganNames',
    tip:'all',
  },
  {
    id:6,
    label:'Номер в Госреестре НСС РУз',
    type:'input',
    key:'numberOfRegisters',
    tip:'all'
  },
  {
    id:7,
    label:'Формы оплаты',
    type:'select',
    key:'typeOfPayment',
    tip:'all',
    options:type_payment
  },
  {
    id:8,
    label:'',
    type:'',
    key:'',
    title:'Сертификация будет осуществлятся после монтажа и установки оборудованна',
    tip:'all',
  },
  {
    id:9,
    label:'Тип решение',
    type:'select',
    key:'typeOfSolutionName',
    tip:'all',
    options:tip_ariza
  },
  {
    id:10,
    label:'№ письма',
    type:'input',
    key:'numberOfMail',
    tip:'obichniy'
  },
  {
    id:11,
    label:'Дата письма',
    type:'date',
    key:'dateOfMail',
    tip:"obichniy"
  },
  {
    id:12,
    label:'Приложите письмо',
    type:'upload',
    key:'attachment',
    tip:'obichniy'  
  },
  {
    id:13,
    label:'Выбирать продукты',
    type:'product',
    key:'productsName',
    tip:"pismo",
   
  },
  {
    id:14,
    label:'Руководитель',
    type:'select',
    key:'rukoVoditel',
    tip:"pismo",
    options:[{label:"M.M.Maxmudov",value:"M.M.Maxmudov"}]
  },
  {
    id:15,
    label:'Исполнитель',
    type:'input',
    key:'ispolnitel',
    tip:"pismo",
  },
  {
    id:16,
    label:'Телефон',
    type:'phone_input',
    key:'phoneNumber',
    tip:"pismo"
  },
]
export const row = [
  {
    name:'Texnomart',
    calories:"Abdulloh Imomov", 
    fat:"06/08/2023", 
    carbs:"16/08/2023", 
    protein:"23",
    deadlinePay:"10",
    sohibPerson:"Abdulloh Imomov"
  },
  {
    name:'Texnomart',
    calories:"Abdulloh Imomov", 
    fat:"06/08/2023", 
    carbs:"16/08/2023", 
    protein:"23",
    deadlinePay:"10",
    sohibPerson:"Abdulloh Imomov"
  },
  {
    name:'Texnomart',
    calories:"Abdulloh Imomov", 
    fat:"06/08/2023", 
    carbs:"16/08/2023", 
    protein:"23",
    deadlinePay:"10",
    sohibPerson:"Abdulloh Imomov"
  },
  {
    name:'Texnomart',
    calories:"Abdulloh Imomov", 
    fat:"06/08/2023", 
    carbs:"16/08/2023", 
    protein:"23",
    deadlinePay:"10",
    sohibPerson:"Abdulloh Imomov"
  },
  {
    name:'Texnomart',
    calories:"Abdulloh Imomov", 
    fat:"06/08/2023", 
    carbs:"16/08/2023", 
    protein:"23",
    deadlinePay:"10",
    sohibPerson:"Abdulloh Imomov"
  },
];
interface Tipdata {
  label:string,
  value:number
}
interface DataStepThree {
  id:number,
  label:string,
  type:string,
  key:string,
  title?:string,
  disabled?:boolean
  options?:Tipdata[]
}

export const DataStepThree:DataStepThree[]=[
  {
    id:1,
    label:'Дата отбора',
    key:"dateOfSelection",
    type:'date',
  },
  {
    id:2,
    label:"Hаименование и  адрес организации, где производился отбор образцов (проб)",
    key:"nameOfOrganizationId",
    type:'warehouse_select',
  },
  {
    id:3,
    label:'Результат наружного осмотра партии',
    key:"result",
    type:'textarea',
  },
  {
    id:4,
    label:"",
    type:'',
    key:'',
    title:'Идентификация',
  },
  {
    id:5,
    label:'Сведение об упаковке и марю1рооке',
    key:"statusOfPacked",
    type:'input'
  },
  {
    id:6,
    label:'Предназначение',
    key:"purpose",
    type:'input'
  },
  {
    id:7,
    label:'Основные характеристики',
    key:"characteristics",
    type:'input'
  },
  {
    id:8,
    label:'Условия лраиеиик',
    key:"storageConditions",
    type:'input'
  },
  {
    id:9,
    label:'Дополнительная информация',
    key:"extraInformation",
    type:'input'
  },
  {
    id:10,
    label:"Добавить ГОСТ",
    key:"normative",
    type:"addbox"
  },
  {
    id:11,
    label:'От .иготооитола(заказчика)',
    key:"fromCustomer",
    type:'input'
  },
  {
    id:12,
    label:'От органа по сертификаия',
    key:"fromOrganization",
    type:'input'
  },
  {
    id:13,
    label:'Инспектор таможенного поста',
    key:"inspector",
    type:'input'
  },
  {
    id:14,
    label:'Номер счета',
    key:"invoiceNumber",
    type:'input'
  },
  {
    id:15,
    label:'Дата счета',
    key:"invoiceDate",
    type:'date'
  },
  {
    id:16,
    label:'Накладная',
    key:"deliveryNote",
    type:'input'
  },

]
export const DataStepThreeProduct:DataStepThree[]=[
  {
    id:1,
    label:"Код- ТН ВЭД продукции'	",
    type:'input',
    key:'codeProduct'
  },
  {
    id:2,
    label:"Наименование продукции",
    type:'input',
    key:'name'
  },
  {
    id:3,
    label:"Изготовитель",
    type:'input',
    key:'manufacturer'
  },
  {
    id:4,
    label:"Общий количество",
    type:'numberinput',
    key:'amount'
  },
  {
    id:5,
    label:"Единицы измерения (шт,кг,и т.д)",
    type:'measurmentId',
    key:'measurementId',
  },
  {
    id:6,
    label:"Дата изготовления",
    type:'date',
    key:'dateOfProducing'
  },
  {
    id:7,
    label:"Срок годности ",
    type:'date',
    key:'expiryDate'
  },
  {
    id:8,
    label:"Срок годности  с прописью",
    type:'Switch',
    key:'isValid'
  },
  {
    id:9,
    label:"Срок годности ",
    type:'date',
    disabled:true,
    key:'expiryWithValidDate'
  },
  {
    id:10,
    label:"Основная информация",
    type:'textarea',
    key:'mainInformation'
  },
  {
    id:11,
    label:"Дополнительная информация",
    type:'input',
    key:'extraInformation'
  },
  {
    id:12,
    label:"Количество отобранных образцов",
    type:'numberinput',
    key:'numberOfSamplesTaken'
  },
  {
    id:13,
    label:"Для испытаний",
    type:'numberinput',
    key:'numberOfForTesting'
  },
  {
    id:14,
    label:"Единицы измерения для образцов",
    type:'measurmentId',
    key:'perMeasurementId'
  },
]
export const DataStepThreeType=[
  "text","number","textarea","select","datapicker","addbox"
]

interface DataStepFive {
  id:number,
  label:string,
  key:string,
  type:string,
  title?:string,
  options?:Sxema_sertifikat[]
}
export const DataStepFive:DataStepFive[]=[
  {
    id:1,
    label:'№ решения',
    key:"numberOfDecision",
    type:'input',
  },
  {
    id:2,
    label:'Дата решения',
    key:"dateOfDecision",
    type:'date',
  },
  {
    id:3,
    label:'Схема сертификации',
    key:"schemeOfCertification",
    type:'input',
  },
  {
    id:4,
    label:'Наименование организации',
    key:"nameOfApplicant",
    type:'input',
  },
  {
    id:5,
    label:'Наименование продукции',
    key:"productIds",
    type:'product',
  },
  {
    id:6,
    label:'Требование НД',
    key:"normativeIds",
    type:'addbox',

  },
  {
    id:7,
    label:'Решение о выдача или об отказе',
    key:"decisionOfDistributionOrRefusal",
    type:'select',
    options:[{label:"Выдать",value:true},{label:"Отказть в выдачи",value:false}]
  },
  {
    id:7,
    label:'Причина отказа',
    key:"reasonOfRefusing",
    type:'textarea',
  },
]
export const DataStepFive_GET:DataStepFive[]=[
  {
    id:1,
    label:'№ решение',
    key:"numberOfDecision",
    type:'input',
  },
  {
    id:2,
    label:'Дата решение',
    key:"dateOfDecision",
    type:'date',
  },
  {
    id:3,
    label:'Схема сертификации',
    key:"schemeOfCertification",
    type:'input',
  },
  {
    id:4,
    label:'Наименование организация',
    key:"nameOfApplicant",
    type:'input',
  },
  {
    id:5,
    label:'Наименование продукции',
    key:"productNames",
    type:'product',
  },
  {
    id:6,
    label:'Требование НД',
    key:"normativeIds",
    type:'addbox',

  },
  {
    id:7,
    label:'Решение выдача или отказ',
    key:"decisionOfDistributionOrRefusal",
    type:'select',
    options:[{label:"Выдать",value:true},{label:"Отказть в выдачи",value:false}]
  },
  {
    id:7,
    label:'Причина отказа',
    key:"reasonOfRefusing",
    type:'textarea',
  },
]
interface Sxema_sertifikat {
  label:string,
  value:string|boolean|number
}
export  const sxema_sertifikat:Sxema_sertifikat[]=[
  {
    label:'1',
    value:'1'
  },
  {
    label:'2',
    value:'2'
  },
  {
    label:'3',
    value:'3'
  },
  {
    label:'4',
    value:'4'
  },
  {
    label:'5',
    value:'5'
  },
]
interface Step_six {
  label:string,
  value:string
}
export  interface dataStepSixType {
  id:number,
  label:string,
  key:string,
  type:string,
  options?:Step_six[]
}
export const dataStepSix:dataStepSixType[]=[
  {
    id:1,
    label:  "Дата заявки (дд.мм.гггг)",
    key:'dateOfApplication',
    type:'date'
  },
  {
    id:2,
    label:  "Наименование заявителя",
    key:'nameOfApplicant',
    type:'input'
  },
  {
    id:3,
    label:  "Адрес заявителя",
    key:'addressOfApplicant',
    type:'input'
  },
  {
    id:4,
    label:  "Ф.И.О руководителя заявителя",
    key:'fullNameOfApplicant',
    type:'input',
    options:[{label:"label",value:"label"}]
  },
  {
    id:5,
    label:  "Схема сертификации",
    key:'schemeOfCertification',
    type:'input'
  },
 ]
export const dataProductStepSix:dataStepSixType[]=[
  {
    id:1,
    label:'Номер заявки',
    key:'numberOfOrder',
    type:"input"
  },
  {
    id:2,
    label:'Наименование продукции',
    key:'productIds',
    type:"product"
  },
  {
    id:3,
    label:'Kод- ТН ВЭД',
    key:'codeOfProduct',
    type:"input"
  },
  {
    id:4,
    label:"Наименование документации (ГОСТ, O'zDst, TS) и др.",
    key:'normativeIds',
    type:"addbox"
  },
  {
    id:5,
    label:'Заявитель',
    key:'applicant',
    type:"input"
  },
  {
    id:6,
    label:'Дополнительные сведения',
    key:'extraProtocol',
    type:"protokol"
  },
  {
    id:7,
    label:'Санитарно-эпидемиологическое заключения',
    key:'',
    type:"upload"
  },
]
export const dataProductStepSixGet:dataStepSixType[]=[
  {
    id:1,
    label:'Номер заявки',
    key:'numberOfOrder',
    type:"input"
  },
  {
    id:2,
    label:'Наименование продукции',
    key:'productNames',
    type:"product"
  },
  {
    id:3,
    label:'Kод- ТН ВЭД',
    key:'codeOfProduct',
    type:"input"
  },
  {
    id:4,
    label:"Наименование документации (ГОСТ, O'zDst, TS) и др.",
    key:'normativeNames',
    type:"addbox"
  },
  {
    id:5,
    label:'Заявитель',
    key:'applicant',
    type:"input"
  },
  {
    id:6,
    label:'Дополнительные сведения',
    key:'extraProtocol',
    type:"input"
  },
  {
    id:7,
    label:'Санитарно-эпидемиологическое заключения',
    key:'',
    type:"upload"
  },
]
export const dataProduct=[
  {
    id:1,
    label:'Наименование OC',
    key:'',
    type:"input"
  },
  {
    id:2,
    label:'Номер свидетельства об аккредетации',
    key:'',
    type:"input"
  },
  {
    id:3,
    label:'Наименование OC',
    key:'',
    type:"input"
  },
  {
    id:4,
    label:'Номер свидетельства об аккредетации',
    key:'',
    type:"input"
  },
  {
    id:5,
    label:'Наименование OC',
    key:'',
    type:"input"
  },
  {
    id:6,
    label:'Номер свидетельства об аккредетации',
    key:'',
    type:"input"
  },
  {
    id:7,
    label:'Наименование OC',
    key:'',
    type:"input"
  },
  {
    id:8,
    label:'Номер свидетельства об аккредетации',
    key:'',
    type:"input"
  }
]
export const productApplicationDTOListObject={
  numberOfOrder: "",
  codeOfProduct: "",
  applicant:"",
  productNames: [],
  normativeNames: [],
  extraProtocol: "",
  summaryAttachmentId: 1
}