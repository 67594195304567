import "./deadline.css";
import '../task/task.css'
import { useNavigate } from "react-router-dom";
import { useEffect, useState,useContext } from "react";
import CardItem from "src/Component/Cards/Cardbox/CardItem";
import Time from "src/Component/Time/Time";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
import { UserContext } from "src/App";
import { SmileOutlined } from '@ant-design/icons';
import { Button, Result,Empty} from 'antd';
function Deadline() {
  const {setheaderTitle,dangerApplicant}=useContext(UserContext)
  const navigate=useNavigate()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  async function getData(){
    setLoading(false)
    const Data=await RequestToken('/order/acceptedOrders?page=0&size=100',"GET",null)
    if(Data.success){
       setLoading(true)
       setData(Data)
    }  
 }
 useEffect(()=>{
     setheaderTitle("Hабор утверждений, срок действия которых близок к завершению")
   getData()
 },[])
  return (
    <div className="w-full h-full flex items-center justify-center">
      {
        loading ?  <div className="vazifalar ">
        <div className="vazifar_wrapper_row">
          {dangerApplicant?.length>0 ? <div className="vazifa_row grid  grid-cols-4">
            {
             dangerApplicant?.map((item:any, index:number) => {
                return (
                    <CardItem location={{pathname:'/dashboard',hash:'',search:''}} 
                     data={item} key={index}/>
                );
              })
            }
          </div>:<Empty description="Hет в наличии"/>
          }</div>
      </div>:<Loading/>
      }
    </div>
  );
}
export default Deadline;
