import { Modal,Select } from "antd"
import {props,parm} from './indexdata'
import { useEffect, useState } from "react"
import { RequestToken } from "src/Hook/Request/Request"
function UpdateModal({isOpenModal,setIsOpenModal,Save,listkey,solution_list}:props){
    const [value,setValue]=useState('')
    const Saved=(e: React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault()
       Save(value)   
       setValue("")       
      }
   
    return (
        <Modal centered footer={null} open={isOpenModal}
        closeIcon={false}>
        <form action="" onSubmit={Saved} className="w-96 ">
          <label className="w-full my-2.5" htmlFor="number_one">
            Номер решения ОС ТСТ
          </label>
          <Select className="w-full my-2" showSearch={true} options={solution_list} onChange={(value:string)=>setValue(value)}/>
          {/* <label className="w-full my-2.5" htmlFor="number_two">
            Номер решения ОС ТСТ
          </label>
          <Input
            className="w-full"
            type="text"
            name="number_two"
            id="number"
            required
          /> */}
          <div className="w-full flex justify-end mt-3">
            <button
              type="button"
              onClick={() => setIsOpenModal(false)}
              className="bg-gray-300 mx-2 px-4 py-2 text-gray-800 text-sm rounded-lg"
            >
              Отменить
            </button>
            <button
              type="submit"
              className="bg-blue-700  px-4 py-2 text-[#fff] text-sm rounded-lg"
            >
              Сохранить
            </button>
          </div>
        </form>
      </Modal>
    )
}
export default UpdateModal