import React from "react";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";

interface props {
  handleChange: (parm: string, name: string,id:number) => void;
  name: string;
  id?:number,
  disabled?:boolean,
  style?:{
    width:string,
    height:string
    borderRadius?:string
    border?:string
  }
  placeholder?:string
}
const DataPicker = ({ handleChange, name,id,style,placeholder,disabled}: props) => {
  const onChange: DatePickerProps["onChange"] = (date, parm) => {
    if(id!==undefined){
      handleChange(parm, name,id);
    }
    else {
      handleChange(parm, name,1);
    }
  };
  return (
    
      <DatePicker
      disabled={disabled}
      allowClear
        style={style ? style:{ width: "350px", height: "45px" }}
        onChange={onChange}
        placeholder={placeholder}   
      />
      );
};

export default DataPicker;
