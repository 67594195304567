import axios from "axios";
const BaseURL=process.env.REACT_APP_BASE_URL

export  const Request= async (url,method,data)=>{
    try{
        const Data=await axios({
            url:BaseURL+url,
            method:method,
            data:data,
            headers:{
                 "Content-Type":"application/json",
                 'Accept':'*/*',
            }
        }).then(res=>{
          return res.data;
        })
        return Data;
    }
    catch(err){
       return err;
    }
};
export  const RequestToken= async (url,method,data,headerType)=>{
    try{
        const Data=await axios({
            url:BaseURL+url,
            method:method,
            data:data,
            
            headers:{
                 "Content-Type":headerType===undefined ? "application/json":"multipart/form-data",
                 'Accept':'*/*',
                 'ngrok-skip-browser-warning': 'true' ,
                 "Authorization":`Bearer ${localStorage.getItem('_token')}`
            }
        }).then(res=>{
          return res.data;
        })
        return Data;
    }
    catch(err){
        return err
    }
};
export  const RequestTokenFile= async (url,method,data,headerType)=>{
    try{
        const Data=await axios({
            url:BaseURL+url,
            method:method,
            responseType:'blob',
            headers:{
                 "Authorization":`Bearer ${localStorage.getItem('_token')}`
            }
        }).then(res=>{
          return res.data;
        })
        return Data;
    }
    catch(err){
        return err
    }
};
