import { Empty, Select, Switch } from "antd";
import { UserContext } from "src/App";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { step_act_data_type,res_act_data_type,userObject } from "./indexData";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import moment from "moment";
interface props {
  type?: string;
  user_list: { label: string; value: number }[];
}
function StepActOfLaw({ user_list, type }: props) {
  const { id } = useParams();
  const { setheaderTitle } = useContext(UserContext);
  const navigate = useNavigate();
  const [test_protocol_list,setTest_protocol_list]=useState<{label:string,value:number}[]>([])
  const [res_data, setRes_data] = useState<res_act_data_type[]>([]);
  const [step_act_data, setStep_act_data] = useState<step_act_data_type>({
    statusOfProduct: false,
    orderId: 0,
    testProtocolId:0,
    userIds: [],
  });
  function error(data: any) {
    if (data.response?.status == 401 || data.response?.status === 403) {
      navigate("/");
    } else {
      Swal.fire({
        title: data.response?.data?.message || data.response?.message,
        icon: "error",
      });
    }
  }
  async function SavedACT() {
    const response = await RequestToken(
      "/actOfWriteOff",
      "POST",
      step_act_data
    );
    if (response.success) {
      Swal.fire({
        title: response.message || response.response?.message,
        icon: "success",
      });
      navigate(`/home/${id}`);
    } else {
      error(response);
    }
  }
  async function getActOfWrite() {
    const response = await RequestToken(`/actOfWriteOff/${id}`, "GET", null);
    if (response.success) {
      setRes_data(response.object?.map((item:res_act_data_type,index:number)=>{
        return {
          applicant:item?.applicant,
          orderDate:moment(item?.orderDate).format('DD-MM-YYYY'),
          actOfSelectionDate:moment(item?.actOfSelectionDate).format('DD-MM-YYYY'),
          testingProtocolDate:moment(item?.testingProtocolDate).format('DD-MM-YYYY'),
          statusOfProduct:item?.statusOfProduct,
          orderNumber:item?.orderNumber,
          users:item?.users?.map((item:{firstName:string,lastName:string})=>{return {firstName:item.firstName,lastName:item.lastName}}),
        }
      }))
    }
  }
  async function get_test_protocol_list(){
    const response_consulution=await RequestToken(`/testingProtocol/byOrderId/${id}`,"GET",null)
    if(response_consulution.success){
      setTest_protocol_list(response_consulution.object?.map((item:{numberOfProtocol:string,id:number},index:number)=>{
        return {label:item.numberOfProtocol,value:item.id}
      }))
    }
    else{error(response_consulution)}
  }
  useEffect(() => {
    setheaderTitle("АКТ О СПИСАНИИ (ВОЗВРАТЕ) ПРОДУКЦИИ");
    setStep_act_data({ ...step_act_data, orderId: parseInt(id || "1") });
    if (type !== "edit") {
      getActOfWrite();
    }
    else{
      get_test_protocol_list()
    }
  }, []);
  console.log(res_data)
  return (
    <div className="w-full " style={{ height: "650px" }}>
      <div className="w-full pt-2" style={{ height: "90%" }}>
        <div className="w-full  py-2 px-3 my-2 flex ">
          <div
            className="h-11 flex items-center border-r-2 border-[#ccc] text-base"
            style={{ width: "40%" }}
          >
            Был ли продукт поврежден во время пробного периода?
          </div>
          <div
            className="h-11 flex items-center text-base justify-start px-3"
            style={{ width: "60%" }}
          >
            {type === "edit" ? (
              <Switch
                className="bg-[#ccc]"
                checked={step_act_data.statusOfProduct}
                onChange={(checked: boolean) =>
                  setStep_act_data({
                    ...step_act_data,
                    statusOfProduct: checked,
                  })
                }
              />
            ) : step_act_data.statusOfProduct ? (
              "Проводится"
            ) : (
              "Hе проводится"
            )}
          </div>
        </div>
        <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2  border-[#ccc]"
            style={{ width: "40%" }}
          >
            От изготовителя (заказчика)
          </div>
          <div className=" flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
            {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={user_list}
                mode="multiple"
                onChange={(value: number[]) =>
                  setStep_act_data({ ...step_act_data, userIds: value })
                }
              />
            ) : res_data?.length>0 ? res_data?.map((item:res_act_data_type,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):"ad"
             }):<Empty description="Нет в наличии"/>}
          </div>
        </div>
        {
          type==='edit' ? <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2 border-[#ccc]"
            style={{ width: "40%" }}
          >
            Пожалуйста, выберите протокол тестирования:
          </div>
          <div className="text-base flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
          {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={test_protocol_list}
                onChange={(value:number)=>setStep_act_data({...step_act_data,testProtocolId:value})}
              />
            ) : (
              res_data?.length>0 ? res_data?.map((item:res_act_data_type,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):""
             }):<Empty description="Нет в наличии"/>
            )}
          </div>
        </div>:null
        }
      </div>
      {type === "edit" ? (
        <div
          className="w-full flex justify-end py-2 px-3 items-end"
          style={{ height: "10%" }}
        >
          <button
            className="px-3 py-2 rounded-md bg-[#4096ff] text-[#fff] text-md"
            onClick={SavedACT}
          >
            Сохранять
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default StepActOfLaw;
