import {useEffect} from 'react'
import { Select } from 'antd';
interface options {
  label:string,
  value:string|boolean|number
}
interface props {
   data:any,
   style:Object ,
   name:string,
   id?:number,
   placeholder?:string
   HandleChange:(parm:string,name:string,id:number,type?:string)=>void,
   value?: string 
   type?: string 
}
export default function SelectIndicator({data,style,HandleChange,name,id,placeholder,value,type}:props) {
  function handleChange(parm:string){
    HandleChange(parm,name,id||0,type||"")
  }
  return (
    <Select
    allowClear
      placeholder={placeholder ? placeholder:"Выбирать..."}
      style={style}
      value={value}
      onChange={(value)=>handleChange(value)}    
      options={data? data:[{label:"ksd",value:'daskl'}]}
    />
  )
}
