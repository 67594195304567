import { Checkbox, Input,Empty,Switch } from 'antd'
import '../../form/FormStep.css'
import { useState ,useEffect,useContext} from 'react'
import { RequestToken } from 'src/Hook/Request/Request'
import { useNavigate } from 'react-router-dom'
import Loading from 'src/Component/Loading/Loading'
import { stepOne,stepOneType,response_statement } from './steponedata'
import moment from 'moment'
import { UserContext } from 'src/App'
import Swal from 'sweetalert2'
interface option {
  label:string,
  value:string}
interface props {
    Id:string
    type:string,
    modalOpen:boolean,
    // product_list:option[]
    setOpenModal:(modalOpen:boolean)=>void
}
function Product({Id,type,modalOpen,setOpenModal}:props){
    const navigate=useNavigate()
    const {setheaderTitle}=useContext(UserContext)
    const [data,setData]=useState<response_statement[]>([])
    const [product_List,setProduct_List]=useState<option[]>([])
    const [loading,setLoading]=useState<boolean>(true)
    async function GetData(){
        setLoading(false)
        const Data= await RequestToken(Id,"GET",null)
        if(Data.success){
           setData({...Data.object})
           setLoading(true)
        }
        else if(!Data.response?.data?.success){
        Swal.fire({
          title:"Ошибка",
          text:Data?.response?.data?.message||Data?.response?.message,
          icon:'error'
        })
        }
    }
    useEffect(()=>{
    GetData()
      setheaderTitle('Основная информация')
    },[])
    return (
        <div className="formlist_wrapper">
        {
          loading ? 
          data ?  <>
         {stepOne.map((item:stepOneType,index:number)=>{
          return (
            <div className={type === 'info' ? "formlist_wrapper_row":"formlist_wrapper_row_null"}>
            <div className="formlist_wrapper_row_left">{item.label}</div>
            <div className="formlist_wrapper_row_right">{(data as any)[item.key] ? Array.isArray((data as any)[item.key]) ? (data as any)[item.key].length>0 ? (data as any)[item.key].map((item:{lastName:string,firstName:string},indexUser:number)=>{
              return (
                <div key={indexUser} className="w-full block border-b-2 border-[#ccc] mb-2 mt-1">
                  {item.lastName} {item.firstName}
                </div>
              )
            }):"Сотрудник не привязан":((item.key).toLocaleLowerCase().includes('date') ?  moment((data as any)[item.key]).format('DD-MM-YYYY'):(data as any)[item.key]):"Нет в наличии"}</div>
           </div>
          )
         })}         
         <div>
         </div>
          </>:<Empty/>:
          <Loading/>
           }
        </div>
    )
}
export default Product;