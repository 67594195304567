import './card.css'
interface props {
    // avatar:()=>void,
    count:number,
    value:string
}
function Cards({count,value}:props){
     
    return(
        <div className="admin-dashboard-card">
           <div className="admin-dashboard-card-icon">
               <span>{}</span>
           </div>
           <div className="admin-dashboard-card-title">
              <div>project</div>
              <div>3</div>
           </div>
        </div>
    )
}
export default Cards;