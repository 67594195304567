import {useEffect, useState} from 'react'
import {ListRegis } from "./indexdata";
import Sample from "src/Component/Laborotry/Sample";
interface props {
   statmentByNumber: any,
   GetJournalData:()=>void,
   setShowWizzard:(showWizzard:boolean)=>void
}
function Wizzard({statmentByNumber,setShowWizzard,GetJournalData}:props){
   const [listRegisData,setlistRegisData]=useState<ListRegis>({
        solutionNumber: "",
        nameOfOrganization: "",
        dateOfReceiving: "",
        nameOfPassingPerson: "",
        nameOfProduct: "",
        typeOfProduct: "",
        manufacturer: "",
        serialNumber: "",
        amount: 0,
        nameOfReceivingPerson: "",
        dateOfIssuing: "",
        isAccepted: false,
        reasonOfRejection: "",
        anotherNotes: ""
      })
    function HandleChange(parm: string|boolean|string[]|number[], name: string, id: number,type?:string,stepID?:number){
       if(type==="number" && typeof parm !== 'boolean'&& typeof parm !=='object'){
        setlistRegisData({...listRegisData,[name as keyof ListRegis]:parseInt(parm)})
       }
       else if(typeof parm==='string'){
        setlistRegisData({...listRegisData,[name as keyof ListRegis]:parm})
       }
       else {
         setlistRegisData({...listRegisData,[name as keyof ListRegis]:parm})
       }
    }
    useEffect(()=>{
      listRegisData.solutionNumber=statmentByNumber.number
      listRegisData.nameOfOrganization=statmentByNumber.order.applicant
      listRegisData.nameOfPassingPerson=statmentByNumber.typeOfSolution.ispolnitel
      listRegisData.nameOfReceivingPerson=statmentByNumber.typeOfSolution.ispolnitel
      setlistRegisData({...listRegisData})
    },[])
    return(
        <div className="wizzard_wrapper w-full h-full ">
           <Sample get_list_data={GetJournalData} API='/journal' listRegisData={listRegisData} handleChange={HandleChange} SampleName='listRegis' setShowWizzard={setShowWizzard}/>
        </div>
    )
}
export default Wizzard;
// applicant=nameOfOrganization
// applicant=nameOfOrganization
// applicant=nameOfOrganization
// applicant=nameOfOrganization
// applicant=nameOfOrganization