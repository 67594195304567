import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Checkbox, Empty, Select, Switch } from "antd";
import { requirment,testMethods,normativ,new_test_result_DTO, user_list } from "../../TableWizzard/TableWizzardType";
import { Cancel, Minus } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RequestToken } from "src/Hook/Request/Request";
interface props {
  test_protocol:any,
  setTestProtocol:(test_protocol:any)=>void
  requirment_list:requirment[]
  nameproducts?:{label:string,value:number}[]
  setLoading:(loading:boolean)=>void,
  program_data:any
  user_list:user_list[]
}
function TablesTestReportOne({requirment_list,setTestProtocol,test_protocol,setLoading,nameproducts,program_data,user_list}:props) {
  const navigate=useNavigate()
  const [requirment_List,setRequirment_list]=useState<requirment[]>([])
  const [test_methods,settest_methods]=useState<number[]>([])
  function Add_Requirment(value: number) {
    const new_obj = {
      testResultId: value,
      result: "",
      isSuccess: false,
      requirement: requirment_List.find((item: requirment) => item.id === value),
      normativeList:requirment_List.find((item: requirment) => item.id === value)?.normativeList,
      testMethods: [],
      nameOfProduct: ""
    }; 
    setTestProtocol((prevProtocol:any) => {
      const newTestResultDTOList = [...prevProtocol.testResultDTOList];
      if (nameproducts !== undefined) {
        for (let i = 0; i < nameproducts.length; i++) {
          newTestResultDTOList.push( {
            testResultId:program_data.products[i].testResults.find((item:{id:number,requirement:{id:number}})=>item.requirement.id===value)?.id,
            result: "",
            isSuccess: false,
            requirement: requirment_List.find((item: requirment) => item.id === value),
            normativeList:requirment_List.find((item: requirment) => item.id === value)?.normativeList,
            testMethods: [],
            nameOfProduct: nameproducts[i].label
          });
        }
      }
      return { ...prevProtocol, testResultDTOList: [...newTestResultDTOList] };
    });
  }
  
  function Remove(id:number,keyname:string,indexParm:number){
    setTestProtocol({...test_protocol,testResultDTOList:test_protocol.testResultDTOList.map((item:any,index1:number)=>{
      if(index1===indexParm){
          return {...item,[keyname]:(item as any)[keyname].filter((item:any,index2:number)=>index2!==id)}
      }
      else{ return item}
    })}) 
  }
  function HandleChange(id:number,keyname:string,parm:number){
    setTestProtocol({...test_protocol,testResultDTOList:test_protocol.testResultDTOList.map((item:any,index:number)=>{
      if(index===id){
        return {...item,[keyname]:[...item.testMethods,item.requirement.testMethods.filter((item1:requirment,index:number)=>{
          if(item1.id===parm){
            return item1;
          }
        })[0]]}
      }
      else{return item}
    })})
  }
  function HandleChangeCheck(id:number,parm:boolean){
    setTestProtocol({...test_protocol,testResultDTOList:test_protocol.testResultDTOList.map((item:any,index:number)=>{
      if(id===index){
         return {...item,isSuccess:parm}
      }
      else { return item}
    })})
  }
  function error(response_data: any) {
    if (
      response_data.response.status === "403" &&
      response_data.response.status === 401
    ) {
      navigate("/");
    } else {
      Swal.fire({
        icon: "error",
        title:
          response_data.response.data.message || response_data.response.message,
      });
    }
  }
  async function Save() {
    setLoading(false);
    const res_applanse = await RequestToken(
      "/testingProtocol",
      "POST",
      test_protocol
    );
    if (res_applanse.success) {
      setLoading(true)
      Swal.fire({
        icon: "success",
        title:res_applanse?.message,
      });
    } else {
      setLoading(true);
      error(res_applanse);
    }
  }
  useEffect(()=>{
    for (let i = 0; i < program_data?.products?.length; i++) {
      for (let j = 0; j < program_data.products[i].testResults?.length; j++) {
      
        setRequirment_list([...requirment_List,program_data.products[i].testResults[j].requirement])
      }  
    }
    setTestProtocol({...test_protocol,programId:program_data.id})
  },[])
  console.log(requirment_List)
  console.log(test_protocol)
  return (
    <div className={`${style.TablesTestReportOne_wrapper} flex flex-wrap h-full`}>
     <div className="w-full " style={{minHeight:"90%",maxHeight:"auto"}}>
     <table className={style.TableTestReportOne}>
      <thead>
        <tr>
          <th >
            <span className="w-full flex"><Checkbox className="mr-2"/> №</span>
          </th>
          <th>Наименование проверяемых параметров и их характеристики</th>
          <th>Пункты НД на требования</th>
          <th>Пункты НД на требования</th>
          <th>
            Результат испытаний (фактические значения с учётом Ur или описание
            характеристики )
          </th>
          <th>
          Результат испытаний (фактические значения с учётом Ur или описание характеристики )
          </th>
          <th>Соответствие требованию НД и/ИЛИ № испытанного образца</th>
        </tr>
      </thead>
      <tbody>
          {
            test_protocol.testResultDTOList.map((item:any,index:number)=>{
                return(
                <tr key={index}>
                <td >
                  <span className="w-full flex">
                  <Checkbox className="mr-2" />{index+1}
                  </span>
                </td>
                <td>{item.requirement.name}</td>
                <td>
                  {item.requirement.normativeList?.map((itemNormativ:{normativeNumber:string})=>{
                    return (
                      <div className={`${style.TableTestReportOne_result} w-full my-2 `}>{itemNormativ.normativeNumber}</div>
                    )
                  })}
                </td>
                {/* jadvalni normativ qismi */}
                <td>
                  {item.testMethods.length>0 ? item.testMethods.map((itemTest:testMethods,index1:number)=>{
                      return (<div style={{width:'120px'}} className={`${style.TableTestReportOne_result} w-full my-2 `}>
                            {itemTest.name}
                           <span onClick={()=>Remove(index1,'testMethods',index)} className={`${style.TableTestReportOne_result_delete} `}><Cancel/></span>
                        </div>
                      )
                  }):<Empty description="Метод испытания не выбран"/>}
                    <Select className="w-4/5" options={item.requirement.testMethods?.map((item:{name:string,id:number})=>{
                      return {label:item.name,value:item.id}
                    })} onChange={(value:number)=>HandleChange(index,'testMethods',value)}/>
                   
                </td>
                <td>  
                  {item.normativeList?.map((itemNormativ:{description:string})=>{
                    return (
                      <div className={`${style.TableTestReportOne_result} w-full my-2 `}>{itemNormativ.description}</div>
                    )
                  })}
                </td>
                <td>
                {item.testMethods.length>0 ? item.testMethods?.map((itemNormativ:any,index1:number)=>{
                      return (
                        <div  className={`${style.TableTestReportOne_result} w-full my-2 `}>
                        {itemNormativ.fullName}
                       <span onClick={()=>Remove(index1,'testMethods',index)} className={`${style.TableTestReportOne_result_delete} `}><Cancel/></span>
                    </div>
                      )
                  }):<Empty description="Метод испытания не выбран"/>}
                </td>
                <td>
                  <div className={`w-full flex`}>
                    <div className="w-2/3 mb-7 flex justify-start">{item.nameOfProduct}</div>
                    <div className="w-1/3 mb-7 flex justify-center">
                      <Switch className="bg-[#ccc]" onChange={(checked:boolean)=>HandleChangeCheck(index,checked)} checked={item.isSuccess}/>
                    </div>
                  </div>
                </td>
              </tr>
            )
            })
          }
          <tr>
            <td colSpan={7} ><Select className="w-1/3" onChange={(value:number)=>Add_Requirment(value)} options={requirment_List.map((item:requirment)=>{
              return {label:item.name,value:item.id}
            })}/><span className="mt-3 ml-4 text-xl">Выбор Требования</span></td>
          </tr>        
      </tbody>
    </table>
     </div>
     <div></div>
    <div className="w-full  flex items-end justify-end pb-2" style={{height:"10%"}}>
            <div className="w-1/2 pl-2"><Select className="w-96 h-11" placeholder={'Назначение сотрудников'} options={user_list} mode="multiple" onChange={(value:number)=>setTestProtocol({...test_protocol,userIds:value})}/></div>
            <div className="w-1/2 flex justify-end pr-2"><button className="px-3 py-2 rounded-lg border-2 mr-2">Отменить</button>
              <button  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white" onClick={Save}>Сохранить</button></div>
           </div>
    </div>
  );
}
export default TablesTestReportOne;
