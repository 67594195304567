import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  normativ_list_type,
  test_method_list,
} from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal, Input, Switch, DatePicker } from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import { UserContext } from "src/App";
import moment from "moment";
import Loading from "src/Component/Loading/Loading";
interface props {
  loading:boolean,
  appilance_list:appilance_list[],
  getApplianceList: () => void;
  setLoading:(loading:boolean)=>void
}
interface new_appilance {
  name: string;
  manufacturer: string;
  serialNumber: string;
  dateOfCertification: string;
}
interface appilance_list {
  id:number;
  name:string;
  manufacturer: string;
  serialNumber: string;
  dateOfCertification:string;
}
const { TextArea } = Input;
function Appilance({loading,setLoading,appilance_list,getApplianceList}:props) {
  const navigate = useNavigate();
  const { setheaderTitle } = useContext(UserContext);
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number | null>(null);
  const [new_appilance, setNew_Appilance] = useState<new_appilance>({
    name: "",
    manufacturer: "",
    serialNumber: "",
    dateOfCertification: "",
  });
  function error(response: any) {
    setLoading(true)
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotifaction(id: number) {
    Swal.fire({
      title: "Осторожность",
      icon: "warning",
      text: "Вы уверены, что хотите удалить эту прибор?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: "Отмена",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  }
  function Cancel() {
    setOpenModal(false);
    setNew_Appilance({
      ...new_appilance,
      name: "",
      dateOfCertification: "",
      manufacturer: "",
      serialNumber: "",
    });
  }
  function UpdateopenModal(id: number) {
    setNew_Appilance(
      appilance_list?.filter((item: appilance_list) => item.id === id)[0]
    );
    setOpenModal(true);
    setUpdateId(id);
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    setLoading(false)
    e.preventDefault();
    const res_data = await RequestToken(
      updateId ? `/appliance/${updateId}` : `/appliance`,
      updateId ? "PUT" : "POST",
      new_appilance
    );
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      getApplianceList();
    } else {
      error(res_data);
    }
  }
  async function Delete(id: number) {
    setLoading(false)
    const res_list = await RequestToken(`/appliance/${id}`, "DELETE", null);
    if (res_list.success) {
      getApplianceList();
      Swal.fire("Удалено!", "", "success");
    } else {
      error(res_list);
    }
  }
  useEffect(() => {
    setheaderTitle("Список прибор");
    
  }, []);
  return (
    <div className="w-full h-full flex items-center justify-center">
      {loading ? <div className="w-full h-full overflow-auto">
      <div className={_role==='ADMIN_OF_LABORATORY' ? "hidden":"w-full mb-2"}>
        <button className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
          onClick={() => setOpenModal(true)}>
          Добавить нового прибор
          <Add />
        </button>
      </div>
      <table className="w-full h-auto">
        <thead>
          <tr>
            <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">
              №
            </th>
            <th className="bg-[#F2F4F8] text-left p-3">Наименование СИ и ИО</th>
            <th className="bg-[#F2F4F8] text-left p-3">Тип (фирма изготовитель)</th>
            <th className="bg-[#F2F4F8] text-left p-3">Заводской (серийный) номер</th>
            <th className="bg-[#F2F4F8] text-left p-3">Дата очередной калибровки, поверки СИ и/или аттестации ИО</th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {appilance_list?.length > 0 ? (
            appilance_list?.map((item: appilance_list, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{index+1}</td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{item.name}</td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{item.manufacturer}</td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{item.serialNumber}</td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{item.dateOfCertification ? moment(item.dateOfCertification).format('DD-MM-YYYY'):<span className="text-[red]">Дата сертификации не указана</span>}</td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl text-[red] cursor-pointer"
                      onClick={() => DeleteNotifaction(item.id)}
                    >
                      <DeleteOutlined />
                    </span>
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl cursor-pointer"
                      onClick={() => UpdateopenModal(item.id)}
                    >
                      <EditOutlined />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>:<Loading/>}
    <Modal centered open={openModal} footer={null} onCancel={Cancel}>
    <div className="w-full font-semibold text-2xl">
      {updateId ? "Изменить прибор" : "Добавить нового прибор"}
    </div>
    <form action="test_method" onSubmit={Saved}>
      <label className="w-full block mt-2" htmlFor="name">
        Наименование СИ и ИО
      </label>
      <Input
        id="name"
        required
        className="h-11"
        placeholder=""
        value={new_appilance.name}
        onChange={(e) =>
          setNew_Appilance({ ...new_appilance, name: e.target.value })
        }
      />
      <label className="w-full block mt-2" htmlFor="manufacturer">
        Тип (фирма изготовитель)
      </label>
      <Input
        id="manufacturer"
        required
        className="h-11"
        placeholder=""
        value={new_appilance.manufacturer}
        onChange={(e) =>
          setNew_Appilance({
            ...new_appilance,
            manufacturer: e.target.value,
          })
        }
      />
      <label className="w-full block mt-2" htmlFor="serialNumber">
        Заводской (серийный) номер
      </label>
      <Input
        id="serialNumber"
        required
        className="h-11"
        placeholder=""
        value={new_appilance.serialNumber}
        onChange={(e) =>
          setNew_Appilance({
            ...new_appilance,
            serialNumber: e.target.value,
          })
        }
      />
      <label className="w-full block mt-2" htmlFor="dateOfCertification">
        Дата очередной калибровки, поверки СИ и/или аттестации ИО
      </label>
      <DatePicker
        className="h-11"
        id="dateOfCertification"
        onChange={(date, dateValue) =>
          setNew_Appilance({
            ...new_appilance,
            dateOfCertification: dateValue,
          })
        }
      />
      <div className="w-full flex justify-end my-2">
        <button
          onClick={Cancel}
          type="button"
          className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
        >
          Отмена
        </button>
        <input
          type="submit"
          id="test_method"
          value={"Сохранить"}
          className="bg-[#1677FF] cursor-pointer rounded-md text-[#fff] px-3 py-2"
        />
      </div>
    </form>
  </Modal>
    </div>
  );
}
export default Appilance;
