import { useEffect,useState } from "react";
import { RequestToken } from "../../Hook/Request/Request"
import { Select } from "antd";
const { Option } = Select;
interface data {
    id:number,
    name:string,
}
interface props{
    name:string,
    id?:number,
    type?:string,
    value?:string
    handleChange:(value:string,name:string,id?:number,type?:string) => void;
}
function MeasurmentID({name,handleChange,id,type,value}:props){
    const [data,setdata]=useState<data[]>([]);
    const getData=async()=>{
        const Data=await RequestToken('/measurement?page=0&size=50',"GET",null);
        if(Data.success){
            setdata(Data.object);
        }
    }
    const HandleChange=(value:string)=>{
      handleChange(value,name,id,type);
    }
    useEffect(() => {
        getData()
    },[])
    
    return(
      <div>
        <Select
        style={{ width: 350 ,height: "45px" }}
        value={data.filter(item=>item.id===parseInt(value?value:'1'))[0]?.name}
        onChange={HandleChange}
        >
         {
            data.map((item,index)=>{
                return(
                    <Option key={index} value={(item.id).toString()}>{item.name}</Option>
                )
            })
         }
        </Select>
      </div>
    )
}
export default MeasurmentID;