import style from "./style.module.css";
import { Input, Modal, Pagination } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Plus } from "src/assets/icons/laboratory";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "src/App";
import {
  ModalContent,
  modalcontentObject,
  data,data_res
} from "./indexdata";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import Loading from "src/Component/Loading/Loading";
import InputMaskReact from "src/Component/MaskInput/Inputmask";
import { TranslateErrorMessage } from "src/lang/translateErrorMessage";
function OrganizationData() {
  const _role=localStorage.getItem('_role')
  const { setheaderTitle } = useContext(UserContext);
  const [data, setdata] = useState<data>({
    nameOfCertificationOrgan: "",
    numberRegister: "",
    address: "",
    phone: "",
    email: "",
    website: "",
    name: "",
  });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [update_modal,setUpdateModal]=useState<number|null>(null)
  const [total, setTotal] = useState<number>(10);
  const [organazation_data_list, setOrnazation_data_list] = useState<data_res[]>(
    []
  );
  function UpdateModal(id:number){
    const new_obj=organazation_data_list.find((item:data_res)=>item.id===id)
   if(new_obj){
    setdata({...data,address:new_obj.address,email:new_obj.email
      ,name:new_obj.name,nameOfCertificationOrgan:new_obj.nameOfCertificationOrgan,numberRegister:new_obj.numberRegister,phone:new_obj.phone,website:new_obj.website})
    setUpdateModal(id)
    setIsOpenModal(true)
   }
  }
  function DeleteNotifaction(id: number) {
    Swal.fire({
      title: "Осторожность",
      icon: "warning",
      text: "Вы уверены, что хотите удалить эту организацию?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: "Отмена",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  }
  async function Save(e: React.FormEvent) {
    e.preventDefault();
    setLoading(false)
    if(data.email.includes('@') && data.email.includes('.') ){
      setIsOpenModal(false);
      const responsedata = await RequestToken(
        update_modal ? `/certificationOrgan/${update_modal}`:"/certificationOrgan",
        update_modal ? "PUT":"POST",
        data
      );
      if (responsedata.success) {
          get_organastion_data()
        
        Cancel()
        Swal.fire({
          title: responsedata.message,
          icon: "success",
        });
      } else {
          Swal.fire({
            title:'Ошибка',
            timer:5000,
            text:TranslateErrorMessage(responsedata?.response?.data?.message||responsedata?.response?.message),
            icon:"error"
            });
      }
    }
    else{
      Swal.fire('Предупреждение','Адрес электронной почты недействителен','warning')
    }
  }
  async function Delete(id:number){
    setLoading(false)
    const responseData=await RequestToken(`/certificationOrgan/${id}`,'DELETE')
    if(responseData.success){
     setLoading(true)
    get_organastion_data()
    Swal.fire({
        title:responseData.message,
        icon:'success'
    })
    
    }
    else {
        Swal.fire({
            title:'Ошибка',
            timer:5000,
            text:responseData?.response?.data?.message||responseData?.response?.message,
            icon:"error"
        })
    }
  }
  function Cancel() {
    setdata({
      address:'',
      email:'',
      name:'',
      nameOfCertificationOrgan:'',
      numberRegister:'',
      phone:'',
      website:''
    })
    setIsOpenModal(false);
    setUpdateModal(null)
  }
  async function get_organastion_data() {
    setLoading(false)
    const response_data = await RequestToken(
      `/certificationOrgan?page=${currentPage - 1}&size=${total}`
    );
    if (response_data.success) {
      setOrnazation_data_list(response_data.object);
      setLoading(true)
    }
  }
  function handleChange(parm: string, name: string, id?: number | undefined){
    setdata({...data,[name]:parm})
  }
  useEffect(() => {
    setheaderTitle("Добавление новых стандартов");
    get_organastion_data();
  }, []);
  return (
    <div className="w-full h-full px-5">
      <div className="w-full flex justify-between" style={{ height: "8%" }}>
        <div className={style.OrganizationData_header_data_add_title}>
          Добавьте новую организацию
        </div>
        {_role==='EMPLOYEE_LABORATORY' ? <div
          className={style.OrganizationData_header_data_add_button}
          onClick={() => setIsOpenModal(true)}
        >
          <span className="mr-2">
            <Plus />
          </span>
          <p>Добавить</p>
        </div>:null}
      </div>
      <div
        className="w-full flex h-auto pt-3 border-b-2 border-[#ccc]"
        style={{ height: "6%" }}
      >
        <div className={style.OrganizationData_tab_label}>
          Данные организации
        </div>
      </div>
      <div className="w-full flex items-center justify-center " style={{ height: "86%" }}>
       {loading ? <div className="w-full h-full"> <div className="w-full " style={{ height: "94%" }}>
          <table className={style.OrganizationData_table}>
            <thead>
              <tr>
                <th className={style.OrganizationData_table_th_id}>№</th>
                <th className={style.OrganizationData_table_th}>
                  Электронная почта
                </th>
                <th className={style.OrganizationData_table_th}>
                  Номер телефона
                </th>
                <th className={style.OrganizationData_table_th}>Адрес</th>
                <th className={style.OrganizationData_table_th}>Веб-сайт</th>
                <th className={style.OrganizationData_table_th}>
                  Регистр номера
                </th>
                <th className={style.OrganizationData_table_th}>
                  Название органа по сертификации
                </th>
                <th className={_role==='ADMIN_OF_LABORATORY' ? style.OrganizationData_table_th:'hidden'}></th>
                <th className={_role==='ADMIN_OF_LABORATORY' ? style.OrganizationData_table_th:'hidden'}></th>
              </tr>
            </thead>
            <tbody>
              {organazation_data_list.map((item: data_res, index: number) => {
                return (
                  <tr key={index}>
                    <td className={style.OrganizationData_table_td_id}>{index+1}</td>
                     {
                        ModalContent.map((item1:modalcontentObject,index1:number)=>{
                            return (
                                <td key={index1} className={style.OrganizationData_table_td}>
                                    {(item as any)[item1.name]}
                                </td>
                            )
                        })
                     }
                    <td className={_role==='ADMIN_OF_LABORATORY' ? style.OrganizationData_table_td_id:'hidden'}>
                     <span className="p-3 text-2xl text-[red] cursor-pointer" onClick={()=>DeleteNotifaction(item.id)}> <DeleteOutlined /></span>
                    </td>
                    <td className={_role==='ADMIN_OF_LABORATORY' ? style.OrganizationData_table_td_id:'hidden'}>
                     <span className="p-3 text-2xl  cursor-pointer" onClick={()=>UpdateModal(item.id)}> <EditOutlined /></span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-end " style={{ height: "6%" }}>
          <Pagination defaultCurrent={1} total={50} />
        </div></div>:<Loading/>}
      </div>
      <Modal open={isOpenModal} centered footer={null} onCancel={Cancel}>
        <form action="step_seven" onSubmit={Save}>
          <div className="w-full mb-4 mt-2 text-xl font-semibold">
           Добавьте новую организацию
          </div>
          <div className="w-full">
            {ModalContent.map((item: modalcontentObject, index: number) => {
              return (
                <div key={index} className="w-full flex justify-between">
                  <label className="w-1/2" htmlFor={item.name}>
                    {item.label}
                  </label>
                  {item.name==='phone' ?
                  <InputMaskReact  className="w-1/2 my-2 h-8" handleChange={handleChange}  name={item.name} value={(data as any)[item.name]} key={index}/>:<Input
                    required
                    placeholder={item.placholder}
                    name={item.name}
                    type={item.type}
                    onChange={(e) => {
                      setdata({ ...data, [item.name]: e.target.value });
                    }}
                    value={(data as any)[item.name]}
                    className="w-1/2 my-2"
                  />}
                </div>
              );
            })}
          </div>
          <div className="w-full flex justify-end mt-3">
            <button
            type="button"
              className="px-3 py-2 rounded-md border-[#e5e7eb] border-2 mx-2"
              onClick={Cancel}
            >
              Отмена
            </button>
            <input
              type="submit"
              className="px-3 cursor-pointer py-2 rounded-md bg-[#1a56db] text-[#fff]"
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}
export default OrganizationData;
