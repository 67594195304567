import moment from 'moment';
import './Styledocx.css'
import {useState,useRef,useEffect} from 'react';
import { useReactToPrint } from 'react-to-print';
import { RequestToken } from 'src/Hook/Request/Request';
import {act_doc_type,productPDF} from 'src/pages/steps/stepthree/StepThreeType'

const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
interface props {
  orderID: string;
  setDownload: (download: boolean) => void;
}
export default  function StepThreeDocs({orderID,setDownload}:props){
  const space = (
    <>
      {" "}
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
    </>
  );
  const [res_data,setResData]=useState<any>()
  const [loading,setLoading]=useState(true)
  const componentRef:any=useRef()
  async function ACT_document_GET(){
    setLoading(false)
    const response_doc=await RequestToken(`/actOfSelection/doc/${orderID}`,"GET",null)
    if(response_doc.success){
      setResData(response_doc.object)
      HandlePrint()
    }
    setLoading(true)
  }
  const HandlePrint=useReactToPrint({
    content:()=>componentRef.current,
    documentTitle:"АКТ идентификации и отбора образцов ТСТ",
    onAfterPrint:()=>setDownload(false)
  })
useEffect(()=>{
 ACT_document_GET()
},[])
  return (
        <>
        {
          loading ? <div className="WordSection1 w-full h-auto flex flex-wrap actual-receipt" style={{width:'594px'}} ref={componentRef}>
         <div className="w-full" style={{height:'1050px'}}>
          {space}
          {space}
         <table
            className="MsoTableGrid w-full"
            border={1}
            cellSpacing={0}
            cellPadding={0}
            style={{ borderCollapse: "collapse", border: "1px solid #6b6b6b" }}
          >
            <tbody>
              <tr>
                <td
                  width={638}
                  colSpan={2}
                  valign="top"
                  style={{
                    width: "478.55pt",
                    border: "1px solid #6b6b6b",
                    borderBottom: "solid windowtext 1.0pt",
                    padding: "0in 5.4pt 0in 5.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                      Государственное унитарное предприятие «UNICON.UZ»
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                      Система сертификации технических средств телекоммуникаций
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  width={291}
                  valign="top"
                  style={{
                    width: "218.05pt",
                    border: "none",
                    borderBottom: "solid windowtext 1.0pt",
                    padding: "0in 5.4pt 0in 5.4pt"
                  }}
                  className='analis_application'
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                      ОРГАН ПО СЕРТИФИКАЦИИ
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span className='analis_application' lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      ТЕХНИЧЕСКИХ СРЕДСТВ
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span className='analis_application' lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      ТЕЛЕКОММУНИКАЦИЙ
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span className='analis_application' lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      (ОС ТСТ)
                    </span>
                  </p>
                </td>
                <td
                  width={347}
                  rowSpan={2}
                  valign="top"
                  style={{
                    width: "260.5pt",
                    border: "none",
                    padding: "0in 5.4pt 0in 5.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      &nbsp;
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  width={291}
                  valign="top"
                  style={{
                    width: "218.05pt",
                    border: "none",
                    padding: "0in 5.4pt 0in 5.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "10.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "#4D5156",
                        background: "white"
                      }} className='analis_application'
                    >
                      100187, Узбекистан, Ташкент, Карасув-3, ул.Мингбулок, 38.
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="MsoNormal w-full"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
          >
            <span
              lang="RU"
              style={{ fontSize: "5.0pt", fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <table
            className="MsoNormalTable w-full"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width="100%"
            style={{ background: "white", borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td
                  width={629}
                  valign="top"
                  style={{ width: "6.55in", padding: ".75pt .75pt .75pt 1.5pt" }}
                >
                  <span
                    lang="RU"
                    style={{ fontSize: "10.0pt", fontFamily: '"Calibri",sans-serif' }}
                  >
                    <br  style={{ pageBreakBefore: "always" }} />
                  </span>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <strong>
                      <span
                        lang="RU"
                        style={{
                          fontSize: "9.0pt",
                          fontFamily: '"Calibri",sans-serif',
                          color: "black"
                        }}
                      >
                        ‎‎
                      </span>
                    </strong>
                    <b>
                      <span
                        lang="RU"
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black"
                        }} className='analis_application'
                      >
                        АКТ
                      </span>
                    </b>
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      <br />
                      <b>‎идентификации и отбора образцов ТСТ </b>
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <b>
                      <span
                        lang="RU"
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black"
                        }}
                      >
                        « »{" "}
                      </span>
                    </b>
                    <b>
                      <span
                        lang="UZ-CYR"
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black"
                        }} className='analis_application'
                      >
                        {moment(res_data?.actOfSelectionDate).format('DD-MM-YYYY')} 
                      </span>
                    </b>
                    <b>
                      <span
                        lang="UZ-CYR"
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black"
                        }}
                      >
                        {" "}
                      </span>
                    </b>
                    <b>
                      <span
                        lang="RU"
                        style={{
                          fontFamily: '"Times New Roman",serif',
                          color: "black"
                        }}
                      >
                        г.
                      </span>
                    </b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="MsoNormal w-full mt-2 analis_application"
            style={{borderBottom:'1px solid black', textAlign: "center", lineHeight: "normal",fontFamily: '"Times New Roman",serif', color: "#333333"  }} >
            {res_data?.applicantFullName}
          </p>
          <p
            className="MsoNormal w-full analis_application"
            style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}
          >
            <span
              lang="RU" className='analis_application'
              style={{ fontSize: "7.0pt", fontFamily: '"Times New Roman",serif' }}
            >
              наименование изготовителя (заявителя)
            </span>
          </p>
          <p
            className="MsoNormal w-full"
            style={{
              marginBottom: "0in",
              textIndent: "35.45pt",
              lineHeight: "normal"
            }}
          >
            <span
              lang="RU"
              style={{ fontSize: "5.0pt", fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <table
            border={0}
            cellSpacing={0}
            cellPadding={0}
            style={{width:"100%", borderCollapse: "collapse", border: "none !important" }}
          >
            <tbody>
              <tr>
                <td className='analis_application w-1/3' style={{fontSize:'11px'}}> Наименование и адрес организации, где производился отбор образцов </td>
                <td className='analis_application w-2/3' style={{fontSize:'11px'}} colSpan={2}>
                  <span className=' analis_application underline ' style={{fontSize:'11px'}} >{res_data?.nameOfOrganization}</span>
                  <span className='w-full block analis_application text-center' style={{fontSize:'7px'}}>Наименование и адрес организации</span>
                </td>
              </tr>
              <tr>
                <td className='analis_application w-1/3' style={{fontSize:'11px'}}> Цель отбора: испытание продукции ТСТ на соответствие с требованиями НД.</td>
                <td className='analis_application w-2/3' style={{fontSize:'11px'}} colSpan={2}>
                {res_data?.normativeNames?.map((item:any,index:number)=>{
                  return (
                    <span className=' analis_application underline mx-2' style={{fontSize:'11px'}} key={index}>{item}</span>
                  )
                })}
                  <span className='w-full block analis_application text-center' style={{fontSize:'7px'}}>Обозначение НД</span>
                </td>
              </tr>
              <tr>
                <td className='analis_application w-1/3' style={{fontSize:'11px'}}> Дополнительная информация,(при наличии):</td>
                <td className='analis_application w-2/3' style={{fontSize:'11px'}} colSpan={2}>
                  <span className='analis_application block underline w-full' style={{fontSize:'11px'}} >Инвойс №: {res_data?.invoiceNumber}, {moment(res_data?.invoiceDate).format('DD-MM-YYYY')}</span>
                  <span className='analis_application block underline w-full' style={{fontSize:'11px'}} >
                  накладная: {res_data?.invoiceNumber}, {res_data?.deliveryNote}</span>
                  <span className='analis_application block underline w-full text-center' style={{fontSize:'8px'}}>
                  категория рег. номер, наименование технической и/или товаро - соправодительной документации, и т.п.
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="MsoNormal w-full"
            style={{
              marginBottom: "0in",
              textAlign: "center",
              textIndent: "35.45pt",
              lineHeight: "normal"
            }}
          >
            <span
              lang="RU"
              style={{ fontSize: "7.0pt", fontFamily: '"Times New Roman",serif' }}
            >
              &nbsp;
            </span>
          </p>
          <table
            border={1}
            cellSpacing={0}
            cellPadding={0}
            width="100%"
            style={{
              
              borderCollapse: "collapse",
              border: "none"
            }}
          >
            <tbody>
              <tr style={{ height: "16.95pt" }}>
                <td
                  width="3%"
                  rowSpan={2}
                  style={{
                    width: "3.04%",
                    border: "solid windowtext 1.0pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <span
                    lang="RU"
                    style={{
                      fontSize: "7.0pt",
                      fontFamily: '"Times New Roman",serif'
                    }}
                  >
                    <br  style={{ pageBreakBefore: "always" }} />
                  </span>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      №
                    </span>
                  </p>
                </td>
                <td
                  width="35%"
                  rowSpan={2}
                  style={{
                    width: "35.32%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Наименование вида продукции
                    </span>
                  </p>
                </td>
                <td
                  width="7%"
                  rowSpan={2}
                  style={{
                    width: "7.46%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "1.4pt",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Ед.изм
                    </span>
                  </p>
                </td>
                <td
                  width="8%"
                  rowSpan={2}
                  style={{
                    width: "8.88%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Размер
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      партии
                    </span>
                  </p>
                </td>
                <td
                  width="10%"
                  rowSpan={2}
                  style={{
                    width: "10.36%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "1.75pt",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Дата изготов-
                      <br />
                      ления
                    </span>
                  </p>
                </td>
                <td
                  width="15%"
                  rowSpan={2}
                  style={{
                    width: "15.22%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "1.75pt",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Изготовитель
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      (страна и предприятие)
                    </span>
                  </p>
                </td>
                <td
                  width="19%"
                  colSpan={2}
                  style={{
                    width: "19.72%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Количество отобранных образцов
                    </span>
                  </p>
                </td>
                <td
                  width="15%"
                  rowSpan={2}
                  style={{
                    width: "15.22%",
                    border: "solid windowtext 1.0pt",
                    borderLeft: "none",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "16.95pt"
                  }}
                >
                  <span className='analis_application' style={{fontSize:'11px'}}> Серийные номера (идентификационные номеров) отобранных образцов</span>
                </td>
              </tr>
              <tr style={{ height: "30.3pt" }}>
                <td
                  width="9%"
                  valign="top"
                  style={{
                    width: "9.08%",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid windowtext 1.0pt",
                    borderRight: "solid windowtext 1.0pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "30.3pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Для контр.образцов
                    </span>
                  </p>
                </td>
                <td
                  width="10%"
                  valign="top"
                  style={{
                    width: "10.64%",
                    borderTop: "none",
                    borderLeft: "none",
                    borderBottom: "solid windowtext 1.0pt",
                    borderRight: "solid windowtext 1.0pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "30.3pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif'
                      }} className='analis_application'
                    >
                      Для испы-
                      <br />
                      таний
                    </span>
                  </p>
                </td>
              </tr>
               {
                res_data?.productInfoPDFDTOList?.map((item:any,index:number)=>{
                  return (
                    <tr key={index} style={{ height: "23.7pt" }}>
                    <td
                      width="3%"
                      valign="top"
                      style={{
                        width: "3.04%",
                        border: "solid windowtext 1.0pt",
                        borderTop: "none",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontSize: "8.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }} className='analis_application'
                        >
                          {index+1}
                        </span>
                      </p>
                    </td>
                    <td
                      width="35%"
                      valign="top"
                      style={{
                        width: "35.32%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{ marginBottom: "0in", lineHeight: "normal" }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontFamily: '"Times New Roman",serif',
                            color: "#333333",
                            background: "white"
                          }}
                        >
                          &nbsp;{item?.productName}
                        </span>
                      </p>
                    </td>
                    <td
                      width="7%"
                      style={{
                        width: "7.46%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="UZ-CYR"
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{item?.measurementName}
                        </span>
                      </p>
                    </td>
                    <td
                      width="8%"
                      style={{
                        width: "8.88%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{item?.amount}
                        </span>
                      </p>
                    </td>
                    <td
                      width="10%"
                      style={{
                        width: "10.36%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{moment(item?.dateOfProducing).format('DD-MM-YYYY')}
                        </span>
                      </p>
                    </td>
                    <td
                      width="15%"
                      style={{
                        width: "15.22%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{item?.manufacturer}
                        </span>
                      </p>
                    </td>
                    <td
                      width="9%"
                      style={{
                        width: "9.08%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{item?.numberOfSamplesTaken}
                        </span>
                      </p>
                    </td>
                    <td
                      width="10%"
                      style={{
                        width: "10.64%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.0pt",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          &nbsp;{item?.numberOfForTesting}
                        </span>
                      </p>
                    </td>
                    <td
                      width="9%"
                      style={{
                        width: "9.08%",
                        borderTop: "none",
                        borderLeft: "none",
                        borderBottom: "solid windowtext 1.0pt",
                        borderRight: "solid windowtext 1.0pt",
                        padding: "0in 5.4pt 0in 5.4pt",
                        height: "23.7pt"
                      }}
                    >
                      <p
                        className="MsoNormal"
                        style={{
                          marginBottom: "0in",
                          textAlign: "center",
                          lineHeight: "normal"
                        }}
                      >
                        <span
                          lang="RU"
                          style={{
                            fontSize: "10px",
                            fontFamily: '"Times New Roman",serif'
                          }}
                        >
                          {res_data?.serialNumber}
                        </span>
                      </p>
                    </td>
                  </tr>
                  )
                })
               }
            </tbody>
          </table>
         </div>
         <div className="w-full" style={{height:'1050px'}}>
          {space}
          {space}
          <p className="MsoNormal w-full analis_application"  >
          <span lang="RU" className='w-full block analis_application' style={{ fontSize:'11px',fontFamily: '"Times New Roman",serif' }} >
                Результат наружного осмотра партии:{" "}<span className=' analis_application' style={{fontSize:'11px'}}>{res_data?.result}</span>
              </span>
              <span className='w-full block analis_application' style={{ fontSize:'11px',fontFamily: '"Times New Roman",serif' }} >состояние упаковки, маркировки</span>
          </p>
         <div className="w-full">
         <span className='w-full block analis_application'  lang="RU" style={{fontSize:'11px', fontFamily: '"Times New Roman",serif' }}>
                состояние упаковки единицы: <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.statusOfPacked}</span>
              </span>
         </div>
         
          <p
            className="MsoNormal w-full"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "normal"
            }}
          >
            <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
              Подписи:
            </span>
          </p>
          <table
            className="MsoTableGrid w-full"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            style={{ borderCollapse: "collapse", border: "none" }}
          >
            <tbody>
              <tr style={{ height: "18.75pt" }}>
                <td
                  width={279}
                  valign="top"
                  style={{
                    width: "209.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "18.75pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "9.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      ‎‎
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      От изготовителя (заказчика
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontSize: "10.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      )
                    </span>
                  </p>
                </td>
                <td
                  width={141}
                  valign="top"
                  style={{
                    width: "105.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "18.75pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={206}
                  valign="top"
                  style={{
                    width: "154.75pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "18.75pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "10.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.applicantFullName }</span>
                      <br />‎
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      Ф.И.О.
                    </span>
                  </p>
                </td>
              </tr>
              <tr style={{ height: "21.65pt" }}>
                <td
                  width={279}
                  valign="top"
                  style={{
                    width: "209.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "21.65pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      От ОС ТСТ
                    </span>
                  </p>
                </td>
                <td
                  width={141}
                  valign="top"
                  style={{
                    width: "105.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "21.65pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={206}
                  valign="top"
                  style={{
                    width: "154.75pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "21.65pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.employee  }</span>
                      <u>
                        <br />
                      </u>
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontSize: "10.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      ‎
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      Ф.И.О.
                    </span>
                  </p>
                </td>
              </tr>
              <tr style={{ height: "15.6pt" }}>
                <td
                  width={279}
                  valign="top"
                  style={{
                    width: "209.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "15.6pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      Инспектор таможенного поста{" "}
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                      (представитель таможенного склада)
                    </span>
                  </p>
                </td>
                <td
                  width={141}
                  valign="top"
                  style={{
                    width: "105.6pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "15.6pt"
                  }}
                >
                  <p
                    className="MsoNormal  analis_application"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }} 
                  >
                    <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={206}
                  valign="top"
                  style={{
                    width: "154.75pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "15.6pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontSize: "10.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.inspector}</span>
                      <br />‎
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontSize: "8.0pt",
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      Ф.И.О.
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="MsoNormal w-full"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
          >
            <b>
              <span
                lang="RU"
                style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
              >
                &nbsp;
              </span>
            </b>
          </p>
         </div>
          
          {
            res_data?.productInfoPDFDTOList?.map((item:any,index:number)=>{
              return  (
                <div className="w-full " key={index} style={{height:'1050px'}}>
                {space}
                {space}
                {space}
               <p
                  className="MsoNormal w-full"
                  style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}
                >
                  <b>
                    <span
                      lang="RU" className='analis_application'
                      style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                    >
                      ИДЕНТИФИКАЦИЯ ПРОДУКЦИИ
                    </span>
                  </b>
                </p>
                <p
                  className="MsoNormal w-full analis_application"
                  style={{ marginBottom: "0in", textAlign: "center", lineHeight: "normal" }}
                >
                  <b>
                    <span
                      lang="RU"
                      style={{ fontSize: "10.0pt", fontFamily: '"Times New Roman",serif' }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <table
                  className="MsoTableGrid w-full "
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  align="left"
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                   
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        valign="top"
                        style={{ width: "100%", padding: "0in 5.4pt 0in 5.4pt" }}
                      >
                        <p
                          className="MsoNormal w-full"
                          style={{ marginBottom: "0in", lineHeight: "normal" }}
                        >
                          <i>
                            <u>
                              <span
                                lang="RU" className='analis_application'
                                style={{ fontFamily: '"Times New Roman",serif' }}
                              >
                                Наименование (марка, модель, тип при наличии):{" "}
                              </span>
                            </u>
                          </i>
                          <span
                            lang="RU"
                            style={{
                              fontFamily: '"Times New Roman",serif',
                              color: "#333333",
                              background: "white"
                            }}
                          >
                            {" "}{item?.productName }
                          </span>
                          <span lang="RU"></span>
                          <i>
                            <u>
                              <span
                                lang="RU"
                                style={{
                                  fontFamily: '"Times New Roman",serif',
                                  color: "#333333",
                                  background: "white"
                                }}
                              >
                               <span className='block w-full bg-[#000] mt-7' style={{height:'1px'}} ></span>
                               <span className='block w-2/3 bg-[#000] mt-7 mb-2' style={{height:'1px'}} ></span>
                              </span>
                            </u>
                          </i>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="MsoNormal w-full"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "normal"
                  }}
                >
                  <i>
                    <u>
                      <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                        <span style={{ textDecoration: "none" }}>&nbsp;</span>
                      </span>
                    </u>
                  </i>
                </p>
                <table
                  className="MsoTableGrid w-full "
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  style={{ borderCollapse: "collapse", border: "none" }}
                >
                  <tbody>
                    <tr style={{ height: "18.95pt" }}>
                      <td
                        valign="top"
                        style={{
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "18.95pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{ marginBottom: "0in", lineHeight: "normal" }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                            Изготовитель (страна и предприятия):{" "}{item?.manufacturer}
                            <i>
                              <u>
                              <span className='block w-full bg-[#000] mt-7' style={{height:'1px'}} ></span>
                              <span className='block w-full bg-[#000] mt-7 mb-2' style={{height:'1px'}} ></span>
                              </u>
                            </i>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="MsoNormal w-full"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "normal"
                  }}
                >
                  <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <table
                  className="MsoTableGrid w-full"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  style={{ borderCollapse: "collapse", border: "none" }}
                >
                  <tbody>
                    <tr style={{ height: "63.0pt" }}>
                      <td
                        valign="top"
                        style={{
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "63.0pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{ marginBottom: "0in", lineHeight: "normal" }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                            Сведения о упаковке и маркировке:<span className='analis_application' style={{fontSize:'11px'}}>{res_data?.statusOfPacked}</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="MsoNormal w-full"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "normal"
                  }}
                >
                  <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <table
                  className="MsoTableGrid w-full"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  style={{ borderCollapse: "collapse", border: "none" }}
                >
                  <tbody>
                    <tr style={{ height: "26.1pt" }}>
                      <td
                        width={621}
                        valign="top"
                        style={{
                          width: "465.45pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "26.1pt"
                        }}
                      >
                        <div
                          style={{
                            border: "none",
                            borderBottom: "solid windowtext 1.5pt",
                            padding: "0in 0in 1.0pt 0in"
                          }}
                        >
                          <p
                            className="MsoNormal"
                            style={{
                              marginBottom: "0in",
                              textAlign: "justify",
                              lineHeight: "normal",
                              border: "none",
                              padding: "0in"
                            }}
                          >
                            <span
                              lang="RU" className='analis_application'
                              style={{ fontFamily: '"Times New Roman",serif' }}
                            >
                              Предназначение:<span className='analis_application' style={{fontSize:'11px'}}>{res_data?.purpose}</span>
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr style={{ height: "59.35pt" }}>
                      <td
                        width={621}
                        valign="top"
                        style={{
                          width: "465.45pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "59.35pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                            Основные характеристики:{" "}<span className='analis_application' style={{fontSize:'11px'}}>{res_data?.characteristics}</span>
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <i>
                            <u>
                              <span
                                className='w-full'
                                lang="RU"
                                style={{ fontFamily: '"Times New Roman",serif' }}
                              >
                                
                              </span>
                            </u>
                          </i>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "8.15pt" }}>
                      <td
                        width={621}
                        valign="top"
                        style={{
                          width: "465.45pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "8.15pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <i>
                            <u>
                              <span
                                lang="RU" className='analis_application'
                                style={{ fontFamily: '"Times New Roman",serif' }}
                              >
                                Условия хранения: <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.storageConditions}</span>
                              </span>
                            </u>
                          </i>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "8.6pt" }}>
                      <td
                        width={621}
                        valign="top"
                        style={{
                          width: "465.45pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "8.6pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                            Дата изготовления:  <span className='analis_application' style={{fontSize:'11px'}}>{moment(item?.dateOfProducing ).format('DD-MM-YYYY')}</span>
                          </span>
                        </p>
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <i>
                            <span
                              lang="RU"
                              style={{ fontFamily: '"Times New Roman",serif' }}
                            >
                              &nbsp;
                            </span>
                          </i>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "7.5pt" }}>
                      <td
                        width={621}
                        valign="top"
                        style={{
                          width: "465.45pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "7.5pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                            Срок годности/Гарантийный срок: <span className='analis_application' style={{fontSize:'11px'}}>{item?.expiryDate}</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    lineHeight: "normal"
                  }}
                >
                  <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
                    &nbsp;
                  </span>
                </p>
                <table
                  className="MsoTableGrid"
                  border={0}
                  cellSpacing={0}
                  cellPadding={0}
                  width={641}
                  style={{ width: "480.5pt", borderCollapse: "collapse", border: "none" }}
                >
                  <tbody>
                    <tr style={{ height: "60.35pt" }}>
                      <td
                        width={641}
                        valign="top"
                        style={{
                          width: "480.5pt",
                          padding: "0in 5.4pt 0in 5.4pt",
                          height: "60.35pt"
                        }}
                      >
                        <p
                          className="MsoNormal"
                          style={{
                            marginBottom: "0in",
                            textAlign: "justify",
                            lineHeight: "normal"
                          }}
                        >
                          <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
                            Дополнительные информации (при наличии): <span className='analis_application' style={{fontSize:'11px'}}>{res_data?.extraInformation}</span>
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
               </div>
              )
            })
          }
          <div className="w-full" style={{height:'1050px'}}>
          {space}
          {space}
          {space}
          <p className="MsoNormal">
            <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }} className='analis_application'>
              Подписи:
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: "0in",
              textAlign: "justify",
              lineHeight: "normal"
            }}
          >
            <span lang="RU" style={{ fontFamily: '"Times New Roman",serif' }}>
              &nbsp;
            </span>
          </p>
          <table
            className="MsoTableGrid"
            border={0}
            cellSpacing={0}
            cellPadding={0}
            width={631}
            style={{ width: "473.0pt", borderCollapse: "collapse", border: "none" }}
          >
            <tbody>
              <tr style={{ height: "30.7pt" }}>
                <td
                  width={281}
                  valign="top"
                  style={{
                    width: "210.95pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "30.7pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}  className='analis_application'
                    >
                      ‎‎От изготовителя (заказчика)
                    </span>
                  </p>
                </td>
                <td
                  width={142}
                  valign="top"
                  style={{
                    width: "106.3pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "30.7pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='mt-6 block' style={{ fontFamily: '"Times New Roman",serif' }}>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={208}
                  valign="top"
                  style={{
                    width: "155.75pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "30.7pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU" className='mt-6 block analis_application'
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      <span className='block w-full underline analis_application' style={{fontSize:'11px'}}>{res_data?.applicantFullName}</span>
                      <br />
                      ‎Ф.И.О.
                    </span>
                  </p>
                </td>
              </tr>
              <tr style={{ height: "35.4pt" }}>
                <td
                  width={281}
                  valign="top"
                  style={{
                    width: "210.95pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "35.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      От ОС ТСТ
                    </span>
                  </p>
                </td>
                <td
                  width={142}
                  valign="top"
                  style={{
                    width: "106.3pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "35.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='mt-6 block' style={{ fontFamily: '"Times New Roman",serif' }}>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={208}
                  valign="top"
                  style={{
                    width: "155.75pt",
                    padding: "0in 5.4pt 0in 5.4pt",
                    height: "35.4pt"
                  }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      className='mt-6 block unerline analis_application'
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                       {res_data?.employee}
                      <u>
                        <br />
                      </u>
                    </span>
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      ‎Ф.И.О.
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }}
                    >
                      &nbsp;
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  width={281}
                  valign="top"
                  style={{ width: "210.95pt", padding: "0in 5.4pt 0in 5.4pt" }}
                >
                  <p
                    className="MsoNormal"
                    style={{ marginBottom: "0in", lineHeight: "normal" }}
                  >
                    <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                      Инспектор таможенного поста{" "}
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "justify",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='analis_application' style={{ fontFamily: '"Times New Roman",serif' }}>
                      (представитель таможенного склада)
                    </span>
                  </p>
                </td>
                <td
                  width={142}
                  valign="top"
                  style={{ width: "106.3pt", padding: "0in 5.4pt 0in 5.4pt" }}
                >
                  <p
                    className="MsoNormal analis_application"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span lang="RU" className='mt-6 block' style={{ fontFamily: '"Times New Roman",serif' }}>
                      _______________
                    </span>
                  </p>
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} className='analis_application'
                    >
                      подпись
                    </span>
                  </p>
                </td>
                <td
                  width={208}
                  valign="top"
                  style={{ width: "155.75pt", padding: "0in 5.4pt 0in 5.4pt" }}
                >
                  <p
                    className="MsoNormal"
                    style={{
                      marginBottom: "0in",
                      textAlign: "center",
                      lineHeight: "normal"
                    }}
                  >
                    <span
                      lang="RU"
                      className='mt-6 block analis_application'
                      style={{
                        fontFamily: '"Times New Roman",serif',
                        color: "black"
                      }} 
                    >
                      {res_data?.inspector}
                      <br />
                      ‎Ф.И.О.
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            className="MsoNormal"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
          >
            <span style={{ fontFamily: '"Times New Roman",serif' }}>&nbsp;</span>
          </p>
          </div>
        </div>:"yuq "
        }
</>

    )
}
//  <span className='block w-full bg-[#000] mt-7' style={{height:'2px'}} ></span>