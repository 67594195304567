import {Input,Form,Select,Button,Space} from 'antd'
import { ReactElement, useState } from 'react';
import Swal from 'sweetalert2';
interface product_item {
  label:string,
  value:number
}
interface requirmentDTOList {
  requirementId: number;
  normativeIds: number[];
  testMethodIds: null;
}
interface programma_change_product {
  productId: number;
  productNameInTestProtocol: string;
}
interface program_data {
  productInTestProtocolList: programma_change_product[];
  productType: string;
  solutionNumber: string;
  certificationOrganId: number;
  userIds: number[];
  requirementForProgramDTOList: requirmentDTOList[];
}

interface props{
  product_list:product_item[]
  programma_data:program_data,
  setProgramma_data:(programma_data:program_data)=>void
  setActiveFormProgram:(activeFormProgram:number)=>void
}
const {Option}=Select
function ProgrammaStepOne({product_list,programma_data,setProgramma_data,setActiveFormProgram}:props){ 
  const [select_product,setSelect_Product]=useState('')
  function HandleChangeProduct(value:number|string){
      if(programma_data.productInTestProtocolList.length<product_list.length && typeof value==='number'){
        setSelect_Product(product_list.find((item:product_item)=>item.value===value)?.label||"")
        setProgramma_data({...programma_data,productInTestProtocolList:[...programma_data.productInTestProtocolList,{productId:value,productNameInTestProtocol:''}]})
      }
      else{
        Swal.fire("Осторожность",'Вы выбрали все товары! Дайте им всем имя по умолчанию','warning')
      }
   }
   function HandleChangeProductName(value:string,indexProduct:number){
    setProgramma_data({...programma_data,productInTestProtocolList:programma_data.productInTestProtocolList?.map((item:programma_change_product,index:number)=>{
      if(index===indexProduct){
         return {...item,productNameInTestProtocol:value}
      }
      else {return item}
    })})
   }
  function onFinish(values: any){
     if(product_list.length===programma_data.productInTestProtocolList?.length){
       setActiveFormProgram(2)
     }
     else{Swal.fire('Осторожность','Вы не выбрали все товары и не назвали их','warning')}
  };
    return (
        <div className="w-full bg-[#fff] h-full ">
          <Form.Item className='w-full text-xl text-gray-800 font-bold pl-7 flex items-center' style={{height:'5%'}}>Программа сертификационных испытаний оборудования</Form.Item>
            <div className="w-full h-full pl-2" style={{height:"95%"}}><Form  layout='vertical' onFinish={onFinish}
      name="control-hooks" className='w-full h-full bg-[#fff] p-5'
    >
    <div className="w-full h-full flex  justify-between items-center" >
    <div className="w-1/4 mr-4 h-full border-2 border-gray-200 rounded-lg bg-[#fff] p-8" >
      <div className="w-full" style={{height:'90%'}}>
      <Form.Item  label="Выбирать продукт" rules={[{ required: true,message:"Выбирать продукт" }]}>
        <Select className='w-full' value={select_product}
          allowClear onChange={(value:number|string)=>HandleChangeProduct(value)}>
          {
            product_list?.map((item:product_item,index:number)=>{
              return (
                <Option value={item.value} key={index}>{item.label}</Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item label="Тип продукции"  rules={[{ required: true,message:"Тип продукции"}]}>
        <Input value={programma_data.productType} className='w-full h-11' onChange={(e)=>setProgramma_data({...programma_data,productType:e.target.value})}/>
      </Form.Item>
      </div>
      <Form.Item style={{height:"10%"}} className='flex items-center justify-end'>
        <Space>
          <button  type="submit" className='p-3 px-3 bg-[#1A56DB] rounded-lg text-[#fff]'>Далее</button>
        </Space>
      </Form.Item>
      </div>
      <div className="w-3/4 h-full border-2 border-gray-200 rounded-lg bg-[#fff] p-8 grid gap-10 grid-cols-2 overflow-auto" >
      {
        programma_data.productInTestProtocolList?.map((item:programma_change_product,index:number)=>{
          return (
            <Form.Item  label={product_list.find((itemProduct:product_item)=>itemProduct.value===item.productId)?.label}
             rules={[{ required: true,message:`${product_list.find((itemProduct:product_item)=>itemProduct.value===item.productId)?.label} требуется`}]} key={index}>
              <Input.TextArea value={item.productNameInTestProtocol} showCount maxLength={1000} className='w-full h-20' onChange={(e)=>HandleChangeProductName(e.target.value,index)}/>
            </Form.Item>
          )
        })
      }
      </div>
    </div>
     
    </Form></div>
        </div>
    )
}
export default ProgrammaStepOne