import style from './style.module.css'
import { useEffect, useState } from "react";
import { Input, Select, Switch,notification} from 'antd';
import { Minus, Plus } from 'src/assets/icons/laboratory';
import { requirment,normativ, testMethods,fourTableObject } from '../../TableWizzard/TableWizzardType';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RequestToken } from 'src/Hook/Request/Request';
interface props {
    requirment_list:requirment[]
    tablewriteChecked:boolean
    namesProducts?:{label:string,value:number}[],
    progremID:number
    setLoading:(loading:boolean)=>void
}

function TablesTestReportTwo({requirment_list,setLoading,tablewriteChecked,progremID,namesProducts}:props){
    const navigate=useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [testResultDTOListID,setTestResultDTOListID]=useState<number>()
    const [productID,setProductID]=useState<number>(0)
    const [writeTable,setWriteTable]=useState<boolean>(false)
    const [fourthTableDTOList,setfourthTableDTOList]=useState<fourTableObject[]>([
        {
            productId: 0,
            requirementId: 0,
            normativeIds: [],
            testMethodIds: [],
            levelOfTestingSeverityDirectImpacts: 0,
            testImpactParametersDirectEffects: 0,
            directImpactResult: "",
            directImpactIsSuccess: false,
            levelOfTestingSeverityIndirectImpacts: 0,
            testImpactParametersIndirectEffects: 0,
            indirectImpactResult: "",
            indirectImpactIsSuccess: false,
            airDischarge1Result: 0,
            airDischarge1IsSuccess: false,
            airDischarge2Result: 0,
            airDischarge2IsSuccess: false,
            airDischarge3Result: 0,
            airDischarge3IsSuccess: false,
            airDischarge4Result: 0,
            airDischarge4IsSuccess: false,
            remark: ""
        }
      ])
    function errorNotifaction(){ api.error({
        message:"Ошибка",
        description:"Вы не можете изменить расписание"
    })}
   function HandleChangeRequirment(value:number,id:number){
    if(writeTable){
        setTestResultDTOListID(value)
        setfourthTableDTOList(fourthTableDTOList.map((item:fourTableObject,index:number)=>{
            return {...item,requirementId:value}
        }))
    }
    else{errorNotifaction()}
   }
   function HandleChange(parm:number|string|boolean,keyname:string,id:number,type?:string){
      if(writeTable){
        if(type==='input'){
            setfourthTableDTOList(fourthTableDTOList.map((item:fourTableObject,index:number)=>{
                if(item.productId===id){
                    return {...item,[keyname]:parm}
                }
                else {return item}
               }))
           }
           else if(type==='number_input' && typeof parm==='string'){
            setfourthTableDTOList(fourthTableDTOList.map((item:fourTableObject,indexTable:number)=>{
                if(item.productId===id){
                    return {...item,[keyname]:parseInt(parm)}
                }
                else {return item}
               }))
           }
           else {
            setfourthTableDTOList(fourthTableDTOList.map((item:fourTableObject,index:number)=>{
                    return {...item,[keyname]:[...(item as any)[keyname],parm]}
               }))
           }
      }
      else{errorNotifaction()}
   }
   function RemoveItem(id:number,keyname:string,index:number){
    setfourthTableDTOList(fourthTableDTOList.map((item:fourTableObject,indexFourthTable:number)=>{
        if(index===indexFourthTable){
            console.log((item as any)[keyname].filter((item:number,index1:number)=>index!==id))
          return {...item,[keyname]:(item as any)[keyname].filter((item1:number,index1:number)=>item1!==id)}
        }
        else { return item}
    }))

   }
   const standart=requirment_list.filter((item:requirment,index:number)=>{
    if(item.id===testResultDTOListID){
      return item
    }
})[0]
function error(response_data: any) {
    setLoading(true)
    if (
      response_data.response.status === "403" &&
      response_data.response.status === 401
    ) {
      navigate("/");
    } else {
      Swal.fire({
        icon: "error",
        title:"Ошибка",
        text:response_data.response.data.message || response_data.response.message,
      });
    }
  }
    async function Saved(){
       if(writeTable){
        setLoading(false)
        const response=await RequestToken('/testingProtocol/fourthTable',"POST",fourthTableDTOList)
        if(response.success){
            setLoading(true)
            Swal.fire({
                title:'Успех',
                text:response.message,
                icon:'success'
            })
        }else{
            error(response)
        }
       }
       else{errorNotifaction()}
    }
    function WriteTableFunc(checked:boolean){
        setWriteTable(checked)
    if(checked && namesProducts!==undefined){
        api.success({
            message: `Успех`,
            description: 'Вы подтвердили заполнение таблицы',
          });
        if(namesProducts.length>1){
                const new_arr=[]
                for (let i=0;i<namesProducts?.length;i++){
                 new_arr.push({ productId: namesProducts[i].value,
                    requirementId: 0,
                    normativeIds: [],
                    testMethodIds: [],
                    levelOfTestingSeverityDirectImpacts: 0,
                    testImpactParametersDirectEffects: 0,
                    directImpactResult: "",
                    directImpactIsSuccess: false,
                    levelOfTestingSeverityIndirectImpacts: 0,
                    testImpactParametersIndirectEffects: 0,
                    indirectImpactResult: "",
                    indirectImpactIsSuccess: false,
                    airDischarge1Result: 0,
                    airDischarge1IsSuccess: false,
                    airDischarge2Result: 0,
                    airDischarge2IsSuccess: false,
                    airDischarge3Result: 0,
                    airDischarge3IsSuccess: false,
                    airDischarge4Result: 0,
                    airDischarge4IsSuccess: false,
                    remark: ""})
                }
                setfourthTableDTOList([...new_arr])        
                setProductID(namesProducts[0].value)
        }
    }
    else{
        api.info({
            message: `Подтвержденный`,
            description: `Хотите заполнить таблицу "Заполнить таблицу" нажать на кнопкуы`,
          });
    }
    }
    console.log(productID)
    console.log(fourthTableDTOList)
    console.log(fourthTableDTOList.filter((item:fourTableObject)=>item.productId===productID))
      return (
        <div className={`${style.TablesTestReportTwo_wrapper} `} >
             {contextHolder}
            <div className="w-full my-1"><Switch className='bg-[#ccc]' id='write_table' checked={writeTable} onChange={(checked:boolean)=>WriteTableFunc(checked)}/>
            <label htmlFor='write_table' className='ml-2'>Заполнить таблицу?</label></div>
            <table className={style.TablesTestReportTwo}>
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><div className="w-full">
                            <label htmlFor="requirmentID">YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                            <Select className='w-full h-11' options={requirment_list.map((item:requirment)=>{return {label:item.name,value:item.id}})} 
                            onChange={(value:number)=>HandleChangeRequirment(value,0)}/>
                            </div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td colSpan={4} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                                 {fourthTableDTOList[0].testMethodIds?.map((item:number,index:number)=>{
                                   if(standart?.testMethods.filter((itemTest:testMethods,indexTest:number)=>{
                                    if(itemTest.id===item){
                                        return item
                                    }
                                   }).length>0){
                                    return <div className='w-full flex my-2'>
                                        <div className={`${style.TableTestReportOne_result} w-3/4`}>
                                            {standart?.testMethods.filter((itemTest:testMethods,index:number)=>{
                                        if(itemTest.id===item){
                                            return itemTest
                                        }
                                       })[0].name}
                                        </div>
                                        <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(item,'testMethodIds',0)}><Minus/></button></span>
                                    </div>
                                   }
                                 })}
                                <Select className='w-3/4' options={standart?.testMethods?.map((item1:testMethods)=>{return {label:item1.name,value:item1.id}})}
                                    onChange={(value:number)=>HandleChange(value,"testMethodIds",0)} placeholder="Выбор метода тестирования " allowClear/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на методы испытания</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {fourthTableDTOList[0].normativeIds?.map((item:number,index:number)=>{
                                   if(standart?.normativeList.filter((itemTest:normativ,indexTest:number)=>{
                                    if(itemTest.id===item){
                                        return item
                                    }
                                   }).length>0){
                                    return <div className='w-full flex my-2'>
                                        <div className={`${style.TableTestReportOne_result} w-3/4`}>
                                            {standart?.normativeList.filter((itemTest:normativ,index:number)=>{
                                        if(itemTest.id===item){
                                            return itemTest
                                        }
                                       })[0].normativeNumber}
                                        </div>
                                        <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(item,'normativeIds',0)}><Minus/></button></span>
                                    </div>
                                   }
                                 })}
                             <Select className='w-3/4' options={standart?.normativeList?.map((item1:normativ)=>{return {label:item1.normativeNumber,value:item1.id}})}
                                    onChange={(value:number)=>HandleChange(value,"normativeIds",0)}/>
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4} rowSpan={3}>
                            <div className={style.TablesTestReportTwo_image}>
                                <img src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg" alt="Product_image" />
                                <p className="w-full text-center">Форма разрядного тока испытательного генератора</p>
                            </div>
                        </td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                        
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>Накопительная ёмкость:</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={1}>150 pF</td>
                       
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                                <Select className={style.TablesTestReportTwo_model_select} options={namesProducts} disabled={!writeTable} onChange={(value:number)=>setProductID(value)}/>
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
            {
                fourthTableDTOList.filter((item:fourTableObject)=>item.productId===productID)?.map((item:fourTableObject,indexTable:number)=>{
                    return (
                        <table className={`${style.TablesTestReportTwo_two} mb-5`} key={indexTable}>
                <thead>
                    <tr>
                        <th colSpan={2} rowSpan={2}>Вид испытаний и или воздействие</th>
                        <th colSpan={2} rowSpan={2} >Степень жесткости испытаний</th>
                        <th colSpan={2} rowSpan={2}>Параметры испытательного воздействия и число разрядов</th>
                        <th colSpan={2}>Критерий качества функционарования</th>
                        <th colSpan={2} rowSpan={2}>Результат испытания</th>
                    </tr>
                    <tr>
                        <th>По НДС</th>
                        <th>По НДС</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`} colSpan={10} >Контактный разряд</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>Непрямые воздействия</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px'}} onChange={(e)=>HandleChange(e.target.value,'directImpactResult',item.productId,'input')} value={item.directImpactResult}/>
                            </div>
                        </td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                                <Input className='w-3/4' type='number' style={{height:'45px'}} value={item.testImpactParametersDirectEffects} onChange={(e)=>HandleChange(e.target.value,'testImpactParametersDirectEffects',item.productId,'number_input')}/>
                                 <span className='w-1/4 pl-2'>kV</span>
                                <p>(10 одиночных разрядов)</p>
                            </div>
                        </td>
                        <td className=' text-center'>B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} type='number' value={item.levelOfTestingSeverityDirectImpacts} onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityDirectImpacts',item.productId,'number_input')}/>
                            </div></td>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.directImpactIsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'directImpactIsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>Непрямые воздействия</td>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px'}} value={item.indirectImpactResult} onChange={(e)=>HandleChange(e.target.value,'indirectImpactResult',item.productId,'input')}
                                  />
                            </div>
                        </td>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>
                            <div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                                <Input className='w-3/4' type='number' value={item.testImpactParametersIndirectEffects} style={{height:'45px'}} onChange={(e)=>HandleChange(e.target.value,'testImpactParametersIndirectEffects',item.productId,'number_input')}/> <span className='w-1/4 pl-2'>kV</span>
                                <p>(10 одиночных разрядов)</p>
                            </div>
                        </td>
                        <td className=' text-center'>B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} type='number' value={item.levelOfTestingSeverityIndirectImpacts}
                              onChange={(e)=>HandleChange(e.target.value,'levelOfTestingSeverityIndirectImpacts',0,'number_input')}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.indirectImpactIsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'indirectImpactIsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`} colSpan={10}>Воздушный разряд</td>
                    </tr>
                    {/* 2qator */}
                    <tr>
                        <td className={style.TablesTestReportTwo_td} rowSpan={4} colSpan={2}>Непрямые воздействия</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                           1
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} flex flex-wrap`} colSpan={2} >
                            <span className='w-full my-2'>2 kV</span>
                            <span className='w-full my-2'>(10 одиночных разрядов)</span>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`} >B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} type='number' value={item.airDischarge1Result} onChange={(e)=>HandleChange(e.target.value,'airDischarge1Result',item.productId,'number_input')}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.airDischarge1IsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'airDischarge1IsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                           2
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} flex flex-wrap`} colSpan={2} >
                            <span className='w-full my-2'>2 kV</span>
                            <span className='w-full my-2'>(10 одиночных разрядов)</span>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`} >B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} onChange={(e)=>HandleChange(e.target.value,'airDischarge2Result',item.productId,'number_input')} value={item.airDischarge2Result}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.airDischarge2IsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'airDischarge2IsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                           3
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} flex flex-wrap`} colSpan={2} >
                            <span className='w-full my-2'>2 kV</span>
                            <span className='w-full my-2'>(10 одиночных разрядов)</span>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`} >B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} value={item.airDischarge3Result}
                               onChange={(e)=>HandleChange(e.target.value,'airDischarge3Result',item.productId,'number_input')}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.airDischarge3IsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'airDischarge3IsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                           4
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} flex flex-wrap`} colSpan={2} >
                            <span className='w-full my-2'>2 kV</span>
                            <span className='w-full my-2'>(10 одиночных разрядов)</span>
                        </td>
                        <td className={`${style.TablesTestReportTwo_td} text-center`} >B</td>
                        <td className={style.TablesTestReportTwo_td}><div className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}>
                              <Input style={{height:'45px',width:'146px'}} type='number' value={item.airDischarge4Result} onChange={(e)=>HandleChange(e.target.value,'airDischarge4Result',item.productId,'number_input')}/>
                            </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.airDischarge4IsSuccess} onChange={(checked:boolean)=>HandleChange(checked,'airDischarge4IsSuccess',item.productId,'input')}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div>
                        </td>
                    </tr>
                    {/* oxiri */}
                    <tr >
                        <td className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`} colSpan={10}>
                          <div className="w-full ">
                          <span className='w-full '>
                            Примечание:
                            </span>
                            <div className='w-1/3 '>
                            <Input onChange={(e)=>HandleChange(e.target.value,'remark',item.productId,'input')} value={item.remark}/>
                            </div>
                          </div>
                        </td >
                    </tr>
                    <tr >
                        <td colSpan={10}>
                        <div className="w-full  flex items-end justify-end" style={{height:"10%"}}>
              <button className="px-3 py-2 rounded-lg border-2 mr-2">Отменить</button>
              <button  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white" onClick={Saved} disabled={!writeTable}>Сохранить</button>
           </div>
                        </td>
                    </tr>
                </tbody>
            </table>
                    )
                })
            }
        </div>
    )
}
export default TablesTestReportTwo