import { Form, Select } from "antd";
import { useEffect, useState } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
interface requirmentDTOList {
  requirementId: number;
  normativeIds: number[];
  testMethodIds: null;
}
interface programma_change_product {
  productId: number;
  productNameInTestProtocol: string;
}
interface program_data {
  productInTestProtocolList: programma_change_product[];
  productType: string;
  solutionNumber: string;
  certificationOrganId: number;
  userIds: number[];
  requirementForProgramDTOList: requirmentDTOList[];
}
interface user_item {
  label: string;
  value: number;
}
interface res_user_item {
  firstName: string;
  lastName: string;
  id: number;
}
interface props {
  programma_data: program_data;
  setProgramma_data: (programma_data: program_data) => void;
  setActiveFormProgram: (activeFormProgram: number) => void;
}
function ProgrammaStepFour({
  programma_data,
  setProgramma_data,
  setActiveFormProgram,
}: props) {
  const [user_list, setUserList] = useState<user_item[]>([]);
  async function get_user_list() {
    const res_user_list = await RequestToken(
      "/user/getUsersWithoutSuperAdmin",
      "GET",
      null
    );
    if (res_user_list.success) {
      setUserList(
        res_user_list.object?.map((item: res_user_item) => {
          return {
            label: item.firstName + " " + item.lastName,
            value: item.id,
          };
        })
      );
    }
  }
  function onFinish(userIds: {userIds:number[]}) {
    setProgramma_data({ ...programma_data, userIds: [...userIds.userIds] });
    saved_program()
  }
  async function saved_program(){
    const res_data=await RequestToken('/program',"POST",programma_data)
    if(res_data.success){
        Swal.fire('Успех',res_data?.message,'success')
        window.location.reload();
    }
    else{
        Swal.fire("Ошибка",res_data?.response?.data?.message||res_data?.response?.message,'error')
    }
  }
  useEffect(() => {
    get_user_list();
  }, []);
  return (
    <div className="w-full h-full p-8">
      <Form layout="vertical" className="h-full" onFinish={onFinish}>
        <div className="w-full" style={{ height: "90%" }}>
          <Form.Item
            label="Сотрудники"
            name={"userIds"}
            className="w-96"
            rules={[
              { required: true, message: "Необходимо выбрать сотрудника!" },
            ]}
          >
            <Select mode="multiple" options={user_list} />
          </Form.Item>
        </div>
        <div className="w-full" style={{ height: "10%" }}>
          <Form.Item>
            <div className="w-full flex justify-between">
              <button className="px-3 py-2 bg-[#1A56DB] text-[#fff] rounded-md">
                Назад
              </button>
              <button
                className="px-3 py-2 bg-[#1A56DB] text-[#fff] rounded-md"
                type="submit"
              >
                Сохранить
              </button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
export default ProgrammaStepFour;
