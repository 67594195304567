import './Loading.css'
function Loading() {
  return (
    <div className="preloader">
      <span className="light"></span>
      <span></span>
      <span></span>
      <span className="light"></span>
    </div>
  );
}
export default Loading;