import { useState } from "react";
import { Modal,Input, Empty } from "antd";
import Swal from "sweetalert2";
import { DeleteOutlined } from "@ant-design/icons";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate } from "react-router-dom";
import { measurment_list_type } from "src/pages/standart/organ/indexType";
interface new_measurment {
    name:string
}
interface props {
    measurment_list?:measurment_list_type[],
    get_measurment_list:()=>void
}
const {TextArea}=Input
function Measurment({get_measurment_list,measurment_list}:props) {
    const navigate=useNavigate()
    const _role=localStorage.getItem('_role')
    const [isOpenModal,setIsOpenModal]=useState<boolean>(false)
    const [new_measurment,setNewMeasurment]=useState<new_measurment>({
        name:'',
    })
    function DeleteNotification(id:number) {
        Swal.fire({
          icon: "warning",
          title: "Вы хотите отключить этот измерение?",
          showCancelButton: true,
          confirmButtonText: "Удалить",
          cancelButtonText: `Отмена`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Delete(id)
          } 
        });
      }
      async function Delete(id:number){
        const res_test_method_list=await RequestToken(`/measurement/${id}`,'DELETE',null)
        if(res_test_method_list.success){
            get_measurment_list()
           Swal.fire("Удалено!", "", "success");
        }
        else{error(res_test_method_list)}
      }
    function Cancel(){
        setNewMeasurment({...new_measurment,name:''})
        setIsOpenModal(false)
    }
    function error(response: any) {
        if (
          response?.response?.status === 401 &&
          response?.response?.status === 403
        ) {
          navigate("/");
        } else {
          Swal.fire({
            title: "Ошибка",
            text: response?.response?.data?.message || response?.response?.messagem,
            icon: "error",
          });
        }
      }
    async function Saved(e: React.FormEvent<HTMLFormElement>){
      e.preventDefault();
      const res_measurment=await RequestToken('/measurement',"POST",new_measurment)
      if(res_measurment.success){
        get_measurment_list()
        Cancel()
         Swal.fire('Успех',res_measurment?.message,'success')
      }else{error(res_measurment)}
    }
    return (
    <div className="w-full h-full">
      <Modal open={isOpenModal} footer={null} onCancel={Cancel} centered>
          <div className="w-full my-2 font-semibold text-2xl">Добавить измерение</div>
          <form action="form" onSubmit={Saved}>
            <label htmlFor="name">Название единицы измерения</label>
            <TextArea id="name" placeholder="метр" required value={new_measurment.name} onChange={(e)=>setNewMeasurment({...new_measurment,name:e.target.value})}/>
           <div className="w-full flex justify-end">
               <div className="w-full flex justify-end my-2">
                <button onClick={Cancel} type="button" className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md">Отмена</button>
                <input type="submit" id="form" value={'Сохранить'} className="bg-[#1677FF] cursor-pointer rounded-md text-[#fff] px-3 py-2"/>
            </div>
           </div>
          </form>
      </Modal>
      <div className={_role==='EMPLOYEE_AGENCY' ? "w-full mb-2":'hidden'}>
        <button className="bg-[#1677FF] text-[#fff] px-3 py-2 rounded-md" onClick={()=>setIsOpenModal(true)}>
          Добавить измерение
        </button>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="bg-[#F2F4F8] text-left p-3" style={{width:'50px'}}>№</th>
            <th className="bg-[#F2F4F8] text-left p-3">Единицы измерения</th>
            <th className={_role==='ADMIN_OF_AGENCY' ? 'bg-[#F2F4F8]':"hidden"} style={{width:'50px'}}></th>
          </tr>
        </thead>
        <tbody>
          {
            measurment_list!==undefined ?  measurment_list?.map((item:measurment_list_type,index:number)=>{
                return (
                    <tr key={index}>
                    <td className="bg-[#fff] p-3" style={{border:'1px solid #DDE1E6',width:'50px'}}>{index+1}</td>
                    <td className="bg-[#fff] p-3" style={{border:'1px solid #DDE1E6'}}>{item.name}</td>
                    <td className={_role==='ADMIN_OF_AGENCY' ? 'bg-[#fff] p-3':"hidden"} style={{border:'1px solid #DDE1E6',width:'50px'}}>
                    <span className="text-xl text-[red] cursor-pointer" onClick={()=>DeleteNotification(item.id)}>
                              <DeleteOutlined />
                            </span>
                    </td>
                  </tr>
                )
            }):<tr>
                <td colSpan={_role==='ADMIN_OF_AGENCY' ? 3:2}><Empty description="Нет в наличии"/></td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
}
export default Measurment;
