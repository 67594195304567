import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { catagorie_list,catagorie_option, normativ_list_type, requirment_list, test_method_list } from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal, Input, Switch, DatePicker, Select } from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import { StandartStore } from "../StandartStore";
import Loading from "src/Component/Loading/Loading";
interface props {
    requirment_list?: requirment_list[];
  get_requirment_list: () => void;
  loading:boolean
}
interface new_requirment {
  name:string,
  testCode:string,
  testMethodIds:number[],
  normativeIds:number[],
  categoryId:number,
}
function Requirment({ requirment_list, get_requirment_list,loading}: props) {
  const navigate = useNavigate();
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [test_method_list,setTest_method_list]=useState<test_method_list[]>()
  const [normativ_list,setNormativ_list]=useState<normativ_list_type[]>()
  const [catagore_list,setCatagore_list]=useState<catagorie_option[]>()
  const [update_laboratory, setUpdate_Laboratory] = useState<number | null>(
    null
  );
  const [new_requirment, setNew_Requirment] = useState<new_requirment>({
    name: "",
  testCode: "",
  testMethodIds: [],
  normativeIds: [],
  categoryId: 0,
  });
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotification(id: number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите отключить этот лаборатория?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id);
      }
    });
  }
  function UpdateModal(id: number) {
    
    // if (new_obj) {
    //   setNew_category({
    //     ...new_category,
    //     parentId: new_obj?.address,
    //     name: new_obj?.name,
    //   });
    // }
    setUpdate_Laboratory(id);
    setOpenModal(true);
  }
  function Cancel() {
    setOpenModal(false);
    setNew_Requirment({
      ...new_requirment,
      name: "",
      categoryId:0,
      normativeIds:[],
      testCode:'',
      testMethodIds:[]
    });
    setUpdate_Laboratory(null);
  }
  async function Delete(id: number) {
    const res_test_method_list = await RequestToken(
      `/laboratory/${id}`,
      "DELETE",
      null
    );
    if (res_test_method_list.success) {
        get_requirment_list();
      Swal.fire("Удалено!", "", "success");
    } else {
      error(res_test_method_list);
    }
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_data = await RequestToken(
      update_laboratory ? `/requirement/${update_laboratory}` : "/requirement",
      update_laboratory ? "PUT" : "POST",
      new_requirment
    );
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      get_requirment_list();
    } else {
      error(res_data);
    }
  }
  async function get_test_method_and_normativ_list(){
    const res_test_method_list=await StandartStore('3')
    const res_normativ_list=await StandartStore('1')
    const res_catagore_list=await StandartStore('6')
    setNormativ_list(res_normativ_list)
    setTest_method_list(res_test_method_list)
    const new_arr:catagorie_option[]=[]
    res_catagore_list.forEach((item:catagorie_list)=>{
      if(item?.children!==null && item.children?.length>0){
         item?.children.forEach((itemChild:{id: number;name: string})=>{
           new_arr.push({
            label:itemChild.name,
            value:itemChild.id
           })
         })
      }
      else{
        new_arr.push({label:item.name,value:item.id})
      }
    })
    setCatagore_list(new_arr)
  }
  useEffect(()=>{
      get_test_method_and_normativ_list()
  },[])
  return (
   <div className="w-full h-full flex items-center justify-center">
     {
      loading ? <div className="w-full h-full">
      <div
        className={_role === "ADMIN_OF_LABORATORY" ? "hidden" : "w-full mb-2"}
      >
        <button
          className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
          onClick={() => setOpenModal(true)}
        >
          Добавить новую категория <Add />
        </button>
      </div>
      <table className="w-full h-auto">
        <thead>
          <tr>
            <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">
              №
            </th>
            <th className="bg-[#F2F4F8] p-3">Будучи решительным технические характеристики (параметры) имя/проведенодела</th>
            <th className="bg-[#F2F4F8] p-3">Определение метода испытания/отбора проб с учетом указанных пунктов</th>
            <th className="bg-[#F2F4F8] p-3">Название тест-объекта</th>
            <th className="bg-[#F2F4F8] p-3">Тестовый код</th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {requirment_list ? (
            requirment_list?.map((item: requirment_list, index: number) => {
              return (
                <tr>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {index + 1}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.name}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.normativeList?.map((item:normativ_list_type,indexNormativ:number)=>{
                        return (
                            <div className="w-full my-2" key={indexNormativ}>{item.normativeNumber}</div>
                        )
                    })}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                  {item.testMethods?.map((item:test_method_list,indexTest:number)=>{
                        return (
                            <div className="w-full my-2" key={indexTest}>{item.fullName}</div>
                        )
                    })}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">{item.testCode}</td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl text-[red] cursor-pointer"
                      onClick={() => DeleteNotification(item.id)}
                    >
                      <DeleteOutlined />
                    </span>
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-xl cursor-pointer"
                      onClick={() => UpdateModal(item.id)}
                    >
                      <EditOutlined />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>:<Loading/>
     }
      <Modal centered open={openModal} footer={null} onCancel={Cancel}>
      <div className="w-full font-semibold text-2xl mb-4">
        Новое добавленное требование
      </div>
      <form action="test_method" onSubmit={Saved}>
        <label className="w-full block mt-2" htmlFor="name">Введите название требования </label>
        <Input
          id="name"
          required
          className="h-11"
          placeholder="интефейс RS-232; "
          value={new_requirment.name}
          onChange={(e) =>
              setNew_Requirment({ ...new_requirment, name: e.target.value })
          }/>
        <label className="w-full block mt-2" htmlFor="testcode">
          написать тестовый код
        </label>
        <Input
          id="testcode"
          required
          className="h-11"
          placeholder="Y.11/SL22"
          value={new_requirment.testCode}
          onChange={(e) =>
              setNew_Requirment({ ...new_requirment, testCode: e.target.value })
          }/>
          <label htmlFor="normativIds">Выберите правилаd</label>
          <Select className="w-full" mode="multiple" allowClear options={normativ_list?.map((item:normativ_list_type)=>{return {label:item.normativeNumber,value:item.id}})}
          onChange={(value:number[])=>setNew_Requirment({...new_requirment,normativeIds:value})}/>
          <label htmlFor="normativIds">Выберите методы тестирования</label>
          <Select className="w-full" mode="multiple" allowClear options={test_method_list?.map((item:test_method_list)=>{return {label:item.name,value:item.id}})}
          onChange={(value:number[])=>setNew_Requirment({...new_requirment,testMethodIds:value})}/>
        <label className="w-full block mt-2" htmlFor="parentID">Выберите категорию</label>
        <Select  className="w-full h-11"  options={catagore_list} 
        onChange={(value:number)=>setNew_Requirment({...new_requirment,categoryId:value})}/>
        <div className="w-full flex justify-end my-2">
          <button
            onClick={Cancel}
            type="button"
            className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
          >
            Отмена
          </button>
          <input
            type="submit"
            id="test_method"
            value={"Сохранить"}
            className="bg-[#1677FF] rounded-md text-[#fff] px-3 py-2 cursor-pointer"
          />
        </div>
      </form>
    </Modal>
   </div>
  );
}
export default Requirment;
