import { Select } from "antd";
import { useEffect, useState } from "react";
import { RequestToken } from "src/Hook/Request/Request";
import { option, props } from "./indextype";
const { Option } = Select;
function Products({ Handle, name, id, ID, type, value,data }: props) {
  const [product, setProduct] = useState<option[]>([]);
  function handleChange(value: string[]) {
    Handle(
      type === "string"
        ? value
        : product.map((item: option) => {
            if (value.includes(item.name)) {
            }
            return item.id;
          }),
      name,
      ID
    );
  }
  async function GetData() {
    const response_list = await RequestToken(`/product/${id}`, "GET", null);
    if (response_list.success) {
      setProduct(
        response_list.object.map((item: { name: string; id: number }) => ({
          name: item.name,
          id: item.id,
        }))
      );
    }
  }
  useEffect(() => {
    if(data===undefined ){
      GetData();
    }
  }, []);
  console.log(data)
  return (
    <Select
      mode="multiple"
      style={{ width: "350px", height: "45px" }}
      placeholder="Выбирать"
      value={value}
      onChange={handleChange}
    >
      {data===undefined ? product?.map((item, index) => {
        return (
          <Option key={index} value={item.name}>
            {item.name}
          </Option>
        );
      }):data?.map((item:any,index:number)=>{
        return (
          <Option key={index} value={item.value}>
          {item.label}
        </Option>
        )
      })}
    </Select>
  );
}
export default Products;
