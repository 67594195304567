import style from "../TablesTestReportTwo/style.module.css";
import { useEffect, useState } from "react";
import { Input, Select, Switch, notification } from "antd";
import { Minus, Plus } from "src/assets/icons/laboratory";
import {
  requirment,
  fiveTableObject,
  normativ,
  testMethods,
} from "../../TableWizzard/TableWizzardType";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
interface props {
  requirment_list: requirment[];
  progremID?: number;
  namesProducts?: {label:string,value:number}[],
  setLoading: (loading: boolean) => void;
}
function TablesTestReportThree({
  requirment_list,
  setLoading,
  namesProducts,
  progremID,
}: props) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [writeTable, setWriteTable] = useState<boolean>(false);
  const [product_id,setProduct_id]=useState<number>(0)
  const [fiveTableDTOList, setfiveTableDTOList] = useState<fiveTableObject[]>([
    {
      productId: 0,
      requirementId: 0,
      normativeIds: [],
      testMethodIds: [],
      levelOfTestingSeverityWire: 0,
      testImpactParametersWire: "string",
      wireResult: "string",
      wireIsSuccess: true,
    },
  ]);
  function errorNotifaction() {
    api.error({
      message: "Ошибка",
      description: "Вы не можете изменить расписание",
    });
  }
  function WriteTableFunc(checked: boolean) {
    setWriteTable(checked);
    if (checked && namesProducts!==undefined) {
      api.success({
        message: `Успех`,
        description: "Вы подтвердили заполнение таблицы",
      });
      if(namesProducts?.length>0){
        const new_arr=[]
        for (let i = 0; i < namesProducts.length; i++) {
           new_arr.push({
            productId: namesProducts[i].value,
            requirementId: 0,
            normativeIds: [],
            testMethodIds: [],
            levelOfTestingSeverityWire: 0,
            testImpactParametersWire: "",
            wireResult: "",
            wireIsSuccess: false,
          })
        }
        setProduct_id(namesProducts[0].value)
        setfiveTableDTOList([...new_arr])
    }
    } else {
      api.info({
        message: `Подтвержденный`,
        description: `Хотите заполнить таблицу "Заполнить таблицу" нажать на кнопкуы`,
      });
    }
   
  }
  function HandleChange(
    parm: string | number | boolean,
    keyname: string,
    index: number,
    type?: string
  ) {
    if (writeTable) {
      if (keyname === "normativeIds" || keyname === "testMethodIds") {
        setfiveTableDTOList(
          fiveTableDTOList.map((item: fiveTableObject, indexTable: number) => {
            return {
              ...item,
              [keyname]: [...(item as any)[keyname], parm],
            };
          })
        );
      } else if (
        keyname === "requirementId" &&
        typeof parm !== "boolean" &&
        typeof parm !== "string"
      ) {
        setfiveTableDTOList(
          fiveTableDTOList.map((item: fiveTableObject, indexTable: number) => {
            return {...item,requirementId:parm}
          })
        );
      } else if (
        type === "number_input" &&
        typeof parm !== "number" &&
        typeof parm !== "boolean"
      ) {
        setfiveTableDTOList(
          fiveTableDTOList.map((item: fiveTableObject, indexTable: number) => {
            if (item.productId === index) {
              return { ...item, [keyname]: parseInt(parm) };
            } else {
              return item;
            }
          })
        );
      } else {
        setfiveTableDTOList(
          fiveTableDTOList.map((item: fiveTableObject, indexTable: number) => {
            if (item.productId === index) {
              return { ...item, [keyname]: parm };
            } else {
              return item;
            }
          })
        );
      }
    } else {
      errorNotifaction();
    }
  }
  function RemoveItem(id: number, keyname: string, index: number) {
    setfiveTableDTOList(
      fiveTableDTOList.map(
        (item: fiveTableObject, indexFourthTable: number) => {
          if (index === indexFourthTable) {
            if (Array.isArray((item as any)[keyname])) {
              return {
                ...item,
                [keyname]: (item as any)[keyname].filter(
                  (item1: number, index1: number) => item1 !== id
                ),
              };
            } else {
              return item;
            }
          } else {
            return item;
          }
        }
      )
    );
  }
  const standarts = requirment_list.filter(
    (item: requirment, index: number) => {
      if (item.id === fiveTableDTOList[0].requirementId) {
        return item;
      }
    }
  )[0];
  function error(response_data: any) {
    setLoading(true);
    if (
      response_data.response.status === "403" &&
      response_data.response.status === 401
    ) {
      navigate("/");
    } else {
      Swal.fire({
        icon: "error",
        title:
          response_data.response.data.message || response_data.response.message,
      });
    }
  }
  async function Saved() {
    if (writeTable) {
      setLoading(false)
      const response = await RequestToken(
        "/testingProtocol/fifthTable",
        "POST",
        fiveTableDTOList
      );
      if (response.success) {
        setLoading(true)
        Swal.fire({
          title: response?.message || response.response.message,
          icon: "success",
        });
      } else {
        error(response);
      }
    } else {
      errorNotifaction();
    }
  }
  console.log(fiveTableDTOList)
  console.log(namesProducts)
  return (
    <div className={style.TablesTestReportTwo_wrapper}>
      {contextHolder}
      <div className="w-full my-1">
        <Switch
          className="bg-[#ccc]"
          checked={writeTable}
          id="write_table"
          onChange={(checked: boolean) => WriteTableFunc(checked)}
        />
        <label htmlFor="write_table" className="ml-2">Заполнить таблицу?</label>
      </div>
      <table className={style.TablesTestReportTwo}>
        <thead className={style.TablesTestReportTwo_thead}>
          <tr>
            <th colSpan={4}>
              Наименование проверяемых параметров и их характеристики
            </th>
            <th colSpan={4}>
              <div className="w-full">
                YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ
              </div>
              <Select 
                className="w-full"
                options={requirment_list.map(
                  (item: requirment, index: number) => {
                    return { label: item.name, value: item.id };
                  }
                )}
                onChange={(value: number) =>
                  HandleChange(value, "requirementId", 0)
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={4}>
              Пункты НД на требования
            </td>
            <td colSpan={4} className={style.TablesTestReportTwo_td}>
              <div
                className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}
              >
                {fiveTableDTOList[0].testMethodIds.map(
                  (item: number, index: number) => {
                    if (
                      standarts?.testMethods.filter(
                        (itemStandart: testMethods) => itemStandart.id === item
                      ).length > 0
                    ) {
                      return (
                        <div className="w-full flex my-2">
                          <div
                            className={`${style.TableTestReportOne_result} w-3/4`}
                          >
                            {
                              standarts?.testMethods.filter(
                                (itemStandart: testMethods) =>
                                  itemStandart.id === item
                              )[0]?.name
                            }
                          </div>
                          <span className="w-1/4 pl-2">
                            <button
                              className="text-[#000]"
                              onClick={() =>
                                RemoveItem(item, "testMethodIds", 0)
                              }
                            >
                              <Minus />
                            </button>
                          </span>
                        </div>
                      );
                    } else {
                      return item;
                    }
                  }
                )}
                <Select
                  className="w-3/4"
                  options={standarts?.testMethods.map(
                    (itemNormativ: testMethods, index: number) => {
                      return {
                        label: itemNormativ.name,
                        value: itemNormativ.id,
                      };
                    }
                  )}
                  onChange={(value: number) =>
                    HandleChange(value, "testMethodIds", 0)
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={4}>
              Пункты НД на требования
            </td>
            <td className={style.TablesTestReportTwo_td} colSpan={4}>
              <div
                className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}
              >
                {fiveTableDTOList[0].normativeIds.map(
                  (item: number, index: number) => {
                    if (
                      standarts?.normativeList.filter(
                        (itemStandart: normativ) => itemStandart.id === item
                      ).length > 0
                    ) {
                      return (
                        <div className="w-full flex my-2">
                          <div
                            className={`${style.TableTestReportOne_result} w-3/4`}
                          >
                            {
                              standarts?.normativeList.filter(
                                (itemStandart: normativ) =>
                                  itemStandart.id === item
                              )[0]?.normativeNumber
                            }
                          </div>
                          <span className="w-1/4 pl-2">
                            <button
                              className="text-[#000]"
                              onClick={() =>
                                RemoveItem(item, "normativeIds", 0)
                              }
                            >
                              <Minus />
                            </button>
                          </span>
                        </div>
                      );
                    } else {
                      return item;
                    }
                  }
                )}
                <Select
                  className="w-3/4"
                  options={standarts?.normativeList.map(
                    (itemNormativ: normativ, index: number) => {
                      return {
                        label: itemNormativ.normativeNumber,
                        value: itemNormativ.id,
                      };
                    }
                  )}
                  onChange={(value: number) =>
                    HandleChange(value, "normativeIds", 0)
                  }
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={3}>
              Накопительная ёмкость:
            </td>
            <td className={style.TablesTestReportTwo_td} colSpan={1}>
              150 pF
            </td>
            <td
              className={style.TablesTestReportTwo_td}
              colSpan={4}
              rowSpan={4}
            >
              <div className={style.TablesTestReportTwo_image}>
                <img
                  src="https://upg.uz/storage/files/products/2586/thumbs/rKI0LMSIZYefya4whqGMpiAJdUcFkDAKzoYAcfoe.jpg"
                  alt="Product_image"
                />
                <p className="w-full text-center">
                  Форма разрядного тока испытательного генератора
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={3}>
              Накопительная ёмкость:
            </td>
            <td className={style.TablesTestReportTwo_td} colSpan={1}>
              150 pF
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={3}>
              Накопительная ёмкость:
            </td>
            <td className={style.TablesTestReportTwo_td} colSpan={1}>
              150 pF
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={3}>
              Накопительная ёмкость:
            </td>
            <td className={style.TablesTestReportTwo_td} colSpan={1}>
              положительная / отричцательная
            </td>
          </tr>
          <tr>
            <td className={style.TablesTestReportTwo_td} colSpan={8}>
              <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                <p>Модель</p>
                <Select className={style.TablesTestReportTwo_model_select} options={namesProducts} disabled={!writeTable} onChange={(value:number)=>setProduct_id(value)}/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
     {
      fiveTableDTOList?.filter((item:fiveTableObject)=>item.productId===product_id)?.map((item:fiveTableObject,indexTable:number)=>{
        return (
          <table key={indexTable} className={`${style.TablesTestReportTwo_two} mb-5`}>
          <thead>
            <tr>
              <th colSpan={2} rowSpan={2}>
                Вид испытаний и или воздействие
              </th>
              <th colSpan={2} rowSpan={2}>
                Степень жесткости испытаний
              </th>
              <th colSpan={2} rowSpan={2}>
                Параметры испытательного воздействия и число разрядов
              </th>
              <th colSpan={2}>Критерий качества функционарования</th>
              <th colSpan={2} rowSpan={2}>
                Результат испытания
              </th>
            </tr>
            <tr>
              <th>По НДС</th>
              <th>По НДС</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`${style.TablesTestReportTwo_td} bg-[#DDE1E6]`}
                colSpan={10}
              >
                Порт электропитания 220V
              </td>
            </tr>
            <tr>
              <td className={style.TablesTestReportTwo_td} colSpan={2}>
                Провод-Провод
              </td>
              <td className={style.TablesTestReportTwo_td} colSpan={2}>
                <div
                  className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}
                >
                  <Input
                    style={{ height: "45px" }}
                    type="number" value={item.levelOfTestingSeverityWire}
                    onChange={(e) =>
                      HandleChange(e.target.value, "levelOfTestingSeverityWire", item.productId, "number_input")
                    }
                  />
                </div>
              </td>
              <td className={style.TablesTestReportTwo_td} colSpan={2}>
                <div
                  className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}
                >
                  <Input
                    className="w-3/4" value={item.testImpactParametersWire}
                    style={{ height: "45px" }}
                    onChange={(e) =>
                      HandleChange(e.target.value, "testImpactParametersWire", item.productId)
                    }
                  />{" "}
                  <span className="w-1/4 pl-2"></span>
                </div>
              </td>
              <td className=" text-center">B</td>
              <td className={style.TablesTestReportTwo_td}>
                <div
                  className={`w-full flex flex-wrap ${style.TablesTestReportTwo_two_form}`}
                >
                  <Input value={item.wireResult}
                    style={{ height: "45px", width: "146px" }}
                    onChange={(e) => HandleChange(e.target.value, "wireResult", item.productId)}
                  />
                </div>
              </td>
              <td colSpan={2} className={style.TablesTestReportTwo_td}>
                <div className="w-full flex">
                  <span className="w-1/3">
                    <Switch  className="bg-[#ccc]" checked={item.wireIsSuccess}
                      onChange={(checked: boolean) =>
                        HandleChange(checked, "wireIsSuccess", item.productId)
                      }
                    />
                  </span>
                  <span className="w-2/3">Соответствует</span>
                </div>
              </td>
            </tr>
  
            {/* oxiri */}
            <tr>
              <td colSpan={10}>
                <div
                  className="w-full  flex items-end justify-end"
                  style={{ height: "10%" }}
                >
                  <button className="px-3 py-2 rounded-lg border-2 mr-2">
                    Отменить
                  </button>
                  <button
                    className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
                    onClick={Saved}
                    disabled={!writeTable}
                  >
                    Сохранить
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        )
      })
     }
    </div>
  );
}
export default TablesTestReportThree;
