import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  normativ_list_type,
  test_method_list,
} from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal,Input, Switch, DatePicker} from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import moment from "moment";
import Loading from "src/Component/Loading/Loading";
interface props {
  test_method_list?: test_method_list[];
  get_test_method_list:()=>void;
  loading:boolean
}
interface new_test_method {
  name: string;
  fullName: string;
  entryYear: string|null;
  validUntil: string|null;
  isValid:boolean;
}
const { TextArea } = Input;
function Testmethod({ test_method_list,get_test_method_list,loading}: props) {
  const navigate=useNavigate()
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [update_test_method,setUpdate_test_method]=useState<number|null>(null)
  const [new_test_method,setNew_test_method]=useState<new_test_method>({
    name:'',
    fullName: '',
    entryYear: '',
    validUntil: '',
    isValid:false,
  })
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotification(id:number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите отключить этот метод тестирования?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id)
      } 
    });
  }
  function UpdateModal(id:number){
    if(test_method_list && test_method_list.length > 0){
      const selectedItem = test_method_list?.find((item: test_method_list) => item.id === id);
      if (selectedItem) {
        setNew_test_method({
          name: selectedItem.name,
          fullName: selectedItem.fullName,
          entryYear: selectedItem.entryYear,
          validUntil: selectedItem.validUntil,
          isValid: selectedItem.isValid||false,
        });
      }
      console.log(id)
      setUpdate_test_method(id)
      setOpenModal(true)
    }
    setOpenModal(true)
  }
  function Cancel() {
    setOpenModal(false);
    setNew_test_method({
      ...new_test_method,
      name: "",
      fullName: "",
      entryYear: "",
      validUntil: "",
      isValid:false
    });
    setUpdate_test_method(null)
  }
  function TimeDisabled(value:boolean){
    setNew_test_method({...new_test_method,entryYear:null,validUntil:null,isValid:value})
  }
  async function Delete(id:number){
    const res_test_method_list=await RequestToken(`/testMethod/${id}`,'DELETE',null)
    if(res_test_method_list.success){
       get_test_method_list()
       Swal.fire("Удалено!", "", "success");
    }
    else{error(res_test_method_list)}
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_data = await RequestToken(update_test_method ? `/testMethod/${update_test_method}`:`/testMethod`, update_test_method ? "PUT":"POST", new_test_method);
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      get_test_method_list()
    } else {
      error(res_data);
    }
  }
  return (
    <div className="w-full h-full flex items-center justify-center">
       {
        loading ? <div className="w-full h-full overflow-auto">
     
        <div className={_role==='ADMIN_OF_LABORATORY' ? "hidden":"w-full mb-2"}>
          <button
            className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
            onClick={() => setOpenModal(true)}>
            Добавить метод испытания <Add />
          </button>
        </div>
        <table className="w-full h-auto">
          <thead>
            <tr>
              <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">№</th>
              <th className="bg-[#F2F4F8] p-3">Обозначение нормативного документа</th>
              <th className="bg-[#F2F4F8] p-3">Наименование нормативного документа</th>
              <th className="bg-[#F2F4F8] p-3">Год ввода и кем утвержден</th>
              <th
                className={
                  _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
                }
                style={{ width: "60px" }}
              ></th>
              <th
                className={
                  _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
                }
                style={{ width: "60px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {test_method_list ? (
              test_method_list?.map((item: test_method_list, index: number) => {
                return (
                  <tr key={index}>
                    <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                      {index+1}
                    </td>
                    <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                      {item.name}
                    </td>
                    <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                      {item.fullName}
                    </td>
                    <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                      {!item.isValid ? (item.entryYear +" Действует до "+item.validUntil):" (без ограничения срока действие) "}
                    </td>
                    <td
                      style={{ border: "1px solid #DDE1E6" }}
                      className={
                        _role === "ADMIN_OF_LABORATORY"
                          ? "text-center p-3"
                          : "hidden"
                      }
                    >
                      <span className="text-xl text-[red] cursor-pointer" onClick={()=>DeleteNotification(item.id)}>
                        <DeleteOutlined />
                      </span>
                    </td>
                    <td
                      style={{ border: "1px solid #DDE1E6" }}
                      className={
                        _role === "ADMIN_OF_LABORATORY"
                          ? "text-center p-3"
                          : "hidden"
                      }
                    >
                      <span className="text-xl cursor-pointer cursor-pointer" onClick={()=>UpdateModal(item.id)}>
                        <EditOutlined />
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>:<Loading/>
       }
    <Modal centered open={openModal} footer={null} onCancel={Cancel}>
    <div className="w-full font-semibold text-2xl">
     {update_test_method ? "Изменете метод испытания":"Добавить метод испытания"}
    </div>
    <form action="test_method" onSubmit={Saved}>
        <label className="w-full block mt-2" htmlFor="name">Название метода испытаний</label>
        <Input id="name" required className="h-11" placeholder="ГОСТ 12.1.006-1984" value={new_test_method.name}  onChange={(e)=>setNew_test_method({...new_test_method,name:e.target.value})}/>
        <label className="w-full block mt-2" htmlFor="fullName">Метод испытаний полное имя</label>
        <TextArea required id="fullName" className="h-40" value={new_test_method.fullName} onChange={(e)=>setNew_test_method({...new_test_method,fullName:e.target.value})}
        placeholder="Система стандартов безопасности труда. Источники тока химические. Требования безопасности" />
        <div className="w-full flex">
        <label className="w-1/2 my-2 mr-3" htmlFor="isValid">Без срок</label>
        <span className="w-1/2 my-2"><Switch id="isValid" className="bg-[#ccc]" onChange={(value:boolean)=>TimeDisabled(value)} checked={new_test_method.isValid}/></span>
        </div>
        <div className="w-full my-3 flex justify-between">
        <label className="w-1/2 mt-2" htmlFor="entryYear">Время начала семестра</label>
        <DatePicker className="w-1/2 h-11" id="entryYear" disabled={new_test_method.isValid}  onChange={(date,dateparm)=>setNew_test_method({...new_test_method,entryYear:moment(dateparm).format('YYYY-MM-DD')})}/>
        </div>
        <div className="w-full my-3 flex justify-between">
        <label className="w-1/2 mt-2" htmlFor="validUntil">Время истечения</label>
        <DatePicker className="w-1/2 h-11" id="validUntil" disabled={new_test_method.isValid}  onChange={(date,dateparm)=>setNew_test_method({...new_test_method,validUntil:moment(dateparm).format('YYYY-MM-DD')})}/>
        </div>
        <div className="w-full flex justify-end my-2">
            <button onClick={Cancel} type="button" className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md">Отмена</button>
            <input type="submit" id="test_method" value={'Сохранить'} className="bg-[#1677FF] cursor-pointer rounded-md text-[#fff] px-3 py-2"/>
        </div>
    </form>
  </Modal>
    </div>
  );
}
export default Testmethod;
