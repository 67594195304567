import { Select,Empty} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "src/App";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
interface props {
    user_list:{label:string,value:number}[],
    type:string
}
interface recomadation {
    orderId:number,
    testProtocolId:number,
    userIds:number[]
}
function Steprecomdacion({user_list,type}:props){
    const navigate=useNavigate()
    const {id}=useParams()
    const { setheaderTitle } = useContext(UserContext);
    const [test_protocol_list,setTest_protocol_list]=useState<{label:string,value:number}[]>([])
    const [res_data,setResData]=useState<any>()
    const [recomadation,setRecomadation]=useState<recomadation>({
        orderId: 0,
        userIds: [],
        testProtocolId:0
      })
      function error(response:any) {
        if(response.response?.status===401 && response.response?.status===403){
          navigate('/')
        }
        else{
            Swal.fire({
                title:response.response?.data.message||response.response?.message,
                icon:'error'
            })
        }
      }
      async function SavedConsulution() {
        const response_consulution = await RequestToken("/recommendation","POST",recomadation);
        if(response_consulution.success){
            Swal.fire({
                title:response_consulution?.message,
                icon:'success'
            })
            navigate(`/home/${id}`)
        }
        else{error(response_consulution)}
      }
      async function get_consultion(){
        const response_consulution=await RequestToken(`/recommendation/${id}`,"GET",null)
        if(response_consulution.success){
          setResData(response_consulution.object)
        }else{
          // error(response_consulution)
        }
      }
      async function get_test_protocol_list(){
        const response_consulution=await RequestToken(`/testingProtocol/byOrderId/${id}`,"GET",null)
        if(response_consulution.success){
          setTest_protocol_list(response_consulution.object?.map((item:{numberOfProtocol:string,id:number},index:number)=>{
            return {label:item.numberOfProtocol,value:item.id}
          }))
        }
        else{error(response_consulution)}
      }
    useEffect(()=>{
        setheaderTitle('Рекомендации')
        if(type!=='edit'){
            get_consultion()
        }else{
          setRecomadation({...recomadation,orderId:parseInt(id||'1')})
          get_test_protocol_list()
        }
    },[])
    return (
        <div className="w-full h-full" style={{ height: "620px" }}>
        <div className="w-full pt-2" style={{ height: "90%" }}>
          <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2 border-[#ccc]"
            style={{ width: "40%" }}
          >
            От изготовителя (заказчика)
          </div>
          <div className=" flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
            {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={user_list}
                mode="multiple"
                onChange={(value: number[]) =>
                  setRecomadation({ ...recomadation, userIds: value })
                }
              />
            ) : res_data?.length>0 ? res_data?.map((item:any,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):"ad"
             }):<Empty description="Нет в наличии"/>}
          </div>
        </div>
        {
          type==='edit' ? <div className="w-full py-2 px-3 my-2 flex ">
          <div
            className="text-base border-r-2 border-[#ccc]"
            style={{ width: "40%" }}
          >
            Пожалуйста, выберите протокол тестирования:
          </div>
          <div className=" flex flex-wrap justify-start px-3" style={{ width: "60%" }}>
          {type === "edit" ? (
              <Select
                style={{ width: "350px" }}
                className="w-full h-11"
                options={test_protocol_list}
                onChange={(value:number)=>setRecomadation({...recomadation,testProtocolId:value})}
              />
            ) : (
              res_data?.length>0 ? res_data?.map((item:any,index:number)=>{
              return item?.users?.length>0 ? item?.users?.map((item:{firstName:string,lastName:string})=>{
                return (
                  <div className="w-full" key={index}>{item.lastName} {item.firstName}</div>
                )
              }):"ad"
             }):<Empty description="Нет в наличии"/>
            )}
          </div>
        </div>:null
        }
        </div>
        <div className="w-full flex justify-end px-3" style={{ height: "8%" }}>
          <button
            className="px-3 py-2 bg-[#4096ff] rounded-md text-[#fff]"
            onClick={SavedConsulution}
          >
            Сохранять
          </button>
        </div>
      </div>
    );
    
}
export default Steprecomdacion