import { useState } from "react"
import { RequestToken } from "src/Hook/Request/Request"
import { normativ_list_type, test_method_list,laboratory_list, catagorie_list, requirment_list,appilance_list} from "src/pages/standart/laboratory/indexTypes"
import { warehouse_list_type } from "src/pages/standart/organ/indexType";
export async function StandartStore(activeKey: string) {
    switch (activeKey) {
      case '1':
        return await getNormativList();
      case '2':
        return await getWareHouseList();
      case '3':
        return await getTestMethodList();
      case '4':
        return await getRequirementList();
      case '5':
          return await getApplianceList();
      case '6':
        return await getCatagorieList();
      case '7':
        return await getLaboratoryList()
      default:
        break;
    }
  }
async function getNormativList() {
    const resNormativList = await RequestToken(`/normative?page=0&size=100`, "GET", null);
    if (resNormativList.success) {
      return resNormativList.object?.map((item:normativ_list_type) => ({
        id: item.id,
        normativeNumber: item.normativeNumber,
        name: item.name,
        description: item.description,
      }));
    }
  }
  async function getTestMethodList() {
    const res_test_method_list = await RequestToken(`/testMethod?page=0&size=100`, "GET", null);
    if (res_test_method_list.success) {
      return res_test_method_list.object?.map((item:test_method_list) => ({
        id: item.id,
        name: item.name,
        fullName: item.fullName,
        isValid: item.isValid,
        entryYear:item.entryYear,
        validUntil:item.validUntil,
      }));
    }
  }
  async function getLaboratoryList() {
    const res_test_method_list = await RequestToken(`/laboratory?page=0&size=100`, "GET", null);
    if (res_test_method_list.success) {
      return res_test_method_list.object?.map((item:laboratory_list) => ({
        id: item.id,
        name: item.name,
        address:item.address
      }));
    }
  }
  async function getCatagorieList() {
    const res_test_method_list = await RequestToken(`/category/allWithParent`, "GET", null);
    if (res_test_method_list.success) {
      return res_test_method_list.object?.map((item:catagorie_list) => ({
        id: item.id,
        name: item.name,
        children:item.children
      }));
    }
  }
  async function getWareHouseList() {
    const res_warehouse_list = await RequestToken(`/warehouse`, "GET", null);
    if (res_warehouse_list.success) {
      return res_warehouse_list.object?.map((item:warehouse_list_type) => ({
        id: item.id,
        name: item.name,
        address:item.address
      }));
    }
  }
  async function getRequirementList() {
    const res_warehouse_list = await RequestToken(`/requirement?page=0&size=100`, "GET", null);
    if (res_warehouse_list.success) {
      return res_warehouse_list.object?.map((item:requirment_list) => ({
        id: item.id,
        name: item.name,
        testCode:item.testCode,
        normativeList:item.normativeList,
        testMethods:item.testMethods,
      }));
    }
  }
  async function getApplianceList() {
    const res_appliance_list = await RequestToken("/appliance?page=0&size=100", "GET", null);
    if (res_appliance_list.success) {
      return  res_appliance_list.object?.map((item: appilance_list, index: number) =>({ id: item.id,
        name: item.name,
        manufacturer:item.manufacturer,
        serialNumber:item.serialNumber,
        dateOfCertification:item.dateOfCertification}))
    }
  }