import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, Select, Spin } from "antd";
import { RequestToken } from "src/Hook/Request/Request";
interface categore_child {
  id: number;
  name: string;
  requirements: requirements[];
  children: unknown;
}
interface testMethods {
  id:number;
  name:string;
  fullName:string;
  entryYear:string|null;
  validUntil:string|null;
  isValid: boolean;
}
interface normativ {
  id:number;
  normativeNumber:string;
  name:string;
  description:string;
}
interface requirements {
  id: number;
  name: string;
  testCode: string;
  normativeList:normativ[],
  testMethods:testMethods[]
}
interface categore_object {
  id: number;
  name: string;
  requirements: any;
  children: categore_child[];
}

interface categore_filter {
  categore_id: number;
  podcategore_id: number;
}
interface requirmentDTOList {
  requirementId: number;
  normativeIds: number[];
  testMethodIds: null;
}
interface programma_change_product {
  productId: number;
  productNameInTestProtocol: string;
}
interface program_data { 
  productInTestProtocolList: programma_change_product[];
  productType: string;
  solutionNumber: string;
  certificationOrganId: number;
  userIds: number[];
  requirementForProgramDTOList: requirmentDTOList[];
}
interface props {
  programma_data:program_data,
  setProgramma_data:(programma_data:program_data)=>void
  setActiveFormProgram: (activeFormProgram: number) => void;
}
const { Option } = Select;
const ProgrammaStepTwo: React.FC<props> = ({ setActiveFormProgram,programma_data,setProgramma_data }) => {
  const [form] = Form.useForm();
  const [categore_list, setCategore_list] = useState<categore_object[]>();
  const [requirement_list,setRequirment_List]=useState<requirements[]>()
  const [normativ_list,setNormativ_list]=useState<normativ[]>()
  const [categore_filter, setCategore_filter] = useState<categore_filter>({
    categore_id: 0,
    podcategore_id: 0,
  });
  const getCategore_List = async () => {
    const res_data = await RequestToken("/category?page=0&size=100", "GET", null);
    if (res_data.success) {
      setCategore_list(res_data.object);
    }
  };
  const handleChangeRequirment=(parm:number)=>{
   setNormativ_list(requirement_list?.find((item:requirements)=>item.id===parm)?.normativeList)
  }
  const HandleFilter = (parm: number) => {
    const search_categore=categore_list?.find((item:categore_object)=>item.id===categore_filter.categore_id)
    const search_podcategore=search_categore?.children?.find((item:categore_child)=>item.id===parm)
    setRequirment_List(search_podcategore?.requirements)
  };
  function onFinish(values: any) {
    console.log(values.items)
    setProgramma_data({...programma_data,requirementForProgramDTOList:values.items})
    setActiveFormProgram(3)
   }
  useEffect(() => {
    getCategore_List();
    
  }, []);
  return (
    <Form
      form={form}
      className="w-full h-full"
      layout="vertical"
      name="dynamic_form_complex"
      autoComplete="off"
      onFinish={onFinish}
      initialValues={{ items: [{}] }}
    >
      <div className="w-full h-full flex ">
        <div
          className="w-1/4 h-full bg-[#fff] px-7 py-8 rounded-lg "
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10" }}
        >
          <div className="w-full text-xl mb-4 text-gray-900 font-bold font-medium">
            Выбор категорий
          </div>
          <div className="w-full " style={{ height: "90%" }}>
            <Form.Item
              label={"Категории"}
              name={"categore"}
              rules={[{ required: true, message: "Выбор категорий" }]}
            >
              <Select
                onChange={(value: number) => setCategore_filter({...categore_filter,categore_id:value})}
                allowClear
              >
                {categore_list?.map((item: categore_object, index: number) => {
                  return (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={"Подкатегории"}
              name={"podcategore"}
              required
              rules={[{ required: true, message: "Выбор подкатегории" }]}
            >
              <Select
                onChange={(value: number) =>
                  HandleFilter(value)
                }
                allowClear
              >
                {categore_list
                  ?.find(
                    (item: categore_object) =>
                      item.id === categore_filter.categore_id
                  )
                  ?.children?.map((item: categore_child, index: number) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <Form.Item className="w-full " style={{ height: "10%" }}>
            <div className="w-full h-full flex items-center justify-between">
              <button
                onClick={() => setActiveFormProgram(1)}
                className="px-3 py-2 bg-[#1A56DB] text-sm text-[#fff] font-medium rounded-md"
              >
                Hазад
              </button>
              <button
                type="submit"
                className="px-3 py-2 bg-[#1A56DB] text-sm text-[#fff] font-medium rounded-md"
              >
                Далее
              </button>
            </div>
          </Form.Item>
        </div>
        <div
          className="w-3/4 h-full ml-4 overflow-auto bg-[#fff] p-8 rounded-lg"
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10" }}
        >
          <div className="w-full text-2xl mb-4 text-gray-900 font-bold">
            Определяемые показатели
          </div>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div className="w-full h-auto grid gap-10 grid-cols-4 ">
                {fields.map((field) => (
                  <Card
                    size="small"
                    //   style={{minHeight:"300px",maxHeight:'1200px'}}
                    title={`Item ${field.name + 1}`}
                    headStyle={{ backgroundColor: "#F2F4F8" }}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label="Name"
                      name={[field.name, "requirementId"]}
                      rules={[{ required: true, message: "qara" }]}
                    >
                      <Select allowClear onChange={(value:number)=>handleChangeRequirment(value)}>
                        {
                          requirement_list?.map((item:requirements,index:number)=>{
                            return (
                              <Option key={index} value={item.id}>{item.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Name"
                      name={[field.name, "normativeIds"]}
                      rules={[{ required: true, message: "qara" }]}
                    >
                      <Select mode="multiple" allowClear onChange={()=>{}}>
                        {
                          normativ_list?.map((item:normativ,index:number)=>{
                            return (
                              <Option key={index} value={item.id}>{item.normativeNumber}</Option>
                            )
                          })
                        }
                      </Select>
                    </Form.Item>
                  </Card>
                ))}

                <Button type="dashed" onClick={() => add()} block>
                  + Add Item
                </Button>
              </div>
            )}
          </Form.List>
        </div>
      </div>
    </Form>
  );
};

export default ProgrammaStepTwo;
