import styleFive from './style.module.css'
import style from '../TablesTestReportTwo/style.module.css'
import { Input, Select, Switch,notification } from 'antd';
import { Minus, Plus } from 'src/assets/icons/laboratory';
import { useState } from 'react';
import { requirment,eightTableDTOList,testMethods,normativ} from '../../TableWizzard/TableWizzardType';
import { RequestToken } from 'src/Hook/Request/Request';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
interface props {    requirment_list:requirment[]
    namesProducts?:{label:string,value:number}[],
    progremID:number
    setLoading:(loading:boolean)=>void}
function TablesTestReportFive({progremID,requirment_list,namesProducts,setLoading}:props){
    const navigate=useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [writeTable, setWriteTable] = useState<boolean>(false);
    const [product_id,setProduct_ID]=useState<number>(0)
    const [eightTableDTOList,setEightTableDTOlist]=useState<eightTableDTOList[]>([
        {
          productId: 0,
          requirementId: 0,
          normativeIds: [],
          testMethodIds: [],
          quasiPeakDetectorFrequency: 0,
          quasiPeakDetectorLevelOfStandard: 0,
          quasiPeakDetectorMeasurementResult: "",
          averageValueDetectorFrequency: 0,
          averageValueDetectorLevelOfStandard: 0,
          averageValueDetectorMeasurementResults: "",
          isSuccess:false
        }
      ])
      function error(response_data: any) {
        setLoading(true);
        if (
          response_data.response.status === "403" &&
          response_data.response.status === 401
        ) {
          navigate("/");
        } else {
          Swal.fire({
            icon: "error",
            title:
              response_data.response.data.message || response_data.response.message,
          });
        }
      }
      async function Saved() {
        if (writeTable) {
            setLoading(false)
          const response = await RequestToken(
            "/testingProtocol/eighthTable",
            "POST",
            eightTableDTOList
          );
          if (response.success) {
            setLoading(true)
            Swal.fire({
              title: response?.message || response.response.message,
              icon: "success",
            });
          } else {
            error(response);
          }
        } else {
          errorNotifaction();
        }
      }
      function errorNotifaction() {
        api.error({
          message: "Ошибка",
          description: "Вы не можете изменить расписание",
        });
      }
      function HandleChange(parm:string|number|boolean,keyname:string,id:number,type?:string){
        if(writeTable){
            setEightTableDTOlist(eightTableDTOList.map((item:eightTableDTOList,indexTable:number)=>{
             if(keyname==='testMethodIds' || keyname==='normativeIds'){
                return {...item,[keyname]:[...(item as any)[keyname],parm]}
             }
             else if(typeof parm==='number' && keyname==='requirementId'){
              return {...item,requirementId:parm}
             }
             else{
              if(item.productId===product_id){
                if(type==='number_input' && typeof parm==='string'){
                  return {...item,[keyname]:parseInt(parm)}
                 }
                 else{
                  return {...item,[keyname]:parm}
                 }
              }
              else {
                  return item
              }
             }
          }))
        }
        else{errorNotifaction()}
        }
    function WriteTableFunc(checked: boolean) {
        setWriteTable(checked);
        if (checked && namesProducts!==undefined) {
          api.success({
            message: `Успех`,
            description: "Вы подтвердили заполнение таблицы",
          });
          if(namesProducts?.length>1){
            const new_arr=[]
            for (let i = 0; i < namesProducts.length; i++) {
               new_arr.push({
                productId: namesProducts[i].value,
                requirementId: 0,
                normativeIds: [],
                testMethodIds: [],
                quasiPeakDetectorFrequency: 0,
                quasiPeakDetectorLevelOfStandard: 0,
                quasiPeakDetectorMeasurementResult: "",
                averageValueDetectorFrequency: 0,
                averageValueDetectorLevelOfStandard: 0,
                averageValueDetectorMeasurementResults: "",
                isSuccess:false
              })
            }
            setEightTableDTOlist(new_arr)
            setProduct_ID(namesProducts[0].value)
        }
        } else {
          api.info({
            message: `Подтвержденный`,
            description: `Хотите заполнить таблицу "Заполнить таблицу" нажать на кнопкуы`,
          });
        }
       
      }
      function RemoveItem(id: number, keyname: string, index: number) {
        setEightTableDTOlist(
            eightTableDTOList.map(
            (item: eightTableDTOList, indexFourthTable: number) => {
              if (index === indexFourthTable) {
                if (Array.isArray((item as any)[keyname])) {
                  return {
                    ...item,
                    [keyname]: (item as any)[keyname].filter(
                      (item1: number, index1: number) => item1 !== id
                    ),
                  };
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }
          )
        );
      }
      const standarts=requirment_list.filter((item:requirment,index:number)=>item.id===eightTableDTOList[0].requirementId)[0]
      console.log(eightTableDTOList)
    return (
        <div className={style.TablesTestReportTwo_wrapper}>
             {contextHolder}
      <div className="w-full my-1">
        <Switch
          className="bg-[#ccc]"
          checked={writeTable}
          id="write_table"
          onChange={(checked: boolean) => WriteTableFunc(checked)}
        />
        <label htmlFor="write_table" className="ml-2">Заполнить таблицу?</label>
      </div>
             <table className={style.TablesTestReportTwo} >
                <thead className={style.TablesTestReportTwo_thead}>
                    <tr>
                        <th colSpan={4}>Наименование проверяемых параметров и их характеристики</th>
                        <th colSpan={4}><label htmlFor="test_eight" className='w-full'>YСТОЙЧИВОСТЬ К ЭЛЕКТРОСТАТИЧЕСКИМ РАЗРЯДАМ</label>
                        <Select className='w-full' id='test_eight' options={requirment_list?.map((itemRequir:requirment)=>{
                            return {label:itemRequir.name,value:itemRequir.id}
                        })} onChange={(value:number)=>HandleChange(value,'requirementId',0)}/></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {
                                    eightTableDTOList[0].testMethodIds?.map((itemTestMethodId:number)=>{
                                        if(standarts?.testMethods.filter((itemTest:testMethods)=>itemTest.id===itemTestMethodId).length>0){
                                            return (  <div className={`w-full my-2 flex `}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.testMethods?.filter((itemTest:testMethods,index:number)=>itemTest.id===itemTestMethodId)[0].name}</div>
                                            <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(itemTestMethodId,'testMethodIds',0)}><Minus/></button></span>
                                        </div>)
                                        }
                                    })
                                }
                                <Select className='w-full' options={standarts?.testMethods.map((itemTest:testMethods)=>{return {label:itemTest.name,value:itemTest.id}})}
                                onChange={(value:number)=>HandleChange(value,'testMethodIds',0)}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>Пункты НД на требования</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={4}>
                            <div className={`w-full mt-2 h-auto flex flex-wrap ${style.TablesTestReportTwo_form}`}>
                            {
                                    eightTableDTOList[0].normativeIds?.map((itemNormativId:number)=>{
                                        if(standarts?.normativeList.filter((itemNormativ:normativ)=>itemNormativ.id===itemNormativId).length>0){
                                            return (  <div className={`w-full my-2 flex `}>
                                            <div className={`w-3/4 ${style.TableTestReportOne_result}`}>{standarts?.normativeList?.filter((itemNormativ:normativ,index:number)=>itemNormativ.id===itemNormativId)[0].normativeNumber}</div>
                                            <span className='w-1/4 pl-2'><button className='text-[#000]' onClick={()=>RemoveItem(itemNormativId,'normativeIds',0)}><Minus/></button></span>
                                        </div>)
                                        }
                                    })
                                }
                                <Select className='w-full' options={standarts?.normativeList.map((itemNorm:normativ)=>{return {label:itemNorm.normativeNumber,value:itemNorm.id}})}
                                onChange={(value:number)=>HandleChange(value,'normativeIds',0)}/>
                            </div>
                        </td>
                    </tr>
                    {/* selectli td tugadi */}
                      {/* td header tuhashi */}
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Требования к текст</td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} rowSpan={2} colSpan={2}>Диапозон частот MHz</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={6}>Норма(предел) значения величины (dBuV)</td>
                     </tr>
                    <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={3}>Квазипиковое значение</td>
                    </tr>
                    {/* td header tuhashi */}
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66-56</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56-43</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                    <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>66</td>
                        <td className={style.TablesTestReportTwo_td} colSpan={3}>56</td>
                    </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td}`} colSpan={8}>
                            <div className={`w-full ${style.TablesTestReportTwo_model}`}>
                                <p>Модель</p>
                                <Select className={style.TablesTestReportTwo_model_select} options={namesProducts} onChange={(value:number)=>setProduct_ID(value)}/>
                            </div>
                        </td>
                     </tr>
                     
                </tbody>
            </table>
           {eightTableDTOList.filter((item:eightTableDTOList)=>item.productId===product_id)?.map((item:eightTableDTOList,indexTable:number)=>{
            return (
                <table className={`${style.TablesTestReportTwo} mb-5`}>
                <thead>
                <tr>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={2}>Требование по НД</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Результат испытаний</td>
                        <td className={style.TablesTestReportTwo_td_grey} colSpan={3}>Соответветствует требованию НД</td>
                     </tr>
                </thead>
                        <tbody>
                        
                     <tr>
                        <td colSpan={2} className={style.TablesTestReportTwo_td}>Не более значений приведенных в таб №2 UzTR</td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div style={{width:"430px"}}>
                              Не более значений приведенных в таб №2 UzTRНе более значений приведенных в таб
                              №2 UzTRНе более значений приведенных в таб 
                              №2 UzTRНе более значений приведенных в таб №2 UzTRНе более значений приведенных в таб №2 UzTR
                            </div>
                        </td>
                        <td colSpan={3} className={style.TablesTestReportTwo_td}>
                            <div className="w-full flex">
                                <span className='w-1/3'><Switch className='bg-[#ccc]' checked={item.isSuccess} onChange={(checked:boolean)=>HandleChange(checked,'isSuccess',indexTable)}/></span>
                                <span className='w-2/3'>Соответствует</span>
                            </div></td>
                     </tr>
                     <tr>
                        <td className={`${style.TablesTestReportTwo_td_grey} text-center`} colSpan={8}>Расчет неопределенности измерений на самом максимальном пиковом значении напряжения ИРП</td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'quasiPeakDetectorFrequency',indexTable,'number_input')} type='number'
                         value={item.quasiPeakDetectorFrequency} /></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div className='flex justify-between'><span>Квазипиковый детектор</span> </div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'quasiPeakDetectorLevelOfStandard',indexTable,'number_input')} type='number'
                        value={item.quasiPeakDetectorLevelOfStandard}/></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'quasiPeakDetectorMeasurementResult',indexTable)}
                        value={item.quasiPeakDetectorMeasurementResult}/></td>
                     </tr>
                     <tr>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'averageValueDetectorFrequency',indexTable,"number_input")}
                        value={item.averageValueDetectorFrequency} type='number'/></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><div  className='flex justify-between'><span>Детектор средних значений</span></div></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'averageValueDetectorLevelOfStandard',indexTable,"number_input")}
                        value={item.averageValueDetectorLevelOfStandard} type='number'/></td>
                        <td className={style.TablesTestReportTwo_td} colSpan={2}><Input className='w-full' onChange={(e)=>HandleChange(e.target.value,'averageValueDetectorMeasurementResults',indexTable)}
                        value={item.averageValueDetectorMeasurementResults}/></td>
                     </tr>
                        </tbody>
                     </table>
            )
           })}
            <div
                className="w-full  flex items-end justify-end"
                style={{ height: "10%" }}
              >
                <button className="px-3 py-2 rounded-lg border-2 mr-2">
                  Отменить
                </button>
                <button
                  className="px-3 py-2 rounded-lg  border-2 bg-[#1A56DB] text-white"
                  onClick={Saved}
                  disabled={!writeTable}
                >
                  Сохранить
                </button>
              </div>
        </div>
    )
}
export default TablesTestReportFive;