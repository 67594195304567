import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
    laboratory_list,
  normativ_list_type,
  test_method_list,
} from "src/pages/standart/laboratory/indexTypes";
import { Empty, Modal, Input, Switch, DatePicker } from "antd";
import { Add } from "src/assets/icons/laboratory";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
interface props {
  laboratory_list?: laboratory_list[];
  get_laboratory_list:()=>void
  loading:boolean
}
interface new_laboratory {
  name: string;
  address: string;
}
const { TextArea } = Input;
function Laboratory({ laboratory_list,get_laboratory_list,loading}: props) {
  const navigate = useNavigate();
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [update_laboratory,setUpdate_Laboratory]=useState<number|null>(null)
  const [new_laboratory, setNew_Laboratory] = useState<new_laboratory>({
    name: "",
    address: "",
  });
  function error(response: any) {
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function DeleteNotification(id:number) {
    Swal.fire({
        icon: "warning",
      title: "Вы хотите отключить этот лаборатория?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id)
      } 
    });
  }
  function UpdateModal(id:number){
    const new_obj=laboratory_list?.find((item:laboratory_list)=>item.id===id)
    if(new_obj){
        setNew_Laboratory({...new_laboratory,address:new_obj?.address,name:new_obj?.name})
    }
    setUpdate_Laboratory(id)
    setOpenModal(true)
 }
  function Cancel() {
    setOpenModal(false);
    setNew_Laboratory({
      ...new_laboratory,
      name: "",
      address: "",
    });
    setUpdate_Laboratory(null)
  }
  async function Delete(id:number){
    const res_test_method_list=await RequestToken(`/laboratory/${id}`,'DELETE',null)
    if(res_test_method_list.success){
       get_laboratory_list()
       Swal.fire("Удалено!", "", "success");
    }
    else{error(res_test_method_list)}
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const res_data = await RequestToken(update_laboratory ? `/laboratory/${update_laboratory}`:'/laboratory', update_laboratory ? "PUT":"POST", new_laboratory);
    if (res_data.success) {
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      get_laboratory_list()
    } else {
      error(res_data);
    }
  }
  return (
     <div className="w-full h-full flex justify-center items-center">
        {
          loading ? <div className="w-full h-full overflow-auto">
          <div className={_role==='ADMIN_OF_LABORATORY' ? 'hidden':"w-full mb-2"}>
            <button
              className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff] flex"
              onClick={() => setOpenModal(true)}
            >
              Добавить новую лабораторию <Add />
            </button>
          </div>
          <table className="w-full h-auto">
            <thead>
              <tr>
                <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">
                  №
                </th>
                <th className="bg-[#F2F4F8] p-3">
                Название лаборатории
                </th>
                <th className="bg-[#F2F4F8] p-3">
                Адрес лаборатории
                </th>
                <th
                  className={
                    _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
                  }
                  style={{ width: "60px" }}
                ></th>
                <th
                  className={
                    _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
                  }
                  style={{ width: "60px" }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {laboratory_list ? (
                laboratory_list?.map((item: laboratory_list, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                        {index+1}
                      </td>
                      <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                        {item.name}
                      </td>
                      <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                        {item.address}
                      </td>
                      <td
                        style={{ border: "1px solid #DDE1E6" }}
                        className={
                          _role === "ADMIN_OF_LABORATORY"
                            ? "text-center p-3"
                            : "hidden"
                        }
                      >
                        <span className="text-xl text-[red] cursor-pointer" onClick={()=>DeleteNotification(item.id)}>
                          <DeleteOutlined />
                        </span>
                      </td>
                      <td
                        style={{ border: "1px solid #DDE1E6" }}
                        className={
                          _role === "ADMIN_OF_LABORATORY"
                            ? "text-center p-3"
                            : "hidden"
                        }
                      >
                        <span className="text-xl cursor-pointer" onClick={()=>UpdateModal(item.id)}>
                          <EditOutlined />
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                    <Empty />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>:<Loading/>
        }
      <Modal centered open={openModal} footer={null} onCancel={Cancel}>
      <div className="w-full font-semibold text-2xl">
        Добавить новую лабораторию
      </div>
      <form action="test_method" onSubmit={Saved}>
        <label className="w-full block mt-2" htmlFor="name">
          Название лаборатории
        </label>
        <Input
          id="name"
          required
          className="h-11"
          placeholder=""
          value={new_laboratory.name}
          onChange={(e) =>
              setNew_Laboratory({ ...new_laboratory, name: e.target.value })
          }
        />
        <label className="w-full block mt-2" htmlFor="name">
          Адрес лаборатории
        </label>
        <Input
          id="name"
          required
          className="h-11"
          placeholder=""
          value={new_laboratory.address}
          onChange={(e) =>
              setNew_Laboratory({ ...new_laboratory, address: e.target.value })
          }
        />

        <div className="w-full flex justify-end my-2">
          <button
            onClick={Cancel}
            type="button"
            className="px-3 py-2 border-[#ccc] border-2 mx-2 rounded-md"
          >
            Отмена
          </button>
          <input
            type="submit"
            id="test_method"
            value={"Сохранить"}
            className="bg-[#1677FF] rounded-md text-[#fff] px-3 py-2"
          />
        </div>
      </form>
    </Modal>
     </div>
  );
}
export default Laboratory;
