import "./Authorization.css";
import { useState, useContext, useEffect } from "react";
import Lottie from "lottie-react"
import laboratoryAnimation from 'src/assets/animation/animation.json'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Request, RequestToken } from "src/Hook/Request/Request";
import { Input, notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/App";
interface auth {
  username:string,
  password:string,
}
function Authorization() {
  const { ChangeRole, setToken, setRole } = useContext(UserContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading,setLoading]=useState<boolean>(true)
  const navigate = useNavigate();
  const [auth, setAuth] = useState<auth>({
    username: "",
    password: "",
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (title:string, message:string, type:string) => {
    const notificationType:any= type || "error";
    api.error({
      message: title,
      description: message,
    });
  };
  async function handleClick(e:React.FormEvent<HTMLFormElement>) {
    e.preventDefault();    
    setLoading(false)
    setDisabled(true);
    if(auth.password && auth.username){
      const data = await Request("/auth/login", "POST", auth);
      if (data.success) {
        setDisabled(false);
        localStorage.setItem("_token", data.object);
        setToken(data.object);
        if (data.success) {
          const Data = await RequestToken("/auth/me", "GET", null);
          console.log(Data)
          localStorage.setItem("_role", Data.roles?.name);
          localStorage.setItem("_user", JSON.stringify({firstName:Data?.firstname,lastName:Data?.lastname}));
  
          setRole(Data?.name);
          if (Data.roles?.name === "SUPER_ADMIN") {
            navigate("/admin/dashboard");
          } else if (Data.roles?.name === "EMPLOYEE_AGENCY"|| Data.roles?.name ==="ADMIN_OF_AGENCY") {
            navigate("/dashboard");
          } else if (Data.roles?.name === "EMPLOYEE_LABORATORY" || Data.roles?.name =="ADMIN_OF_LABORATORY") {
            navigate("/laborotory/deadline");
          } else {
            navigate("/");
          }
          openNotificationWithIcon("Yспех", "Вы успешно вошли в", "success");
        }
      } else if (data.response?.status === 401) {
        setDisabled(false);
        setLoading(true)
        openNotificationWithIcon(
          "Ошибка",
          "Ваш логин не подтвержден! повторно введите пароль или логин",
          "error"
        );
      } else {
        setLoading(true)
        setDisabled(false);
        openNotificationWithIcon("Ошибка",
        "Ваш логин не подтвержден! повторно введите пароль или логин",
        "error")
      }
    }
    else{
       openNotificationWithIcon('Ошибка',auth.username===' '?'Введите ваш логин':"'Введите ваш Пароль'",'error')
    }
  }
  useEffect(()=>{
    document.cookie = "username=john_doe; expires=Sat, 30 Nov 2023 12:00:00 GMT; path=/dashboard";
  },[])
  return (
    <div className="Authorization">
      {contextHolder}
      <div className="Authorization_left">
      {/* <div className="Authorization-logo">
        <img src={logo} alt="logotip" />
      </div> */}
      <div  className="Authorization-animation">
           <Lottie animationData={laboratoryAnimation}/>
           <div className="w-full block">
           Государственное унитарное предприятие «UNICON.UZ – Центр науки, технологий и маркетинговых исследований» Орган по сертификации телекоммуникационного технического оборудования
           </div>
      </div>
      </div>
     
      <div className="Authorization-box">
        <form onSubmit={handleClick} action="submit">
          <div className="label_box">
            <div className="label_box_title">Сертификация</div>
            <div className="label_box_text">
              Пожалуйста, войдите, чтобы продолжить
            </div>
          </div>
          <label
            htmlFor="login"
            style={{
              width: "100%",
              marginTop: "20px",
              fontSize: "18px",
              fontFamily: "Inter",
              color: "rgb(245 241 241)",
              fontWeight: "500",
            }}
          >
            Логин
          </label>
          <Input
          required
            id={"login"}
            name={"login"}
            type={"text"}
            placeholder={"Текст"}
            style={{
              width: "410px",
              height: "50px",
              borderRadius: "4px",
              padding: "0px 10px",
            }}
            className={"input"}
            onChange={(e)=>setAuth({...auth,username:e.target.value})}
          />
          <label
            htmlFor="parol"
            style={{
              width: "100%",
              marginTop: "20px",
              fontSize: "18px",
              fontFamily: "Inter",
              color: "rgb(245 241 241)",
              fontWeight: "500",
            }}
          >
            Пароль
          </label>
          <Input.Password
          required
          id="parol"
          style={{
            width: "410px",
            height: "50px",
            borderRadius: "4px",
            padding: "0px 10px",
          }}
        placeholder="************"
        className={"bg-[#fff]"}
        onChange={(e)=>setAuth({...auth,password:e.target.value})}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
          <div className="form-footer">
            {loading ? <input
              type="submit"
              value={"Войти"}
              disabled={disabled}
              className={`w-full border-2 border-[#0F62FE] bg-[#0F62FE] py-4 px-3 text-[#fff] cursor-pointer`}
            />:<button className={`w-full border-2 border-[#0F62FE] bg-[#0F62FE] py-4 px-3 text-[#fff] cursor-pointer`}><Spin/></button>}
          </div>
        </form>
      </div>
    </div>
  );
}
export default Authorization;
