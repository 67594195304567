import 'src/pages/form/FormStep.css'
import React, { useState ,useEffect} from 'react';
import {  Select, Space } from 'antd';

import type { SelectProps, RadioChangeEvent } from 'antd';
import { RequestToken } from 'src/Hook/Request/Request';

const options: SelectProps['options'] = [];
for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}
interface props { 
    name:string,
    id?:number,
    normativ_list?:normativeData[]
    value?:string[]
    type?:string
    className?:string
    Handle:(data:number[],name:string,id?:number)=>void;
    HandleString?:(data:string[],name:string,id?:number)=>void;
}

interface normativeData {
  id:number,
  label:string,
  value:string 
}
const AddBox= ({Handle,name,id,value,normativ_list,type,HandleString,className}:props) => {
  const [data,setData]=useState<normativeData[]>([])
  const handleChange = (value: string[]) => {   
         console.log(value)
          if(HandleString){
            HandleString(value,name)
          }
          else{
            if(Array.isArray(value) ){
              const data1= data.filter((item)=>{
                if(value.includes(item.label)){
                   return item.id;
                }})
               Handle(data1.map(item=>item.id),name,id||3)
            }
          }
  };
  async function getData(){
    const Data1=await RequestToken("/normative?page=0&size=100","GET",null)
    if(Data1.success){
      setData(Data1.object.map((item:{id:number,normativeNumber:string})=>{
        return {id:item.id,label:item.normativeNumber,value:item.normativeNumber}
      }))
    }
    else{
      
    }
  }
  useEffect(()=>{
 if(normativ_list===undefined){
  getData()
 }
  },[])   
  return (
    <>
      <Space direction="vertical"  className={className ? className:''}
          style={className ? {}:{ width: "350px" }}> 
        <Select
          mode="multiple"
          placeholder="Please select"
          onChange={handleChange}
          className={className ? className:''}
          style={className ? {}:{ width: "350px"}}
          options={normativ_list||data}
          value={value}
        />
      </Space>
    </>
  );
};

export default AddBox;