  import React, { useEffect } from 'react';
  import moment from 'moment';
  import { useState } from 'react';
  import { RequestToken } from '../../Hook/Request/Request';
  interface props{
    start:string,
    end:string
  }
  const Time = ({start,end}:props) => {
      
    const startDate = moment(start,"YYYY-MM-DDTHH:mm:ss");
    const endDate = moment(end,"YYYY-MM-DDTHH:mm:ss");
    
    // Initialize total working hours
    let totalWorkingHours = 0;

    // Loop through each day between the start and end dates
    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      const dayOfWeek = date.day();

      // Check if the current day is not Saturday (day 6) and not Sunday (day 0)
      if (dayOfWeek !== 6 && dayOfWeek !== 0) {
        const startWorkingHours = date.isSame(startDate, 'day') ? Math.max(8, startDate.hours()) : 8;
        const endWorkingHours = date.isSame(endDate, 'day') ? Math.min(18, endDate.hours()) : 18;

        // Calculate working hours for the current day
        const workingHours = Math.max(0, endWorkingHours - startWorkingHours);

        // Add working hours for the current day to the total
        totalWorkingHours += workingHours;
      }
    }
   
    return (
    <>{totalWorkingHours>10 ? <span style={{color:'green'}}>{totalWorkingHours}</span>:
    <span style={{color:'red'}} >{totalWorkingHours}</span>}</>
      );
  };

  export default Time;
