import { useState,useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {Button} from 'antd'
import { useReactToPrint } from "react-to-print";
import { RequestToken } from "src/Hook/Request/Request";
import StepTwoDocs from "./StepTwoDocs";
import StepThreeDocs from "./StepThreeDocs";
import StepFiveDocs from "./StepFiveDocs";
import StepOneDocx from "./StepOneDocs";
import StepSixDocs from "./StepSixDocs";
import StepSevenDocx from "./StepSevenDocs";
import { ApplicantDocx } from "./renderDocsType";
import StepActDocs from "./StepActDocs";
import Consultion from "./StepConsultion";
import Recomadaction from "./StepRecomadaction";
import TestingProtocol from "./Laboratory/testprotocol";
interface props {
    docxID:string,
    orderID:string,
    setDownload:(download:boolean)=>void
}

function    RenderDocx({docxID,orderID,setDownload}:props){
    const [res_data, setResData] = useState<ApplicantDocx|null|undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const docsData:{id:string,component:JSX.Element}[]=[
        {
            id:"1",
            component:<StepOneDocx orderID={orderID} setDownload={setDownload} />
        },
        {
            id:"2",
            component:<StepTwoDocs orderID={orderID} setDownload={setDownload} />
        },
        {
            id:"3",
            component:<StepThreeDocs orderID={orderID} setDownload={setDownload}/>
        },
        // {
        //     id:"4",
        //     component:<TestingProtocol orderID={orderID} setDownload={setDownload} setLoading={setLoading}/>
        // },
        {
            id:'5',
            component:<StepActDocs orderID={orderID} setDownload={setDownload}/>
        },
        {
            id:"6",
            component:<StepFiveDocs orderID={orderID} setDownload={setDownload}/>
        },
        {
            id:"7",
            component:<Consultion  orderID={orderID} setDownload={setDownload}/>
        },
        {
            id:"8",
            component:<Consultion  orderID={orderID} setDownload={setDownload}/>
        },
        {
            id:"9",
            component:<Recomadaction  orderID={orderID} setDownload={setDownload}/>
        },
        {
            id:"10",
            component:<StepSevenDocx orderID={orderID} setDownload={setDownload}/>
        }
    ]
    const [loader, setLoader] = useState(false);
    //   useEffect(() => {
    //     get_applicant();
    //   }, []);
    console.log(docsData[parseInt(docxID)-1].component)
    console.log(docxID)
    return(
        <div className="w-full bg-red" style={{height:'100%'}}>
           {/* <div className="w-full " style={{display:'flex',justifyContent:"flex-end",height:"5%"}}>
              <Button className="bg-[#1677ff] px-3 py-1 text-[#fff]" onClick={HandlePrint} >Cкачать</Button>
           </div> */}
         <div className="w-full overflow-auto flex justify-center" style={{height:'95%'}}>
         <div className="bg-[#fff]" style={{width:"684px"}} >
          <div className=" flex justify-center w-full"  style={{height:"95%",overflowX:"hidden", overflowY:'auto'}}>
             <div style={{width:"594px",height:'auto'}}>
             {
               docsData[parseInt(docxID)-1].component
            } 
             </div>
           </div>
        </div> </div>        
        </div>
    )
}
export default RenderDocx;   