import "./Dashboard.css";
import { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Loading from "src/Component/Loading/Loading";
import {
  analticRadioData,res_dashboard,res_user
} from "./dashboardType";
import { PieChart, Pie, Cell } from 'recharts'
import { UserContext } from "src/App";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate } from "react-router-dom";
import TableUsers from "src/Component/AdminComponent/Table/Table";
import ChartCard from "src/Component/Cards/ChartCards";
import { Doughnut } from 'react-chartjs-2';
import Radiogroup from "src/Component/Radio";
function DashboardSuperAdmin() {
  const navigate = useNavigate();
  const { setheaderTitle } = useContext(UserContext);
  const [data, setdata] = useState<res_dashboard>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [ChangeSpeedometrChart,setChangeSpeedometrChart]=useState<boolean>(true)
  async function GetData() {
    setLoading(false)
    const responseData = await RequestToken("/superAdminDashboard", "GET", null);
    if (responseData.success) {
      setLoading(true)
      setdata(responseData.object);
    } else if (
      responseData.response?.status === 403 ||
      responseData.response?.status === 401
    ) {
      navigate("/");
    }
  }
  useEffect(() => {
    GetData()
    setheaderTitle("Панель приборов");
  }, []);
  const SemiChartOptions={
    cutout: '70%',
        plugins: {
      legend: {
        display: false,
    },},}
    const RADIAN = Math.PI / 180;
const pie_chart_data = [
  { name: 'Количество лабораторных отчетов в процессе работы', value: ChangeSpeedometrChart ? data?.amountOfStep4InProcess:((data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0)>0 ? (data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0):(data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0)*(-1)), color: ChangeSpeedometrChart ?'#7b4ae5':"#d45324" },
  { name: 'Количество отчетов, полученных лабораторией', value: data?.amountOnStep4, color: '#d4d3d5' }, 
];
const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;
const value = 50;

const needle = (value:any, data:any, cx:any, cy:any, iR:any, oR:any, color:any) => {
  let total = 0;
  data.forEach((v:any) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
  ];
};
const data_charts = [
  { name: 'Основная информация', value: data?.amountOnStep1 },
  { name: 'Решения', value: data?.amountOnStep2 },
  { name: 'АКТ идентификации и отбора образцов ТСТ', value: data?.amountOnStep3 },
  { name: 'Протокол испытаний"', value: data?.amountOnStep4 },
  { name: 'Решение о выдаче', value: data?.amountOnStep5 },
  { name: 'Заявка для сетификата', value: data?.amountOnStep6 },
  { name: 'Форма сертификата', value: data?.amountOnStep7 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#f2e896', '#fcbbd5', '#a39be8',];
console.log(data?.userForDashboardDTOList)
  return (
    <div className="dashboard-super-Admin ">
      {
        loading ? 
        <div className="dashboard-super-Admin-wrapper ">
          <div className="dashboard-super-Admin-box-row grid  gap-4  grid-cols-4 ">
          <div className="h-20 flex border border-{#DDE1E6} p-4 bg-[#fff]">
                <div className="static_card_box_left w-3/4">
                  <div className="static_card_box_description">
                    Общее количество заявок
                  </div>
                  <div className="font-bold text-2xl">
                    {data?.amountOfAll}
                  </div>
                </div>
              </div>
              <div className="h-20 flex border border-{#DDE1E6} p-4 bg-[#fff]">
                <div className="static_card_box_left w-3/4">
                  <div className="static_card_box_description">
                  Заявки в процессе
                  </div>
                  <div className="font-bold text-2xl">
                    {data?.amountOfInProcess}
                  </div>
                </div>
              </div>
              <div className="h-20 flex border border-{#DDE1E6} p-4 bg-[#fff]">
                <div className="static_card_box_left w-3/4">
                  <div className="static_card_box_description">
                  Завершенные заявки
                  </div>
                  <div className="font-bold text-2xl">
                    {data?.amountOfIsDone}
                  </div>
                </div>
              </div>
              <div className="h-20 flex border border-{#DDE1E6} p-4 bg-[#fff]">
                <div className="static_card_box_left w-3/4">
                  <div className="static_card_box_description">
                  Просроченные
                  </div>
                  <div className="font-bold text-2xl">
                    {data?.amountOfExpired}
                  </div>
                </div>
              </div>
          </div>
          <div className="dashboard-super-Admin-chart  grid  gap-4  grid-cols-2 ">
          <div className="Chartcard_wrapper_box  p-4 bg-[#fff]">
          <div className="Chartcard_wrapper_box_info ">
                <div className="Chartcard_wrapper_box_info-title text-2xl font-semibold">
                  Статистика по отчетам
                </div>
              </div>
              <div className="ChartCatd_wrapper_box_chart overflow-hidden flex">
                <div className="w-auto h-full " style={{alignItems:'space-between'}}>
               {
                data_charts?.map((item:{name:string,},index:number)=>{
                  return (
                    <div className="w-full flex my-2" key={index}>
                       <span className={` block mx-2 mt-1`} style={{width:"20px",height:'10px',backgroundColor:COLORS[index]}}></span> <span>{item.name}:<span className="font-semibold"></span></span>
                    </div>
                  )
                })
               }
                </div>
                <div className="flex justify-end" style={{width:"380px",height:'230px',overflow:'hidden'}}>
                <PieChart width={300} height={220} >
        <Pie
          data={data_charts}
          cx={120}
          cy={120}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data_charts.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
                </div>
              </div>
            </div>
            <div className="Chartcard_wrapper_box p-4 bg-[#fff]">
          <div className="Chartcard_wrapper_box_info ">
                <div className="Chartcard_wrapper_box_info-title font-semibold text-2xl">
                Статика по данным лабораторного раздела
                </div>
              </div>
              <div className="ChartCatd_wrapper_box_chart overflow-hidden flex">
              <div className="flex justify-end" style={{width:"380px",height:'230px',overflow:'hidden'}}>
              <PieChart width={380} height={230}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={pie_chart_data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {pie_chart_data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, pie_chart_data, cx, cy, iR, oR, '#f33838')}
      </PieChart>
                </div>
                <div className="w-auto">
                  <div onClick={()=>setChangeSpeedometrChart(true)} className="w-full my-2 flex"><span className="bg-[#7b4ae5] block mt-2 mx-2" style={{width:'20px',height:'10px'}}></span>Начались тесты по {data?.amountOfStep4InProcess} утверждениям</div>
                  <div onClick={()=>setChangeSpeedometrChart(false)} className="w-full my-2 flex"><span className="bg-[#d45324] block mt-2 mx-2" style={{width:'20px',height:'10px'}}></span>Тесты по {(data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0)>0 ? (data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0):((data?.amountOnStep4||0)-(data?.amountOfStep4InProcess||0))*(-1)} утверждениям не запущены</div>
                  <div className="w-full my-2 flex"><span className="bg-[#d4d3d5] block mt-2 mx-2" style={{width:'20px',height:'10px'}}></span>В лабораторный отдел поступает {data?.amountOnStep4} заявлений</div>
                <div className="w-full text-center text-3xl flex items-center justify-center">
                  83.4%
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-super-Admin-analtic grid  gap-4  grid-cols-1">
             <div className="dashboard-super-Admin-analtic-box bg-[#fff] w-full">
              <div className="h-full">
              <TableUsers OpenModal={setOpenModal} count={1} dataheader={[{title:"Имя",dataIndex:"firstName"},{title:"Фамилия",dataIndex:"lastName"},{title:"Позиция",dataIndex:"positionName"},{title:'Количество заявлений, выданных сотрудником',dataIndex:'amountOfOrder'}]}
              data={data?.userForDashboardDTOList||[]} openModal={openModal} saveApi="" type="view" />
              </div>
             </div>
          </div>
        </div>:<div className="w-full h-full flex items-center justify-center"><Loading/></div>
      }
    </div>
  );
}
export default DashboardSuperAdmin;
