import style from "./Table.module.css";
import * as React from "react";
import {DeleteOutlined,EditOutlined} from '@ant-design/icons'
import moment from "moment";
import { useState, useEffect } from "react";
// import Table from '@mui/joy/Table';
import { Empty, Modal, Select, message, Switch } from "antd";
import { Button, Input } from "antd";
import Swal from "sweetalert2";
import { RequestToken } from "src/Hook/Request/Request";
import { rows, data, dataheader, props, item } from "./TableType";
import Loading from "src/Component/Loading/Loading";
import UserList from "src/Component/data/userlist";
import { useNavigate } from "react-router-dom";
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
const { Option } = Select;
export default function TableUsers({
  width,
  minwidth,
  data,
  saveApi,
  count,
  setCount,
  dataheader,
  openModal,
  OpenModal,
  type,
  types,
  Delete,
  update,
  Edit,
  DeleteFunction
}: props) {
  const navigate=useNavigate()
  const [value, setValue] = useState<string>("");
  const [ModalOkText, setModalOkText] = useState<string>("");
  const [orderID, setOrderID] = useState<number>(0);
  const [userlist, setUserlist] = useState([]);
  const [newuserlist, setNewUserlist] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [Data, setData] = useState<rows[]>([]);
  async function Save() {
    setLoading(false);
    const Data = await RequestToken(saveApi, "POST", {
      orderId: orderID,
      userIds: newuserlist,
    });
    if (Data.success) {
      setLoading(true);
      getuserList();        
      OpenModal(false);
      // getData();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${Data.message} `,
      });
    } else {
      setLoading(true);
      Swal.fire({
        icon:"error",
        title:Data.message,
      });
    }
  }
  function Cancel() {
    setValue("");
    OpenModal(false);
  
  }
  async function getuserList() {
    setLoading(false);
    const data1 = await RequestToken("/user/getUsersWithoutSuperAdmin", "GET", null);
    if (data1.success) {
      setUserlist(data1.object);
      setLoading(true)
    } else {
      Swal.fire({
        icon: "error",
        title: "Ошибка",
        text: data1.message,
      });
    }
  }
   function DeleteObject(id:number){
    if (DeleteFunction) {
      DeleteFunction(saveApi, id);
    }
  }
  useEffect(() => {
    if(type === "Assigment") {
      getuserList()
    }
  }, []);
   function handleOpenModal(record: number,user:any) {
     setModalOkText(user.length>0 ? "Измения":"Сохранить")
    setOrderID(record);
    OpenModal(true);
  }
  function handleSelectChange(value: number[],name:string,id:number) {
   setNewUserlist(value);
  }
  function EditStandart(id:number){
  if(Edit){
    Edit(id)
  }
  }
  console.log(data)
    return ( 
    <div className="table_wrapper">
      {loading ? 
        <div className="table_wrapper_box">
          <Modal
            centered
            open={openModal}
            style={{ width: "120px", height: "140px" }}
            onCancel={Cancel}
            okText={ModalOkText}
            cancelText="Oтмена"
            onOk={Save}>           
        <div className="Modal_wrapper_box">
          <div className="Modal_wrapper_box_header">
            <div>Добавить нового сотрудника</div>
          </div>
          <div className="Modal_wrapper_box_body">
            <div>
               <UserList HandleChange={handleSelectChange} name="name" id={1} data={userlist} />
            </div>
          </div>
        </div>
  
          </Modal>
          <table className={"text-left text-sm font-light "+style.table_admin}  >
              <thead className="border-b font-medium dark:border-neutral-500">
                {
                 <tr>
                  {
                     dataheader.map((item,index) => {
                      return (
                          <th key={index}  className="px-2 py-3 text-left   uppercase tracking-wider">
                            {item.title}
                          </th>
                      );
                    })
                  }
                 </tr>
                }
              </thead>
              <tbody>
                {
                  data.length>0 ? data.map((item:any, index:number) => {
                    return (
                      <tr key={index} style={{width:'100%'}}>
                        {
                          dataheader.map((item1, index1) => {
                            return (
                              <td className={item1.dataIndex==='id' ? style.table_admin_td_id:style.table_admin_td} key={index1} >
                                {
                                 item1.dataIndex==='render' ? <>{item?.user?.length>0 ? item?.user?.map((item:{firstName:string,lastName:string},index:number)=>{
                                  return (
                                    <div key={index}>{item.lastName} {item.firstName}</div>
                                  )
                                 }):<Button className={item.user.length>0 ? 'bg-[#a3e635]':"bg-[#ef4444]"}
                                 onClick={()=>handleOpenModal(item.id,item.user)}>Ответственное лицо</Button>}</>:
                                  Array.isArray((item as any)[item1.dataIndex]) ? 
                                 (item as any)[item1.dataIndex].length>0 ?  (item as any)[item1.dataIndex].map((userItem:{firstName:string,lastName:string,description:string},userIndex:number)=>{
                                   return (
                                     <div>{
                                       <div style={{borderBottom:'1px solid #DDE1E6'}} className="w-full flex">
                                          {userItem.hasOwnProperty('username') ? (userItem.lastName+" "+userItem.firstName):<span className="w-full flex flex-wrap">
                                           <span className="w-full text-lg font-medium opacity-80">{userItem.firstName}</span> 
                                           <span className="w-full mb-2 ">{userItem.lastName}</span> 
                                          </span>}
                                       </div>  
                                     }</div> 
                                   )
                                 }):<div className="text-red-500">Hет в наличии</div>:(item1.dataIndex).includes('date') ? 
                                 moment((item as any)[item1.dataIndex]).format('DD-MM-YYYY HH:MM'):(item as any)[item1.dataIndex] ? 
                                   (item as any)[item1.dataIndex]
                                 :<div className="text-red-500">Hет в наличии</div>
                                }
                              </td>
                            );
                          })
                        }
                        <td onClick={()=>DeleteObject(item.id)} style={{width:'70px', border:"1px solid #DDE1E6"}} className={Delete ? "w-full text-center ":'hidden'} ><button  className=' rounded-md text-[red] text-2xl'><DeleteOutlined /></button></td>
                        <td onClick={()=>EditStandart(item.id)} style={{width:'70px', border:"1px solid #DDE1E6"}} className={update ? "w-full text-center ":'hidden'} ><button  className=' rounded-md text-[#edd528] text-2xl'><EditOutlined/></button></td>
                      </tr>
                    )
                  }):<tr>
                    <td colSpan={dataheader.length} className="text-center"><Empty/></td>
                  </tr>
                }
              </tbody>
            </table>
          {types !== "table" ? (
            <>
              <div className="more">
                {Data.length > 10 ? (
                  <div >Еще...</div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
       : (
        <Loading />
      )}
    </div>
  );
}
