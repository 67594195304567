
import style from "./style.module.css";
import { useEffect, useState } from "react";
import type { CollapseProps } from "antd";
import { Collapse, Input } from "antd";
import { Collapse_Option_One,Collapse_Option_Three,Collapse_Option_Two,Collapse_Option_Four,Collapse_Option_Five} from "./CollapseData";
import { programID_data } from "src/pages/laboratory/testreport/indexdata";
interface props {
  program_ID?:programID_data,
  stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string}
  setTimeState:(stateTime:{dateFrom:string,dateUntil:string,dateOfReceiving:string})=>void
  setProgramID:(programID_data:programID_data)=>void
}
function CollapseComponent({program_ID,setProgramID,setTimeState,stateTime}:props) {
  const [Key, setKey] = useState<string>("3"); 
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "1. Заявитель",
      children: <Collapse_Option_One setProgramID={setProgramID} program_ID={program_ID}/>,
    },
    {
      key: "2",
      label: "2. Объект испытаний",
      children:<Collapse_Option_Two setProgramID={setProgramID} program_ID={program_ID}/>,
    },
    {
      key: "3",
      label: "3. Дата получения образцов на испытание",
      children:<Collapse_Option_Three stateTime={stateTime} setTimeState={setTimeState} setProgramID={setProgramID} program_ID={program_ID}/>,
    },
    {
      key:'4',
      label:"4 ЦЕЛЬ ИСПЫТАНИЙ:",
      children:<Collapse_Option_Four setProgramID={setProgramID} program_ID={program_ID}/>
    },
    {
      key:'5',
      label:"5 ПРОГРАММА И МЕТОДЫ ИСПЫТАНИЙ:",
      children:<Collapse_Option_Five setProgramID={setProgramID} program_ID={program_ID}/>
    }
  ];
  const onChange = (key: string | string[]) => {
    console.log(key)
    setKey(key[0])
  };
  return (
    <Collapse items={items} accordion={true} defaultActiveKey={Key} onChange={onChange} />
  );
}
export default CollapseComponent;