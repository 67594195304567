import { Empty, Input, Modal,Switch,notification } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/App';
import { RequestToken } from 'src/Hook/Request/Request';
import { EditIcon } from 'src/assets/icons/laboratory';
import { res_testing_protocol_List,test_protocol } from './indexType';
import style from 'src/pages/Admin/pages/acceptstatement/style.module.css'
import Swal from 'sweetalert2';
import moment from 'moment';
function Numbered(){
    const {setheaderTitle}=useContext(UserContext)
    const [api, contextHolder] = notification.useNotification();
    const [auto_numbering,setAuto_numbering]=useState<boolean>(false)
    const [openModal,setOpenModal]=useState<boolean>(false)
    const [test_protocol,setTest_Protocol]=useState<test_protocol>({
        testProtocolId: 0,
        number: ""
      })
    const [testing_protocol_list,setTestingProtocol]=useState<res_testing_protocol_List[]>([])
    async function get_testing_protocol(){
        const res_testing_protocol_list=await RequestToken('/testingProtocol?page=0&size=100','GET',null)
        if(res_testing_protocol_list.success){
         setTestingProtocol(res_testing_protocol_list.object)
        }
    }
    function AutoNumbering(checked:boolean){
        setAuto_numbering(checked)
       if(checked){
        const auto_number=Math.floor(Math.random() * (99999 - 10000 + 1) + 10000);
        setTest_Protocol({...test_protocol,number:auto_number.toLocaleString()})
        api.success({
          message: `Номер был сгенерирован`,
          description: "Протокол испытаний автоматически сгенерирован"
        });
       }
       else{
        setTest_Protocol({...test_protocol,number:''})
        api.warning({
          message: `Завершено`,
          description: "Aвтоматическая нумерация отключена"
        });
       }
      }
      const Get_Test_Protocol= (id:number)=>{
        setTest_Protocol({...test_protocol,testProtocolId:id})
        setOpenModal(true)
       }
      const SetNumbered=async (e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault()
         const response=await RequestToken('/testingProtocol/setNumber','POST',test_protocol)
         if(response.success){
          Swal.fire({
            title:response?.message||response.response?.message,
            icon:'success'
          })
           get_testing_protocol()
          Cancel()
         }else{Swal.fire({
           title:response.response?.data?.message||response.response?.message,
           icon:'error'
         })}
       }
      function Cancel(){
        setAuto_numbering(false)
        setTest_Protocol({...test_protocol,number:"",testProtocolId:0})
        setOpenModal(false)
      }
    useEffect(()=>{
        get_testing_protocol()
     setheaderTitle('Протокол испытаний, укажите номер')
    },[])
    return(
        <div className="w-full h-full">
            {contextHolder}
           <div className="w-full " style={{padding:'0px 27px'}}>
           <table className="w-full h-auto">
             <thead>
                <tr>
                    <th className={`${style.table_th_id} text-left`}>№</th>
                    <th className={style.table_th}>Заявитель</th>
                    <th className={style.table_th}>Hомер решения</th>
                    <th className={style.table_th}>Hомер протокола</th>
                    <th className={style.table_th}>Дата</th>
                    <th className={style.table_th_id}></th>
                </tr>
             </thead>
             <tbody>
              {
               testing_protocol_list?.length > 0 ? testing_protocol_list?.map((item:res_testing_protocol_List,index:number)=>{
                    return (     <tr key={index}>
                        <td className={style.table_td_id}>{index+1}</td>
         <td className={style.table_td}>{item.applicant}</td>
         <td className={style.table_td}>{item.solutionNumber}</td>
         <td className={style.table_td}>{item.numberOfProtocol}</td>
         <td className={style.table_td}>{moment(item.date).format('DD-MM-YYYY')}</td>
         <td className={style.table_td_id}><div className='w-full h-auto py-2 flex items-center justify-center cursor-pointer' 
         onClick={()=>{Get_Test_Protocol(item.id)}}><EditIcon/></div></td>
                     </tr>)
                }):<tr>
                    <td colSpan={6} className='text-center'><Empty description="item.date"/></td>
                </tr>
              }                     
             </tbody>
           </table>
           </div>
           <Modal centered footer={null}
      open={openModal} onCancel={Cancel}>
        <div className='w-full text-xl'>Пронумеруйте протокол испытаний</div>
        <form action="setNumber" onSubmit={SetNumbered}>
          <label htmlFor="protocolID" className='text-md mt-2 mb-1 w-full'> Идентификационный номер<span className='text-[red]'>*</span></label>
          <Input className='w-full h-11' id='protocolID' onChange={(e)=>setTest_Protocol({...test_protocol,number:e.target.value})} 
           value={test_protocol.number}
          placeholder='0122112' required/>
          <label htmlFor="avto_number" className='w-full mr-3 my-3 flex items-center'>Aвтоматическая нумерация:<Switch
           checked={auto_numbering}
          className='bg-[#ccc] ml-3' onChange={(checked:boolean)=>AutoNumbering(checked)}/></label>
          
          <div className='w-full mt-3 flex justify-end'>
            <button onClick={(Cancel)} className='border-2 border-[#ccc] rounded-md px-3 py-2 mr-2' >Отменить</button>
            <input type="submit" id='setNumber' value={"Сохранить"} className='bg-[#4096ff] text-[#fff] text-md rounded-md px-3 py-2' 
            onChange={(e)=>{setTest_Protocol({...test_protocol,number:e.target.value})}}/>
          </div>
        </form>
      </Modal>
        </div>
    )
}
export default Numbered;