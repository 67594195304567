import { EditIcon, Eyes, Exit } from "src/assets/icons/laboratory/index";
const _role=localStorage.getItem('_role')
export const DropDownDataStatementList = [
  {
    key: "1",
    label: (
     _role==="EMPLOYEE_AGENCY" ? <div className="w-full h-8 flex justify-around ">
     <span className="w-1/5 h-full  flex items-center">
       <EditIcon />
     </span>
     <span className="w-4/5 h-full  flex items-center justify-start text-gray-500 font-normal">
       Изменить
     </span>
   </div>:<></>
    ),
  },
  {
    key: "2",
    label: (
      <div className="w-full h-8 flex justify-around">
        <span className="w-1/5 h-full  flex items-center">
          <Eyes />
        </span>
        <span className="w-4/5 h-full  flex items-center justify-start text-gray-500 font-normal">
          Посмотреть детали
        </span>
      </div>
    ),
  },
];
export const type_statement=[
    {
        label:'Прогресс',
        value:'inProgress'
    },
    {
        label:'Заявления с истекшим сроком действия',
        value:'/order/expired'
    },
    {
        label:'завершенный',
        value:'byStatus'
    },
]
export const month=[
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
  {
    id:1,
    title:'Январь'
  },
]
