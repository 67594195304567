import { useEffect, useState } from "react";
import style from "./style.module.css";
import { RequestToken } from "src/Hook/Request/Request";
import moment from "moment";
import { Empty } from "antd";
import { Cancel, Success } from "src/assets/icons/laboratory";
import Swal from "sweetalert2";
import Loading from "src/Component/Loading/Loading";
interface order_object {
  id: number;
  company: string;
  applicant: string;
  applicantAddress: string;
  applicantFullName: string;
  phoneNumber: string;
  numberOrder: string;
  dateOrder: string;
}
function AcceptStatment() {
  const [order_list, setOrder_list] = useState<order_object[]>([]);
  const [loading,setLoading]=useState<boolean>(true)
  async function getOrder_List() {
    setLoading(false)
    const response_order_list = await RequestToken("/order/notAccepted", "GET", null);
    if (response_order_list.success) {
      setOrder_list(
        response_order_list.object.map((item: order_object) => {
          return {
            id: item.id,
            company: item.company,
            applicant: item.applicant,
            applicantAddress: item.applicantAddress,
            applicantFullName: item.applicantFullName,
            phoneNumber: item.phoneNumber,
            numberOrder: item.numberOrder,
            dateOrder: item.dateOrder,
          };
        })
      );
      setLoading(true)
    }
    else{
      setLoading(true)
    }
  }
  async function AcceptStatment(id:number,status:boolean) {
    setLoading(false)
    const response = await RequestToken("/order/isAccepted", "POST", {
      orderId: id,
      isAccepted: status,
    });
    if(response.success){
        Swal.fire({
            title:'Успех',
            text:response?.message,
            icon:'success',
        })
      getOrder_List()
    }
    else {
      setLoading(true)
        Swal.fire({
            title:"Ошибка",
            text:response?.response?.data?.message||response?.response?.message,
            icon:'error'
        })
    }
  }
  async function Delete(id:number){
     
    const res=await RequestToken(`/order/${id}`,"DELETE",null)
    if(res.success){
      Swal.fire('Удалено',res.message,'success')
      getOrder_List()
    }
    else{
      Swal.fire('Ошибка','Произошла ошибка при отмене оператора.','error')
    }
  }
  useEffect(() => {
    getOrder_List();
  }, []);
  return (
    <div className="w-full h-full flex items-center justify-center">
      {
        loading ? <div className="w-full h-full px-6">
        <table className="w-full h-auto">
          <thead>
            <tr>
              <th className={style.table_th_id}>№</th>
              <th className={style.table_th}>Компания</th>
              <th className={style.table_th}>Заявитель</th>
              <th className={style.table_th}>Заявитель адресс</th>
              <th className={style.table_th}>Имя заявителя</th>
              <th className={style.table_th}>Номер телефона</th>
              <th className={style.table_th}>Порядок номеров</th>
              <th className={style.table_th}>Порядок дат</th>
              <th className={style.table_th_id}></th>
              <th className={style.table_th_id}></th>
            </tr>
          </thead>
          <tbody>
            {order_list.length > 0 ? (
              order_list?.map((item: order_object, index: number) => {
                return (
                  <tr>
                    <td className={style.table_td_id}>{index+1}</td>
                    <td className={style.table_td}>{item.company}</td>
                    <td className={style.table_td}>{item.applicant}</td>
                    <td className={style.table_td}>{item.applicantAddress}</td>
                    <td className={style.table_td}>{item.applicantFullName}</td>
                    <td className={style.table_td}>{item.phoneNumber}</td>
                    <td className={style.table_td}>{item.numberOrder}</td>
                    <td className={style.table_td}>
                      {moment(item.dateOrder).format("DD-MM-YYYY")}
                    </td>
                    <td className={style.table_td_id}>
                      <div
                        className="w-full h-auto py-2 flex items-center justify-center cursor-pointer"
                        onClick={() => Delete(item.id)}
                      >
                        <Cancel />
                      </div>
                    </td>
                    <td className={style.table_td_id}>
                      <div
                        className="w-full h-auto py-2 flex items-center justify-center cursor-pointer"
                        onClick={() => AcceptStatment(item.id, true)}
                      >
                        <Success />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  <Empty description="Заявления не найдены" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>:<Loading/>
      }
    </div>
  );
}
export default AcceptStatment;
