import {
  SampleStepListType,
  AllStepType,
  SampleStepType,
} from "src/mockdata/laboratoryData";
interface normative {
  id: number;
  createAt: string;
  updateAt: string;
  createdBy: number;
  updatedBy: number;
  isActive: boolean;
  normativeNumber: string;
  name: string;
  description: string;
}
export interface search_obj {
  user:number|string|null
}
export interface user_list {
  label: string;
  value: number;
}
export interface programID_data {
  namesOfProducts: string[];
  nameOfCertificationOrgan:string;
  numberRegister:string;
  address:string;
  phone:string;
  email:string;
  website: string;
  applicant:string;
  schemeOfCertification:string;
  users:{label:string,value:number}[]
  solution?:any
}
export interface product_list {
  name:string,
  type:string,
}
export const SampleStepOne: SampleStepType = {
  title: "Добавить испытание",
  list: [
    {
      id: 1,
      label: "Наименование продукции",
      type: "input",
      key: "",
      placholder: "Оборудование для видеоконференций модели CS-BAR-K9",
    },
    {
      id: 2,
      label: "Тип продукции",
      type: "select",
      key: "",
    },
  ],
};
export const SampleStepTwo: SampleStepType = {
  title: "Определяемые показатели",
  list: [
    {
      id: 1,
      label: "Долговечность маркировки",
      type: "select",
      key: "",
      decription: "Электротехнические изделия и связанное с ним оборудование",
    },
    {
      id: 2,
      label: "Электрическая прочность изоляции",
      type: "select",
      key: "",
      decription:
        "Оборудование информационной технологии, включая электрическое конторское оборудование и связанное с ним оборудование",
    },
    {
      id: 3,
      label: "Электрическое сопротивление изоляции",
      type: "input",
      key: "",
      decription:
        "Аудио-, видео- и аналогичная электронная аппаратура (и связанное с ним составные части)",
    },
    {
      id: 3,
      label: "Защита от коротких замыканий",
      type: "input",
      key: "",
      decription:
        "Оборудование информационной технологии, включая электрическое конторское оборудование и связанное с ним оборудование",
    },
    {
      id: 3,
      label: "Долговечность маркировки",
      type: "input",
      key: "",
      decription: "Электротехнические изделия и связанное с ним оборудование",
    },
    {
      id: 3,
      label: "Электрическая прочность изоляции",
      type: "input",
      key: "",
      decription:
        "Оборудование информационной технологии, включая электрическое конторское оборудование и связанное с ним оборудование",
    },
    {
      id: 3,
      label: "Электрическое сопротивление изоляции",
      type: "input",
      key: "",
      decription:
        "Аудио-, видео- и аналогичная электронная аппаратура (и связанное с ним составные части)",
    },
    {
      id: 3,
      label: "Защита от коротких замыканий",
      type: "input",
      key: "",
      decription:
        "Оборудование информационной технологии, включая электрическое конторское оборудование и связанное с ним оборудование",
    },
  ],
};

export const AllSampleStepTestReport: AllStepType[] = [
  {
    id: 1,
    stepLabel: "1. Заявитель",
    component: SampleStepOne,
  },
  {
    id: 2,
    stepLabel: "2. Изделие",
    component: SampleStepTwo,
  },
];
interface dataheaderRow {
  body: string;
  header: string;
}
export interface res_testing_protocol_List {
  id: number;
  numberOfProtocol:string;
  solutionNumber:string;
  applicant: string;
  date: string;
}
export const dataTableTestReport: string[] = [
  // 'Тип изделия','Фирма производитель','Получатель','more','Статус'
  "Получатель",
  "Дата и время поступления образцов",
  "Номер решения",
  "Hомер протокола"
];
