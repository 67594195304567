import {AllSampleStepRegis} from 'src/Component/wizzard/indexdata'
import {AllSampleStepProgram} from 'src/pages/laboratory/program/indexData'
import {AllSampleStepTestReport} from 'src/pages/laboratory/testreport/indexdata'
export interface SampleStepListType {
    id: number;
    label: string;
    type: string;
    key: string;
    placholder?:string
    decription?:string
    options?:string
  }
  export interface SampleStepType {
    title: string;
    title1?: string;
    formType?:string,
    rows_number?:string,
    formTypeCatagorie?:string,
    continue?:boolean,
    list: SampleStepListType[];
    listcatagorie?: SampleStepListType[];
  }
  export interface AllStepType {
    id: number;
    stepLabel: string;
    component: SampleStepType;
  }
export interface dataType {
       listRegis:AllStepType[],
       programSample:AllStepType[],
       testreport:AllStepType[],
}
export const data:dataType={
      listRegis:AllSampleStepRegis,
      programSample:AllSampleStepProgram,
      testreport:AllSampleStepTestReport
}
export const tabslist=['Все','Принятые','Отменённые']
export const history=['Роутер','Телефон','Роутер','Телефон','Роутер','Телефон','Роутер','Телефон','Роутер','Телефон',]