import { Select } from "antd";
import {props,dataObject} from './indextype'
const {Option }=Select
function UserList({data,HandleChange,style,name,id}:props){
    function onchange(value:number[]){
      HandleChange(value,name,id)
    }
    return (
        <Select
        mode="multiple"
        onChange={onchange}
        placeholder="Выберите сотрудника"
        style={style ? style:{width:"300px",height:"65px"}}>
           {
             data?.map((item:dataObject,index:number)=>{
                return(
                    <Option key={index} value={item.id}>{item.lastName} {item.firstName}</Option>
                )
             })
           }
        </Select>
    )
}
export default UserList;