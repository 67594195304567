import moment from 'moment';
import './Styledocx.css'
import style from "./style.module.css";
import { useEffect, useRef,useState} from "react";
import { useReactToPrint } from "react-to-print";
import { RequestToken } from "src/Hook/Request/Request";
import Cert_logo from 'src/assets/image/cert_logo.jpg'
import qr from 'src/assets/image/qr.png'
import { response_document_certificat } from 'src/pages/steps/stepseven/StepSevenType';
const space = (
  <>
    {" "}
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
    <p className="MsoNormal">
      <span lang="RU" style={{ fontSize: "12.0pt" }}>
        &nbsp;
      </span>
    </p>
  </>
);
const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
interface props {
  orderID: string;
  setDownload: (download: boolean) => void;
}
function StepSevenDocx({ orderID, setDownload }: props) {
  const componentRef: any = useRef();
  const [res_data,setRes_Data]=useState<response_document_certificat[]>([])
  async function Get_Certificat_document() {
    const response_certificat_document = await RequestToken(
      `/formOfCertificate/doc/${orderID}`,
      "GET",
      null
    );
    const res=response_certificat_document.object[0]
    if (response_certificat_document.success) {
      setRes_Data([...res_data,{
        givingDate:res?.givingDate,
        productCodes:res?.productCodes,
        certificationOrgan:{
          address:res?.certificationOrgan?.address,
          email:res?.certificationOrgan?.email,
          nameOfCertificationOrgan:res?.certificationOrgan?.nameOfCertificationOrgan,
          numberRegister:res?.certificationOrgan?.numberRegister,
          phone:res?.certificationOrgan?.phone,
          website:res?.certificationOrgan?.website
        },
        applicant:res?.applicant,
        manufacturers:res?.manufacturers,
        productNames:res?.productNames,
        amounts:res?.amounts,
        schemeOfCertification:res?.schemeOfCertification,
        applicantAddress:res?.applicantAddress,
        actOfSelectionDate:res?.actOfSelectionDate,
        invoiceNumber:res?.invoiceNumber,
        invoiceDate:res?.invoiceDate,
        deliveryNote:res?.deliveryNote,
        normativeNames:res?.normativeNames,
        testingProtocolNumber:res?.testingProtocolNumber,
        testingProtocolDate:res?.testingProtocolDate,
        director:{
          lastName:res?.director?.lastName,
          firstName:res?.director?.firstName,
          position:res?.director?.position?.name
        },
        expert:{
          lastName:res?.expert?.lastName,
          firstName:res?.expert?.firstName,
          position:res?.expert?.position?.name
        },
        registrationOfGovernmentRegistry:res?.registrationOfGovernmentRegistry
      }])
      HandlePrint();
    }
  }
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "СЕРТИФИКАТ_СООТВЕТСТВИЯ",
      onAfterPrint:()=>setDownload(false)
  });
  useEffect(() => {
    Get_Certificat_document();
  }, []);
  return (
  <>
  {res_data?  res_data?.map((item:response_document_certificat,index:number)=>{
    return (
      <div key={index} ref={componentRef} className="h-auto" style={{ width: "594px" }}>
      {space}
      <div className="w-full flex justify-center">
        <div className="w-5/6 text-center font-semibold analis_application" style={{fontSize:'11px'}}>
        НАЦИОНАЛЬНАЯ СИСТЕМА СЕРТИФИКАЦИИ РЕСПУБЛИКИ УЗБЕКИСТАН
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center">
        <div className="w-5/6 analis_application text-center" style={{fontSize:'11px'}}>Орган по сертификации «UNICON.UZ» - Государственное унитарное предприятие Центр научно- технических и маркетинговых исследований, O'ZAK.MS.0059</div>
        <div className="w-5/6 analis_application text-center" style={{fontSize:'11px'}}>Адрес: 100187, Ўзбекистон Республикаси Тошкент шаҳри, Мирзо Улуғбек тумани, Қорасув-3,Мингбулоқ кўчаси, 38-уй, Тел.: +998 (71) 208-82-39</div>
      </div>
      <div className="analis_application w-full flex justify-center" style={{borderTop:'1px solid black'}}>
      <div className="w-5/6 analis_application text-center" style={{fontSize:'10px'}}> (наименование органа по сертификации, адрес, № в Государственном реестре)</div>
      </div>
      {space}
      <div className="w-full text-center font-semibold analis_application">
      СЕРТИФИКАТ СООТВЕТСТВИЯ
      </div>
      <div className="w-full flex">
      <div className="w-1/2 h-32 flex justify-center">
        <img src={Cert_logo} style={{transform:'scale(0.8)'}} alt="cert_logo" />
      </div>
      <div className="w-1/2 pt-5">
        <div className='analis_application' style={{fontSize:'11px'}}>Зарегистрирован в Государственном реестре </div>
        <div className='analis_application' style={{fontSize:'11px'}}>От <span className='font-semibold'> {moment(item?.givingDate).format('DD-MM-YYYY')} г</span>. </div>
        <div className='analis_application' style={{fontSize:'11px'}}>№ {item?.registrationOfGovernmentRegistry||"--"}</div>
        <div className='analis_application' style={{fontSize:'11px'}}>Действителен до </div>
        <div className='analis_application' style={{fontSize:'11px'}}>Код ТН ВЭД {item?.productCodes?.map((value: string, index: number)=>{return (<span className='analis_application' 
        style={{fontSize:'11px'}} key={index}>{value}</span>)})}</div>
        <div className="w-full analis_application text-right" style={{fontSize:'11px'}}>(справочно)</div>
      </div>
      </div>

      <span  className="w-full block text-center font-semibold analis_application" style={{fontSize:'11px'}}>{item?.manufacturers?.map((value: string, index: number)=>{
        return (<span key={index} className='analis_application font-semibold' style={{fontSize:'11px'}} >{value}</span>)
      })}</span>
      <span className='w-full block bg-[#000]' style={{height:'1px'}}></span>
      <span  className="w-full block text-center font-semibold analis_application" style={{fontSize:'11px'}}>(наименование предприятия, страна-изготовитель) </span>
      <div className="w-full my-2 text-left analis_application" style={{fontSize:'11px'}}>Настоящий сертификат удостоверяет, что идентифицированная должным образом продукция:</div>
      <div className="w-full mt-4 text-center font-semibold analis_application" style={{fontSize:'11px'}}>{item?.productNames?.map((value: string, index: number)=>{
        return (
          <span className='font-semibold analis_application block' style={{fontSize:'11px'}} key={index}>{value}</span>
        )
      })}</div>
      <div className='w-full flex flex-wrap justify-center'>
        <div className="w-1/2 text-center analis_application" style={{borderTop:'1px solid #000'}}>(наименование, тип, марка)</div>
        <div className="w-full text-center font-semibold analis_application" style={{fontSize:'11px'}}>{item?.amounts?.map((value:number,index:number)=>{
          return (
            <span key={index} className='font-semibold analis_application' style={{fontSize:'11px'}}>{value}</span>
          )
        })}</div>
        <div className="w-1/2  text-center analis_application" style={{borderTop:'1px solid #000'}}>(количество или серийное производство)</div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2 analis_application" style={{fontSize:'11px'}}>соответствует требованиям нормативной документации </div>
        <div className="w-1/2 analis_application font-semibold" style={{fontSize:'11px'}}>{item?.normativeNames?.map((value:string,indexNormativ:number)=>{
          return (
            <span className='analis_application font-semibold mx-2' style={{fontSize:'11px'}} key={indexNormativ}>{value}</span>
          )
        })}</div>
      </div>
      <div className="w-full analis_application my-1" style={{fontSize:'11px'}}>Схема сертификации: <span style={{fontSize:'11px'}} className='font-semibold'>{item?.schemeOfCertification}</span></div>
      <div className="w-full analis_application my-1 flex flex-wrap">
        <div className='w-1/3 analis_application' style={{fontSize:'11px'}}>Заявитель (изготовитель, продавец): </div>
       <div className='w-2/3 font-semibold analis_application' style={{borderBottom:"1px solid #000",fontSize:'11px'}}>{item?.applicant}</div>
       <div className="w-full flex justify-end analis_application text-center" style={{fontSize:'9px'}}>(нужное подчеркнуть)</div>
       </div>
       <div className="w-full  my-1">
          <div className="w-full analis_application  font-semibold" style={{fontSize:'11px'}}>{item?.applicantAddress}</div>
          <div className="w-full analis_application text-center" style={{borderTop:'1px solid #000',fontSize:'11px'}}>(фактический и юридический адрес)</div>
       </div>
       <div className="w-full analis_application" style={{fontSize:'11px'}}>Сертификат выдан на основании:</div>
       <div className="w-full analis_application mb-4" style={{fontSize:'11px'}}>а) документов Акт идентификации и отбора образцов ТСТ от {moment(item.actOfSelectionDate).format('DD-MM-YYYY')} г. Инвойс № {item?.invoiceNumber} от {moment(item?.invoiceDate).format('DD-MM-YYY')} г. {item?.deliveryNote}.</div>
       <div className="w-full analis_application mb-4" style={{fontSize:'11px'}}>б) испытания образцов {item.certificationOrgan.nameOfCertificationOrgan}, № {item?.certificationOrgan?.numberRegister}, Протокол испытаний № {item?.testingProtocolNumber||"--"} от {moment(item?.testingProtocolDate).format('DD-MM-YYYY')} г.</div>
       <div className="w-full analis_application mb-4" style={{fontSize:'11px'}}>в) акт проверки производства Проверка производства не предусмотрена схемой сертификации.</div>
       <div className="w-full flex">
        <div className="w-2/3">
          <div className="w-full my-2">
            <span className='w-full block analis_application' style={{fontSize:'11px'}}>Инспекционный контроль осуществляет с периодичностью:</span>
            <span className='w-full font-semibold analis_application' style={{fontSize:'11px'}}>Не предусмотрен схемой сертификации.</span>
          </div>
          <div className="w-full my-2">
            <span className='analis_application' style={{fontSize:'11px'}}>Особые отметки: </span>
            <span className='font-semibold analis_application' style={{fontSize:'11px'}}>Сертификат действителен при соблюдении условий эксплуатации.</span>
          </div>
          <div className="w-full my-2">
            <span className='analis_application' style={{fontSize:'11px'}}>Знак соответствия проставляется: </span>
            <span className='font-semibold analis_application' style={{fontSize:'11px'}}>Не проставляется</span>
          </div>
          <div className="w-full flex">
            <div className="w-2/3 analis_application" style={{fontSize:'11px'}}>Руководитель органа по сертификации:</div>
            <div className="w-1/3 font-semibold flex justify-start "><span className='analis_application underline font-semibold'  style={{fontSize:'11px'}}>{item?.director.lastName} {item?.director?.firstName.slice(0,1)}</span></div>
          </div>
          <div className="w-full flex justify-between">
            <div className="w-2/3 analis_application" style={{fontSize:'11px'}}>Эксперт:</div>
            <div className="w-1/3 font-semibold flex justify-start "><span className='analis_application underline font-semibold'  style={{fontSize:'11px'}}>{item?.expert.lastName} {item?.expert?.firstName.slice(0,1)}</span></div>
          </div>
        </div>
        <div className="w-1/3 flex items-center justify-center">
          <img src={qr}/>
        </div>
       </div>
    </div>
    )
  }):""}
  </>
  );
}
export default StepSevenDocx;
