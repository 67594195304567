export interface stepOneType {
    id:number,
    label:string,
    key:string
}
export interface response_statement {
    dateOrder:string
    numberOrder:string
    company:string
    applicantFullName:string
    phoneNumber:string
    inn:string
    applicantAddress:string
    schemeCertification:string
}
export const stepOne:stepOneType[]=[
    {
        id:1,
        label:'Дата заявки (дд.мм.гггг)',
        key:'dateOrder'
    },
    {
        id:2,
        label:'Номер заявки',
        key:'numberOrder'
    },
    {
        id:3,
        label:'Предприятие',
        key:'company'
    },
    {
        id:5,
        label:'Ф.И.О руководителя заявителя',
        key:'applicantFullName'
    },
    {
        id:6,
        label:'Телефон',
        key:'phoneNumber'
    },
    {
        id:7,
        label:'ИНН',
        key:'inn'
    },
    {
        id:8,
        label:'Адрес заявителя',
        key:'applicantAddress'
    },
    {
        id:9,
        label:'Схема сертификации',
        key:'schemeCertification'
    },
    {
        id:10,
        label:"Исполнительный персонал",
        key:"user"
    }
]