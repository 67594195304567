import "./addAriza.css";
import { useState } from "react";
import { products } from "../../mockdata/AllData";
import { Button, DatePicker, Input, Checkbox } from "antd";
import Loading from "../../Component/Loading/Loading";
import SelectIndicator from "../../Component/Select/Select";
import { RequestToken } from "../../Hook/Request/Request";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
interface product {
  codeProduct: string;
  name: string;
  amount: number;
  price: number;
  numberHygeineCertificate: string;
  dateHygeineCertificate: string;
  regulatoryDocument: string;
  isTransported: boolean;
  manufacturer: string;
  extraInformation: string;
  dateOfProducing: string;
  expiryDate: string;
  numberOfSamplesTaken: number;
  numberOfForTesting: number;
  measurementType: string;
  measurementId: number;
  documentId: number;
  markId: number;
}
interface data {
  company: string;
  numberOrder: string;
  dateOrder: string;
  applicant: string;
  applicantAddress: string;
  applicantFullName: string;
  phoneNumber: string;
  madeIn: string;
  schemeCertification: string;
  dateOfReceiveTemplate: string;
  isActive: boolean;
  productDtoList: product[];
  inn: number;
}
function AddAriza() {
  const navigate=useNavigate()
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<data>({
    company: "string",
    numberOrder: "string",
    dateOrder: "2023-08-10T10:44:42.739Z",
    applicant: "string",
    applicantAddress: "string",
    applicantFullName: "string",
    phoneNumber: "string",
    madeIn: "string",
    schemeCertification: "string",
    dateOfReceiveTemplate: "2023-08-10T10:44:42.739Z",
    isActive: true,
    productDtoList: [
      {
        codeProduct: "string",
        name: "Iphone 15pro",
        amount: 2,
        price: 45,
        numberHygeineCertificate: "string",
        dateHygeineCertificate: "2023-08-10T10:44:42.739Z",
        regulatoryDocument: "string",
        isTransported: true,
        manufacturer: "string",
        extraInformation: "string",
        dateOfProducing: "2023-08-10T10:44:42.739Z",
        expiryDate: "2023-08-10T10:44:42.739Z",
        numberOfSamplesTaken: 5,
        numberOfForTesting: 7,
        measurementType: "string",
        measurementId: 1,
        documentId: 2,
        markId: 2,
      },
      {
        codeProduct: "string",
        name: "sumsung ultra",
        amount: 2,
        price: 7895,
        numberHygeineCertificate: "sumsung numberHygeineCertificate",
        dateHygeineCertificate: "2023-08-10T10:44:42.739Z",
        regulatoryDocument: "regulatoryDocument sumsung",
        isTransported: true,
        manufacturer: "sumsung manufacturer",
        extraInformation: "sumsung extraInformation",
        dateOfProducing: "2023-08-10T10:44:42.739Z",
        expiryDate: "2023-08-10T10:44:42.739Z",
        numberOfSamplesTaken: 5,
        numberOfForTesting: 7,
        measurementType: "sumsung measurementType",
        measurementId: 1,
        documentId: 2,
        markId: 2,
      }
    ],
    inn: 0,
  });
  function handleChange(parm: string, name: string) {
  }
  async function Save() {
    setLoading(false);
    const Data = await RequestToken("/order", "POST", data);
    setLoading(true);
    if(Data.success){
      Swal.fire({
        icon: 'success',
        title:'Cохранен',
        text: 'Теперь вы можете смотреть со страницы задач!',
      })
    }
    else if(Data.message==='Network Error'){
      navigate('/error/500')
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        text: 'Теперь вы можете смотреть со страницы задач!',
      })
    }
  }
  return (
    <div className="AddAriza">
      {loading ? (
        <>
          <div className="AddAriza_header">
            <SelectIndicator
              data={products}
              style={{
                width: "400px",
                height: "60px",
              }}
              HandleChange={handleChange}
              value={"1"}
              name="Схема cергтификация"
            />
            <Button type="dashed">Cancel</Button>
            <Button type="primary" onClick={Save}>
              Save
            </Button>
          </div>
          <div className="AddAriza_main">
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">company</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, company: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">numberOrder</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, numberOrder: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">dateOrder</div>
              <div className="AddAriza_main_row_right">
                <DatePicker
                  onChange={(date, dateparm) =>
                    setData({ ...data, dateOrder: dateparm })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">applicant</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, applicant: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">applicantAddress</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, applicantAddress: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">applicantFullName</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, applicantFullName: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">phoneNumber</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, phoneNumber: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">madeIn</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) => setData({ ...data, madeIn: e.target.value })}
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">schemeCertification</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, schemeCertification: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">
                dateOfReceiveTemplate
              </div>
              <div className="AddAriza_main_row_right">
                <DatePicker
                  onChange={(date, dateparm) =>
                    setData({ ...data, dateOfReceiveTemplate: dateparm })
                  }
                />
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">isActive</div>
              <div className="AddAriza_main_row_right">
                <Checkbox
                  checked={data.isActive}
                  onChange={(e) =>
                    setData({ ...data, isActive: e.target.checked })
                  }
                >
                  Active
                </Checkbox>
              </div>
            </div>
            <div className="AddAriza_main_row">
              <div className="AddAriza_main_row_left">inn</div>
              <div className="AddAriza_main_row_right">
                <Input
                  className="px-2 py-3"
                  type="number"
                  placeholder="Basic usage"
                  onChange={(e) =>
                    setData({ ...data, inn: parseInt(e.target.value) })
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default AddAriza;
