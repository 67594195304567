import { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { normativ_list_type } from "src/pages/standart/laboratory/indexTypes";
import { Empty, Input, Modal } from "antd";
import { Textarea } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import Loading from "src/Component/Loading/Loading";
interface props {
  normativ_list?: normativ_list_type[];
  getNormativList:()=>void
  loading:boolean
  setLoading:(loading:boolean)=>void
}
interface new_normative {
  normativeNumber: string;
  name: string;
  description: string;
}
const { TextArea } = Input;
function Normativ({ normativ_list,getNormativList,setLoading,loading}: props) {
  const navigate = useNavigate();
  const _role: string | null = localStorage.getItem("_role");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [updateModal,setUpdateModal]=useState<number|null>(null)
  const [new_normativ, setNew_Normativ] = useState<new_normative>({
    normativeNumber: "",
    name: "",
    description: "",
  });
  function DeleteNotifacion(id:number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите удалить это значение по умолчанию?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id)
      } 
    });
  }
  function error(response: any) {
    setLoading(true)
    if (
      response?.response?.status === 401 &&
      response?.response?.status === 403
    ) {
      navigate("/");
    } else {
      Swal.fire({
        title: "Ошибка",
        text: response?.response?.data?.message || response?.response?.messagem,
        icon: "error",
      });
    }
  }
  function UpdateModal(id:number){
    const new_obj=normativ_list?.find((item:normativ_list_type)=>item.id===id)
    if(new_obj){
      setNew_Normativ({...new_normativ,description:new_obj?.description,name:new_obj?.name,normativeNumber:new_obj?.normativeNumber})
    }
    setUpdateModal(id)
    setOpenModal(true)
  }
  function Cancel() {
    setOpenModal(false);
    setNew_Normativ({
      ...new_normativ,
      description: "",
      name: "",
      normativeNumber: "",
    });
    setUpdateModal(null)
  }
  async function Delete(id:number){
    setLoading(false)
    const res_normativ_list=await RequestToken(`/normative/${id}`,'DELETE',null)
    if(res_normativ_list.success){
      Swal.fire("Saved!", "", "success");
      getNormativList()
    }
    else{
      error(res_normativ_list)
    }
  }
  async function Saved(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(false)
    const res_data = await RequestToken(updateModal ? `/normative/${updateModal}`:`/normative`, updateModal ? "PUT":"POST", new_normativ);
    if (res_data.success) {
      setLoading(true)
      Swal.fire({
        title: "Успех ",
        text: res_data?.message,
        icon: "success",
      });
      Cancel();
      getNormativList()
    } else {
      error(res_data);
    }
  }
  return (
  <div style={{height:'100%'}} className="w-full h-full flex items-center justify-center">
    {
      loading ? <div className="w-full h-full overflow-auto">
      <div className={_role==='ADMIN_OF_LABORATORY' ? "hidden":"w-full mb-2"}>
        <button
          className="px-3 py-2 bg-[#1677FF] rounded-md text-[#fff]"
          onClick={() => setOpenModal(true)}
        >
          Добавить новый ГОСТ
        </button>
      </div>
      <table className="w-full h-auto">
        <thead>
          <tr>
            <th style={{ width: "60px" }} className="bg-[#F2F4F8] p-3">
              Номер
            </th>
            <th className="bg-[#F2F4F8] p-3">Стандартный номер</th>
            <th className="bg-[#F2F4F8] p-3">Нормативное название</th>
            <th className="bg-[#F2F4F8] p-3">Нормативное описание</th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
            <th
              className={
                _role === "ADMIN_OF_LABORATORY" ? "bg-[#F2F4F8] p-3" : "hidden"
              }
              style={{ width: "60px" }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {normativ_list ? (
            normativ_list?.map((item: normativ_list_type, index: number) => {
              return (
                <tr key={index}>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {index+1}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.normativeNumber}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.name}
                  </td>
                  <td style={{ border: "1px solid #DDE1E6" }} className="p-3">
                    {item.description}
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span className="text-xl text-[red]  cursor-pointer" onClick={()=>DeleteNotifacion(item.id)}>
                      <DeleteOutlined />
                    </span>
                  </td>
                  <td
                    style={{ border: "1px solid #DDE1E6" }}
                    className={
                      _role === "ADMIN_OF_LABORATORY"
                        ? "text-center p-3"
                        : "hidden"
                    }
                  >
                    <span className="text-xl cursor-pointer" onClick={()=>UpdateModal(item.id)}>
                      <EditOutlined />
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={_role === "ADMIN_OF_LABORATORY" ? 6 : 4}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>:<Loading/>
    }
    <Modal centered open={openModal} footer={null} onCancel={Cancel}>
        <div className="w-full font-semibold text-2xl">Добавить новый ГОСТ</div>
        <form action="normativ_saved" onSubmit={Saved}>
          <label className="mt-2" htmlFor="normativNumber">
            Нормативный номер
          </label>
          <Input
            required
            id="normativNumber"
            className="h-11"
            placeholder="O‘z DSt 1060:2004"
            onChange={(e) =>
              setNew_Normativ({
                ...new_normativ,
                normativeNumber: e.target.value,
              })
            }
            value={new_normativ.normativeNumber}
          />
          <label className="mt-2" htmlFor="normativname">
            Нормативное название
          </label>
          <TextArea
            className="h-32"
            value={new_normativ.name}
            required
            onChange={(e) =>
              setNew_Normativ({ ...new_normativ, name: e.target.value })
            }
            id="normativname"
            placeholder="O‘z DSt 1060:2004"
          />
          <label className="mt-4" htmlFor="normativdecription">
            Нормативное описание
          </label>
          <TextArea
            className="h-32"
            value={new_normativ.description}
            required
            onChange={(e) =>
              setNew_Normativ({ ...new_normativ, description: e.target.value })
            }
            id="normativdecription"
            placeholder="O‘z DSt 1060:2004"
          />
          <div className="w-full flex justify-end my-2">
            <button
              type="button"
              onClick={Cancel}
              className="px-3 mx-2 py-2 rounded-md border-[#ccc] border-2"
            >
              Oтмена
            </button>
            <input
              className="px-3 py-2 bg-[#1677FF] text-[#fff] cursor-pointer rounded-md"
              type="submit"
              value={"Сохранить"}
              id="normativ_saved"
            />
          </div>
        </form>
      </Modal>
  </div>
  );
}
export default Normativ;
