export function FilesIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="91"
        height="90"
        viewBox="0 0 131 118"
        fill="none"
      >
        <path
          d="M67.0896 0.256572L62.8271 1.35579L10.3121 14.8983L6.04959 15.9976C3.98062 16.5336 2.20876 17.8684 1.12274 19.7092C0.0367206 21.55 -0.27477 23.7464 0.256607 25.8166L18.7398 97.4902C19.2758 99.5592 20.6107 101.331 22.4514 102.417C24.2922 103.503 26.4887 103.815 28.5588 103.283L28.5698 103.28L89.5878 87.5451L89.5988 87.5422C91.6678 87.0062 93.4397 85.6714 94.5257 83.8306C95.6117 81.9898 95.9232 79.7934 95.3918 77.7232L76.9086 6.0496C76.3726 3.98062 75.0378 2.20875 73.197 1.12272C71.3562 0.0366963 69.1598 -0.274801 67.0896 0.256572Z"
          fill="#F2F2F2"
        />
        <path
          d="M67.6689 2.50346L62.6202 3.8054L11.6774 16.9425L6.62882 18.2445C5.15545 18.6262 3.89366 19.5767 3.12028 20.8876C2.34689 22.1985 2.12507 23.7626 2.50347 25.2368L20.9867 96.9104C21.3684 98.3838 22.3189 99.6456 23.6298 100.419C24.9407 101.192 26.5048 101.414 27.979 101.036L27.99 101.033L89.008 85.2976L89.0191 85.2947C90.4924 84.913 91.7542 83.9624 92.5276 82.6516C93.3009 81.3407 93.5228 79.7766 93.1444 78.3024L74.6612 6.62881C74.2795 5.15546 73.3289 3.89368 72.0181 3.12029C70.7072 2.3469 69.1431 2.12507 67.6689 2.50346Z"
          fill="white"
        />
        <path
          d="M63.8567 25.7133L32.9892 33.6734C32.6444 33.7623 32.2785 33.7106 31.9718 33.5297C31.6652 33.3488 31.443 33.0534 31.3541 32.7087C31.2651 32.3639 31.3168 31.998 31.4978 31.6913C31.6787 31.3847 31.974 31.1625 32.3187 31.0735L63.1862 23.1134C63.5309 23.0249 63.8966 23.0768 64.203 23.2577C64.5094 23.4387 64.7314 23.7339 64.8202 24.0784C64.9091 24.423 64.8575 24.7887 64.6768 25.0953C64.4962 25.4018 64.2012 25.6241 63.8567 25.7133Z"
          fill="#F2F2F2"
        />
        <path
          d="M70.3502 28.7177L34.1205 38.0606C33.7758 38.1495 33.4098 38.0978 33.1032 37.9169C32.7965 37.736 32.5743 37.4406 32.4854 37.0959C32.3965 36.7511 32.4482 36.3852 32.6291 36.0785C32.81 35.7719 33.1053 35.5497 33.4501 35.4608L69.6797 26.1178C70.0245 26.0289 70.3905 26.0806 70.6971 26.2615C71.0038 26.4425 71.226 26.7378 71.3149 27.0825C71.4038 27.4273 71.3521 27.7933 71.1712 28.0999C70.9903 28.4065 70.695 28.6288 70.3502 28.7177Z"
          fill="#F2F2F2"
        />
        <path
          d="M68.927 45.3745L38.0595 53.3346C37.8888 53.3786 37.7111 53.3886 37.5365 53.3639C37.362 53.3393 37.194 53.2805 37.0421 53.1909C36.8903 53.1013 36.7576 52.9827 36.6516 52.8418C36.5456 52.701 36.4684 52.5406 36.4244 52.3699C36.3803 52.1992 36.3704 52.0215 36.395 51.8469C36.4197 51.6724 36.4785 51.5044 36.5681 51.3525C36.6576 51.2007 36.7763 51.068 36.9171 50.962C37.058 50.856 37.2183 50.7788 37.3891 50.7348L68.2566 42.7746C68.4273 42.7306 68.605 42.7207 68.7795 42.7453C68.9541 42.77 69.1221 42.8288 69.2739 42.9183C69.4258 43.0079 69.5585 43.1265 69.6645 43.2674C69.7705 43.4083 69.8477 43.5686 69.8917 43.7393C69.9357 43.91 69.9457 44.0878 69.921 44.2623C69.8964 44.4369 69.8376 44.6049 69.748 44.7567C69.6584 44.9085 69.5398 45.0412 69.3989 45.1472C69.2581 45.2532 69.0977 45.3305 68.927 45.3745Z"
          fill="#F2F2F2"
        />
        <path
          d="M75.4205 48.3789L39.1908 57.7218C39.0201 57.7658 38.8424 57.7758 38.6679 57.7511C38.4933 57.7265 38.3253 57.6677 38.1735 57.5781C38.0216 57.4885 37.8889 57.3699 37.7829 57.229C37.6769 57.0882 37.5997 56.9278 37.5557 56.7571C37.5117 56.5864 37.5017 56.4087 37.5264 56.2341C37.551 56.0595 37.6098 55.8916 37.6994 55.7397C37.789 55.5879 37.9076 55.4552 38.0485 55.3492C38.1893 55.2432 38.3497 55.166 38.5204 55.1219L74.7501 45.779C75.0948 45.6901 75.4608 45.7418 75.7674 45.9227C76.0741 46.1037 76.2963 46.399 76.3852 46.7437C76.4741 47.0885 76.4224 47.4545 76.2415 47.7611C76.0606 48.0677 75.7653 48.29 75.4205 48.3789Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.9972 65.0357L43.1296 72.9958C42.785 73.0844 42.4192 73.0326 42.1128 72.8516C41.8063 72.6707 41.5843 72.3755 41.4954 72.0309C41.4065 71.6863 41.4581 71.3205 41.6389 71.0139C41.8196 70.7073 42.1147 70.4851 42.4592 70.396L73.3267 62.4358C73.6715 62.3469 74.0374 62.3986 74.3441 62.5795C74.6507 62.7605 74.8729 63.0558 74.9618 63.4005C75.0508 63.7453 74.9991 64.1112 74.8181 64.4179C74.6372 64.7245 74.3419 64.9468 73.9972 65.0357Z"
          fill="#F2F2F2"
        />
        <path
          d="M80.4908 68.0401L44.2611 77.383C44.0903 77.4272 43.9125 77.4373 43.7379 77.4127C43.5632 77.3882 43.3951 77.3294 43.2431 77.2399C43.0911 77.1503 42.9583 77.0317 42.8522 76.8908C42.7461 76.7498 42.6689 76.5894 42.6248 76.4186C42.5808 76.2478 42.5708 76.07 42.5955 75.8953C42.6202 75.7207 42.6791 75.5526 42.7688 75.4007C42.8585 75.2488 42.9773 75.1161 43.1183 75.0101C43.2593 74.9042 43.4198 74.827 43.5907 74.7831L79.8203 65.4402C80.1651 65.3513 80.531 65.403 80.8377 65.5839C81.1443 65.7649 81.3665 66.0602 81.4555 66.4049C81.5444 66.7497 81.4927 67.1156 81.3118 67.4223C81.1308 67.7289 80.8355 67.9512 80.4908 68.0401Z"
          fill="#F2F2F2"
        />
        <path
          d="M27.7197 41.8353L16.7959 44.6523C16.6306 44.6948 16.4553 44.6699 16.3083 44.5832C16.1613 44.4964 16.0547 44.355 16.0119 44.1897L13.5044 34.4661C13.4619 34.3008 13.4868 34.1254 13.5735 33.9784C13.6602 33.8315 13.8017 33.7249 13.9669 33.6821L24.8907 30.8651C25.056 30.8226 25.2313 30.8475 25.3783 30.9342C25.5253 31.0209 25.6319 31.1624 25.6747 31.3276L28.1822 41.0513C28.2247 41.2166 28.1998 41.3919 28.1131 41.5389C28.0264 41.6859 27.8849 41.7925 27.7197 41.8353Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.79 61.4965L21.8663 64.3135C21.701 64.3559 21.5256 64.3311 21.3786 64.2444C21.2316 64.1576 21.125 64.0161 21.0822 63.8509L18.5747 54.1273C18.5322 53.962 18.5571 53.7866 18.6438 53.6396C18.7306 53.4926 18.872 53.3861 19.0372 53.3433L29.961 50.5263C30.1263 50.4838 30.3017 50.5087 30.4486 50.5954C30.5956 50.6821 30.7022 50.8236 30.745 50.9888L33.2525 60.7125C33.295 60.8778 33.2701 61.0531 33.1834 61.2001C33.0967 61.3471 32.9552 61.4537 32.79 61.4965Z"
          fill="#E6E6E6"
        />
        <path
          d="M37.86 81.1577L26.9363 83.9747C26.771 84.0171 26.5956 83.9922 26.4487 83.9055C26.3017 83.8188 26.1951 83.6773 26.1523 83.5121L23.6448 73.7885C23.6023 73.6232 23.6272 73.4478 23.7139 73.3008C23.8006 73.1538 23.9421 73.0472 24.1073 73.0044L35.031 70.1874C35.1963 70.145 35.3717 70.1699 35.5187 70.2566C35.6657 70.3433 35.7723 70.4848 35.8151 70.65L38.3226 80.3736C38.3651 80.5389 38.3402 80.7143 38.2535 80.8613C38.1667 81.0083 38.0253 81.1149 37.86 81.1577Z"
          fill="#E6E6E6"
        />
        <path
          d="M109.662 18.4247H46.6249C44.4876 18.4271 42.4385 19.2772 40.9273 20.7884C39.416 22.2997 38.5659 24.3488 38.5635 26.4861V100.505C38.5659 102.642 39.416 104.691 40.9273 106.202C42.4385 107.713 44.4876 108.564 46.6249 108.566H109.662C111.799 108.564 113.848 107.713 115.359 106.202C116.871 104.691 117.721 102.642 117.723 100.505V26.4861C117.721 24.3488 116.871 22.2997 115.359 20.7884C113.848 19.2772 111.799 18.4271 109.662 18.4247Z"
          fill="#E6E6E6"
        />
        <path
          d="M109.662 20.7454H46.6248C45.1027 20.7471 43.6436 21.3525 42.5673 22.4287C41.4911 23.5049 40.8857 24.9641 40.884 26.4861V100.505C40.8857 102.027 41.4911 103.486 42.5673 104.562C43.6436 105.638 45.1027 106.244 46.6248 106.245H109.662C111.184 106.244 112.643 105.638 113.719 104.562C114.795 103.486 115.401 102.027 115.402 100.505V26.4861C115.401 24.9641 114.795 23.5049 113.719 22.4287C112.643 21.3525 111.184 20.7471 109.662 20.7454Z"
          fill="white"
        />
        <path
          d="M100.175 61.4183H68.2973C68.1209 61.4185 67.9462 61.384 67.7831 61.3166C67.6201 61.2492 67.4719 61.1504 67.3471 61.0257C67.2223 60.901 67.1233 60.7529 67.0557 60.59C66.9881 60.427 66.9534 60.2523 66.9534 60.0759C66.9534 59.8995 66.9881 59.7248 67.0557 59.5618C67.1233 59.3989 67.2223 59.2508 67.3471 59.1261C67.4719 59.0014 67.6201 58.9026 67.7831 58.8352C67.9462 58.7678 68.1209 58.7333 68.2973 58.7335H100.175C100.53 58.7339 100.872 58.8755 101.123 59.1272C101.374 59.3789 101.516 59.7201 101.516 60.0759C101.516 60.4317 101.374 60.7729 101.123 61.0246C100.872 61.2763 100.53 61.4179 100.175 61.4183Z"
          fill="#E6E6E6"
        />
        <path
          d="M105.712 65.9491H68.2973C68.1209 65.9493 67.9462 65.9147 67.7831 65.8473C67.6201 65.7799 67.4719 65.6811 67.3471 65.5564C67.2223 65.4317 67.1233 65.2837 67.0557 65.1207C66.9881 64.9577 66.9534 64.783 66.9534 64.6066C66.9534 64.4302 66.9881 64.2555 67.0557 64.0926C67.1233 63.9296 67.2223 63.7815 67.3471 63.6568C67.4719 63.5322 67.6201 63.4333 67.7831 63.3659C67.9462 63.2986 68.1209 63.264 68.2973 63.2642H105.712C106.068 63.2642 106.41 63.4056 106.662 63.6574C106.913 63.9091 107.055 64.2506 107.055 64.6066C107.055 64.9627 106.913 65.3041 106.662 65.5559C106.41 65.8076 106.068 65.9491 105.712 65.9491Z"
          fill="#E6E6E6"
        />
        <path
          d="M100.175 81.7228H68.2973C68.1209 81.723 67.9462 81.6884 67.7831 81.621C67.6201 81.5537 67.4719 81.4548 67.3471 81.3301C67.2223 81.2055 67.1233 81.0574 67.0557 80.8944C66.9881 80.7315 66.9534 80.5568 66.9534 80.3803C66.9534 80.2039 66.9881 80.0292 67.0557 79.8663C67.1233 79.7033 67.2223 79.5552 67.3471 79.4306C67.4719 79.3059 67.6201 79.207 67.7831 79.1397C67.9462 79.0723 68.1209 79.0377 68.2973 79.0379H100.175C100.531 79.0379 100.872 79.1793 101.124 79.4311C101.376 79.6829 101.517 80.0243 101.517 80.3803C101.517 80.7364 101.376 81.0778 101.124 81.3296C100.872 81.5814 100.531 81.7228 100.175 81.7228Z"
          fill="#E6E6E6"
        />
        <path
          d="M105.712 86.2535H68.2973C68.1209 86.2537 67.9462 86.2191 67.7831 86.1517C67.6201 86.0844 67.4719 85.9855 67.3471 85.8608C67.2223 85.7362 67.1233 85.5881 67.0557 85.4251C66.9881 85.2622 66.9534 85.0875 66.9534 84.9111C66.9534 84.7346 66.9881 84.5599 67.0557 84.397C67.1233 84.234 67.2223 84.0859 67.3471 83.9613C67.4719 83.8366 67.6201 83.7377 67.7831 83.6704C67.9462 83.603 68.1209 83.5684 68.2973 83.5686H105.712C105.889 83.5684 106.063 83.603 106.226 83.6704C106.389 83.7377 106.538 83.8366 106.662 83.9613C106.787 84.0859 106.886 84.234 106.954 84.397C107.021 84.5599 107.056 84.7346 107.056 84.9111C107.056 85.0875 107.021 85.2622 106.954 85.4251C106.886 85.5881 106.787 85.7362 106.662 85.8608C106.538 85.9855 106.389 86.0844 106.226 86.1517C106.063 86.2191 105.889 86.2537 105.712 86.2535Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.1565 68.0058H49.8754C49.7047 68.0056 49.5411 67.9377 49.4204 67.8171C49.2998 67.6964 49.2319 67.5328 49.2317 67.3621V57.3204C49.2319 57.1497 49.2998 56.9861 49.4204 56.8654C49.5411 56.7447 49.7047 56.6769 49.8754 56.6767H61.1565C61.3271 56.6769 61.4908 56.7447 61.6115 56.8654C61.7321 56.9861 61.8 57.1497 61.8002 57.3204V67.3621C61.8 67.5328 61.7321 67.6964 61.6115 67.8171C61.4908 67.9377 61.3271 68.0056 61.1565 68.0058Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.1565 88.3103H49.8754C49.7047 88.3101 49.5411 88.2422 49.4204 88.1215C49.2998 88.0008 49.2319 87.8372 49.2317 87.6666V77.6248C49.2319 77.4541 49.2998 77.2905 49.4204 77.1699C49.5411 77.0492 49.7047 76.9813 49.8754 76.9811H61.1565C61.3271 76.9813 61.4908 77.0492 61.6115 77.1699C61.7321 77.2905 61.8 77.4541 61.8002 77.6248V87.6666C61.8 87.8372 61.7321 88.0008 61.6115 88.1215C61.4908 88.2422 61.3271 88.3101 61.1565 88.3103Z"
          fill="#E6E6E6"
        />
        <path
          d="M100.21 38.8155H76.7082C76.3521 38.8155 76.0107 38.6741 75.7589 38.4223C75.5072 38.1705 75.3657 37.8291 75.3657 37.4731C75.3657 37.117 75.5072 36.7756 75.7589 36.5238C76.0107 36.272 76.3521 36.1306 76.7082 36.1306H100.21C100.566 36.1306 100.908 36.272 101.159 36.5238C101.411 36.7756 101.552 37.117 101.552 37.4731C101.552 37.8291 101.411 38.1705 101.159 38.4223C100.908 38.6741 100.566 38.8155 100.21 38.8155Z"
          fill="#CCCCCC"
        />
        <path
          d="M105.748 43.3463H76.7082C76.5319 43.3463 76.3573 43.3115 76.1944 43.2441C76.0316 43.1766 75.8836 43.0777 75.7589 42.9531C75.6343 42.8284 75.5354 42.6804 75.4679 42.5175C75.4004 42.3547 75.3657 42.1801 75.3657 42.0038C75.3657 41.8275 75.4004 41.653 75.4679 41.4901C75.5354 41.3272 75.6343 41.1792 75.7589 41.0546C75.8836 40.9299 76.0316 40.831 76.1944 40.7636C76.3573 40.6961 76.5319 40.6614 76.7082 40.6614H105.748C106.104 40.6614 106.445 40.8028 106.697 41.0546C106.949 41.3063 107.09 41.6478 107.09 42.0038C107.09 42.3599 106.949 42.7013 106.697 42.9531C106.445 43.2048 106.104 43.3463 105.748 43.3463Z"
          fill="#CCCCCC"
        />
        <path
          d="M71.5067 48.8369H49.84C49.6693 48.8367 49.5057 48.7689 49.385 48.6482C49.2644 48.5275 49.1965 48.3639 49.1963 48.1932V31.2836C49.1965 31.1129 49.2644 30.9493 49.385 30.8286C49.5057 30.708 49.6693 30.6401 49.84 30.6399H71.5067C71.6774 30.6401 71.841 30.708 71.9616 30.8286C72.0823 30.9493 72.1502 31.1129 72.1504 31.2836V48.1932C72.1502 48.3639 72.0823 48.5275 71.9616 48.6482C71.841 48.7689 71.6774 48.8367 71.5067 48.8369Z"
          fill="#6C63FF"
        />
      </svg>
    );
  }
  export function FileIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="131"
        height="118"
        viewBox="0 0 131 118"
        fill="none"
      >
        <path
          d="M67.0896 0.256572L62.8271 1.35579L10.3121 14.8983L6.04959 15.9976C3.98062 16.5336 2.20876 17.8684 1.12274 19.7092C0.0367206 21.55 -0.27477 23.7464 0.256607 25.8166L18.7398 97.4902C19.2758 99.5592 20.6107 101.331 22.4514 102.417C24.2922 103.503 26.4887 103.815 28.5588 103.283L28.5698 103.28L89.5878 87.5451L89.5988 87.5422C91.6678 87.0062 93.4397 85.6714 94.5257 83.8306C95.6117 81.9898 95.9232 79.7934 95.3918 77.7232L76.9086 6.0496C76.3726 3.98062 75.0378 2.20875 73.197 1.12272C71.3562 0.0366963 69.1598 -0.274801 67.0896 0.256572Z"
          fill="#F2F2F2"
        />
        <path
          d="M67.6689 2.50346L62.6202 3.8054L11.6774 16.9425L6.62882 18.2445C5.15545 18.6262 3.89366 19.5767 3.12028 20.8876C2.34689 22.1985 2.12507 23.7626 2.50347 25.2368L20.9867 96.9104C21.3684 98.3838 22.3189 99.6456 23.6298 100.419C24.9407 101.192 26.5048 101.414 27.979 101.036L27.99 101.033L89.008 85.2976L89.0191 85.2947C90.4924 84.913 91.7542 83.9624 92.5276 82.6516C93.3009 81.3407 93.5228 79.7766 93.1444 78.3024L74.6612 6.62881C74.2795 5.15546 73.3289 3.89368 72.0181 3.12029C70.7072 2.3469 69.1431 2.12507 67.6689 2.50346Z"
          fill="white"
        />
        <path
          d="M63.8567 25.7133L32.9892 33.6734C32.6444 33.7623 32.2785 33.7106 31.9718 33.5297C31.6652 33.3488 31.443 33.0534 31.3541 32.7087C31.2651 32.3639 31.3168 31.998 31.4978 31.6913C31.6787 31.3847 31.974 31.1625 32.3187 31.0735L63.1862 23.1134C63.5309 23.0249 63.8966 23.0768 64.203 23.2577C64.5094 23.4387 64.7314 23.7339 64.8202 24.0784C64.9091 24.423 64.8575 24.7887 64.6768 25.0953C64.4962 25.4018 64.2012 25.6241 63.8567 25.7133Z"
          fill="#F2F2F2"
        />
        <path
          d="M70.3502 28.7177L34.1205 38.0606C33.7758 38.1495 33.4098 38.0978 33.1032 37.9169C32.7965 37.736 32.5743 37.4406 32.4854 37.0959C32.3965 36.7511 32.4482 36.3852 32.6291 36.0785C32.81 35.7719 33.1053 35.5497 33.4501 35.4608L69.6797 26.1178C70.0245 26.0289 70.3905 26.0806 70.6971 26.2615C71.0038 26.4425 71.226 26.7378 71.3149 27.0825C71.4038 27.4273 71.3521 27.7933 71.1712 28.0999C70.9903 28.4065 70.695 28.6288 70.3502 28.7177Z"
          fill="#F2F2F2"
        />
        <path
          d="M68.927 45.3745L38.0595 53.3346C37.8888 53.3786 37.7111 53.3886 37.5365 53.3639C37.362 53.3393 37.194 53.2805 37.0421 53.1909C36.8903 53.1013 36.7576 52.9827 36.6516 52.8418C36.5456 52.701 36.4684 52.5406 36.4244 52.3699C36.3803 52.1992 36.3704 52.0215 36.395 51.8469C36.4197 51.6724 36.4785 51.5044 36.5681 51.3525C36.6576 51.2007 36.7763 51.068 36.9171 50.962C37.058 50.856 37.2183 50.7788 37.3891 50.7348L68.2566 42.7746C68.4273 42.7306 68.605 42.7207 68.7795 42.7453C68.9541 42.77 69.1221 42.8288 69.2739 42.9183C69.4258 43.0079 69.5585 43.1265 69.6645 43.2674C69.7705 43.4083 69.8477 43.5686 69.8917 43.7393C69.9357 43.91 69.9457 44.0878 69.921 44.2623C69.8964 44.4369 69.8376 44.6049 69.748 44.7567C69.6584 44.9085 69.5398 45.0412 69.3989 45.1472C69.2581 45.2532 69.0977 45.3305 68.927 45.3745Z"
          fill="#F2F2F2"
        />
        <path
          d="M75.4205 48.3789L39.1908 57.7218C39.0201 57.7658 38.8424 57.7758 38.6679 57.7511C38.4933 57.7265 38.3253 57.6677 38.1735 57.5781C38.0216 57.4885 37.8889 57.3699 37.7829 57.229C37.6769 57.0882 37.5997 56.9278 37.5557 56.7571C37.5117 56.5864 37.5017 56.4087 37.5264 56.2341C37.551 56.0595 37.6098 55.8916 37.6994 55.7397C37.789 55.5879 37.9076 55.4552 38.0485 55.3492C38.1893 55.2432 38.3497 55.166 38.5204 55.1219L74.7501 45.779C75.0948 45.6901 75.4608 45.7418 75.7674 45.9227C76.0741 46.1037 76.2963 46.399 76.3852 46.7437C76.4741 47.0885 76.4224 47.4545 76.2415 47.7611C76.0606 48.0677 75.7653 48.29 75.4205 48.3789Z"
          fill="#F2F2F2"
        />
        <path
          d="M73.9972 65.0357L43.1296 72.9958C42.785 73.0844 42.4192 73.0326 42.1128 72.8516C41.8063 72.6707 41.5843 72.3755 41.4954 72.0309C41.4065 71.6863 41.4581 71.3205 41.6389 71.0139C41.8196 70.7073 42.1147 70.4851 42.4592 70.396L73.3267 62.4358C73.6715 62.3469 74.0374 62.3986 74.3441 62.5795C74.6507 62.7605 74.8729 63.0558 74.9618 63.4005C75.0508 63.7453 74.9991 64.1112 74.8181 64.4179C74.6372 64.7245 74.3419 64.9468 73.9972 65.0357Z"
          fill="#F2F2F2"
        />
        <path
          d="M80.4908 68.0401L44.2611 77.383C44.0903 77.4272 43.9125 77.4373 43.7379 77.4127C43.5632 77.3882 43.3951 77.3294 43.2431 77.2399C43.0911 77.1503 42.9583 77.0317 42.8522 76.8908C42.7461 76.7498 42.6689 76.5894 42.6248 76.4186C42.5808 76.2478 42.5708 76.07 42.5955 75.8953C42.6202 75.7207 42.6791 75.5526 42.7688 75.4007C42.8585 75.2488 42.9773 75.1161 43.1183 75.0101C43.2593 74.9042 43.4198 74.827 43.5907 74.7831L79.8203 65.4402C80.1651 65.3513 80.531 65.403 80.8377 65.5839C81.1443 65.7649 81.3665 66.0602 81.4555 66.4049C81.5444 66.7497 81.4927 67.1156 81.3118 67.4223C81.1308 67.7289 80.8355 67.9512 80.4908 68.0401Z"
          fill="#F2F2F2"
        />
        <path
          d="M27.7197 41.8353L16.7959 44.6523C16.6306 44.6948 16.4553 44.6699 16.3083 44.5832C16.1613 44.4964 16.0547 44.355 16.0119 44.1897L13.5044 34.4661C13.4619 34.3008 13.4868 34.1254 13.5735 33.9784C13.6602 33.8315 13.8017 33.7249 13.9669 33.6821L24.8907 30.8651C25.056 30.8226 25.2313 30.8475 25.3783 30.9342C25.5253 31.0209 25.6319 31.1624 25.6747 31.3276L28.1822 41.0513C28.2247 41.2166 28.1998 41.3919 28.1131 41.5389C28.0264 41.6859 27.8849 41.7925 27.7197 41.8353Z"
          fill="#E6E6E6"
        />
        <path
          d="M32.79 61.4965L21.8663 64.3135C21.701 64.3559 21.5256 64.3311 21.3786 64.2444C21.2316 64.1576 21.125 64.0161 21.0822 63.8509L18.5747 54.1273C18.5322 53.962 18.5571 53.7866 18.6438 53.6396C18.7306 53.4926 18.872 53.3861 19.0372 53.3433L29.961 50.5263C30.1263 50.4838 30.3017 50.5087 30.4486 50.5954C30.5956 50.6821 30.7022 50.8236 30.745 50.9888L33.2525 60.7125C33.295 60.8778 33.2701 61.0531 33.1834 61.2001C33.0967 61.3471 32.9552 61.4537 32.79 61.4965Z"
          fill="#E6E6E6"
        />
        <path
          d="M37.86 81.1577L26.9363 83.9747C26.771 84.0171 26.5956 83.9922 26.4487 83.9055C26.3017 83.8188 26.1951 83.6773 26.1523 83.5121L23.6448 73.7885C23.6023 73.6232 23.6272 73.4478 23.7139 73.3008C23.8006 73.1538 23.9421 73.0472 24.1073 73.0044L35.031 70.1874C35.1963 70.145 35.3717 70.1699 35.5187 70.2566C35.6657 70.3433 35.7723 70.4848 35.8151 70.65L38.3226 80.3736C38.3651 80.5389 38.3402 80.7143 38.2535 80.8613C38.1667 81.0083 38.0253 81.1149 37.86 81.1577Z"
          fill="#E6E6E6"
        />
        <path
          d="M109.662 18.4247H46.6249C44.4876 18.4271 42.4385 19.2772 40.9273 20.7884C39.416 22.2997 38.5659 24.3488 38.5635 26.4861V100.505C38.5659 102.642 39.416 104.691 40.9273 106.202C42.4385 107.713 44.4876 108.564 46.6249 108.566H109.662C111.799 108.564 113.848 107.713 115.359 106.202C116.871 104.691 117.721 102.642 117.723 100.505V26.4861C117.721 24.3488 116.871 22.2997 115.359 20.7884C113.848 19.2772 111.799 18.4271 109.662 18.4247Z"
          fill="#E6E6E6"
        />
        <path
          d="M109.662 20.7454H46.6248C45.1027 20.7471 43.6436 21.3525 42.5673 22.4287C41.4911 23.5049 40.8857 24.9641 40.884 26.4861V100.505C40.8857 102.027 41.4911 103.486 42.5673 104.562C43.6436 105.638 45.1027 106.244 46.6248 106.245H109.662C111.184 106.244 112.643 105.638 113.719 104.562C114.795 103.486 115.401 102.027 115.402 100.505V26.4861C115.401 24.9641 114.795 23.5049 113.719 22.4287C112.643 21.3525 111.184 20.7471 109.662 20.7454Z"
          fill="white"
        />
        <path
          d="M100.175 61.4183H68.2973C68.1209 61.4185 67.9462 61.384 67.7831 61.3166C67.6201 61.2492 67.4719 61.1504 67.3471 61.0257C67.2223 60.901 67.1233 60.7529 67.0557 60.59C66.9881 60.427 66.9534 60.2523 66.9534 60.0759C66.9534 59.8995 66.9881 59.7248 67.0557 59.5618C67.1233 59.3989 67.2223 59.2508 67.3471 59.1261C67.4719 59.0014 67.6201 58.9026 67.7831 58.8352C67.9462 58.7678 68.1209 58.7333 68.2973 58.7335H100.175C100.53 58.7339 100.872 58.8755 101.123 59.1272C101.374 59.3789 101.516 59.7201 101.516 60.0759C101.516 60.4317 101.374 60.7729 101.123 61.0246C100.872 61.2763 100.53 61.4179 100.175 61.4183Z"
          fill="#E6E6E6"
        />
        <path
          d="M105.712 65.9491H68.2973C68.1209 65.9493 67.9462 65.9147 67.7831 65.8473C67.6201 65.7799 67.4719 65.6811 67.3471 65.5564C67.2223 65.4317 67.1233 65.2837 67.0557 65.1207C66.9881 64.9577 66.9534 64.783 66.9534 64.6066C66.9534 64.4302 66.9881 64.2555 67.0557 64.0926C67.1233 63.9296 67.2223 63.7815 67.3471 63.6568C67.4719 63.5322 67.6201 63.4333 67.7831 63.3659C67.9462 63.2986 68.1209 63.264 68.2973 63.2642H105.712C106.068 63.2642 106.41 63.4056 106.662 63.6574C106.913 63.9091 107.055 64.2506 107.055 64.6066C107.055 64.9627 106.913 65.3041 106.662 65.5559C106.41 65.8076 106.068 65.9491 105.712 65.9491Z"
          fill="#E6E6E6"
        />
        <path
          d="M100.175 81.7228H68.2973C68.1209 81.723 67.9462 81.6884 67.7831 81.621C67.6201 81.5537 67.4719 81.4548 67.3471 81.3301C67.2223 81.2055 67.1233 81.0574 67.0557 80.8944C66.9881 80.7315 66.9534 80.5568 66.9534 80.3803C66.9534 80.2039 66.9881 80.0292 67.0557 79.8663C67.1233 79.7033 67.2223 79.5552 67.3471 79.4306C67.4719 79.3059 67.6201 79.207 67.7831 79.1397C67.9462 79.0723 68.1209 79.0377 68.2973 79.0379H100.175C100.531 79.0379 100.872 79.1793 101.124 79.4311C101.376 79.6829 101.517 80.0243 101.517 80.3803C101.517 80.7364 101.376 81.0778 101.124 81.3296C100.872 81.5814 100.531 81.7228 100.175 81.7228Z"
          fill="#E6E6E6"
        />
        <path
          d="M105.712 86.2535H68.2973C68.1209 86.2537 67.9462 86.2191 67.7831 86.1517C67.6201 86.0844 67.4719 85.9855 67.3471 85.8608C67.2223 85.7362 67.1233 85.5881 67.0557 85.4251C66.9881 85.2622 66.9534 85.0875 66.9534 84.9111C66.9534 84.7346 66.9881 84.5599 67.0557 84.397C67.1233 84.234 67.2223 84.0859 67.3471 83.9613C67.4719 83.8366 67.6201 83.7377 67.7831 83.6704C67.9462 83.603 68.1209 83.5684 68.2973 83.5686H105.712C105.889 83.5684 106.063 83.603 106.226 83.6704C106.389 83.7377 106.538 83.8366 106.662 83.9613C106.787 84.0859 106.886 84.234 106.954 84.397C107.021 84.5599 107.056 84.7346 107.056 84.9111C107.056 85.0875 107.021 85.2622 106.954 85.4251C106.886 85.5881 106.787 85.7362 106.662 85.8608C106.538 85.9855 106.389 86.0844 106.226 86.1517C106.063 86.2191 105.889 86.2537 105.712 86.2535Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.1565 68.0058H49.8754C49.7047 68.0056 49.5411 67.9377 49.4204 67.8171C49.2998 67.6964 49.2319 67.5328 49.2317 67.3621V57.3204C49.2319 57.1497 49.2998 56.9861 49.4204 56.8654C49.5411 56.7447 49.7047 56.6769 49.8754 56.6767H61.1565C61.3271 56.6769 61.4908 56.7447 61.6115 56.8654C61.7321 56.9861 61.8 57.1497 61.8002 57.3204V67.3621C61.8 67.5328 61.7321 67.6964 61.6115 67.8171C61.4908 67.9377 61.3271 68.0056 61.1565 68.0058Z"
          fill="#E6E6E6"
        />
        <path
          d="M61.1565 88.3103H49.8754C49.7047 88.3101 49.5411 88.2422 49.4204 88.1215C49.2998 88.0008 49.2319 87.8372 49.2317 87.6666V77.6248C49.2319 77.4541 49.2998 77.2905 49.4204 77.1699C49.5411 77.0492 49.7047 76.9813 49.8754 76.9811H61.1565C61.3271 76.9813 61.4908 77.0492 61.6115 77.1699C61.7321 77.2905 61.8 77.4541 61.8002 77.6248V87.6666C61.8 87.8372 61.7321 88.0008 61.6115 88.1215C61.4908 88.2422 61.3271 88.3101 61.1565 88.3103Z"
          fill="#E6E6E6"
        />
        <path
          d="M100.21 38.8155H76.7082C76.3521 38.8155 76.0107 38.6741 75.7589 38.4223C75.5072 38.1705 75.3657 37.8291 75.3657 37.4731C75.3657 37.117 75.5072 36.7756 75.7589 36.5238C76.0107 36.272 76.3521 36.1306 76.7082 36.1306H100.21C100.566 36.1306 100.908 36.272 101.159 36.5238C101.411 36.7756 101.552 37.117 101.552 37.4731C101.552 37.8291 101.411 38.1705 101.159 38.4223C100.908 38.6741 100.566 38.8155 100.21 38.8155Z"
          fill="#CCCCCC"
        />
        <path
          d="M105.748 43.3463H76.7082C76.5319 43.3463 76.3573 43.3115 76.1944 43.2441C76.0316 43.1766 75.8836 43.0777 75.7589 42.9531C75.6343 42.8284 75.5354 42.6804 75.4679 42.5175C75.4004 42.3547 75.3657 42.1801 75.3657 42.0038C75.3657 41.8275 75.4004 41.653 75.4679 41.4901C75.5354 41.3272 75.6343 41.1792 75.7589 41.0546C75.8836 40.9299 76.0316 40.831 76.1944 40.7636C76.3573 40.6961 76.5319 40.6614 76.7082 40.6614H105.748C106.104 40.6614 106.445 40.8028 106.697 41.0546C106.949 41.3063 107.09 41.6478 107.09 42.0038C107.09 42.3599 106.949 42.7013 106.697 42.9531C106.445 43.2048 106.104 43.3463 105.748 43.3463Z"
          fill="#CCCCCC"
        />
        <path
          d="M71.5067 48.8369H49.84C49.6693 48.8367 49.5057 48.7689 49.385 48.6482C49.2644 48.5275 49.1965 48.3639 49.1963 48.1932V31.2836C49.1965 31.1129 49.2644 30.9493 49.385 30.8286C49.5057 30.708 49.6693 30.6401 49.84 30.6399H71.5067C71.6774 30.6401 71.841 30.708 71.9616 30.8286C72.0823 30.9493 72.1502 31.1129 72.1504 31.2836V48.1932C72.1502 48.3639 72.0823 48.5275 71.9616 48.6482C71.841 48.7689 71.6774 48.8367 71.5067 48.8369Z"
          fill="#6C63FF"
        />
      </svg>
    );
  }