import './Analytical.css'
import { products } from '../../../../mockdata/AllData';
import SelectIndicator from '../../../../Component/Select/Select';
import Cards from '../../../../Component/AdminComponent/cards/card';

function Analtical() {
  function handleChange(parm:string){

  }
  return <div className="Analtical">
    <div className="Analtical-info">
        <div className="Analtical-info-title">
            <div>Панель приборов</div>
            <div>Информация о вашем текущем плане и использовании</div>
        </div>
        <div className="Analtical-info-mounth">
            <SelectIndicator data={products} style={{
                width: "400px",
                height: "60px",
              }} value={''} name='' HandleChange={handleChange}/>
        </div>
    </div>
     <div className="Analtical-chart">
        <div className="Analtical-chart-static my-3">
            <Cards  count={3} value={'project'}/>
            <Cards  count={3} value={'project'}/>
            <Cards  count={3} value={'project'}/>
            <Cards  count={3} value={'project'}/>
        </div>
     </div>
  </div>;
}
export default Analtical;
