import './Styledocx.css'
import style from './style.module.css'
import { useEffect, useRef ,useState} from "react";
import { useReactToPrint } from "react-to-print";
import { RequestToken } from "src/Hook/Request/Request";
import {Recomadaction_type} from "src/pages/steps/steprecomdacion/indexType";
import moment from "moment";
import { user_object } from 'src/pages/steps/stepconclusion/indexType';
const space = (
    <>
      {" "}
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
      <p className="MsoNormal">
        <span lang="RU" style={{ fontSize: "12.0pt" }}>
          &nbsp;
        </span>
      </p>
    </>
  );
  const space_row = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
  interface props {
    orderID: string;
    setDownload: (download: boolean) => void;
  }
function Recomadaction({ orderID, setDownload }: props){
    const [res_data, setRes_data] = useState<Recomadaction_type[]>();
    const componentRef: any = useRef();
  async function getConclusion() {
    const response = await RequestToken(`/recommendation/${orderID}`, "GET", null);
    if (response.success) {
      setRes_data(response.object?.map((item:any,index:number)=>{
        return {
          orderNumber: item?.orderNumber,
          orderDate:moment(item?.orderDate).format('DD-MM-YYYY'),
          applicant:item?.applicant,
          schemeOfApplication:item?.schemeOfApplication,
          normativeNames:item?.normativeNames,
          productNames:item?.productNames,
          invoiceNumber: item?.invoiceNumber,
          invoiceDate:moment(item?.invoiceDate).format('DD-MM-YYYY'),
          deliveryNote:item?.deliveryNote,
          solutionNumber:item?.solutionNumber,
          programTesting:item?.programTesting,
          programChecking:item?.programChecking,
          actOfSelectionDate:moment(item?.actOfSelectionDate).format('DD-MM-YYYY'),
          testingProtocolNumber:item?.testingProtocolNumber,
          testingProtocolDate:moment(item?.testingProtocolDate).format('DD-MM-YYYY'),
          users:item?.users?.map((itemuser:{firstName:string,lastName:string,position:{name:string}})=>{return {firstName:itemuser.firstName,lastName:itemuser.lastName,position:itemuser.position?.name}}),
        }
      }))
      HandlePrint();
    }
  }
  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "РЕКОМЕНДАЦИИ",
    onAfterPrint:()=>setDownload(false)
  });
  useEffect(() => {
    getConclusion()
  }, []);
    return (
        <div className="h-auto" style={{width:"594px"}} ref={componentRef}>
            {
              res_data?.map((item:Recomadaction_type,index:number)=>{
                return (
                  <>    {space}
                  <div className="w-full " style={{height:'860px'}}>
                  <div className="w-full text-center analis_application text-base">ОРГАН ПО СЕРТИФИКАЦИИ </div>
                  <div className="w-full text-center italic font-semibold analis_application text-base">ОС ТСТ ГУП «UNICON.UZ»</div>
                  {space}
              <div className="w-full text-base analis_application text-center font-semibold">РЕКОМЕНДАЦИИ </div>
              <div className="w-full flex justify-center mb-4">
                <div className="w-full">
                   <div className="w-full text-center analis_application" style={{fontSize:'10px'}}>по результатам проведённого анализа данных, сформированных в процессе сертификации ТСТ
       по заявке за № {item?.orderNumber} от {item?.orderDate} г.
       </div>
                </div>
              </div>
              <div className="w-full flex justify-end my-2 analis_application" style={{fontSize:"10px"}}>г. Ташкент</div>
              <div className="w-full  analis_application" style={{fontSize:'10px'}}>{space_row}Дополнительная группа анализа ОС ТСТ рассмотрев комплект документов по заявке на проведение сертификации 
              (копия НД на производимую продукцию (при наличии), образец маркировки продукции или информация о продукции, копии товаросопроводительных 
              документов с отметкой о прибытии на таможенную территорию Республики Узбекистан (товарно-транспортная накладная, инвойс, счет-фактура) от заявителя: 
              <span className='block w-full h-7 flex justify-between' style={{borderBottom:'1px solid black'}}>
                <span className='w-3/5 flex items-end font-semibold text-center' style={{fontSize:'10px'}}>{item?.applicant}</span>
                <span className='w-2/5 flex items-end justify-end' style={{fontSize:'10px'}}> сообщает следующее: </span>
              </span>
              <span className='analis_application' style={{fontSize:'6px'}}>наименование заявителя</span>
              </div>
              <div className="w-full flex items-end h-8 underline analis_application" style={{fontSize:'10px'}}>- оценка соответствия была проведена по схеме сертификации № {item.schemeOfApplication}</div>
              <div className="w-full flex items-end h-8 underline analis_application" style={{fontSize:'10px'}}>- на соответствие требованиям НД: {item?.normativeNames?.map((item:string,index:number)=>{
               return (
                   <span style={{fontSize:'10px'}} className='font-semibold mx-2' key={index}>{item}</span>
               )
              })}</div>
              {
               item?.productNames?.map((item:string,index:number)=>{
                   return (
                       <span className="w-full flex items-end h-8 font-semibold underline analis_application" style={{fontSize:'10px'}} key={index}>{item}</span>
                   )
               })
              }
              <div className='w-full analis_application' style={{fontSize:'10px'}}>Согласно проведенного анализа документов и полученных результатов сертификации ТСТ:</div>
              <div className='w-full analis_application flex justify-end font-semibold' style={{fontSize:'10px'}}>Таблица</div>
              <table className='w-full'>
                <thead>
                   <tr>
                       <th className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>№ п.п.</th>
                       <th className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Наименование анализируемого документа</th>
                       <th className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>№ документа</th>
                       <th className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Обнаружения и наблюдения, выявленные в процессе анализа данных  </th>
                   </tr>
                </thead>
                <tbody>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>1</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Заявка на сертификацию</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>{item?.orderNumber}</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>2</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Прочие документы по сертификации, прилагаемые к заявке </td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Инвойс №{item?.invoiceNumber} от {item?.invoiceDate}г. CMR-накладная № {item?.deliveryNote}</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>3</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Решение по заявке <span className='italic analis_application' style={{fontSize:"10px"}}>(правильность выбора АИЛ с указанием наименования АИЛ, № реестра свидетельства об аккредитации)</span></td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>{item?.solutionNumber}</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>4</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Программа испытаний (правильность выбора и применения НД на требование к продукции и метода испытаний)</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>б/н</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>5</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Программа проверки условий производства (для схемы № 3)</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>-</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>-</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>6</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Акт идентификации и отбора образцов</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>{item?.actOfSelectionDate}</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>7</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Протокол (ы) испытаний <span className='italic' style={{fontSize:'10px'}}>(№ протокола и дата оформления)</span></td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>№ {item?.testingProtocolNumber}-Лб от {item?.testingProtocolDate}г</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>8</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Программа испытаний (правильность выбора и применения НД на требование к продукции и метода испытаний)</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>-</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>-</td>
                   </tr>
                   <tr>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>9</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>Проект сертификата соответствия</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>б/н</td>
                       <td className={`${style.table_five_td} analis_application`} style={{fontSize:'10px'}}>соответствует</td>
                   </tr>
                </tbody>
              </table>
              <div className="w-full analis_application my-2" style={{fontSize:"10px"}}>На основании проведённого анализа данных по сертификации Дополнительная группа анализа рекомендует 
       (не рекомендует) Сертификационной комиссии рассмотреть возможность выдачи сертификата соответствия на заявленную продукцию
       </div>   
               <div className="w-full italic analis_application mt-2" style={{fontSize:'10px'}}>В случае мотивированного отказа в выдаче сертификата соответствия Дополнительной группе анализа </div>   
                <div className="w-full flex">
                  <div className='w-1/3 h-5 flex items-end italic' style={{fontSize:'10px'}}>указать причину отказа в выдаче </div>
                  <div className='w-2/3 h-5 ' style={{borderBottom:'1px solid black'}}></div>
               </div> 
                  </div>
                  {space}
                  {space}
                  {space}
                  {space}
                  <div className="w-full" style={{height:'860px'}}>
                   <div className="w-full font-semibold mb-2 analis_application" style={{fontSize:'11px'}}>Руководитель:</div>
                   <div className="w-full flex">
                       <div className="w-1/2">
                           <span className='block h-5 w-32  analis_application' style={{borderBottom:'1px solid black'}}></span>
                           <span className='analis_application' style={{fontSize:'6px',marginTop:'-10px'}}>подпись</span>
                       </div>
                       <div className="w-1/2">
                           <span className='analis_application' style={{fontSize:'10px'}}>  Ж. Эльчиев</span>
                       </div>
                   </div>
                   <div className="w-full font-semibold mb-2 analis_application" style={{fontSize:'11px'}}>Группа анализа:</div>
                   {
                       item?.users?.map((itemUser:user_object,index:number)=>{
                           return (
                               <div className="w-full flex" key={index}>
                       <div className="w-1/2">
                           <span className='block h-5 w-32 analis_application' style={{borderBottom:'1px solid black'}}></span>
                           <span className='analis_application' style={{fontSize:'6px',marginTop:'-20px'}}>подпись</span>
                       </div>
                       <div className="w-1/2">
                           <span className='analis_application' style={{fontSize:'10px'}}>{itemUser.lastName} {itemUser.firstName}</span>
                       </div>
                   </div>
                           )
                       })
                   }
                  </div>
                  </>
                )
              })
            }      
        </div>
    )
}
export default Recomadaction;