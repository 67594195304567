import './StepSix.css'
import { useState,useEffect, useContext } from 'react';
import { useParams,useNavigate} from 'react-router-dom';
import { Input,Button ,Upload, Empty} from 'antd';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import AddBox from 'src/Component/data/addbox/addbox';
import DataPicker from 'src/Component/DatePickerFolder/DataPicker/DataPicker';
import {  dataStepSix,dataProductStepSix,dataStepSixType, dataProductStepSixGet} from "src/mockdata/AllData";
import { props ,Objects,data,  responseObject,productApplicationDTOList} from './StepSixDataType';
import TextArea from 'src/Component/textarea/textArea';
import SelectIndicator from 'src/Component/Select/Select';
import { RequestToken } from 'src/Hook/Request/Request';
import Swal from 'sweetalert2';
import Products from 'src/Component/data/product';
import moment from 'moment';
import { UserContext } from 'src/App';
function StepSix({type}:props){
    // function HandleChange(Data: string, name: string) {
    //     setData({ ...data, [name]: Data });
    //   }
    const {id}=useParams(); 
    const {setheaderTitle}=useContext(UserContext)
    const navigate=useNavigate();
     const [data,setdata]=useState<data>({
      orderId: id? parseInt(id) : 1,
      productApplicationDTOList: [
        {
          productIds: [],
          normativeIds: [],
          extraProtocol: "",
          summaryAttachmentId: 1
        }
      ]
    })
    const [responseData, setResponseData] = useState<responseObject>({
      addressOfApplicant:"",
      fullNameOfApplicant:"",
      dateOfApplication:'',
      nameOfApplicant:"",
      schemeOfCertification:'',
      productApplicationDTOListForGet:[
        {
          numberOfOrder: "",
          codeOfProduct: "",
          applicant:"",
          productNames: [],
          normativeNames: [],
          extraProtocol: "",
          summaryAttachmentId: 1
        }
      ]
    });
    const [order,setOrder] = useState<any>()
    const date_length=responseData.productApplicationDTOListForGet.length>0 ? responseData.productApplicationDTOListForGet[0].normativeNames.length:0
      function addProduct(){
         if(date_length===0 ){
          console.log('kirib ketdi')
          const newArray = [...data.productApplicationDTOList, {
            productIds: [],
            normativeIds: [],
            extraProtocol: "",
            summaryAttachmentId: 1
          }];
          setdata({ ...data, productApplicationDTOList: newArray });
        }
        else{
          Swal.fire({
            title:"Это заявление было завершено",

          })
        }
      }
      function removeproduct(id:number){
        let data1=data.productApplicationDTOList.filter((item,index)=>{if(index!==id){
          return item;
        }})
        setdata({...data,productApplicationDTOList:data1})
      }
      function HandleChange(Data: string, name: string) {
        setdata({ ...data, [name]: Data });
    
      }
      function Handle(Data:number[]|string[],name:string,id?:number){
        setdata({...data,productApplicationDTOList:data.productApplicationDTOList?.map((item,index)=>{
          if(index===id){
            (item as any)[name]=Data;
            return item;
          }
          else{
           return item;
         }
        })})
      }
      function RenderSwitch(
        parm: string,
        key: string,
        options?: Objects[] ,
        Id?:number
      ) {
        switch (parm) {
          case "input":
            return (
              <Input
                type="text"
                name={key}
                onChange={(e) => HandleChange(e.target.value, key)}
                style={{ width: "350px", height: "45px" }}
                placeholder=""
                required
                value={(responseData as any)[key]}
              />
            );
    
          case "date":
            return <DataPicker name={key} handleChange={HandleChange}  />;
          case "select":
            return (
              <SelectIndicator
                HandleChange={HandleChange}
                data={options}
                name={key}
                style={{ width: "350px", height: "45px" }}
                value={(responseData as any)[key]}
              />
            );
          case 'addbox':
              return <AddBox Handle={Handle} name={key} id={Id} value={(responseData as any)[key]} /> 
          case 'product':
              return <Products Handle={Handle} name={key}  id={id? parseInt(id):1} ID={Id} type={"number"} value={(responseData as any)[key]}/> 
          case "textarea":
            return <TextArea name={key} HandleChange={HandleChange} value={(responseData as any)[key]}/>;
          case 'upload':
              return <>
              <span className="mx-4">DOCX,PDF,DOC,PNG,JPEG,JPG</span>
              <Upload
                 action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                 listType="picture"
                 maxCount={1}>
                 <Button icon={<UploadOutlined />}>
                  Upload (Max: 1) 
                 </Button>
              </Upload>
              </> 
          default:
            break;
        }
        
      }

      function handleInputChange(parm:string,name:string,id?:number,type?:string){
        let newarray = data.productApplicationDTOList.map((item, index) => {
          if (index === id) {
            console.log(parm);
            item.extraProtocol = parm; // Qiymatni yangilash
          }
          return item; // Itemni qaytarish
        });
        setdata({ ...data, productApplicationDTOList: newarray });
      }
      function RenderProductSwitch(
        parm: string,
        key: string,
        Id:number,
        options?: Objects[] 
      ) {
     
        switch (parm) {
          case "protokol":
            return (
              <Input
                type="text"
                name={key}
                onChange={(e) => handleInputChange(e.target.value, key,Id)}
                style={{ width: "350px", height: "45px" }}
                placeholder=""
                required
                value={data.productApplicationDTOList[Id].extraProtocol}
              />
            );
           case "input":
            return (
              <Input
                type="text"
                name={key}
                onChange={(e) => handleInputChange(e.target.value, key,Id)}
                style={{ width: "350px", height: "45px" }}
                placeholder=""
                required
                value={(responseData.productApplicationDTOListForGet[date_length===0 ? 0:Id] as any)[key]}
              />
            );
            case 'product':
              return <Products Handle={Handle} name={key}  id={id? parseInt(id):1} ID={Id} type={"number"} 
              value={(responseData.productApplicationDTOListForGet[date_length===0 ? 0:Id] as any)[key]}/> 
          case "date":
            return <DataPicker name={key} handleChange={handleInputChange} id={Id}/>;
          case "select":
            return (
              <SelectIndicator
                HandleChange={HandleChange}
                data={options}
                name={key}
                style={{ width: "350px", height: "45px" }}
                value={(responseData.productApplicationDTOListForGet[date_length===0 ? 0:Id] as any)[key]}
              />
            );
            case 'addbox':
              return <AddBox Handle={Handle}  name={key} id={Id} value={(responseData.productApplicationDTOListForGet[date_length===0 ? 0:Id] as any)[key]} /> 
          case "textarea":
            return <TextArea name={key} HandleChange={HandleChange} 
            value={(responseData.productApplicationDTOListForGet[date_length===0 ? 0:Id] as any)[key]}/>;
          default:
            break;
        }
      }
      async function Save(){
       const resdata=await RequestToken("/applicationForCertificate","POST",data,undefined);
       if(resdata.success){
        Swal.fire({
          icon:'success',
          title: resdata.message,
          timer: 2500
        })
        navigate(`/home/${id}`)
       }
       else{
        Swal.fire({
          icon: 'error',
          title: resdata.message,
          timer: 2500
        })
       }
      }
      async function GetData(){
        const Data=await RequestToken(`/applicationForCertificate/${id}`,"GET",null);
        if(Data.success ){
          setResponseData(Data.object) 
        }
        else if(Data.response?.status===403){
          navigate("/")
        }
      }
      async function getOrder () {
        const response = await RequestToken(`/order/${id}`,"GET",null)
        if (response.success) {
          setOrder(response.object)
        }
        GetData()
      }
      useEffect(() => {
          getOrder()
          setheaderTitle("Заявка для сетификата")
      },[])
    return (
        <div className="StepThree_wrapper">
        {dataStepSix?.map((item:dataStepSixType,index) => {
          return (
            <>
              {item.hasOwnProperty("title") ? (
                <div className="StepThree_wrapper_row " key={item.id}>
                  <div className="StepThree_wrapper_row_title"></div>
                </div>
              ) : (
                <div className="StepThree_wrapper_row my-2" key={item.id}>
                  <div className="StepThree_wrapper_row_left">{item.label}</div>
                  <div className="StepThree_wrapper_row_right">
                    {type === "edit"
                      ? RenderSwitch(item.type, item.key,item.options)
                      :(responseData as any)[item.key] ? Array.isArray((responseData as any )[item.key])
                      ? (responseData as any )[item.key].map((item:any,index:number)=>{
                        return (
                          <div key={index}>{item}</div>
                        )
                      }):item.key==='dateOfApplication' ? moment((responseData as any)[item.key]).format('DD-MM-YYYY'):(responseData as any )[item.key]
                      :"нет в наличии"
                      }
                  </div>
                </div>
              )}
            </>
          );
        })}
        {
          responseData.productApplicationDTOListForGet.length>0 ?  
          responseData.productApplicationDTOListForGet?.map((item:any,index:number)=>{
            return (
              <div className="StepThree_wrapper_row product_wrapper_box" key={index}>
              <div className="product_wrapper_box_header step">
                <div>Продукт {index+1}</div>
                {type==="edit" ? <div onClick={()=>removeproduct(index)}><DeleteOutlined /></div>:""}  
              </div>
              { 
               (type==='edit' ? dataProductStepSix:dataProductStepSixGet).map((arrayItem,productIndex)=>{
                  return (
                    <div className="StepThree_wrapper_row my-2" key={productIndex}>
                    <div className="StepThree_wrapper_row_left">
                      {arrayItem.label}
                 
                    </div>
                    <div className="StepThree_wrapper_row_right">
                       {
                         type=='edit'? RenderProductSwitch(arrayItem.type,arrayItem.key,index) :item[arrayItem.key] ?Array.isArray(item[arrayItem.key]) ? item[arrayItem.key].map((stringItem:string)=>{
                          return (
                            <div className='w-full text-left'>{stringItem}</div>
                          )
                         }):arrayItem.key.includes('date') ? moment(item[arrayItem.key]).format('DD-MM-YYYY'):item[arrayItem.key]
                         :'Hет в наличии' 
                       }
                    </div>
                  </div>
                  )
                })
              } 
            </div>
            )
          }):<Empty/>
        }
      {
        type==="edit" ?  <>
        <div onClick={addProduct} className="add_tr w-full flex flex-wrap justify-center cursor-pointer">
        <span className="add_tr_span w-full text-4xl text-center" >
          <PlusCircleOutlined />
        </span>
        <div> Добавить новые ГОСТ</div>
      </div>
      <div className="StepThree_wrapper_row ">
          <div className="StepThree_wrapper_row_left"></div>
          <div className="StepThree_wrapper_row_right ">
            <Button style={{ height: "45px" }} className="px-5 mx-3">
              Отмена
            </Button>
            <Button
              type="primary"
              style={{ height: "45px" }}
              className="px-3"
              onClick={Save}
            >
              Cохранить
            </Button>
          </div>
        </div>
        </>:""
      }
       
      </div>
    )
}
export default StepSix;