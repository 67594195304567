import { useState, useEffect, useContext } from "react";
import { DataFiveType, Objects, props, normative } from "./StepFiveType";
import { DataStepFive, DataStepFive_GET } from "src/mockdata/AllData";
import DataPicker from "src/Component/DatePickerFolder/DataPicker/DataPicker";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { Input, Upload, Button, Select } from "antd";
import SelectIndicator from "src/Component/Select/Select";
import { useParams, useNavigate } from "react-router-dom";
import { RequestToken } from "src/Hook/Request/Request";
import Swal from "sweetalert2";
import moment from "moment";
import { UserContext } from "src/App";
const { TextArea } = Input;
const cleanObject = {
  normativeIds: [],
  numberOfDecision: "",
  dateOfDecision: "",
  schemeOfCertification: "",
  nameOfProduct: "",
  nameOfApplicant: "",
  decisionOfDistributionOrRefusal: false,
  reasonOfRefusing: "",
  orderId: 0,
};
function StepFive({ type, normativ_list, products_list,orderData}: props) {
  const { id } = useParams();
  const {setheaderTitle}=useContext(UserContext)
  const [product_List, setProduct_List] = useState<{label:string,value:number}[]>([]);
  const navigate = useNavigate();
  const [data, setData] = useState<DataFiveType>({
    normativeIds: [],
    numberOfDecision: "",
    dateOfDecision: "",
    schemeOfCertification: "",
    productIds: [],
    nameOfApplicant: "",
    decisionOfDistributionOrRefusal: true,
    reasonOfRefusing: "",
    orderId: parseInt(id||"1") ,
  });
  function HandleChange(param: string, name: string) {
    console.log(param + name);
    if (data) {
      setData({ ...data, [name]: param });
    }
  }
  function Handle(Data: string[] | number[], name: string) {
    setData({ ...data, [name]: [...Data] });
  }
  function RenderSwitch(type: string, key: string, options?: Objects[]) {
    switch (type) {
      case "input":
        return (
          <Input
            style={{ width: "350px", height: "45px" }}
            onChange={(e) => HandleChange(e.target.value, key)}
            value={(data as any)[key as keyof DataFiveType]}
          />
        );
      case "select":
        return (
          <SelectIndicator
            HandleChange={HandleChange}
            data={options}
            style={{ width: "350px", height: "45px" }}
            name={key}
            value={(data as any)[key]}
          />
        );
      case "addbox":
        return (
          <Select
            mode="multiple"
            options={normativ_list?.map((item: normative) => {
              return { label: item.label, value: item.label };
            })}
            value={data.normativeIds}
            style={{ width: "350px"}}
          />
        );
      case "date":
        return <DataPicker name={key} handleChange={HandleChange} />;
      case "product":
        return (
          <Select
            onChange={(value:string[])=>Handle(value,key)}
            mode="multiple"
            style={{ width: "350px"}}
            options={product_List}
            value={(data as any)[key]}
          />
        );
      case "textarea":
        return (
          <TextArea
            onChange={(e) => HandleChange(e.target.value, key)}
            style={{ width: "350px", height: "125px" }}
            disabled={data.decisionOfDistributionOrRefusal}
          />
        );
      case "upload":
        return (
          <>
            <span className="mx-4">DOCX,PDF,DOC,PNG,JPEG,JPG</span>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </>
        );
      default:
        break;
    }
  }
  async function Save() {
    const newdata = {
      productIds:[...data.productIds],
      decisionOfDistributionOrRefusal: data.decisionOfDistributionOrRefusal,
      reasonOfRefusing: data.reasonOfRefusing,
      orderId: data.orderId,
    };
    const Data = await RequestToken("/decision", "POST", newdata);
    if (Data.success) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "malades yashavor",
      });

      navigate(`/home/${id}`);
    } else {
      Swal.fire({
        icon: "error",
        title: "Ошибка",
        text:Data.response?.data?.message||Data.response?.message,
      });
    }
  }
  async function getData() {
    const Data = await RequestToken(`/decision/${id}`, "GET", null);
    if (Data.success) {
      setData({ ...data,productIds:Data.object?.productNames,dateOfDecision:moment(Data.object?.solutionDate).format('DD-MM-YYYY'),
        decisionOfDistributionOrRefusal:Data.object?.decisionOfDistributionOrRefusal,normativeIds:Data.object?.normativeNames,
      nameOfApplicant:Data.object?.nameOfOrganization,numberOfDecision:Data.object?.solutionNumber,reasonOfRefusing:Data.object?.reasonOfRefusing,
      schemeOfCertification:Data.object?.schemeOfCertification});
    }
  }
  async function get_solution() {
    const response_applicant = await RequestToken(
      `/solution/withOrderDetail/${id}`,
      "GET",
      null
    );
    if(response_applicant.success){
    setData({...data,numberOfDecision:response_applicant.object?.number,
      nameOfApplicant:response_applicant.object?.order?.applicant,normativeIds:response_applicant.object?.nameOfDocument?.map((item:{normativeNumber:string})=>{return item.normativeNumber}),dateOfDecision:moment(response_applicant.object?.date).format('DD-MM-YYYY'),schemeOfCertification:response_applicant.object?.order.schemeCertification})
   
      setProduct_List(response_applicant.object?.order?.products?.map((item:{name:string,id:number})=>{return {label:item.name,value:item.id}}))
    }
    else{
      Swal.fire({
        title:"Ошибка",
        text:"заполните решение",
        icon:"error"
      })
    }
  }
  useEffect(() => {
    if (type !== "edit") {
      getData();
    } else if(type==='edit'){
      get_solution()
    }
    setheaderTitle('Решения о выдаче')
  }, []);
  return (
    <div className="StepThree_wrapper">
      {DataStepFive?.map((item) => {
        return (
          <>
            {item.hasOwnProperty("title") ? (
              <div className="StepThree_wrapper_row" key={item.id}>
                <div className="StepThree_wrapper_row_title">{item.title}</div>
              </div>
            ) : (
              <div className="StepThree_wrapper_row" key={item.id}>
                <div className="StepThree_wrapper_row_left">{item.label}</div>
                <div className="StepThree_wrapper_row_right">
                  {type === "edit"
                    ? RenderSwitch(item.type, item.key, item.options)
                    : data[item.key as keyof DataFiveType]
                    ? Array.isArray(data[item.key as keyof DataFiveType])
                      ? (data as any)[item.key as keyof DataFiveType].map(
                          (val: string) => {
                            return (
                              <div className="formlist_wrapper_row_right_gost">
                                {val}
                              </div>
                            );
                          }
                        )
                      : item.key.toLocaleLowerCase().includes('decisionOfDistributionOrRefusal'.toLocaleLowerCase()) ? 
                      data.decisionOfDistributionOrRefusal ? <span className="text-[#4096ff]">Продолжать</span>:<span className="text-[red]">Отказ</span> :data[item.key as keyof DataFiveType]
                    : "нет в наличии"}
                </div>
              </div>
            )}
          </>
        );
      })}
      <div className={type === "edit" ? "StepThree_wrapper_row " : "hidden"}>
        <div className="StepThree_wrapper_row_left"></div>
        <div className="StepThree_wrapper_row_right ">
          <Button style={{ height: "45px" }} className="px-5 mx-3">
            Отмена
          </Button>
          <Button
            type="primary"
            style={{ height: "45px" }}
            className="px-3"
            onClick={Save}
          >
            Cохранить
          </Button>
        </div>
      </div>
    </div>
  );
}
export default StepFive;
