import "./style.css";
import { UserContext } from "src/App";
import { useContext, useEffect, useState } from "react";
import {Dropdown, Empty,Modal } from "antd";
import type { MenuProps } from 'antd';
import Wizzard from "src/Component/wizzard";
import UpdateModal from "src/Component/Modal/UpdateModal";
import Navbar from "../../../Component/navbar";
import { RequestToken } from "src/Hook/Request/Request";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import style from 'src/Component/Laborotry/Table/style.module.css'
import {EyeOutlined,EditOutlined } from "@ant-design/icons";
import Loading from "src/Component/Loading/Loading";
function ListRegis() {
  const navigate=useNavigate()
  const _role=localStorage.getItem("_role")
  const { setheaderTitle } = useContext(UserContext);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [showWizzard, setShowWizzard] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<boolean|null>(null);
  const [applicantId,setApplicanId]=useState<number>()
  const [loading,setLoading]=useState(true)
  const [solution_list,setSolution_List]=useState<{label:string,value:string}[]>([])
  const [listRegisData,setListRegisData]=useState<any>([])
  const [StatmentByNumber,setStatmentByNumber]=useState<{[index:string]:unknown}>({})
  const items:MenuProps['items'] =[
    {
      label: <div onClick={()=>setOpenModal(true)}><span className="text-base mr-2"><EyeOutlined /></span>Просмотр</div>,
      key: '0',
    },
    {
      label: <span onClick={()=>Edit_List_regis('sdjk')} className={'my-2'}><span className="text-base mr-2"><EditOutlined /></span>Изменять</span>,
      key: '1',
    },
  ]
  const  SearchSolutionNumber = async (parm:string) => {
    setLoading(false)
    if(parm){
      const responseData=await RequestToken(`/solution/byNumber/${parm}`,"GET",null)
      if(responseData.success){
        setShowWizzard(true)
        setIsOpenModal(false)
        setLoading(true)
        setStatmentByNumber({...responseData.object})
      }
      else if(responseData.response?.status===404){
        console.log(responseData)
        Swal.fire({
          title: `${responseData.response.data.message}`, 
          icon:'error' 
        })
      }
      else{
        setLoading(true)
        Swal.fire({
          title: `${responseData?.response?.data?.message||responseData?.response?.message}`, 
          icon:'error' 
        })
      }
    }
  };
  async function GetJournalData(){
    setLoading(true)
    const responseData=await RequestToken(`/journal/?page=${0}&size=${100}`,"GET",null)
   if(responseData.success){
    setListRegisData(responseData.object)
    setLoading(true)
   }
   else if(responseData.response?.status===401 || responseData.response?.status===403){
      navigate('/')
   }
   else {
    setLoading(true)
    Swal.fire({
      title:'error'
    })
   }
  }
  const Edit_List_regis=(parm:string)=>{
    SearchSolutionNumber(parm)
  }
  async function get_solution_number_list(){
    const res_solution_number_list=await RequestToken('/solution','GET',null)
    if(res_solution_number_list.success){
      setSolution_List(res_solution_number_list.object?.map((item:{applicant:string,solutionNumber:string})=>{return {label:("Нумер решения: "+item.solutionNumber+" "+" Заявитель: "+item.applicant),value:item.solutionNumber}}))   
    }
  }
 
  function ChangeTabName(tabname:string){
    if(tabname==='Все'){
      setFilterType(null)
    }
    else if(tabname==='Принятые'){
      setFilterType(false)
    }
    else if(tabname==='Отменённые'){
      setFilterType(true)
    }
  }
  useEffect(() => {
    GetJournalData()
    get_solution_number_list()
   setheaderTitle("Журнал регистрации ТСТ");
 }, []);
  return (
  <div  className="w-full h-full flex items-center justify-center">
    {
       loading ? <>
        <UpdateModal solution_list={solution_list} listkey={['number_one','number_two']} Save={SearchSolutionNumber} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal}/>
        {showWizzard ? (
          <Wizzard GetJournalData={GetJournalData} setShowWizzard={setShowWizzard} statmentByNumber={StatmentByNumber} />
        ) : (
          <>
            <div className="w-full h-full   list_regis_wrapper">
              <div className=" list_regis_wrapper_header">
                <Navbar title="Журнал регистрации приема и выдачи образцов сертифицируемых ТСТ " data={null} setOpenModal={setIsOpenModal} showTabs={true} showModal={_role==='EMPLOYEE_LABORATORY' ? true:false}
                showTitle={true} setTabName={ChangeTabName}/>  
               </div> 
              <div className="w-full list_regis_wrapper_table overflow-auto">
                <table className={style.table}>
                  <thead>
                    <tr className={style.tr}>
                      <th className={style.th}>Рег.№ п/п</th>
                      <th className={style.th}>Тип изделия</th>
                      <th className={style.th}>Фирма производитель</th>
                      <th className={style.th}>Заявитель</th>
                      <th className={style.th}>Получатель</th>
                      <th className={style.th}>Дата и время поступления образцов</th>
                      <th className={style.th}>Статус</th>
                      {/* <th className={style.th}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                       listRegisData?.filter((item:any)=>{
                        if(filterType===null){
                          return item
                        }
                        else{
                          if(item.isAccepted===filterType){
                            return item
                          }
                        }
                      })?.length>0 ?  listRegisData?.filter((item:any)=>{
                        if(filterType===null){
                          return item
                        }
                        else{
                          if(item.isAccepted===filterType){
                            return item
                          }
                        }
                      })?.map((item:any,index:number)=>{
                        return (
                          <tr className={style.td} key={index}>
                            <td className={style.td}>{index+1}</td>
                            <td className={style.td}>{item.typeOfProduct}</td>
                            <td className={style.td}>{item.nameOfProduct}</td>
                            <td className={style.td}>{item.nameOfOrganization}</td>
                            <td className={style.td}>{item.nameOfReceivingPerson}</td>
                            <td className={style.td}>{moment(item.dateOfIssuing).format('DD-MM-YYYY HH:MM:SS')}</td>
                            <td className={style.td}><div className={item.isAccepted ? `${style.status}  bg-[#EB4410]`:`${style.status} bg-[#35D361]`}>{item.isAccepted ? "Отмена":"Принято"}</div></td>
                            {/* <td className={style.td}><span className="text-2xl cursor-pointer" onClick={()=>Edit_List_regis(item.solutionNumber)}><EditOutlined/></span></td> */}
                          </tr>
                        )
                      }):<tr>
                        <td colSpan={8} className="text-center"><Empty description="Нет в наличии"/></td>
                      </tr>
                    }
                  </tbody>
                </table>
                {/* <LaborotoryTable datarows={['Тип изделия','Фирма производитель','Заявитель','Получатель','Дата и время поступления образцов','Статус','more']} data={listRegisData}/> */}
              </div>
            </div>
          </>
        )}
      </>:<Loading/>
    }
  </div>
  );
}
export default ListRegis;
