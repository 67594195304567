import './newuser.css'
import  {useState,useEffect, useContext} from 'react';
import { EyeInvisibleOutlined, EyeTwoTone,EditOutlined,DeleteOutlined} from '@ant-design/icons';
import { Button, Modal, Form, Input, Select, notification } from 'antd';
import { RequestToken } from 'src/Hook/Request/Request';
import Swal from 'sweetalert2';
import TableUsers from "src/Component/AdminComponent/Table/Table";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {TranslateErrorMessage} from 'src/lang/translateErrorMessage';
import {translateUtil} from 'src/lang/translationUtils'
import { UserContext } from 'src/App';
import Loading from 'src/Component/Loading/Loading';
interface response_user_list {
  id:number,
  firstName:string,
  lastName:string,
  username:string,
  create:string,
  roles:{name:string}
  position:{name:string,isActive:boolean}|null
}
interface res_user_list {
  id:number,
  firstName:string,
  lastName:string,
  username:string,
  create:string,
  roles:string
  position:string|null
}
interface user_list{
  id:number,
  username:string
  create:string
  roles:string
  position:string
}
type newUser_type = {
  firstName:string,
  lastName:string,
  username: string;
  password: string;
  role:string; 
  positionId:number|string|null
};
const dataheader=[
  {title:'№',dataIndex:'id',width:50,maxWidth:400},  
  {title:'Имя',dataIndex:'firstName',width:350,maxWidth:500}, 
  {title:'Фамилия',dataIndex:'lastName',width:350,maxWidth:500}, 
  {title:'Имя пользователя',dataIndex:'username',width:350,maxWidth:500}, 
  {title:'Роли',dataIndex:'roles',width:350,maxWidth:500},    
  {title:'Cоздавать время',dataIndex:'create',width:350,maxWidth:500},    
  {title:'Позиция',dataIndex:'position',width:350,maxWidth:500},    
]
const roles=[
   {
     label:"Уникон ",
     value:"ORGAN"
   },
   {
     label:"Соҳибкор",
     value:"SOHIBKOR"
   },
   {
    label:"Уникон Админ",
    value:"ADMIN_ORGAN"
  },
  {
    label:"Соҳибкор Админ",
    value:"ADMIN_LABORATORY"
  }
  ]
const Newuser = () => {
  const navigate=useNavigate()
  const {setheaderTitle}=useContext(UserContext)
  const [api, contextHolder] = notification.useNotification();
  const [loading,setLoading]=useState<boolean>(false)
  const [search,setSearch]=useState<string>('')
  const [update_user_id,setUpdate_user_id]=useState<number|null>(null)
  const [isopenModal,setIsOpenModal]=useState<boolean>(false)
  const [position_list,setPosition_List]=useState<{label:string,value:number}[]>([])
  const [newuser,setNewUser]=useState<newUser_type>({
    firstName: "",
    lastName: "",
    username:'',
    password:'',
    role:'',
    positionId:0,
  })
  const [user_list,setUser_list]=useState<res_user_list[]>([])
  function UpdateModal(id:number){
    setUpdate_user_id(id)
    const update_user=user_list.find((item:res_user_list)=>item.id===id)
    console.log(update_user)
    if(update_user ){
      const positionID=position_list.find((item:{label:string,value:number})=>item.label===update_user.position)?.value
    if(positionID){
      setNewUser({firstName:update_user.firstName,
        lastName:update_user.lastName,
        username:update_user.username,
        password:'',
        role:translateUtil(update_user.roles),
        positionId:positionID
      })
    }
    else{
      setNewUser({firstName:update_user.firstName,
        lastName:update_user.lastName,
        username:update_user.username,
        password:'',
        role:translateUtil(update_user.roles),
        positionId:''
      })
      
    }
    setIsOpenModal(true)
    }
  }
  function Search(value:string){
    console.log(value)
   const new_user_list=[...user_list]
   if(value){
    const search_value=value?.toLowerCase()
    console.log(search_value)
    const result_users_list=user_list.filter((item:res_user_list)=>{
      if(item.username===search_value){
        return item
      }
     })
     setUser_list(result_users_list)
   }
   setUser_list(new_user_list)
  }
  function DeleteNotifacion(id:number) {
    Swal.fire({
      icon: "warning",
      title: "Вы хотите удалить это значение по умолчанию?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: `Отмена`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Delete(id)
      } 
    });
  }
  const Save =async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(newuser.password[0]!==' '  && newuser.username[0]!==' ' && newuser.role && newuser.positionId && newuser.firstName[0]!==' ' && newuser.lastName[0]!==' '){
         setLoading(false)
        const Data=await RequestToken(update_user_id===null ? "/api/auth/register":`/user/${update_user_id}`,update_user_id===null ? "POST":"PUT",newuser)
        if(Data.success){
        GetData()
        Cancel()
          setIsOpenModal(false)
            Swal.fire({
             icon:"success",
             title:"Сохранено",
             text:"Hовый  сотрудник успешно добавлен"
            })
        }
        else{
          setLoading(true)
            Swal.fire({
                icon:"error",
                title:"Ошибка",
                text:TranslateErrorMessage(Data.response.data.message),
               })
        }
       }
       else{
        let error_message:string=''
        if(!newuser.username){ error_message=' Имя пользователя'}
        else if(!newuser.password){ error_message=' Пароль'}
        else if(!newuser.role){ error_message=' Роли'}
        api.warning({
          message: 'Ошибка',
          description:<div>Вы оставили <span className='text-[red] font-medium '>{error_message}</span>пустым</div>,
          duration: 2,
        });
       }
      };
   
  function Cancel(){
    setNewUser({
      firstName: "",
      lastName: "",
      username:'',
      password:'',
      role:'',
      positionId:0
    })
    setUpdate_user_id(null)
    setIsOpenModal(false)
  }
  async function Delete(id:number){
    const res_user_list=await RequestToken(`/user/${id}`,"DELETE",null)
    if(res_user_list.success){
      Swal.fire('Удалено',res_user_list?.message,'success')
    }else{
      Swal.fire({title:"Ошибка",text:res_user_list.response?.data?.message||res_user_list?.response?.message,icon:'error'})
    }
    GetData()
  }
  async function Get_Position_List(){
    const response_position_list=await RequestToken('/position?page=0&size=100',"GET")
    if(response_position_list.success){
      setLoading(true)
      setPosition_List(response_position_list.object.map((item:{id:number,name:string})=>{return {label:item.name,value:item.id}}))
    }
  }
  async function GetData(){
    setLoading(false)
    const responseData=await RequestToken("/user/getUsersWithoutSuperAdmin","GET",null)
    if(responseData.success){
      Get_Position_List()
      setUser_list(responseData.object.map((item:response_user_list,index:number)=>{
        return {
          id:item.id,
          firstName:item.firstName,
          lastName:item.lastName,
          username:item.username,
          roles:item.roles?.name,
          create:moment(item.create).format("DD-MM-YYYY"),
          position:item.position===null ? "Нет в наличии":item.position.isActive ? item.position.name:'Эта позиция больше не существует, ее нужно изменить.'
        }
      }))
    }
    else if(responseData.response?.status===403 || responseData.response?.status===401){
      navigate('/')
    }
    else{
      Swal.fire({
        title:responseData.message,
        icon:'error'
      })
    }
  }
  useEffect(()=>{
    GetData()
    setheaderTitle('Сотрудники органа по сертификации телекоммуникационного оборудования')
  },[])
  console.log(user_list)
  return (
    <div className="Newuser_wrapper bg-[#fff]" style={{borderRadius:'25px'}}>
      {contextHolder}
     {loading ?  <div className="w-full h-full ">
      <div className="newuser_wrapper_header w-full px-7" >
        <div style={{width:'80%'}} className='h-full flex justify-start newuser_wrapper_header'>Список сотрудников<Input className='w-96 mx-5' placeholder='Поиск...' onChange={(e)=>setSearch((e.target.value)?.toLowerCase())} allowClear style={{height:'50px'}}/></div> 
        <div style={{width:'20%'}} className='h-full flex items-center justify-end'>
        <Button onClick={()=>setIsOpenModal(true)} style={{height:'50px'}} type='primary'>Добавьте нового пользователя</Button> 
        </div>
       </div>
       <div className="Newuser_wrapper_body">
       <div className="w-full flex pl-7 Newuser_wrapper_body_child justify-center" >
       <table className='w-full h-auto'>
       <thead>
         <tr>
           <th className='p-3 text-left bg-[#F2F4F8]'>№</th>
           <th className='p-3 text-left bg-[#F2F4F8]'>ИМЯ</th>
           <th className='p-3 text-left bg-[#F2F4F8]'>ФАМИЛИЯ</th>
           <th className='p-3 text-left bg-[#F2F4F8]'>ИМЯ ПОЛЬЗОВАТЕЛЯ</th>
           <th className='p-3 text-left bg-[#F2F4F8]'>РОЛИ</th>
           {/* <th className='p-3 text-left bg-[#F2F4F8]'>CОЗДАВАТЬ ВРЕМЯ</th> */}
           <th className='p-3 text-left bg-[#F2F4F8]'>ПОЗИЦИЯ</th>
           <th className='p-3 text-left bg-[#F2F4F8]'></th>
           <th className='p-3 text-left bg-[#F2F4F8]'></th>
         </tr>
       </thead>
       <tbody>
         {
           user_list?.filter((item:res_user_list)=>{
            if(item.firstName?.toLowerCase().includes(search) || item.lastName?.toLowerCase().includes(search) || item.username?.toLowerCase().includes(search)){
              return item
            }
           }).map((item:res_user_list,index:number)=>{
             return (
               <tr key={index}>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{index+1}</td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{item.firstName}</td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{item.lastName}</td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{item.username}</td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{translateUtil(item.roles)}</td>
           {/* <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{item.create}</td> */}
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}>{item.position}</td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}><span className='p-3 cursor-pointer text-2xl' onClick={()=>UpdateModal(item.id)}><EditOutlined /></span></td>
           <td className='bg-[#fff] p-3' style={{border:'1px solid #DDE1E6'}}><span className='p-3 cursor-pointer text-2xl text-[red]' onClick={()=>DeleteNotifacion(item.id)}><DeleteOutlined/></span></td>
         </tr>
             )
           })
         }
       </tbody>
      </table>
       </div>
       </div>
      </div>:<Loading/>
     }
      <Modal
      centered 
      footer={null}
      open={isopenModal}
      onCancel={Cancel}>
         <form action="sumbit" onSubmit={Save}>
          <div className="w-full mb-3 text-2xl font-semibold">{update_user_id===null ? "Добавьте нового пользователя":"Изменить информацию о сотруднике"}</div>
          <label htmlFor="firstName" className='w-full text-lg my-1'>Имя</label>
          <Input required value={newuser.firstName} id='firstName' className='w-full h-11' onChange={(e)=>setNewUser({...newuser,firstName:e.target.value.replace(/[^a-zA-Z]/g, '')})}/>
          <label htmlFor="lastName" className='w-full text-lg my-1'>Фамилия</label>
          <Input required value={newuser.lastName} id='lastName' className='w-full h-11' onChange={(e)=>setNewUser({...newuser,lastName:e.target.value.replace(/[^a-zA-Z]/g, '')})}/>
          <label htmlFor="username" className='w-full text-lg my-1'>Имя пользователя</label>
          <Input required value={newuser.username} id='username' className='w-full h-11' onChange={(e)=>setNewUser({...newuser,username:e.target.value})}/>
          <label htmlFor="password" className='w-full text-lg my-1'>Пароль</label>
          <Input.Password required value={newuser.password} id='password' className='w-full h-11' iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={(e)=>setNewUser({...newuser,password:e.target.value})}/>
          <label htmlFor="roles" className='w-full text-lg my-1'>Роли</label>
          <Select id='roles' value={newuser.role} className='w-full h-11 mb-3' options={roles} onChange={(value:string) => setNewUser({ ...newuser, role: value })}/>
          <label htmlFor="position" className='w-full text-lg my-1'>Позиция</label>
          <Select id='position' value={position_list.find((item:{label:string,value:number})=>item.value===newuser.positionId)?.label} className='w-full h-11 mb-3' options={position_list.map((item:{label:string,value:number})=>{return {label:item.label,value:item.label}})} onChange={(value:string) => setNewUser({ ...newuser, positionId:position_list.find((item:{label:string,value:number})=>item.label===value)?.value||1 })}/>
          <div className="w-full flex justify-end">
            <button className='px-5 py-2 border-2 border-[#ccc] rounded-md cursor-pointer' type='button' onClick={Cancel}>Отмена</button>
            <input type="submit"  className='px-5 py-2 ml-2 text-[#fff] bg-[#4096ff] rounded-md cursor-pointer' id='sumbit' value={'Сохранить'}/>
          </div>
         </form>
      </Modal>
    </div> 
  )
  };

export default Newuser;