import {useContext, useEffect,useState} from 'react'
import Swal from "sweetalert2";
import { RequestToken } from '../../../../Hook/Request/Request';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Empty, Modal,Input} from 'antd';
import { UserContext } from 'src/App';
import { translateUtil } from 'src/lang/translationUtils';
import Loading from 'src/Component/Loading/Loading';
function Laboratory() {
  const navigate=useNavigate()
  const {setheaderTitle}=useContext(UserContext)
  const [count,setCount]=useState<number>(10)
  const [loading,setLoading]=useState<boolean>(true)
  const [openModal,setOpenmodal]=useState<boolean>(false)
  const [fulldaata,setFullData]=useState<any>()
  const [data,setdata]=useState([])
  function OpenModal(parm: boolean) {
    Swal.fire({
      title: "info",
      text: "Do you want to continue",
      icon: "info",
      confirmButtonText: "Ok",
    });
  }
  async function SearchApplicant(parm:string,type?:string){
    if(parm){
      const response=await RequestToken(`/order/byApplicant/${parm}`,"GET",null)
      if(response.success){
        setdata(response.object)
      }
    }
    else{
      GetData()
    }
  }
    async function GetData(){
      setLoading(false)
      const responseData=await RequestToken("/order/acceptedOrders?page=0&size=100",'GET',null)
      if(responseData.success){
        setdata(responseData.object)
        setLoading(true)
      }
      else if(responseData.response?.status===403 || responseData.response?.status===401){
        navigate('/')
      }
      else{
        setLoading(true)
      }
    }
    async function GetAction(id:number){
      setCount(id)
      const response=await RequestToken(`/order/byAction/${id}`,"GET",null)
      if(response.success){
        setFullData(response.object)
        setOpenmodal(true)
      }
      else{
        Swal.fire({
          title:"Ошибка",
          text:response?.response?.data?.message||response?.response?.message,
          icon:'error'
        })
      }
    }
    useEffect(()=>{
      GetData()
      setheaderTitle('Мониторинг сотрудников')
    },[])
    return (
      <div className="w-full h-full">
        {
          loading ? <div className="dashboard-super-Admin">
          <Modal open={openModal} footer={null} cancelText={null} centered onCancel={()=>setOpenmodal(false)}>
            <div className="w-full text-xl font-bold "> Полная информация</div>
            <div className="w-full mt-5">
                  <div className="w-full flex my-3">
                  <div className="w-1/2">Имя заявителя</div>
                  <div className="w-1/2">{fulldaata?.order?.applicantFullName}</div>
                  </div>
                  <div className="w-full flex my-3">
                  <div className="w-1/2">Имя адреес</div>
                  <div className="w-1/2">{fulldaata?.order?.applicantAddress}</div>
                  </div>
                  <div className="w-full flex my-3">
                  <div className="w-1/2">Должность сотрудника</div>
                  <div className="w-1/2">{fulldaata?.order?.user?.map((item:{firstName:string,lastName:string,position:{name:string}})=>{
                    return (
                      <div className='w-full my-2'>
                         {item?.position?.name}
                      </div>
                    )
                  })}</div>
                  </div>
                  <div className="w-full flex my-3">
                  <div className="w-1/2">Процесс</div>
                  <div className="w-1/2">{translateUtil(fulldaata?.actionEnum)}</div>
                  </div>
                </div>
                <div className="w-full my-4 flex justify-end">
                  <button onClick={()=>setOpenmodal(false)} className='px-3 py-2 rounded-md text-[#fff] bg-[#1677FF]'>Понятно</button>
                </div>
          </Modal>
          <div className="dashboard-super-Admin-title">
            <div>
              {/* Mониторинг */}
            </div>
            <div style={{ width: "400px", height: "50px"}}>
            <Input.Search placeholder="Поиск" onChange={(e)=>SearchApplicant(e.target.value)} className="h-11 " style={{width:"260px"}}/>
            </div>
          </div>
          <div className="flex items-center dashboard-super-admin-body justify-end" style={{width:'100%'}}>
            <table style={{width:"100%"}}>
              <thead>
                <tr>
                  <th style={{border:'1px solid #DDE1E6',width:'50px'}} className='bg-[#F2F4F8] p-3'>№</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Номер заявки</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Компания</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Сотрудник орган сертификации</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Oставшеесявшееся время</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Сотрудник Лаборатория</th>
                  <th style={{border:'1px solid #DDE1E6'}} className='bg-[#F2F4F8] p-3'>Заявитель</th>
                  <th style={{border:'1px solid #DDE1E6',width:'50px'}} className='bg-[#F2F4F8] p-3'></th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.length>0 ? data?.map((item:any,index:number)=>{
                    return (
                      <tr key={index}>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{index+1}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.numberOrder}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.company}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.user?.filter((item:{roles:{name:string}})=>item?.roles?.name==='EMPLOYEE_AGENCY')?.map((item:{firstName:string,lastName:string},index:number)=>{
                    return (
                      <div key={index}>{item?.lastName} {item?.firstName}</div>
                    )
                  })}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.days>0 ?  `${item?.days} осталось дня`:`Прошло ${(item?.days)*(-1)} дня`}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.user?.filter((item:{roles:{name:string}})=>item?.roles?.name==='EMPLOYEE_LABORATORY')?.map((item:{firstName:string,lastName:string},index:number)=>{
                    return (
                      <div key={index}>{item?.lastName} {item?.firstName}</div>
                    )
                  })}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'>{item?.applicant}</td>
                  <td style={{border:'1px solid #DDE1E6'}} className='bg-[#fff] p-3'><span className='text-xl cursor-pointer' onClick={()=>GetAction(item.id)}>...</span></td>
                </tr>
                    )
                  }):<tr>
                    <td colSpan={8} className='text-center'><Empty description="Нет в наличии"/></td>
                  </tr>
                }
              </tbody>
            </table>
            
          </div>
        </div>:<Loading/>
        }
      </div>
    );
}
export default Laboratory